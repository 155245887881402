import { detect } from 'detect-browser';

function createTempFrom(html) {
  const temporaryDiv = document.createElement('div');
  temporaryDiv.innerHTML = html;
  return temporaryDiv;
}
function getHTMLText(html) {
  const temporaryDiv = createTempFrom(html);
  return temporaryDiv.textContent;
}
function getPDFLinks(html) {
  const temp = createTempFrom(html);
  const pdfLinks = [];
  Array.from(temp.querySelectorAll('a')).forEach((link) => {
    if (link.href.match(/.pdf/)) {
      pdfLinks.push(link.href);
    }
  });
  return pdfLinks;
}

function getHTMLCharts(html) {
  const temp = createTempFrom(html);
  const charts = [];
  Array.from(temp.querySelectorAll('a')).forEach((link) => {
    if (link.href.match(/.png/)) {
      charts.push(link.href);
    }
  });
  if (charts.length > 0) {
    return charts;
  }
  return null;
}

function replacePDFLinks(html) {
  if (detect().name === 'ie') {
    return html;
  }
  const temp = createTempFrom(html);
  Array.from(temp.querySelectorAll('a')).forEach((link) => {
    if (link.href.match(/.pdf/) && link.querySelectorAll('img').length > 0 && link.href.match(/\/wp-content\/uploads\//)) {
      const pdf = document.createElement('iframe');
      const gembed = `https://docs.google.com/gview?url=${link.href}&embedded=true`;
      pdf.title = link.href;
      pdf.src = gembed;
      pdf.width = '100%';
      pdf.style = 'height: calc(100vh - 140px)';
      link.parentNode.replaceChild(
        pdf,
        link,
      );
    }
  });
  return temp.innerHTML;
}

function injectTickerLinks(html, tickers = ['AMZN', 'MSFT', 'GOOG'], tickerPath = 'http://localhost:3000/ticker/') {
  const regex = /\(([A-Z/.]{1,16})\)/;
  let outputHTML = html;
  let hasMatch = regex.test(outputHTML);
  while (hasMatch) {
    if (tickers.includes(regex.exec(outputHTML)[1])) {
      outputHTML = outputHTML.replace(regex, `(<a href=${tickerPath}$1>$1</a>)`);
    } else {
      outputHTML = outputHTML.replace(regex, '(<span>$1</span>)');
    }
    hasMatch = regex.test(outputHTML);
  }
  return outputHTML;
}

export {
  getHTMLText,
  getHTMLCharts,
  getPDFLinks,
  replacePDFLinks,
  injectTickerLinks,
};
