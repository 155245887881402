import {
  USER_STAR_POST,
  USER_UNSTAR_POST,
  USER_HYDRATE,
  USER_SET_PORTFOLIOS,
  USER_SET_TICKER_CALLS,
  USER_SET_SETTINGS,
  LOCK_SCREEN,
  USER_LOGGED_IN,
} from '../types/actions';
import * as api from '../api';


export const hydrate = payload => ({
  type: USER_HYDRATE,
  payload,
});

export const starPost = id => ({
  type: USER_STAR_POST,
  id,
});

export const unstarPost = id => ({
  type: USER_UNSTAR_POST,
  id,
});

export const setStar = dispatch => (id, starred) => {
  if (starred) {
    dispatch(starPost(id));
    api.user.addStarredPost({ postId: id });
  } else {
    dispatch(unstarPost(id));
    api.user.deleteStarredPost(id);
  }
};

export const setLockScreen = status => ({
  type: LOCK_SCREEN,
  status,
});

export const setUserLoggedIn = status => ({
  type: USER_LOGGED_IN,
  status,
});

// Set ticker calls
export const setTickerCalls = tickerObj => (dispatch) => {
  // @todo check for errors when settings charts bullish or bearish.
  api.user.updateBullishBearish(tickerObj);
  dispatch({
    type: USER_SET_TICKER_CALLS,
    tickerObj,
  });
};

export const setSettings = settings => ({
  type: USER_SET_SETTINGS,
  settings,
});

export const updateCSSettings = (name, val) => (dispatch, getState) => {
  const [n1, n2] = name.split('.');
  const curState = getState();
  const newSettings = {
    ...curState.user.settings,
    chartScanner: {
      ...curState.user.settings.chartScanner,
      [n1]: n2 !== undefined ? ({
        ...curState.user.settings.chartScanner[n1],
        [n2]: val,
      }) : val,
    },
  };
  dispatch(setSettings(newSettings));
};


export const setPortfolios = portfolios => ({
  type: USER_SET_PORTFOLIOS,
  portfolios,
});

export const getPortfolios = (cacheExpTime = 900000) => (dispatch, getState) => {
  const { lastFetched } = getState().user.portfolios;
  if (Date.now() - lastFetched > cacheExpTime) {
    // get the portfolios
    return api.user.getPortfolios()
      .then((results) => {
        const result = {
          byId: {},
          lastFetched: Date.now(),
          names: [],
        };
        // convert to a normalized object
        for (let i = 0; i < results.length; i += 1) {
          result.byId[results[i].id] = results[i];
          result.names.push(results[i].name);
        }
        dispatch(setPortfolios(result));
        return result;
      });
  }
  return Promise.resolve(getState().user.portfolios);
};

export const createPortfolio = name => async (dispatch) => {
  const portfolios = await api.user.getPortfolios();
  if (!/^(\S.*\S|\S)$/.test(name)) {
    throw new Error("Portfolio names can't begin or end with spaces");
  } else if (portfolios.includes(name)) {
    throw new Error('Portfolio with that name already exists');
  }
  return api.user.createPortfolio(name).then(() => dispatch(getPortfolios(0)));
};

export const updatePortfolio = (name, id) => async (dispatch) => {
  const portfolios = await api.user.getPortfolios();
  if (!/^(\S.*\S|\S)$/.test(name)) {
    throw new Error("Portfolio names can't begin or end with spaces");
  } else if (portfolios.includes(name)) {
    throw new Error('Portfolio with that name already exists');
  }
  return api.user.updatePortfolio(name, id).then(() => dispatch(getPortfolios(0)));
};

export const deletePortfolio = id => dispatch =>
  api.user.deletePortfolio(id).then(() => dispatch(getPortfolios(0)));

export const saveSettings = () => (dispatch, getState) => {
  const { settings } = getState().user;
  api.user.saveSettings(settings);
  // @todo show success feedback
  // .then(results => console.log(results));
};

export const togglePortfolioCustomReport = (id, shouldIncludeReport) => dispatch =>
  api.user.togglePortfolioCustomReport(id, shouldIncludeReport).then(dispatch(getPortfolios(0)));
  // being lazy here- update via api and then just pull in the data for refresh

export const loadSettings = () => (dispatch, getState) => {
  const curState = getState();
  const newSettings = {
    ...curState.user.settings,
  };
  api.user.getSettings()
    .then((results) => {
      newSettings.chartScanner = {
        ...results,
        range: parseInt(results.range, 10),
      };
      if (newSettings.chartScanner.indicators) {
        Object.keys(newSettings.chartScanner.indicators).forEach((key) => {
          newSettings.chartScanner.indicators[key] = (newSettings.chartScanner.indicators[key] === 'true');
        });
      }
      dispatch(setSettings(newSettings));
    });
};

export const setUser = (dispatch) => {
  api.user.getUser().then((user) => {
    dispatch(setUserLoggedIn(true));
    if (!user.error) {
      dispatch(setUserLoggedIn(false));
      dispatch(hydrate({ access: user }));
    }
  });
};

export const initialize = (dispatch) => {
  const promises = [];
  promises.push(api.user.getStarredPosts());
  promises.push(api.user.getBullishBearish());
  promises.push(api.user.getPortfolios());
  promises.push(api.user.getUser());
  return Promise.all(promises)
    .then(([starred, calls, portfolios, access]) => {
      const dateUpdated = Date.now();
      const result = {
        tickerCalls: {
          byId: calls,
          lastFetched: dateUpdated,
        },
        starred: {
          byId: {},
          lastFetched: dateUpdated,
        },
        portfolios: {
          byId: {},
          lastFetched: dateUpdated,
          names: [],
        },
        access,
      };
      for (let i = 0; i < starred.length; i += 1) {
        result.starred.byId[starred[i]] = true;
      }
      for (let i = 0; i < portfolios.length; i += 1) {
        result.portfolios.byId[portfolios[i].id] = portfolios[i];
        result.portfolios.names.push(portfolios[i].name);
      }
      dispatch(hydrate(result));
      if (!access.error) {
        return result;
      }
      return access.error;
    });
};

export const copyPortfolio = id => api.user.copyPortfolio(id);
