import React from 'react';
import PropTypes from 'prop-types';

class Legend extends React.PureComponent {
  render() {
    const {
      calcSize,
      margins,
      labels,
      padding,
      waterLine,
      position,
    } = this.props;

    const textSize = 12;
    const colors = this.props.colors.slice();

    if (waterLine !== undefined) {
      colors.push('red');
    }

    // const legendHeight = calcSize.height / 5;
    // const legendHeight = 30;
    // const legendWidth = calcSize.width / 5;
    // const legendWidth = 75;
    // const rectHeight = (legendHeight - ((colors.length + 1) * padding)) / colors.length;
    const rectHeight = 7.25;

    return (
      <g
        style={{
          transform: `translateX(${margins.left + padding}px) translateY(${margins.top + padding}px)`,
        }}
      >
        {/* <rect
          stroke="black"
          strokeWidth="1px"
          width={legendWidth}
          height={legendHeight}
          fill="rgba(235,235,235,0.8)"
        /> */}
        {labels.map((x, idx) => {
          const y = padding + (idx * (padding + rectHeight));
          return (
            <React.Fragment>
              {/* <rect
                x={padding}
                y={y}
                fill={x}
                width={calcSize.width / 15}
                height={rectHeight}
              /> */}
              <text
                // x={(2 * padding) + (calcSize.width / 15)}
                x={padding + (position === 'right' ? (calcSize.width - margins.left - padding - margins.right) : 0)}
                y={y + (textSize / 4) + (rectHeight / 2)}
                style={{ fontSize: `${textSize}px` }}
                textAnchor={position === 'right' ? 'end' : 'start'}
                fill={colors[idx]}
              >
                {x}
              </text>
            </React.Fragment>
          );
        })}
      </g>);
  }
}

Legend.defaultProps = {
  position: 'left',
  waterLine: undefined,
  labels: [],
};

Legend.propTypes = {
  calcSize: PropTypes.object.isRequired,
  position: PropTypes.string,
  margins: PropTypes.object.isRequired,
  colors: PropTypes.array.isRequired,
  labels: PropTypes.array,
  padding: PropTypes.number.isRequired,
  waterLine: PropTypes.number,
};

export default Legend;
