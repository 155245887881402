import React from 'react';
import { connect } from 'react-redux';
import { Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { setPageTitle } from '../../actions';
import interactiveCardImg from '../../img/cardImages/interactiveCard_2.png';
import researchCardImg from '../../img/cardImages/researchCard_2.png';
import portfolioCardImg from '../../img/cardImages/portfolioCard_3.png';
import { BlueLink } from '../presentational/ColoredText';
import HomeCard from '../presentational/cards/HomeCard';
// import logoImg from '../../img/logo.png';
import { setLockScreen } from '../../actions/user';
// import Upgrade from '../presentational/upgrade';

const linkWindowStyles = isMobile ? {} : { height: '80px', overflowY: 'scroll' };

class Home extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      lockScreenHandler,
    } = this.props;
    document.title = 'Bespoke Investment Group | Home';
    dispatch((setPageTitle('HOME')));
    lockScreenHandler(false);
  }

  // Forcing to set the LockScreen to False.
  // This will be display even when the user is not logged in.
  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props.lockScreen !== prevProps.lockScreen &&
      this.props.lockScreen
    ) {
      this.props.lockScreenHandler(false);
    }
  }

  render() {
    const { access } = this.props;
    let linkResearch = '/research';
    if (access === null) {
      linkResearch = '/research/think-big-blog/';
    }
    // const membershipLookup = {
    //   1: 'Free',
    //   2: 'Newsletter',
    //   3: 'Premium',
    //   4: 'Institutional',
    //   5: 'Trial',
    //   7: 'Chart of the Day',
    //   8: 'Premium Plus FI',
    //   9: 'Pulse',
    //   10: 'Earnings Package',
    // };
    //
    // let membership = '';
    // if (access !== null) {
    //   membership = membershipLookup[access.mem_level].toLowerCase().replace(/ /g, '-');
    // }

    return (
      <React.Fragment>
        <center>
          {/* <h1 style={{ fontSize: '4em' }}>Welcome to Bespoke!</h1> */}
          {/* <img */}
          {/*  src={logoImg} */}
          {/*  alt="Bespoke Interactive" */}
          {/*  style={{ */}
          {/*    maxHeight: '120px', */}
          {/*    marginTop: '.6em', */}
          {/*    marginBottom: '1.6em', */}
          {/*    maxWidth: '90%', */}
          {/*  }} */}
          {/* /> */}
          <p
            style={{
              fontSize: '1.6em',
              marginTop: '1em',
              display: access === null ? '' : 'none',
            }}
          >
          Welcome to Bespoke Interactive, our <b> brand new </b>
          web experience designed to give you easy access to all of our
          best research and tools! Get started by clicking one of the cards below,
          or type something into the search bar.
          </p>
          <div className="ui stackable two column grid" style={{ display: access === null || access.mem_level !== 2 ? 'none' : '' }}>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    View Bespoke&lsquo;s <a href="https://media.bespokepremium.com/uploads/2025/03/Bespoke-Report-Q2-Pros-and-Cons-x871.pdf" rel="noopener noreferrer" target="_blank">Q2 Pros and Cons</a> Report
                  </p>
                </Message>
              </center>
            </Grid.Column>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    <a href="https://www.bespokepremium.com/bespoke-membership-options/?level=p" rel="noopener noreferrer" target="_blank">CLICK HERE</a> to try Bespoke Premium
                  </p>
                </Message>
              </center>
            </Grid.Column>
          </div>
          <div className="ui stackable two column grid" style={{ display: access === null || access.mem_level !== 3 ? 'none' : '' }}>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    View Bespoke&lsquo;s <a href="https://media.bespokepremium.com/uploads/2025/03/Bespoke-Report-Q2-Pros-and-Cons-x871.pdf" rel="noopener noreferrer" target="_blank">Q2 Pros and Cons</a> Report
                  </p>
                </Message>
              </center>
            </Grid.Column>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    <a href="https://www.bespokepremium.com/checkout/?rid=p54nW4" rel="noopener noreferrer" target="_blank">CLICK HERE</a> to try Bespoke Institutional
                  </p>
                </Message>
              </center>
            </Grid.Column>
          </div>
          <div className="ui stackable two column grid" style={{ display: access === null || access.mem_level !== 4 ? 'none' : '' }}>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    View Bespoke&lsquo;s <a href="https://media.bespokepremium.com/uploads/2025/03/Bespoke-Report-Q2-Pros-and-Cons-x871.pdf" rel="noopener noreferrer" target="_blank">Q2 Pros and Cons</a> Report
                  </p>
                </Message>
              </center>
            </Grid.Column>
            <Grid.Column>
              <center>
                <Message
                  positive
                  style={{
                    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                    marginBottom: 5,
                  }}
                >
                  <p style={{ fontSize: '1.5em' }}>
                    Read our newest <a href="https://www.bespokepremium.com/interactive/research/conference-call-recaps">Conference Call Recaps</a>
                  </p>
                </Message>
              </center>
            </Grid.Column>
          </div>
        </center>
        <br />
        <div className="ui stackable three column grid">
          <Grid.Column>
            <HomeCard
              dispatch={this.props.dispatch}
              link={linkResearch}
              image={researchCardImg}
              header="My Research"
              meta="Easy access to all of your reports in one place"
              description={
                (access !== null) ?
                  <React.Fragment>
                    <br />
                    <b>Quick Links:</b>
                    <ul>
                      <Link to="research/starred-reports"><li>My Favorites</li></Link>
                      <Link to="research/featured-reports"><li>Featured Reports</li></Link>
                      <Link to="research/morning-lineup"><li>Morning Lineup</li></Link>
                    </ul>
                  </React.Fragment> : ''
              }
            />
          </Grid.Column>
          <Grid.Column>
            <HomeCard
              dispatch={this.props.dispatch}
              link="/tools"
              image={interactiveCardImg}
              header="Interactive Tools"
              meta="Explore investment ideas with our interactive tools."
              description={
                <React.Fragment>
                  <br />
                  <b>Quick Links:</b>
                  <ul style={linkWindowStyles}>
                    <Link to="/trend-analyzer/"><li>Trend Analyzer</li></Link>
                    <Link to="/chart-scanner/"><li>Chart Scanner</li></Link>
                    <Link to="/earnings-explorer/"><li>Earnings Explorer</li></Link>
                    <Link to="/ticker/SPY"><li>Security Analysis</li></Link>
                    <Link to="/economic-monitors/"><li>Economic Monitor</li></Link>
                    <Link to="/economic-database/"><li>Economic Database</li></Link>
                    <Link to="/international-revenues/"><li>International Revenues</li></Link>
                    <Link to="/seasonality-tool/"><li>Seasonality Tool</li></Link>
                  </ul>

                </React.Fragment>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <HomeCard
              style={{ overflow: 'hidden' }}
              dispatch={this.props.dispatch}
              link="/portfolio"
              image={portfolioCardImg}
              header="My Portfolios"
              meta="Create and view your custom portfolios."
              description={
                (access !== null) ?
                  <React.Fragment>
                    <br />
                    <b>My Portfolios:</b>
                    <ul style={linkWindowStyles}>
                      {(this.props.portfolios && this.props.portfolios.byId) ?
                      Object.entries(this.props.portfolios.byId).map(x => <BlueLink onClick={() => this.props.dispatch(push(`/portfolio/${x[1].id}`))}><li>{x[1].name}</li></BlueLink>) :
                      <p>loading...</p>
                      }
                    </ul>
                  </React.Fragment> : ''
              }
            />
          </Grid.Column>
        </div>
        <div className="ui three column doubling stackable masonry grid">
          {/* cardsJSX */}
        </div>
      </React.Fragment>
    );
  }
}
Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.object.isRequired,
  lockScreenHandler: PropTypes.func.isRequired,
  lockScreen: PropTypes.bool.isRequired,
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  staticCards: state.content.static,
  portfolios: state.user.portfolios,
  lockScreen: state.user.lockScreen,
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  lockScreenHandler: status => dispatch(setLockScreen(status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
