import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header, Modal, Button, Icon } from 'semantic-ui-react';
import { deletePortfolio } from '../../../../actions/user';

// const deletePortfolio = ({ portfolioName }) => (
//   <Modal
//     basic
//     trigger={<Button color="red" inverted><Icon name="remove" /> Delete</Button>}
//   >
//     <Modal.Header>
//       WARNING: you&apos;re about to delete your portfolio <b>{portfolioName}</b>
//     </Modal.Header>
//     <Modal.Content>
//       <p>
//         This action is not reversible. Are you sure you want to delete {portfolioName}?
//       </p>
//     </Modal.Content>
//     <Modal.Actions>
//       <Button basic color="white" inverted>
//         Nevermind
//       </Button>
//       <Button color="red" inverted>
//         <Icon name="remove" /> Delete
//       </Button>
//     </Modal.Actions>
//   </Modal>
// );

class DeletePortfolio extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { name } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <Button color="red" size="mini" onClick={this.click}>
          <Icon link name="trash alternate" />DELETE
        </Button>
        <Modal basic open={open} {...this.props} onClose={this.close}>
          <Header icon="warning sign" content={`WARNING: About to Delete Custom Portfolio "${name}"`} />
          <Modal.Content>
            <p>
            This action is not reversible. Are you sure you want to delete {name}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="white" inverted onClick={this.close}>
              Nevermind
            </Button>
            <Button color="red" inverted onClick={() => this.props.deletePortfolio(this.props.id).then(this.close)}>
              <Icon name="remove" /> Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

DeletePortfolio.propTypes = {
  name: PropTypes.string.isRequired,
  deletePortfolio: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  deletePortfolio: id => dispatch(deletePortfolio(id)),
});

export default connect(null, mapDispatchToProps)(DeletePortfolio);
