import * as Pages from './pages';
import * as Actions from './actions';

export const actions = Actions;
export const interactiveContent = {
  SUMMARY: 'SUMMARY',
  CHART: 'CHART',
  TREND: 'TREND',
  SCREENS: 'SCREENS',
  POSTS: 'POSTS',
  CHARTS: 'CHARTS',
};
export const pages = Pages;

export const staticContent = {
  ARTICLE: 'ARTICLE',
  RESEARCH: 'RESEARCH',
  POST: 'POST',
  POSTS: 'POSTS',
  PDF: 'PDF',
  VIDEO: 'VIDEO',
  TWEET: 'TWEET',
};
