import React from 'react';
// import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { withRouter, Link } from 'react-router-dom';
// import { Table, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import SortableTable from '../../SortableTable';
import * as Text from '../../ColoredText';


// const leftAlign = () => ({ textAlign: 'left' });

// const formatTicker = ticker => ticker; // (<Link to={`/ticker/${ticker}`}>{ticker}</Link>);
const forceTwoDec = x => (!Number.isNaN(Number(x)) ? parseFloat(x).toFixed(2) : 'N/A');

// // const formatTicker = ticker => ticker;
const formatPercent = num => `${Math.round(num)}%`;

const formatColor = (val) => {
  if (val > 0) {
    return <Text.GreenText>{val}</Text.GreenText>;
  } else if (val < 0) {
    return <Text.RedText>{val}</Text.RedText>;
  }
  return val;
};
// const formatDate = date => date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1');

// const numberWithCommas = x => (!Number.isNaN(Number(x)) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A');


class QuarterlyTable extends React.PureComponent {
  preHeader = (
    <Table.Row>
      <Table.HeaderCell colSpan={2} />
      <Table.HeaderCell colSpan={2}>
        Beat Rate
      </Table.HeaderCell>
      <Table.HeaderCell rowSpan={2} style={{ width: '80px' }}>
        % Raised Guidance
      </Table.HeaderCell>
      <Table.HeaderCell colSpan={5}>
      Average Stock Price Change on Earnings Reaction Days
      </Table.HeaderCell>
    </Table.Row>
  );

  columns = [
    {
      key: 'quarter',
      text: 'Quarter',
    },
    {
      key: 'num_reports',
      text: '# of Reports',
    },
    {
      key: 'eps_beat_pct',
      text: 'EPS',
      formatFunc: formatPercent,
    },
    {
      key: 'rev_beat_pct',
      text: 'Sales',
      formatFunc: formatPercent,
    },
    {
      key: 'raised_guidance_pct',
      text: '% Raised Guidance',
      formatFunc: formatPercent,
      style: { display: 'none' },
    },
    {
      key: 'avg_gap',
      text: 'Opening Gap %',
      formatFunc: x => formatColor(forceTwoDec(x)),
    },
    {
      key: 'avg_o2c',
      text: 'Open to Close %',
      formatFunc: x => formatColor(forceTwoDec(x)),
    },
    {
      key: 'avg_1day',
      text: 'Full 1 Day %',
      formatFunc: x => formatColor(forceTwoDec(x)),
    },
    {
      key: 'pct_one_day_positive',
      text: '% 1 Day Positive',
      formatFunc: formatPercent,
    },
    {
      key: 'avgabsolute',
      text: 'Volatility %',
      formatFunc: x => forceTwoDec(x),
    },
  ];

  render() {
    const { data, ticker, loading } = this.props;
    return (
      <React.Fragment>
        {ticker ? <h1 style={{ fontSize: '16px' }} >{`Quarterly Earnings Summary for ${ticker.description} (${ticker.title})`}</h1> : null }
        <Dimmer.Dimmable style={{ marginTop: '20px', borderRadius: '4px' }} dimmed={loading}>
          <Dimmer inverted active={loading} >
            <Loader />
          </Dimmer>
          <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }} className="search-forth-step screen-seventh-step">
            <SortableTable
              {...this.props}
              style={{
                textAlign: 'center',
                fontSize: '20px',
                marginTop: '0px',
              }}
              rows={data}
              columns={this.columns}
              preHeader={this.preHeader}
            />
          </div>
        </Dimmer.Dimmable>
      </React.Fragment>);
  }
}

// QuarterlyTable.defaultProps = {
//   ticker: null,
//   data: {},
// };

// QuarterlyTable.propTypes = {
//   ticker: PropTypes.string,
//   data: PropTypes.object,
// };


QuarterlyTable.propTypes = {
  ticker: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  // mode: state.view.earningsExplorer.mode,
  // ticker: state.view.earningsExplorer.ticker,
  loading: state.view.earningsExplorer.quarterlyDataLoading,
  data: state.view.earningsExplorer.quarterlyData,
  ticker: state.view.earningsExplorer.ticker,
  // screenDataLoading: state.view.earningsExplorer.screenDataLoading,
  // tableDataLoading: state.view.earningsExplorer.tableDataLoading,
});

export default connect(mapStateToProps, null)(QuarterlyTable);
