import {
  CATEGORIES_SET_DATA,
  CATEGORIES_SET_LOADING,
  CATEGORIES_CREATE,
  CATEGORIES_SET_UPDATE,
  CATEGORIES_HAS_ERRORED,
  SCREENS_SET_DATA,
  SCREENS_SET_LOADING,
  SCREENS_HAS_ERRORED,
  ECAL_SET_DATA,
  ECAL_SET_LOADING,
  ECAL_HAS_ERRORED,
  ECALAGG_SET_DATA,
  ECALAGG_SET_LOADING,
  ECALAGG_HAS_ERRORED,
  EE_SET_ERROR,
  EE_SET_END_DATE,
  EE_SET_FILTER_DATE,
  EE_SET_ECAL_AGG_FILTER_DATE,
  EE_SET_CHART_DATA,
  EE_SET_CHART_DATA_LOADING,
  EE_SET_QUARTERLY_DATA,
  EE_SET_QUARTERLY_DATA_LOADING,
  EE_SET_TABLE_DATA,
  EE_SET_TABLE_DATA_LOADING,
  EE_SET_SCREEN_DATA,
  EE_SET_SCREEN_DATA_LOADING,
  EE_SET_SORT,
  EE_SET_MODE,
  EE_SET_TICKER,
  EE_SET_TABLE_PAGE,
  CS_SET_DATA,
  CS_SET_LOADING,
  CS_HAS_ERRORED,
  SZN_SET_DATA,
  SZN_SET_DATES,
  SZN_SET_LOADING,
  SZN_HAS_ERRORED,
  MOVERS_SET_LOADING,
  MOVERS_SET_DATA,
  OBOS_SET_DATA,
  OBOS_SET_LOADING,
  OBOS_SET_TIMEFRAME,
  FIFTY_DMA_SET_DATA,
  FIFTY_DMA_SET_LOADING,
  FIFTY_DMA_SET_TIMEFRAME,
  TWOHUNDRED_DMA_SET_DATA,
  TWOHUNDRED_DMA_SET_LOADING,
  TWOHUNDRED_DMA_SET_TIMEFRAME,
  MP_DATA,
  MP_DETAIL_SET_LOADING,
  MP_DETAIL_DATA,
  MP_SET_ERROR,
} from '../../types/actions';
import * as api from '../../api';

export const categorySetData = ({ data, lastFetched }) => ({
  type: CATEGORIES_SET_DATA,
  data,
  lastFetched,
});

export const categorySetUpdate = ({ categoryData }) => ({
  type: CATEGORIES_SET_UPDATE,
  categoryData,
});

export const categorySetLoading = loading => ({
  type: CATEGORIES_SET_LOADING,
  loading,
});

export const categoryHasErrored = error => ({
  type: CATEGORIES_HAS_ERRORED,
  error,
});

export const screensSetData = ({ data, lastFetched }) => ({
  type: SCREENS_SET_DATA,
  data,
  lastFetched,
});

export const screensSetLoading = loading => ({
  type: SCREENS_SET_LOADING,
  loading,
});

export const screensHasErrored = error => ({
  type: SCREENS_HAS_ERRORED,
  error,
});

export const ecalSetData = ({ data, lastFetched }) => ({
  type: ECAL_SET_DATA,
  data,
  lastFetched,
});

export const ecalSetLoading = loading => ({
  type: ECAL_SET_LOADING,
  loading,
});

export const ecalHasErrored = error => ({
  type: ECAL_HAS_ERRORED,
  error,
});

export const ecalAggSetData = ({ data, lastFetched }) => ({
  type: ECALAGG_SET_DATA,
  data,
  lastFetched,
});

export const ecalAggSetLoading = loading => ({
  type: ECALAGG_SET_LOADING,
  loading,
});

export const ecalAggHasErrored = error => ({
  type: ECALAGG_HAS_ERRORED,
  error,
});

export const moversSetLoading = loading => ({
  type: MOVERS_SET_LOADING,
  loading,
});

export const moversSetData = ({ data, lastFetched }) => ({
  type: MOVERS_SET_DATA,
  data,
  lastFetched,
});

export const OBOSSetLoading = loading => ({
  type: OBOS_SET_LOADING,
  loading,
});

export const OBOSSetTimeframe = timeframe => ({
  type: OBOS_SET_TIMEFRAME,
  timeframe,
});

export const OBOSSetData = ({ data, lastFetched }) => ({
  type: OBOS_SET_DATA,
  data,
  lastFetched,
});

export const FiftyDmaSetLoading = loading => ({
  type: FIFTY_DMA_SET_LOADING,
  loading,
});

export const FiftyDmaSetTimeframe = timeframe => ({
  type: FIFTY_DMA_SET_TIMEFRAME,
  timeframe,
});

export const FiftyDmaSetData = ({ data, lastFetched }) => ({
  type: FIFTY_DMA_SET_DATA,
  data,
  lastFetched,
});

export const TwohundredDmaSetLoading = loading => ({
  type: TWOHUNDRED_DMA_SET_LOADING,
  loading,
});

export const TwohundredDmaSetTimeframe = timeframe => ({
  type: TWOHUNDRED_DMA_SET_TIMEFRAME,
  timeframe,
});

export const TwohundredDmaSetData = ({ data, lastFetched }) => ({
  type: TWOHUNDRED_DMA_SET_DATA,
  data,
  lastFetched,
});

export const MPSetData = data => ({
  type: MP_DATA,
  data,
});

export const MPDetailSetData = ({ data }) => ({
  type: MP_DETAIL_DATA,
  data,
});

export const MPSetLoading = loading => ({
  type: MP_DETAIL_SET_LOADING,
  loading,
});

export const setMPError = error => ({
  type: MP_SET_ERROR,
  error,
});

export const createDataCategory = ({ listData }) => ({
  type: CATEGORIES_CREATE,
  listData,
});

export const saveCategoryNew = listData => (dispatch) => {
  const promise = api.category.saveNewCategory(listData)
    .then((result) => {
      dispatch(createDataCategory(listData));
      return result;
    }).catch(err => dispatch(categoryHasErrored(err)));
  return promise;
};

export const updateCategoryData = listData => (dispatch) => {
  const promise = api.category.editCategoryUpdate(listData.changeIdCategory, listData)
    .then((result) => {
      dispatch(categorySetUpdate(listData));
      return result;
    }).catch(err => dispatch(categoryHasErrored(err)));
  return promise;
};

export const getCategories = (cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of categories
  const { categories } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (categories.lastFetched === null || (currentTime - categories.lastFetched) > cacheExpTime) {
    dispatch(categorySetLoading(true));
    promise = api.category.getAll()
      .then((result) => {
        dispatch(categorySetData({ data: result, lastFetched: currentTime }));
        return result;
      })
      .catch(err => dispatch(categoryHasErrored(err)));
  } else {
    promise = Promise.resolve(categories.data);
  }
  promise = promise.finally(() => dispatch(categorySetLoading(false)));
  return promise;
};

export const getScreens = (cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of screens
  const { screens } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (screens.lastFetched === null || (currentTime - screens.lastFetched) > cacheExpTime) {
    dispatch(screensSetLoading(true));
    promise = api.screen.getAll()
      .then((result) => {
        dispatch(screensSetData({ data: result, lastFetched: currentTime }));
        return result;
      })
      .catch(err => dispatch(screensHasErrored(err)));
  } else {
    promise = Promise.resolve(screens.data);
  }
  promise = promise.finally(() => dispatch(screensSetLoading(false)));
  return promise;
};

export const getEcal = (cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of ecal
  const { ecal } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (ecal.lastFetched === null || (currentTime - ecal.lastFetched) > cacheExpTime) {
    dispatch(ecalSetLoading(true));
    promise = api.tools.getECData()
      .then((result) => {
        dispatch(ecalSetData({ data: result, lastFetched: currentTime }));
        return result;
      })
      .catch(err => dispatch(ecalHasErrored(err)));
  } else {
    promise = Promise.resolve(ecal.data);
  }
  promise = promise.finally(() => dispatch(ecalSetLoading(false)));
  return promise;
};

export const getEcalAgg = (cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of ecal
  const { ecalAgg } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (ecalAgg.lastFetched === null || (currentTime - ecalAgg.lastFetched) > cacheExpTime) {
    dispatch(ecalAggSetLoading(true));
    promise = api.tools.getEEAggregateData()
      .then((result) => {
        dispatch(ecalAggSetData({ data: result, lastFetched: currentTime }));
        return result;
      })
      .catch(err => dispatch(ecalAggHasErrored(err)));
  } else {
    promise = Promise.resolve(ecalAgg.data);
  }
  promise = promise.finally(() => dispatch(ecalAggSetLoading(false)));
  return promise;
};

/* Earnings Explorer Views */
export const setEndDate = date => ({
  type: EE_SET_END_DATE,
  date,
});

export const setFilterDate = date => ({
  type: EE_SET_FILTER_DATE,
  date,
});

export const setEcalAggFilterDate = date => ({
  type: EE_SET_ECAL_AGG_FILTER_DATE,
  date,
});

export const setEEError = error => ({
  type: EE_SET_ERROR,
  error,
});

export const setEEChartData = data => ({
  type: EE_SET_CHART_DATA,
  data,
});

export const setEEChartDataLoading = loading => ({
  type: EE_SET_CHART_DATA_LOADING,
  loading,
});

export const setEETableData = data => ({
  type: EE_SET_TABLE_DATA,
  data,
});

export const setEETableDataLoading = loading => ({
  type: EE_SET_TABLE_DATA_LOADING,
  loading,
});

export const setEEScreenData = data => ({
  type: EE_SET_SCREEN_DATA,
  data,
});

export const setEEScreenDataLoading = loading => ({
  type: EE_SET_SCREEN_DATA_LOADING,
  loading,
});

export const setEEQuarterlyData = data => ({
  type: EE_SET_QUARTERLY_DATA,
  data,
});

export const setEEQuarterlyDataLoading = loading => ({
  type: EE_SET_QUARTERLY_DATA_LOADING,
  loading,
});

export const getQuarterlyData = ticker => (dispatch) => {
  dispatch(setEEQuarterlyDataLoading(true));
  api.tools.getEEQuarterlyAggregateData(ticker)
    .then(res => dispatch(setEEQuarterlyData(res)))
    .finally(dispatch(setEEQuarterlyDataLoading(false)));
};

export const getChartData = ticker => (dispatch) => {
  dispatch(setEEChartDataLoading(true));
  dispatch(setEETableDataLoading(true));
  api.ticker.getEarningsByDate([ticker], '1980-01-01', new Date(Date.now()).toISOString().split('T')[0])
    .then((result) => {
      dispatch(setEEChartData(result[ticker]));
      dispatch(setEETableData(result[ticker]));
      // const newState = {
      //   mode: 'single',
      //   ticker,
      //   sortCol: 'date',
      //   sortDir: 'DESC',
      //   tableData: result[ticker],
      //   chartData: result[ticker],
      //   aggTableHidden: false,
      //   aggDescription === '' ||
      //   tableDescription: `Historical Earnings Reports for ${company} (${ticker})`,
      // };
    })
    .finally(() => {
      dispatch(setEEChartDataLoading(false));
      dispatch(setEETableDataLoading(false));
    });
};

export const getScreenData = screen => (dispatch) => {
  dispatch(setEEScreenDataLoading(true));
  return api.tools.getEEScreenResults(screen)
    .then(results => dispatch(setEEScreenData(results)))
    .catch(error => dispatch(setEEError(error)))
    .finally(() => dispatch(setEEScreenDataLoading(false)));
};

export const setEESort = col => (dispatch, getState) => {
  const { sortCol, sortDir } = getState().view.earningsExplorer;
  const newDir = col === sortCol ? `${sortDir === 'ASC' ? 'DESC' : 'ASC'}` : 'ASC';

  dispatch({
    type: EE_SET_SORT,
    sortCol: col,
    sortDir: newDir,
  });
};

// private default sort helper function
const defaultModeSort = (mode) => {
  let sortCol;
  let sortDir = 'DESC';
  switch (mode) {
    case 'ecal':
      sortCol = 'reportdate';
      sortDir = 'ASC';
      break;
    case 'single':
    case 'screen':
      sortCol = 'date';
      break;
    default:
      break;
  }
  return { sortCol, sortDir };
};

export const setEEMode = mode => ({
  type: EE_SET_MODE,
  mode,
  ...defaultModeSort(mode),
});

export const setEETicker = ticker => ({
  type: EE_SET_TICKER,
  ticker,
});

export const setEETablePage = page => ({
  type: EE_SET_TABLE_PAGE,
  page,
});

// Chart Scanner
export const setCSData = data => ({
  type: CS_SET_DATA,
  data,
});

export const setCSLoading = loading => ({
  type: CS_SET_LOADING,
  loading,
});

export const setCSError = error => ({
  type: CS_HAS_ERRORED,
  error,
});

export const getCSData = (category, id, date = null) => (dispatch, getState) => {
  dispatch(setCSLoading(true));
  let promise = Promise.resolve();
  let title = '';
  const curState = getState();
  const endDate = new Date(Date.now()).toISOString().split('T')[0];
  let startDate = endDate.split('-');
  startDate[0] = parseInt(startDate[0], 10) - 1;
  startDate = startDate.join('-');

  // Set the chart scanner loading state

  // get reference data + 1 year of historical data for tickers in list, set state accordingly
  switch (category) {
    case 'indices': {
      const [cat] = curState.data.categories.data.filter(x => x.id === parseInt(id, 10));
      promise = api.category.getMembers(id);
      title = ` - Indices${cat ? `: ${cat.name}` : ''}`;
      break;
    }
    case 'sectors': {
      const [cat] = curState.data.categories.data.filter(x => x.id === parseInt(id, 10));
      promise = api.category.getMembers(id);
      title = ` - Sectors${cat ? `: ${cat.name}` : ''}`;
      break;
    }
    case 'etfs': {
      const [cat] = curState.data.categories.data.filter(x => x.id === parseInt(id, 10));
      promise = api.category.getMembers(id);
      title = ` - ETFs${cat ? `: ${cat.name}` : ''}`;
      break;
    }
    case 'custom-portfolios': {
      const pf = curState.user.portfolios.byId[id];
      promise = Promise.resolve((pf && pf.members) || []);
      title = pf ? ` - ${pf.name}` : '';
      break;
    }
    case 'my-charts':
      promise = Promise.resolve(curState.user.tickerCalls.byId[id] || []);
      title = ` - My Charts${id ? `: ${id}` : ''}`;
      break;
    case 'screens': {
      const [screen] = curState.data.screens.data.filter(x => x.id === parseInt(id, 10));
      promise = api.screen.getMembers(id);
      title = ` - Screens${screen ? `: ${screen.name}` : ''}`;
      break;
    }
    case 'modal-portfolios': {
      promise = api.tools.getMPData(id, date);
      title = '';
      break;
    }
    default:
      dispatch(setCSLoading(false));
      break;
  }

  return promise
    .then(results =>
      // once results have been retrieved, get historical + reference data
      Promise.all([
        api.ticker.getReference(!results.data
          ? results : results.data.map(item => (item.ticker))),
        api.ticker.getHistorical(!results.data ? results
          : results.data.map(item => (item.ticker)), startDate, endDate),
      ]))
    .then(([reference, history]) => {
      // console.log(reference);
      // console.log(history);
      const data = {
        byId: {},
        allIds: [],
        error: null,
        title,
      };

      reference.forEach((item) => {
        data.byId[item.ticker] = {
          ticker: item,
          history: history[item.ticker].map(x => ({ ...x, date: new Date(x.date) })).reverse(),
        };
        data.allIds.push(item.ticker);
      });
      // set the data
      dispatch(setCSData(data));
    })
    .catch(err => dispatch(setCSError(err)))
    .finally(() => dispatch(setCSLoading(false)));
};

// @todo modify to include graph data + table data
export const setSeasonalityData = data => ({
  type: SZN_SET_DATA,
  data,
});

export const setSeasonalityDates = dates => (dispatch, getState) => {
  const curDates = getState().data.seasonality.dates;
  // check the dates to ensure they're in the range, if not set to closest in range
  dispatch({
    type: SZN_SET_DATES,
    dates: {
      ...curDates,
      ...dates,
    },
  });
};

export const setSeasonalityLoading = loading => ({
  type: SZN_SET_LOADING,
  loading,
});

export const setSeasonalityError = error => ({
  type: SZN_HAS_ERRORED,
  error,
});

export const getSeasonalityComposite = (ticker, years) => dispatch =>
  api.tools.getSeasonalityComposite(ticker, Number(years) + 1)
    .then(compositeData => dispatch(setSeasonalityData({ compositeData })));


export const getSeasonality = (category, data) => (dispatch) => {
  let promise = Promise.resolve();
  dispatch(setSeasonalityLoading(true));
  switch (category) {
    case 'equities':
      promise = api.tools.getSeasonalityEquities(data);
      break;
    case 'global':
      promise = api.tools.getSeasonalityIndices(data);
      break;
    case 'etfs':
      promise = api.tools.getSeasonalityETFS(data);
      break;
    case 'custom':
      promise = api.tools.getSeasonalityCustomPortfolios(data);
      break;
    default:
      return promise;
  }
  return promise
    .then((results) => {
      const {
        sznData,
        years,
        // secData,
        // bestPerformers,
        // worstPerformers,
      } = results;
      const allData = {
        chartData: [],
        tableData: [],
        years,
      };

      const chartData = [];
      const tableData = [];

      // Generate top 5 and bottom 5 for chart data
      // Also generate table data
      Object.keys(sznData).forEach((key) => {
        let total_pos = 0;
        const num_years = Object.keys(sznData[key].yearly).length;

        chartData.push({
          label: (category === 'global' ? sznData[key].sector : key),
          value: sznData[key].med,
        });
        const tableRow = {
          ticker: key,
          med: sznData[key].med,
          avg: sznData[key].avg,
          pfNames: sznData[key].pfNames,
          sector: sznData[key].sector,
          name: sznData[key].name,
        };
        // calculate pct pos
        // calculate pct beat S&P 500
        // Add yearly data for each year
        Object.entries(sznData[key].yearly).forEach(([year, val]) => {
          if (parseFloat(val) > 0) {
            total_pos += 1;
          }
          tableRow[`year_${year}`] = val;
        });
        tableRow.pct_pos = Math.round((100 * total_pos) / num_years);
        tableData.push(tableRow);
      });

      tableData.sort((a, b) => b.med - a.med);

      chartData.sort((a, b) => b.value - a.value);
      if (chartData.length > 10) {
        chartData.splice(5, chartData.length - 10);
      }
      allData.chartData = chartData;
      allData.tableData = tableData;

      dispatch(setSeasonalityData(allData));
    })
    .catch(err => dispatch(setSeasonalityError(err)))
    .finally(() => dispatch(setSeasonalityLoading(false)));
};

export const getBiggestMovers = (date = null) => (dispatch) => {
  // Check cache for list of Biggest Movers
  const currentTime = Date.now();
  let promise;
  dispatch(moversSetLoading(true));
  promise = api.tools.getBiggestMovers(date)
    .then((result) => {
      dispatch(moversSetData({ data: result, lastFetched: currentTime }));
      return result;
    })
    .catch(err => dispatch(screensHasErrored(err)));
  promise = promise.finally(() => dispatch(moversSetLoading(false)));
  return promise;
};

export const getOBOSChart = (category = '28', timeframe = '6-months') => (dispatch) => {
  // Check cache for list of Biggest Movers
  const currentTime = Date.now();
  let promise;
  dispatch(OBOSSetLoading(true));
  dispatch(OBOSSetTimeframe(timeframe));
  promise = api.tools.getOBOSPercentage(category, timeframe)
    .then((result) => {
      const data = result.data.map(x => (
        {
          ...x,
          date: new Date(x.date),
          ob: x.ob / 100,
          os: x.os / 100,
        }));
      dispatch(OBOSSetData({ data, lastFetched: currentTime }));
      return result;
    })
    .catch(err => dispatch(screensHasErrored(err)));
  promise = promise.finally(() => dispatch(OBOSSetLoading(false)));
  return promise;
};

export const getFiftyDmaChart = (category = '28', timeframe = '6-months') => (dispatch) => {
  // Check cache for list of Biggest Movers
  const currentTime = Date.now();
  let promise;
  dispatch(FiftyDmaSetLoading(true));
  dispatch(FiftyDmaSetTimeframe(timeframe));
  promise = api.tools.getFiftyDmaPercentage(category, timeframe)
    .then((result) => {
      const data = result.data.map(x => (
        {
          ...x,
          date: new Date(x.date),
          ob: x.ob / 100,
          os: x.os / 100,
        }));
      dispatch(FiftyDmaSetData({ data, lastFetched: currentTime }));
      return result;
    })
    .catch(err => dispatch(screensHasErrored(err)));
  promise = promise.finally(() => dispatch(FiftyDmaSetLoading(false)));
  return promise;
};

export const getTwohundredDmaChart = (category = '28', timeframe = '6-months') => (dispatch) => {
  // Check cache for list of Biggest Movers
  const currentTime = Date.now();
  let promise;
  dispatch(TwohundredDmaSetLoading(true));
  dispatch(TwohundredDmaSetTimeframe(timeframe));
  promise = api.tools.getTwohundredDmaPercentage(category, timeframe)
    .then((result) => {
      const data = result.data.map(x => (
        {
          ...x,
          date: new Date(x.date),
          ob: x.ob / 100,
          os: x.os / 100,
        }));
      dispatch(TwohundredDmaSetData({ data, lastFetched: currentTime }));
      return result;
    })
    .catch(err => dispatch(screensHasErrored(err)));
  promise = promise.finally(() => dispatch(TwohundredDmaSetLoading(false)));
  return promise;
};

export const getMPDetails = id => dispatch => api.tools.getMPDetails(id)
  .then(results => dispatch(MPDetailSetData(results)))
  .catch(error => dispatch(setMPError(error)));

export const getMPData = (id, date) => (dispatch) => {
  dispatch(MPSetLoading(true));
  return api.tools.getMPData(id, date)
    .then(results => dispatch(MPDetailSetData(results)))
    .catch(error => dispatch(setMPError(error)))
    .finally(() => dispatch(MPSetLoading(false)));
};

export const getModelPortfolios = () => (dispatch) => {
  dispatch(MPSetLoading(true));
  return api.tools.getModelPortfolios()
    .then((results) => {
      const result = {
        byId: {},
        names: [],
      };
      // convert to a normalized object
      for (let i = 0; i < results.length; i += 1) {
        result.byId[results[i].id] = results[i];
        result.names.push(results[i].name);
      }
      dispatch(MPSetData(result));
      return result;
    })
    .catch(error => dispatch(setMPError(error)))
    .finally(() => dispatch(MPSetLoading(false)));
};

export const deleteMPortfolio = id => dispatch =>
  api.tools.deleteMPortfolio(id).then(() => dispatch(getModelPortfolios(0)));

export const deleteMPortfolioDetail = id => dispatch =>
  api.tools.deleteMPDetails(id).then(() => dispatch(getModelPortfolios(0)));

export const sellMPortfolioDetail = (id, ticker, shares, price) => dispatch =>
  api.tools.sellMPortfolioDetail(id, { ticker, shares, price })
    .then(() => dispatch(getModelPortfolios(0)));

export const createModelPortfolio = body => async (dispatch) => {
  if (!/^(\S.*\S|\S)$/.test(body.name)) {
    throw new Error("Model Portfolio names can't begin or end with spaces");
  }

  return api.tools.postModelPortfolio(body)
    .then(() => dispatch(getModelPortfolios()))
    .catch(error => dispatch(setMPError(error)));
};

export const editModelPortfolio = body => async (dispatch) => {
  if (!/^(\S.*\S|\S)$/.test(body.name)) {
    throw new Error("Model Portfolio names can't begin or end with spaces");
  }

  return api.tools.putModelPortfolio(body)
    .then(() => dispatch(getModelPortfolios()))
    .catch(error => dispatch(setMPError(error)));
};

export const addCreateMPDetail = (mpId, details) => async (dispatch) => {
  if (
    details.ticker_id === '' ||
    details.ticker === '' ||
    details.ticker_name === '' ||
    details.sector === '' ||
    details.entry_price === '' ||
    details.shares === '' ||
    details.date === ''
  ) {
    throw new Error('All fields are required!');
  }

  return api.tools.postMPDetails(mpId, details)
    .then(() => dispatch(getModelPortfolios()));
};

export const getHistoricalChanges = () => dispatch => api.tools.getHistoricalChange()
  .then(results => results)
  .catch(error => dispatch(setMPError(error)));
