import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import ChartScannerCard from '../../../presentational/cards/ChartScannerCard';

class ChartGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.chartGridStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    };

    if (isMobile) {
      this.chartGridStyle = {
        display: 'none',
      };
    }

    this.chartItemStyle = {};
  }

  render() {
    const {
      data,
      chartSize,
      range,
    } = this.props;
    // Create a flexbox grid to display all the charts
    return (
      <div style={this.chartGridStyle}>
        { (data.length > 0) ?
          data.map(item => (
            <ChartScannerCard
              key={item && item.ticker && item.ticker.ticker}
              ticker={item.ticker}
              history={item.history}
              chartSize={chartSize}
              range={range}
            />
         )) :
         'Please select something.'
        }
      </div>);
  }
}

ChartGrid.defaultProps = {
  chartSize: { width: 330, height: 250 },
  range: 0,
};

ChartGrid.propTypes = {
  data: PropTypes.array.isRequired,
  chartSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  range: PropTypes.number,
};

export default ChartGrid;
