// Modal that prompts user to upgrade
// Use it with a trigger to show modal instead of linking to content,
// or use directly on blocked content without a trigger
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';

// const UpgradeModal = props => (
//   <Modal basic closeOnDimmerClick={false} {...props}>
//     <Header icon="lock" content="Restricted Content" />
//     <Modal.Content>
//       <p>
//         You are seeing this message because this content is restricted for your current
//         membership level.
//         If you would like to see this content, please upgrade your membership level.
//       </p>
//     </Modal.Content>
//     <Modal.Actions>
//       <Button basic color="white" inverted onClick={props.history.goBack}>
//         <Icon name="remove" /> No Thanks
//       </Button>
//       <Button color="yellow" inverted>
//         <Icon name="checkmark" /> Upgrade
//       </Button>
//     </Modal.Actions>
//   </Modal>
// );

// converted class component for open/close state control
class SignUpModal extends React.PureComponent {
  state = this.props.launcher ? { open: false } : { open: true };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;
    const {
      history,
      launcher,
      message,
      linkLogin,
      linkSignup,
      memLevel,
    } = this.props;

    let signUpLink = linkSignup;
    if (memLevel !== null) {
      signUpLink = `${linkSignup}?level=${memLevel[0].toString().trim().substring(0, 1).toLowerCase()}`;
    }

    return (
      <React.Fragment>
        {launcher ? React.cloneElement(this.props.launcher, { onClick: this.click }) : <span />}
        <Modal
          closeIcon
          dimmer="blurring"
          basic
          open={open}
          onClose={launcher ? this.close : history.goBack}
          closeOnDimmerClick={false}
          {...this.props}
        >
          <Header icon="lock" content="Restricted Content" />
          <Modal.Content>
            <p>
              {message}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="white" inverted onClick={() => { window.location = linkLogin; }}>
              <Icon name="unlock" /> Log In
            </Button>
            <Button color="yellow" inverted onClick={() => { window.location = signUpLink; }}>
              <Icon name="edit" /> Sign Up
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

const signup = props => (
  props.trigger ?
    <SignUpModal {...props} trigger={null} launcher={props.trigger} />
    :
    <SignUpModal defaultOpen {...props} />
);

SignUpModal.propTypes = {
  launcher: PropTypes.any.isRequired,
  message: PropTypes.string.isRequired,
  linkLogin: PropTypes.string.isRequired,
  linkSignup: PropTypes.string.isRequired,
  memLevel: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

signup.defaultProps = {
  trigger: null,
  message: 'You are seeing this message because this content is restricted.  If you would like to see this content, please log in or sign up.',
  linkLogin: 'https://www.bespokepremium.com/login',
  linkSignup: 'https://www.bespokepremium.com/bespoke-membership-options/',
  memLevel: null,
};

signup.propTypes = {
  trigger: PropTypes.any,
  message: PropTypes.string,
  linkLogin: PropTypes.string,
  linkSignup: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default withRouter(signup);
