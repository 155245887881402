import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ToolCard from '../../presentational/cards/ToolCard';
import { setPageTitle } from '../../../actions';
// import modelPortfolisImg from '../../../img/cardImages/tools/bespoke-baskets.png';

class Admin extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    document.title = 'Bespoke Investment Group | Admin';
    dispatch((setPageTitle('ADMIN')));
  }

  render() {
    // @todo needs uncoupling
    // note using 'teal' for base color enum. Workaround
    const admins = [
      {
        name: 'Bespoke Baskets',
        desc: 'Add, edit or delete new Bespoke Baskets.',
        // image: modelPortfolisImg,
        link: '/admin/bespoke-baskets',
      },
      {
        name: 'Tickers Manager',
        desc: 'Add, Edit or Delete Tickers and Categories',
        link: '/admin/tickers-manager',
      },
    ];
    return (
      <React.Fragment>
        <h1 className="mobile-hide">Admin</h1>
        <div className="ui five column doubling grid">
          {admins.map(x => (
            <Grid.Column>
              <ToolCard {...x} dispatch={this.props.dispatch} />
            </Grid.Column>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
Admin.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  staticCards: state.content.static,
  portfolios: state.user.portfolios,
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
