import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Dimmer, Loader, Grid, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import queryString from 'query-string-es5';
import {
  getCategories,
  getScreens,
} from '../../actions/data/tools';
import { getPortfolios } from '../../actions/user';
import { navigateToPage } from '../../actions/search';
import { getSummaryData } from '../../actions/data/tickers';
import TrendAnalyzerCard from '../presentational/cards/TrendAnalyzerCard';
import * as api from '../../api';
import UpgradeConnected from '../presentational/upgradeConnected';
import SharePortfolioModal from '../presentational/interactive/customPortfolios/sharePortfolioModal';
import Touring from '../presentational/interactive/tour';

class TrendAnalyzerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: {
        etf: {
          key: 'etf',
          name: 'ETF',
          retrievalFunction: id => api.category.getMembers(id),
        },
        sp500: {
          key: 'sp500',
          name: 'S&P 500',
          retrievalFunction: id => api.category.getMembers(id),
        },
        nasdaq: {
          key: 'nasdaq',
          name: 'NASDAQ-100',
          retrievalFunction: id => api.category.getMembers(id),
        },
        djia: {
          key: 'djia',
          name: 'Dow Jones Industrial Average',
          retrievalFunction: id => api.category.getMembers(id),
        },
        custom: {
          key: 'custom',
          name: 'Custom Portfolios',
          retrievalFunction: id => api.user.getPortfolioMembers(id),
        },
        screens: {
          key: 'screens',
          name: 'Screens',
          retrievalFunction: id => api.screen.getMembers(id),
        },
      },
      subMenuItems: {},
      subMenuCategory: null,
      subMenuError: null,
      tickersToDisplay: [],
    };
    // subMenu Criteria
    this.includeInSubmenu = (item, category) => {
      switch (category) {
        case 'etf':
          return (item && item.in_trend_analyzer && item.is_etf_cat);
        case 'sp500':
          return (item && item.in_trend_analyzer && !item.is_etf_cat && !item.is_index
            && item.id !== 17);
        case 'nasdaq':
        case 'djia':
          return (item &&
            item.in_trend_analyzer &&
            !item.is_etf_cat &&
            item.is_index &&
            item.name.toLowerCase() === category);
        case 'custom':
        case 'screens':
          return true;
        case 'download':
        default:
          return false;
      }
    };

    this.initialLoad = false;
    this.initialDate = false;

    this.updateSubMenu = (subMenuItems, subMenuCategory) => {
      this.setState({
        subMenuItems,
        subMenuCategory,
        subMenuError: null,
      });
    };

    this.updateSubmenuError = (subMenuError) => {
      this.setState({
        subMenuError,
      });
    };

    this.updateTickerDisplay = (tickersToDisplay) => {
      this.setState({ tickersToDisplay });
    };

    this.calendarChange = (event, { name, value }) => {
      if (name === 'date') {
        const d = moment(value).format('YYYY-MM-DD');
        const cat = this.props.match.params && this.props.match.params.category;
        const catId = this.props.match.params &&
          this.props.match.params.id && parseInt(this.props.match.params.id, 10);

        this.props.navigateToPage(`/trend-analyzer/${cat}/${catId}?date=${d}`);
      }
    };

    this.setTrendAnalyzerError = error => false && error;
  }

  componentDidMount() {
    // if the url
    document.title = 'Bespoke | Trend Analyzer';
    // Get / Check stock data for selected category
    this.props.getCategories();
    this.props.getScreens();
    this.props.getPortfolios();
  }

  componentDidUpdate(prevProps) {
    // URL matching
    const {
      categories,
      match,
      screens,
      portfolios,
      getTrendAnalyzerTickers,
      location,
    } = this.props;
    const query = queryString.parse(location.search);
    // if (query.date && query.date !== date && !this.initialDate) {
    //   this.initialDate = true;
    //   this.updateDate(query.date);
    //   return;
    // }
    const date = query.date ? query.date : moment().format('YYYY-MM-DD');
    let cat = match.params && match.params.category;
    let catId = match.params && match.params.id && parseInt(match.params.id, 10);
    if (cat === undefined) {
      cat = 'etf';
    }

    if (catId === undefined) {
      let itemArray = [];
      switch (cat) {
        case 'etf':
        case 'sp500':
        case 'djia':
        case 'nasdaq':
          itemArray = categories.data;
          break;
        case 'screens':
          itemArray = screens.data;
          break;
        case 'custom':
          itemArray = Object.values(portfolios.byId);
          break;
        default:
          break;
      }
      for (let i = 0; i < itemArray.length; i += 1) {
        if (this.includeInSubmenu(itemArray[i], cat)) {
          catId = itemArray[i].id;
          break;
        }
      }
      if (cat === 'etf') {
        catId = 21; // hardcode go to us indices on load
      }
      if (catId) {
        this.props.navigateToPage(`/trend-analyzer/${cat}/${catId}?date=${date}`);
        return;
      }
    }

    if (cat) {
      if (cat !== this.state.subMenuCategory) {
        const newSubMenu = {};
        switch (cat) {
          case 'etf':
          case 'sp500':
          case 'djia':
          case 'nasdaq':
            if (categories.lastFetched) {
              for (let i = 0; i < categories.data.length; i += 1) {
                if (this.includeInSubmenu(categories.data[i], cat)) {
                  newSubMenu[categories.data[i].id] = {
                    key: categories.data[i].id,
                    name: categories.data[i].name,
                  };
                }
              }
              this.updateSubMenu(newSubMenu, cat);
            }
            break;
          case 'custom': {
            if (portfolios.lastFetched) {
              const pfKeys = Object.keys(portfolios.byId);
              for (let i = 0; i < pfKeys.length; i += 1) {
                newSubMenu[pfKeys[i]] = {
                  key: portfolios.byId[pfKeys[i]].id,
                  name: portfolios.byId[pfKeys[i]].name,
                };
              }
              this.updateSubMenu(newSubMenu, cat);
            }
            break;
          }
          case 'screens':
            if (screens.lastFetched) {
              for (let i = 0; i < screens.data.length; i += 1) {
                if (this.includeInSubmenu(screens.data[i], cat)) {
                  newSubMenu[screens.data[i].id] = {
                    key: screens.data[i].id,
                    name: screens.data[i].name,
                  };
                }
              }
              this.updateSubMenu(newSubMenu, cat);
            }
            if (screens.error !== null) {
              this.updateSubmenuError(screens.error);
            }
            break;
          default:
            break;
        }
      }
      // dispatch a promise to get the tickers and then update the display.
      if (
        prevProps.match !== this.props.match ||
        !this.initialLoad ||
        prevProps.location.search !== this.props.location.search
      ) {
        if (!this.initialLoad) {
          this.initialLoad = true;
        }

        if (catId === undefined) {
          this.updateTickerDisplay(undefined);
        }
        getTrendAnalyzerTickers(this.state.menuItems[cat].retrievalFunction, catId, date)
          .then((tickers) => {
            const results = Object.entries(tickers.tickers)
              .map(x => x[1]).filter(el => el !== undefined);
            const tickersDate = tickers.date.split('T')[0];
            if (tickersDate !== date) {
              this.props.navigateToPage(`/trend-analyzer/${cat}/${catId}?date=${tickersDate}`);
            }
            this.updateTickerDisplay(results);
          })
          .catch(err => this.setTrendAnalyzerError(err));
      }
    }
  }

  render() {
    // URL matching
    const {
      match,
      categories,
      screens,
      tickers,
      location,
    } = this.props;
    // load detection
    const menuLoading = categories.isLoading || screens.isLoading;
    const cat = match.params && match.params.category;
    const catId = match.params && match.params.id && parseInt(match.params.id, 10);
    const query = queryString.parse(location.search);
    const date = query.date ? query.date : moment().format('YYYY-MM-DD');

    const steps = [
      {
        selector: '.first-step',
        content: 'Our Trend Analyzer tool uses a proprietary algorithm to identify how stocks and ETFs across asset classes are trending so that users can make more informed decisions.',
      },
      {
        selector: '.second-step',
        content: 'Click through a number of ETF, index, and sector categories to view trends for the securities you\'re most interested in.  You can also view your Custom Portfolios with this tool as well as a number of screens like each day\'s 52-week highs and lows.',
      },
      {
        selector: '.third-step',
        content: 'Click the column headers to easily sort the stocks or ETFs in each category.',
      },
      {
        selector: '.forth-step',
        content: 'Our "Trend" score identifies whether the security is in a long-term uptrend, downtrend, or sideways trend.  Long-term uptrends are preferrable when looking for long ideas.',
      },
      {
        selector: '.fifth-step',
        content: 'Our "Timing" score lets users know whether a security is trading at a good or poor entry point relative to its historical trading range.  A security in an uptrend that has poor timing means it\'s too overbought at the moment and best to wait for a pullback for a better entry point.',
      },
      {
        selector: '.sixth-step',
        content: 'Look for securities in uptrends with good timing to find potential long ideas.  Securities in dowtrends with bad timing are best to avoid.  You can find all securities with these two specific setups in the Screens section.',
      },
      {
        selector: '.seventh-step',
        content: 'The "Trading Range" section lets you see where securities are trading relative to their normal trading range.  The dot represents its current price, while the tail end shows where it was trading one week ago.  The green shading represents "oversold" territory, with dark green representing extreme levels.  The red shading represents "overbought" territory.  The black, vertical line represents each security\'s 50-day moving average.',
      },
      {
        selector: '.eighth-step div.ui.mini.left.icon.input',
        content: 'You can look back at historical readings by changing the date.',
      },
      {
        selector: '.ninth-step',
        content: 'Download our Trend Analyzer readings to a CSV file, or copy and share a link to a certain category with other team members that have access.',
      },
      {
        selector: '.tenth-step',
        content: 'Our Trend Analyzer tool is available to Bespoke Premium and Bespoke Institutional members.  This tool is meant to be a starting point for further research and is NOT a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="trendAnalizerTouring"
        steps={steps}
      />);

    // Menu items
    const {
      menuItems,
      subMenuItems,
      tickersToDisplay,
      subMenuError,
    } = this.state;
    const mainMenu = (
      <Menu className="second-step" stackable>
        {Object.entries(menuItems).map(([, x]) => (
          <Menu.Item
            as={Link}
            to={`/trend-analyzer/${x.key}?date=${date}`}
            key={x.key}
            name={x.name}
            active={(x.key === cat)}
            position={x.pos}
          >
            {x.name}
          </Menu.Item>
        ))}
        {/* <Menu.Item */}
        {/* href="https://www.bespokepremium.com/trend_analyzer.php?download=1" */}
        {/* key="download" */}
        {/*  position="right" */}
        {/* > */}
        {/*  Download Today&apos;s Data */}
        {/* </Menu.Item> */}
      </Menu>
    );
    // Generate sub-menu
    const subMenu = (
      <Dimmer.Dimmable>
        <Dimmer style={{ borderRadius: '4px' }} inverted active={menuLoading || subMenuError !== null}>
          {(subMenuError !== null ? <p>{subMenuError && subMenuError.message}</p> : <Loader />)}
        </Dimmer>
        <Menu
          secondary
          // size="small"
          style={{
            flexWrap: 'wrap',
            justifyContent: 'left',
          }}
          className="archive-tabs"
        >
          {Object.entries(subMenuItems).map(([, x]) => (
            <Menu.Item
              as={Link}
              to={`/trend-analyzer/${cat}/${x.key}?date=${date}`}
              key={x.key}
              name={x.name}
              active={(x.key === catId)}
              position={x.pos}
            >
              {x.name}
            </Menu.Item>
          ))}
        </Menu>
      </Dimmer.Dimmable>
    );

    const id = cat === 'custom' && catId ? catId : '';
    const title = id === '' ? 'Share Trend Analyzer' : '';
    const text = id !== '' ? 'Copy link to share this page with other Bespoke Premium and Institutional members.' : '';

    // Extract the tickers
    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <style>{this.responsiveStyle}</style>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Grid>
                <Grid.Row column={2}>
                  <Grid.Column width={8}>
                    <h1 className="first-step">Trend Analyzer {tour}</h1>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <DateInput
                      name="date"
                      value={date}
                      onChange={this.calendarChange}
                      dateFormat="YYYY-MM-DD"
                      iconPosition="left"
                      initialDate={date}
                      size="mini"
                      style={{ width: '150px' }}
                      readOnly
                      className="eighth-step"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column className="right aligned content" verticalAlign="middle">
              <div className="ninth-step" style={{ display: 'inline-block' }}>
                <Button
                  color="blue"
                  size="mini"
                  onClick={() => { window.location = `https://www.bespokepremium.com/trend_analyzer.php?download=1&date=${date}`; }}
                >
                  <Icon name="download" />
                  DOWNLOAD
                </Button>
                <SharePortfolioModal disabled={!tickersToDisplay} id={id} title={title} text={text} search={`?date=${date}`} />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {mainMenu}
        {subMenu}
        {tickersToDisplay || tickers.isLoading ? <TrendAnalyzerCard
          height="auto"
          tickers={tickersToDisplay}
          loading={tickers.isLoading}
          name={(subMenuItems[catId] ? subMenuItems[catId].name : '')}
          date={date}
        />
        :
        <center>
          <p>
            You do not have any Custom Portfolio, click the button to add a new one.
            <br />
            <br />
            <Link to="/portfolio?action=add">
              <Button > Add Portfolio </Button>
            </Link>
          </p>
        </center>
        }

      </React.Fragment>
    );
  }
}

TrendAnalyzerPage.propTypes = {
  match: PropTypes.object.isRequired,
  tickers: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  screens: PropTypes.object.isRequired,
  portfolios: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  getPortfolios: PropTypes.func.isRequired,
  getScreens: PropTypes.func.isRequired,
  getTrendAnalyzerTickers: PropTypes.func.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  tickers: state.data.tickers,
  categories: state.data.categories,
  screens: state.data.screens,
  portfolios: state.user.portfolios,
  search: state.router.location.search, // for help menu
});
const mapDispatchToProps = dispatch => ({
  getCategories: cacheExpTime => dispatch(getCategories(cacheExpTime)),
  getPortfolios: cacheExpTime => dispatch(getPortfolios(cacheExpTime)),
  getScreens: cacheExpTime => dispatch(getScreens(cacheExpTime)),
  getTrendAnalyzerTickers: async (tickerRetrievalFunction, functionArgs, date = null) =>
    dispatch(getSummaryData(await tickerRetrievalFunction(functionArgs), 900000, date)),
  navigateToPage: url => dispatch(navigateToPage(url, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrendAnalyzerPage);
