import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const numStyle = (val) => {
  if (parseFloat(val) > 0) {
    return { color: 'green' };
  } else if (parseFloat(val) < 0) {
    return { color: 'red' };
  }
  return undefined;
};

const formatTicker = ticker => (<Link to={`/ticker/${ticker}`}>{ticker}</Link>);

const formatPercent = num => `${num}%`;

const formatDate = date => new Date(date)
  .toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const forceTwoDec = x => parseFloat(x).toFixed(2);

class EarningsCalendarTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { key: 'stock', text: 'Ticker', formatFunc: formatTicker },
      { key: 'company', text: 'Company' },
      { key: 'reportdate', text: 'Report Date', formatFunc: formatDate },
      { key: 'reporttime', text: 'Report Time' },
      {
        key: 'currenteps',
        text: 'EPS Est. ($/Shr)',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      { key: 'revisedeps', text: 'Rev. Est. ($,Mln)', formatFunc: numberWithCommas },
      { key: 'prioronfile', text: 'Prior On File' },
      { key: 'epsbeat', text: 'EPS Beat', formatFunc: formatPercent },
      { key: 'revenuebeat', text: 'Revenue Beat', formatFunc: formatPercent },
      { key: 'guidance', text: 'Guidance', formatFunc: formatPercent },
      {
        key: 'avg_gap',
        text: 'Avg. Gap % Change',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'avg_o2c',
        text: 'Avg. Open to Close % Change',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'avg_1day',
        text: 'Avg. 1 Day % Change',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      { key: 'avgabsolute', text: 'Average Volatility', formatFunc: forceTwoDec },
    ];
  }

  render() {
    const {
      displayData,
      sortChange,
      sortCol,
      sortDir,
    } = this.props;
    const arr = sortDir === 'DESC' ? `${String.fromCharCode(160)}↑` : `${String.fromCharCode(160)}↓`;
    const table = (
      <Table
        style={{ fontSize: '20px' }}
      >
        <Table.Header>
          <Table.Row>
            {this.columns.map(col => (
              <Table.HeaderCell
                onClick={() => sortChange(col.key)}
                key={col.key}
                style={{
                  cursor: 'pointer',
                  color: (sortCol === col.key ? 'darkblue' : undefined),
                  textAlign: col.key === 'stock' || col.key === 'company' ? 'left' : 'center',
                }}
              >
                {col.key === sortCol ? `${col.text}${arr}` : col.text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body style={{ whiteSpace: 'nowrap' }}>
          {displayData.map(row => (
            <Table.Row key={row.primary_key}>
              {this.columns.map(col => (
                <Table.Cell
                  key={col.key}
                  style={{
                    textAlign: col.key === 'stock' || col.key === 'company' ? 'left' : 'center',
                    ...(col.styleFunc ? col.styleFunc(row[col.key]) : {}),
                  }}
                >
                  {col.formatFunc ? col.formatFunc(row[col.key]) : row[col.key]}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );

    return table;
  }
}

EarningsCalendarTable.defaultProps = {
  sortChange: () => undefined,
  displayData: [],
};

EarningsCalendarTable.propTypes = {
  sortChange: PropTypes.func,
  displayData: PropTypes.array,
  sortCol: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
};

export default EarningsCalendarTable;
