import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dimmer, Loader, Search } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import EarningsCalendarInfo from './earningsCalendarInfo';
import * as api from '../../../api';

class EarningsCalendarInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.fetchSingleTicker = this.fetchSingleTicker.bind(this);
    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.TOTAL_MATCH_LIMIT = 10;

    this.state = {
      datesRange: '',
      searchLoading: false,
      searchResults: [],
      searchValue: '',
      tickerInfo: null,
    };
  }

  componentDidMount() {
    const { ticker } = this.props;
    if (ticker) {
      this.fetchSingleTicker(ticker, false);
    }
  }

  fetchSingleTicker(ticker, setPage = true) {
    // fetch the ticker and set state. should be in URL.
    if (setPage) {
      this.props.navigateToPage(`/earnings-calendar/${ticker}`);
    }
    api.tools.getECTicker(ticker)
      .then(result => this.setState({
        tickerInfo: result,
        searchLoading: false,
      }))
      .catch(() => this.setState({
        searchLoading: false,
      }));
  }

  handleDateChange(event, data) {
    const { name, value } = data;
    if (Object.prototype.hasOwnProperty.call(this.state, name)) {
      this.setState({ [name]: value });
    }
    this.props.handleDateChange(event, data);
  }

  handleSearchChange(e, data) {
    const { tickerList } = this.props;
    const exactMatches = [];
    const partialMatches = [];
    for (let i = 0, len = tickerList.length; i < len; i += 1) {
      // Assign vars
      const { company, stock } = tickerList[i];
      // check exact matches first
      if (company.toLowerCase() === data.value.toLowerCase()
        || stock.toUpperCase() === data.value.toUpperCase()) {
        exactMatches.push({ title: stock, description: company });
      } else if (company.toLowerCase().startsWith(data.value.toLowerCase()) ||
          stock.toUpperCase().startsWith(data.value.toUpperCase())) {
        partialMatches.push({ title: stock, description: company });
      }
    }
    const newResults = [...exactMatches, ...partialMatches]
      .slice(0, this.TOTAL_MATCH_LIMIT);
    // generate the updated list
    this.setState({
      searchValue: data.value,
      searchResults: newResults,
    });
  }

  handleSearchSelect(e, data) {
    const ticker = data.result.title;
    this.setState({
      searchLoading: true,
      searchValue: ticker,
    });
    this.fetchSingleTicker(ticker);
  }

  handleSearchFocus(e, data) {
    const { tickerInfo } = this.state;
    if (data.value === (tickerInfo && tickerInfo.ticker)) {
      this.setState({
        searchValue: '',
      });
    }
  }

  render() {
    const {
      minDate,
      maxDate,
      isLoading,
    } = this.props;
    const tickerSelector = (
      <Search
        placeholder="Search for tickers..."
        value={this.state.searchValue}
        loading={this.state.searchLoading}
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleSearchSelect}
        onFocus={this.handleSearchFocus}
        results={this.state.searchResults}
      />);
    const tickerDisplay = this.state.tickerInfo ?
      (<EarningsCalendarInfo tickerInfo={this.state.tickerInfo} />) : (null);
    const dateRange = (
      <DatesRangeInput
        name="datesRange"
        inline
        dateFormat="MM/DD/YYYY"
        value={this.state.datesRange}
        iconPosition="left"
        onChange={this.handleDateChange}
        minDate={minDate && new Date(minDate).toLocaleDateString()}
        maxDate={maxDate && new Date(maxDate).toLocaleDateString()}
      />);
    return (
      <Grid stackable columns="2">
        <Grid.Row>
          <Grid.Column style={{ fontSize: '20px' }} className="nohover">
            <p style={{ fontSize: '14px' }}>Select a date range</p>
            <Dimmer.Dimmable dimmed={isLoading}>
              <Dimmer inverted active={isLoading}>
                <Loader />
              </Dimmer>
              {dateRange}
            </Dimmer.Dimmable>
          </Grid.Column>
          <Grid.Column>
            <p>Search for tickers</p>
            {tickerSelector}
            {tickerDisplay}
          </Grid.Column>
        </Grid.Row>
      </Grid>);
  }
}

EarningsCalendarInput.defaultProps = {
  isLoading: false,
  minDate: undefined,
  maxDate: undefined,
  ticker: undefined,
  tickerList: [],
  handleDateChange: () => undefined,
};

EarningsCalendarInput.propTypes = {
  isLoading: PropTypes.bool,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
  ticker: PropTypes.string,
  tickerList: PropTypes.array,
  handleDateChange: PropTypes.func,
  navigateToPage: PropTypes.func.isRequired,
};

export default EarningsCalendarInput;
