import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'semantic-ui-react';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { setView } from '../../actions';

/**
 * @todo  link property looks smelly.
 */
function CardBase(props) {
  let ret;
  const propPass = {
    ...props,
  };
  delete propPass.linkString;
  delete propPass.state;
  delete propPass.onClickHandler;
  delete propPass.expandViewHandler;
  if (props.loadable) {
    ret = (
      <div className="dimmable">
        <div className={`ui inverted dimmer${props.isLoading ? ' active' : ''}`} style={{ borderRadius: '8px' }}>
          <div className="ui loader" />
        </div>
        <Card
          className={props.inverted ? 'inverted' : ''}
          // tabIndex={0}
          link={(props.linkString !== null)}
          onClick={props.linkString ? () => props.onClickHandler(props.linkString) : undefined}
          {...propPass}
        />
      </div>
    );
  } else {
    ret = (
      <Card
        className={props.inverted ? 'inverted' : ''}
        // tabIndex={0}
        link={(props.linkString !== null)}
        onClick={props.linkString ? () => props.onClickHandler(props.linkString) : undefined}
        {...propPass}
      />
    );
  }
  return ret;
}

CardBase.defaultProps = {
  linkString: null,
  loadable: undefined,
  isloading: undefined,
  inverted: undefined,
};

CardBase.propTypes = {
  expandViewHandler: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  linkString: PropTypes.string,
  loadable: PropTypes.bool,
  isloading: PropTypes.bool,
  inverted: PropTypes.bool,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  expandViewHandler: target => dispatch(setView(target)),
  onClickHandler: link => dispatch(push(link)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardBase);
