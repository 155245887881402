import React from 'react';
import UserInit from './UserInit';
import ResearchInit from './ResearchInit';

const Initialize = () => (
  <React.Fragment>
    <UserInit />
    <ResearchInit />
  </React.Fragment>
);

export default Initialize;
