import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Input,
  Segment,
  // Button,
} from 'semantic-ui-react';
import { setSeasonalityDates, getSeasonality, getSeasonalityComposite } from '../../../../actions/data/tools';
import MonthDayInput from './MonthDayInputRedux';

// Date selector, number of years selector
class SeasonalityInput extends React.PureComponent {
  componentDidUpdate() {
    const { category, dates } = this.props;
    this.props.getSeasonality(category, {
      numYears: dates.numYears,
      dates: {
        smonth: dates.smonth,
        sday: dates.sday,
        emonth: dates.emonth,
        eday: dates.eday,
      },
    });
    this.props.getSeasonalityComposite('SPY', dates.numYears);
  }

  render() {
    const {
      // smonth,
      // sday,
      // emonth,
      // eday,
      numYears,
      category,
    } = this.props.dates;
    const { setDate } = this.props;
    // get the month / day options from store
    return (
      <Segment className="fifth-step">
        <Grid columns={3} stackable className="compact">
          <Grid.Row >
            <Grid.Column >
              <MonthDayInput category={category} label="Start" monthTarget="smonth" dayTarget="sday" />
            </Grid.Column>
            <Grid.Column>
              <MonthDayInput category={category} label="End" monthTarget="emonth" dayTarget="eday" />
            </Grid.Column>
            <Grid.Column >
              <Input category={category} label={{ basic: true, content: 'Years' }} fluid type="number" min="3" max="20" value={numYears} onChange={(e, d) => setDate({ numYears: d.value })} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

SeasonalityInput.propTypes = {
  dates: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  getSeasonality: PropTypes.func.isRequired,
  getSeasonalityComposite: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  dates: state.data.seasonality.dates,
});

const mapDispatchToProps = dispatch => ({
  setDate: dates => dispatch(setSeasonalityDates(dates)),
  getSeasonality: (category, data) => dispatch(getSeasonality(category, data)),
  getSeasonalityComposite: (ticker, numYears) =>
    dispatch(getSeasonalityComposite(ticker, numYears)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonalityInput);
