import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3Axis from 'd3-axis';
import { select as d3Select } from 'd3-selection';
import { timeFormat } from 'd3-time-format';
import moment from 'moment';
import { timeYear, timeMonth, timeDay } from 'd3-time';

import './Axis.css';

class Axis extends Component {
  constructor(props) {
    super(props);
    this.updateScale(props);
  }

  componentDidMount() {
    this.renderAxis();
  }

  componentWillUpdate(nextProps) {
    this.updateScale(nextProps);
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  updateScale(props) {
    if (props.zoomTransform) {
      if (this.props.orient === 'Bottom') {
        this.scale = props.zoomTransform.rescaleX(props.scale);
      }
    }
  }

  calculateFormat() {
    const start = moment(this.props.scale.domain()[0]);
    const end = moment(this.props.scale.domain()[1]);
    const duration = moment.duration(end.diff(start));
    const years = duration.years();
    let months = duration.months();
    let days = duration.days();
    let every = 1;

    if (years > 12) {
      every = Math.ceil(years / 12);
      return ['%Y', timeYear.every(every)];
    }

    if (years >= 4) {
      return ['%Y', timeYear.every(every)];
    }

    months += years * 12;
    if (months > 6) {
      every = Math.ceil(months / 6);
      return ['%b %Y', timeMonth.every(every)];
    }

    if (months >= 4) {
      return ['%b %Y', timeMonth.every(every)];
    }

    days += months * 30;
    if (days > 6) {
      every = Math.ceil(days / 6);
      return ['%a %d', timeDay.every(every)];
    }

    return ['%a %d', timeDay.every(every)];
  }

  renderAxis() {
    const {
      isTime,
      timeFormatter,
      timeTicks,
      rotateX,
      zoomTransform,
      timeFormatterAuto,
    } = this.props;
    const len = this.props.scale.domain().length;
    const axisType = `axis${this.props.orient}`;
    const axis = d3Axis[axisType]()
      .scale(this.props.scale);
    if (!isTime) {
      axis.ticks(4);
    } else if (timeFormatterAuto) {
      const c = this.calculateFormat();
      axis.tickFormat(timeFormat(c[0]));
      axis.ticks(c[1]);
    } else {
      axis
        .tickFormat(timeFormat(timeFormatter))
        .tickSizeOuter(6)
        .tickPadding([12]);
      if (timeTicks) {
        axis.ticks(timeTicks[0], timeTicks[1])
          .tickSizeOuter(6)
          .tickPadding([12]);
      } else {
        axis.ticks(6)
          .tickSizeOuter(6)
          .tickPadding([12]);
      }
    }
    if (len > 10) {
      axis.tickValues(this.props.scale.domain().filter((d, i) => !(i % Math.floor(len / 5))));
    }
    if (rotateX > 0 && axisType === 'axisBottom') {
      d3Select(this.axisElement).call(axis)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', `rotate(-${rotateX})`);
    } else {
      d3Select(this.axisElement).call(axis);
    }

    if (zoomTransform) {
      console.log('Zoom Enabled');
    }
  }

  render() {
    return (
      <g
        className={`Axis Axis-${this.props.orient}`}
        ref={(el) => { this.axisElement = el; }}
        transform={this.props.translate}
      />
    );
  }
}

Axis.defaultProps = {
  isTime: false,
  timeFormatter: '%-m/%-d/%y',
  timeTicks: undefined,
  rotateX: 0,
  zoomTransform: undefined,
  timeFormatterAuto: false,
};

Axis.propTypes = {
  orient: PropTypes.string.isRequired,
  scale: PropTypes.func.isRequired,
  translate: PropTypes.string.isRequired,
  isTime: PropTypes.bool,
  timeFormatter: PropTypes.string,
  timeTicks: PropTypes.array,
  rotateX: PropTypes.number,
  zoomTransform: PropTypes.object,
  timeFormatterAuto: PropTypes.bool,
};

export default Axis;
