
import React from 'react';
import PropTypes from 'prop-types';
import { ChartCanvas, Chart } from 'react-stockcharts';
import {
  CandlestickSeries,
  BarSeries,
  LineSeries,
  OHLCSeries,
} from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  MouseCoordinateX,
  MouseCoordinateY,
  CrossHairCursor,
  CurrentCoordinate,
} from 'react-stockcharts/lib/coordinates';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { fitWidth } from 'react-stockcharts/lib/helper';
// import debounce from 'lodash/debounce';
import { last } from 'react-stockcharts/lib/utils';
import { sma } from 'react-stockcharts/lib/indicator';
import Colors from './colors';

// const debouncedFitWidth = debounce(fitWidth, 150);

class CandleStickStockScaleChart extends React.PureComponent {
  render() {
    const {
      type,
      initialData,
      width,
      height,
      range,
    } = this.props;
    const xScaleProvider = discontinuousTimeScaleProvider
      .inputDateAccessor(d => d.date);
    const sma20 = sma()
      .options({ windowSize: 20, className: 'sma20' })
      .merge((d, c) => ({ ...d, sma20: c }))
      .accessor(d => d.sma20)
      .stroke('#1B9CFC')
      .fill('#1B9CFC');
    const sma50 = sma()
      .options({ windowSize: 50, className: 'sma50' })
      .merge((d, c) => ({ ...d, sma50: c }))
      .accessor(d => d.sma50)
      .stroke('#1B9CFC')
      .fill('#1B9CFC');
    const sma100 = sma()
      .options({ windowSize: 100, className: 'sma100' })
      .merge((d, c) => ({ ...d, sma100: c }))
      .accessor(d => d.sma100)
      .stroke('#182C61')
      .fill('#182C61');
    const sma200 = sma()
      .options({ windowSize: 200, className: 'sma200' })
      .merge((d, c) => ({ ...d, sma200: c }))
      .accessor(d => d.sma200)
      .stroke('#182C61')
      .fill('#182C61');
    const calculatedData = sma200(sma100(sma50(sma20(initialData))));
    const {
      data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = (xScaleProvider(calculatedData));
    console.log(range);
    const xExtents = [
      xAccessor(last(data)),
      xAccessor(data.length >= 126 && range === 0 ? data[data.length - 126] : data[0]),
    ];

    // let series = (
    //   <CandlestickSeries
    //     fill={d => (d.close > d.open ? Colors.green : Colors.red)}
    //     stroke={d => (d.close > d.open ? Colors.green : Colors.red)}
    //     wickStroke={d => (d.close > d.open ? Colors.green : Colors.red)}
    //   />);

    // if (chartType === 'ohlc') {
    //   series = (
    //     <OHLCSeries
    //       stroke={d => (d.close > d.open ? Colors.green : Colors.red)}
    //     />
    //   );
    // }

    return (
      <div style={{ marginLeft: '-22px' }}>
        <ChartCanvas
          height={height}
          width={width + 50}
          ratio={1}
          margin={{
            left: 58, right: 45, top: 10, bottom: 30,
          }}
          type={type}
          seriesName="Close"
          data={data}
          xScale={xScale}
          xAccessor={xAccessor}
          displayXAccessor={displayXAccessor}
          xExtents={xExtents}
          panEvent={false}
          zoomEvent={false}
          clamp
          padding={2}
        >
          <Chart
            id={1}
            yExtents={[d => [d.high, d.low], sma50.accessor(), sma200.accessor()]}
            height={height * 0.65}
            origin={[0, 0]}
          >

            <YAxis stroke="#000" axisAt="left" orient="left" ticks={5} />
            <OHLCSeries
              className="ohlc chart-series"
              stroke={d => (d.close > d.open ? Colors.green : Colors.red)}
              classNames={d => (d.close > d.open ? 'up' : 'down')}
            />
            <CandlestickSeries
              className="candle chart-series"
              fill={d => (d.close > d.open ? Colors.green : Colors.red)}
              stroke={d => (d.close > d.open ? Colors.green : Colors.red)}
              wickStroke={d => (d.close > d.open ? Colors.green : Colors.red)}
            />
            <LineSeries className="line chart-series" yAccessor={d => d.close} stroke="#000" />
            <LineSeries className="sma20" yAccessor={sma20.accessor()} stroke={sma20.stroke()} />
            <LineSeries className="sma50" yAccessor={sma50.accessor()} stroke={sma50.stroke()} />
            <LineSeries className="sma100" yAccessor={sma100.accessor()} stroke={sma100.stroke()} />
            <LineSeries className="sma200" yAccessor={sma200.accessor()} stroke={sma200.stroke()} />
            <CurrentCoordinate yAccessor={sma50.accessor()} fill={sma50.stroke()} />
            <CurrentCoordinate yAccessor={sma200.accessor()} fill={sma200.stroke()} />
            <MouseCoordinateX
              at="bottom"
              orient="bottom"
              displayFormat={timeFormat('%Y-%m-%d')}
            />
            <MouseCoordinateY
              at="left"
              orient="left"
              displayFormat={format('.2f')}
            />
          </Chart>
          <Chart
            id={2}
            height={(height * 0.25) - 20}
            yExtents={[d => d.volume, 0]}
            origin={() => [0, (height * 0.65) + 10]}
          >
            <YAxis stroke="#000" axisAt="left" orient="left" ticks={2} tickFormat={format('.2s')} />
            <XAxis
              axisAt="bottom"
              orient="bottom"
              ticks={4}
              // tickFormat={d => d}
              // displayFormat={(d) => { console.log(d); return d; }}
            />
            <MouseCoordinateY
              at="left"
              orient="left"
              displayFormat={format('.4s')}
            />
            <BarSeries
              yAccessor={d => d.volume}
              fill="#bbb"
            />
          </Chart>
          <CrossHairCursor />
        </ChartCanvas>
      </div>
    );
  }
}

CandleStickStockScaleChart.propTypes = {
  initialData: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']),
  height: PropTypes.number,
  range: PropTypes.number,
};

CandleStickStockScaleChart.defaultProps = {
  type: 'svg',
  height: 200,
  range: 0,
};

export default fitWidth(CandleStickStockScaleChart);
