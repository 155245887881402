import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const backButton = ({ history }) => <Icon link color="teal" name="arrow left" onClick={() => history.goBack()} />;

backButton.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(backButton);
