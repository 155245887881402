import React from 'react';
import { Modal, Icon, Input, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class SharePortfolioModal extends React.PureComponent {
  state = { open: false, label: 'COPY' };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });

  handleCopy = () => {
    document.getElementsByClassName('shareLink')[0].select();
    document.execCommand('copy');
    this.setState({ label: 'COPIED!' });
  };

  render() {
    const { open, label } = this.state;
    const {
      id,
      title,
      text,
      disabled,
      search,
      location,
    } = this.props;
    const pfID = btoa(id);
    let link = `https://bespokepremium.com/interactive/portfolio/copy/${pfID}`;

    if (id === '') {
      let s = location.search;
      if (search) {
        s = search;
      }
      link = `https://bespokepremium.com/interactive${this.props.pathname}${s}`;
    }

    let titleHeader = title;
    let textContent = text;
    if (title === '') {
      titleHeader = 'Share This Portfolio';
    }
    if (text === '') {
      textContent = 'Copy link to share portfolio with other Bespoke Premium and Institutional members.';
    }
    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click(), disabled })}
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={open}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
          {...this.props}
        >
          <center>
            <br />
            <h1>{titleHeader}</h1>
            <Modal.Content>
              {textContent}
              <br />
              <br />
              <Input
                style={{ width: '80%' }}
                defaultValue={link}
                action
              >
                <input className="shareLink" />
                <Button
                  color="teal"
                  labelPosition="right"
                  icon
                  onClick={this.handleCopy}
                >
                  {label}<Icon name="copy" />
                </Button>
              </Input>
              <br />
              <br />
              <br />
              <br />
            </Modal.Content>
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

SharePortfolioModal.defaultProps = {
  content: (
    <Button color="blue" size="mini">
      <Icon link name="share square" />SHARE
    </Button>
  ),
  id: '',
  title: '',
  text: '',
  disabled: false,
  search: null,
};

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
});

SharePortfolioModal.propTypes = {
  content: PropTypes.element,
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  location: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(SharePortfolioModal));
