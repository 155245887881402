import * as TickerAPI from './ticker';
import * as ResearchAPI from './research';
import * as ScreenAPI from './screen';
import * as UserAPI from './user';
import * as CategoryAPI from './category';
import * as ToolsAPI from './tools';
import * as MailchimpAPI from './mailchimp';
import executeQuery from './query';

export const query = executeQuery;
export const research = ResearchAPI;
export const ticker = TickerAPI;
export const screen = ScreenAPI;
export const category = CategoryAPI;
export const user = UserAPI;
export const tools = ToolsAPI;
export const mailchimp = MailchimpAPI;
