import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Grid, Label, Table } from 'semantic-ui-react';
import InternationalRevenuesTable from '../presentational/interactive/internationalRevenuesTable';
import InternationalRevenuesSearch from '../presentational/interactive/internationalRevenuesSearch';
import { navigateToPage } from '../../actions/search';
import { getList } from '../../actions/data/tickers';
import * as api from '../../api';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';

const sortFunc = (col, dir) => (a, b) => {
  let varA;
  let varB;
  const naVal = (dir === 'DESC' ? -Infinity : Infinity);
  switch (col) {
    case 'ticker':
    case 'company':
    case 'sector':
      varA = (typeof a[col] === 'string') ?
        a[col].toUpperCase() : a[col];
      varB = (typeof b[col] === 'string') ?
        b[col].toUpperCase() : b[col];
      break;
    case 'recentdom':
    case 'year1dom':
    case 'year2dom':
    case 'recentint':
    case 'year1int':
    case 'year2int':
      varA = a[col] === 'n/a' ? naVal : parseFloat(a[col]);
      varB = b[col] === 'n/a' ? naVal : parseFloat(b[col]);
      break;
    default:
      return 0;
  }

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (dir === 'DESC' ? (comparison * -1) : comparison);
};

const calcAgg = (sectors, result) => {
  const agg = {};
  sectors.forEach((sector) => {
    agg[sector.value] = {
      recentdom: [],
      year1dom: [],
      year2dom: [],
      recentint: [],
      year1int: [],
      year2int: [],
    };
  });
  for (let i = 0; i < result.length; i += 1) {
    const {
      sector,
      recentdom,
      year1dom,
      year2dom,
      recentint,
      year1int,
      year2int,
    } = result[i];
    agg.all.recentdom.push(recentdom);
    agg[sector].recentdom.push(recentdom);
    agg.all.year1dom.push(year1dom);
    agg[sector].year1dom.push(year1dom);
    agg.all.year2dom.push(year2dom);
    agg[sector].year2dom.push(year2dom);
    agg.all.recentint.push(recentint);
    agg[sector].recentint.push(recentint);
    agg.all.year1int.push(year1int);
    agg[sector].year1int.push(year1int);
    agg.all.year2int.push(year2int);
    agg[sector].year2int.push(year2int);
  }
  // reduce
  sectors.forEach((sector) => {
    Object.entries(agg[sector.value]).forEach(([key, value]) => {
      const filtered = value.filter(x => x !== 'n/a');
      const len = filtered.length;
      agg[sector.value][key] = filtered.reduce((acc, curr) =>
        acc + parseFloat(curr), 0) / len;
    });
  });
  return agg;
};

// @todo
// - Create + display aggregate data
// - Determine when "sector" is really a ticker
class InternationalRevenues extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearchSelect = this.handleSearchSelect.bind(this);
    this.handleSectorChange = this.handleSectorChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.fetchIRData = this.fetchIRData.bind(this);

    this.cacheExpTime = 900000;
    this.sectors = [
      { value: 'all', text: 'All' },
      { value: 'Cons. Discret.', text: 'Consumer Discret.' },
      { value: 'Cons. Staples', text: 'Consumer Staples' },
      { value: 'Energy', text: 'Energy' },
      { value: 'Financials', text: 'Financials' },
      { value: 'Health Care', text: 'Health Care' },
      { value: 'Industrials', text: 'Industrials' },
      { value: 'Materials', text: 'Materials' },
      { value: 'Real Estate', text: 'Real Estate' },
      { value: 'Technology', text: 'Technology' },
      { value: 'Comm. Svcs.', text: 'Telecom Services' },
      { value: 'Utilities', text: 'Utilities' },
    ];
    for (let i = 0, len = this.sectors.length; i < len; i += 1) {
      this.sectors[i].key = this.sectors[i].value;
    }
    this.state = {
      searchTickers: [],
      isLoading: true,
      sortCol: 'ticker',
      sortDir: 'ASC',
      data: {
        lastFetched: null,
        rows: [],
        aggregates: {},
      },
      displayData: [],
    };

    // Responsive styles (first column is fixed)
    this.responsiveStyle = '.table-scrollX { margin:1em 0; }';
    this.responsiveStyle += '@media screen and (max-width: 768px) {';
    this.responsiveStyle += '.table-scrollX { position:relative; width:100%; z-index:1; overflow-x:scroll; border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
    this.responsiveStyle += '.table-scrollX table.left-fixed { width: 100%; border: none !important; }';
    // this.responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
    // // @todo - needs to be autocomputed based on height of first row
    // this.responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
    // this.responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
    // this.responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
    // this.responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
    // // Remove left border from second col
    // this.responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
    // // Row hover z-index;
    // this.responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
    this.responsiveStyle += '}';
  }

  componentDidMount() {
    // Get list of all tickers for links
    this.props.getList();
    // Get initial data
    this.fetchIRData(true);
  }

  componentDidUpdate() {
    // check if data is stale
    if (!this.state.isLoading) {
      this.fetchIRData();
    }
  }

  fetchIRData(initial = false) {
    let sector = this.props.match && this.props.match.params && this.props.match.params.sector;
    const ticker = this.props.match && this.props.match.params && this.props.match.params.ticker;
    const { sortCol, sortDir } = this.state;
    if (!sector) {
      sector = 'all';
    }
    const { data, isLoading } = this.state;
    if (Date.now() - this.cacheExpTime > data.lastFetched) {
      if (!initial && !isLoading) {
        this.setState({ isLoading: true });
      }
      api.tools.getIRData()
        .then((result) => {
          const newState = {
            isLoading: false,
            data: {
              lastFetched: Date.now(),
              rows: result,
              aggregates: calcAgg(this.sectors, result),
            },
          };
          if (initial) {
            newState.searchTickers = [];
          }
          for (let i = 0; i < result.length; i += 1) {
            const row = result[i];
            if (initial) {
              newState.searchTickers.push({
                ticker: row.ticker,
                company: row.company,
                sector: row.sector,
              });
            }
            if (ticker === row.ticker) {
              ({ sector } = row);
            }
          }
          newState.displayData = result.filter(x => (sector === 'all' ? true : sector === x.sector))
            .sort(sortFunc(sortCol, sortDir));
          return this.setState(newState);
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  handleSortChange(column) {
    let newDir = 'ASC';
    const { sortCol, sortDir, displayData } = this.state;
    if (sortCol === column) {
      newDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    const sortedData = displayData.sort(sortFunc(column, newDir));
    this.setState({
      sortCol: column,
      sortDir: newDir,
      displayData: sortedData,
    });
  }

  handleSectorChange(event, data) {
    // Re-Filter + sort (if different)
    let oldSector = this.props.match && this.props.match.params && this.props.match.params.sector;
    if (!oldSector) {
      oldSector = 'all';
    }
    const sector = data.value;
    if (oldSector !== sector) {
      const newData = this.state.data.rows
        .filter(x => (sector === 'all' ? true : sector === x.sector))
        .sort(sortFunc(this.state.sortCol, this.state.sortDir));
      this.setState({
        displayData: newData,
      });
    }
    this.props.navigateToPage(`/international-revenues/${sector}`);
  }

  handleSearchSelect(event, data) {
    const ticker = data.result.title;
    const { sector } = data.result;
    // Change state accordingly
    let oldSector = this.props.match && this.props.match.params && this.props.match.params.sector;
    if (!oldSector) {
      oldSector = 'all';
    }
    if (oldSector !== sector) {
      const newData = this.state.data.rows
        .filter(x => (sector === 'all' ? true : sector === x.sector))
        .sort(sortFunc(this.state.sortCol, this.state.sortDir));
      this.setState({
        displayData: newData,
      });
    }
    this.props.navigateToPage(`/international-revenues/ticker/${ticker}`);
  }

  render() {
    let errorMessage = null;
    let { sector, ticker } = this.props.match && this.props.match.params;
    if (!sector && !ticker) {
      sector = 'all';
    }
    const tickers = this.props.tickers && this.props.tickers.list;
    const {
      displayData,
      sortDir,
      sortCol,
      searchTickers,
      data,
    } = this.state;

    const steps = [
      {
        selector: '.first-step',
        content: 'Our International Revenues Database provides a geographic revenue breakdown for stocks in the Russell 1,000.',
      },
      {
        selector: '.second-step',
        content: 'Investors should know a stock\'s revenue breakdown because of the impact that currency moves and geo-political risks can have on numbers.',
      },
      {
        selector: '.third-step',
        content: 'Stocks with high international revenue exposure benefit from a weak US dollar and are hurt by US dollar strength.  Stocks with high domestic revenue exposure benefit from a strong US dollar and are more insulated from global economic weakness and trade concerns.',
      },
      {
        selector: '.forth-step',
        content: 'Select a sector to view the international vs. domestic revenue breakdown over the past three years for every stock in the sector.  You can click any of the columns to sort the list.',
      },
      {
        selector: '.fifth-step',
        content: 'Enter a specific ticker (only stocks in the Russell 1,000) to view that company\'s international vs. domestic revenue breakdown and compare it to industry averages.',
      },
      {
        selector: '.sixth-step',
        content: 'Our International Revenues Database is available to Bespoke Premium and Bespoke Institutional members and is updated each year in early May.  Most companies only break out their geographic revenue exposure in their annual reports that are usually published in April.',
      },
    ];
    const tour = (
      <Touring
        cookieName="internationalRevenueTouring"
        steps={steps}
      />);

    for (let i = 0; i < data.rows.length; i += 1) {
      const row = data.rows[i];
      if (row.ticker.toUpperCase() === ticker) {
        ticker = row;
        ({ sector } = row);
        break;
      }
    }
    // Check if sector is valid
    const isValidSector = this.sectors.map(x => x.value).includes(sector);
    if (!isValidSector) {
      if (ticker) {
        errorMessage = (<h2>Invalid Ticker</h2>);
      } else {
        errorMessage = (<h2>Invalid Sector</h2>);
      }
      if (this.state.isLoading) {
        errorMessage = null;
      }
    }
    const filterInput = (
      <InternationalRevenuesSearch
        onSelect={this.handleSearchSelect}
        tickers={searchTickers}
      />
    );
    const selector = (
      <Dropdown
        style={{ maxWidth: '300px' }}
        closeOnChange
        onChange={this.handleSectorChange}
        selection
        search
        loading={this.state.isLoading}
        placeholder="Select a sector..."
        value={sector}
        options={this.sectors}
        className="forth-step"
      />);
    const aggregate = data.lastFetched !== null && isValidSector ? (
      <div className="table-scrollX second-step">
        <Table
          celled
          unstackable
          style={{ fontSize: '20px' }}
          className="left-fixed"
        >
          <Table.Header>
            <Table.Row key="pre-header">
              <Table.HeaderCell />
              <Table.HeaderCell style={{ textAlign: 'center' }} colSpan="3">
                Percentage Domestic (%)
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }} colSpan="3">
                Percentage International (%)
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row key="main-header">
              <Table.HeaderCell />
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                Most Recent
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                1 Year Ago
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                2 Years Ago
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                Most Recent
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                1 Year Ago
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>
                2 Years Ago
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { ticker ? (
              <Table.Row>
                <Table.Cell>
                  <strong>{`${ticker.company} (${ticker.ticker})`}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.recentdom !== 'n/a' ?
                    parseFloat(ticker.recentdom).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.year1dom !== 'n/a' ?
                    parseFloat(ticker.year1dom).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.year2dom !== 'n/a' ?
                    parseFloat(ticker.year2dom).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.recentint !== 'n/a' ?
                    parseFloat(ticker.recentint).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.year1int !== 'n/a' ?
                    parseFloat(ticker.year1int).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  <strong>{ticker.year2int !== 'n/a' ?
                    parseFloat(ticker.year2int).toFixed(1) : 'n/a'}
                  </strong>
                </Table.Cell>
              </Table.Row>
              ) : null}
            { sector && sector !== 'all' ? (
              <Table.Row>
                <Table.Cell>
                  {`${sector} (Average)`}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].recentdom.toFixed(1)}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].year1dom.toFixed(1)}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].year2dom.toFixed(1)}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].recentint.toFixed(1)}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].year1int.toFixed(1)}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'center' }}>
                  {data.aggregates[sector].year2int.toFixed(1)}
                </Table.Cell>
              </Table.Row>) : null}
            <Table.Row key="all">
              <Table.Cell>
                Russell 1000 (Average)
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.recentdom.toFixed(1)}
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.year1dom.toFixed(1)}
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.year2dom.toFixed(1)}
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.recentint.toFixed(1)}
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.year1int.toFixed(1)}
              </Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>
                {data.aggregates.all.year2int.toFixed(1)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>) : null;
    const table = isValidSector ? (
      <InternationalRevenuesTable
        sortChange={this.handleSortChange}
        data={displayData}
        tickers={tickers}
        sortDir={sortDir}
        sortCol={sortCol}
      />) : errorMessage;
    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <style>{this.responsiveStyle}</style>
        <h1 className="first-step">International Revenues Database {tour}</h1>
        <Grid doubling stackable columns="4">
          <Grid.Column key="sector-selector">
            <Grid.Row>
              {selector}
            </Grid.Row>
            <Grid.Row>
              <Label pointing>Select a sector</Label>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column key="filter-input">
            <Grid.Row>
              {filterInput}
            </Grid.Row>
            <Grid.Row>
              <Label pointing>Filter results by company or ticker</Label>
            </Grid.Row>
          </Grid.Column>
        </Grid>
        {aggregate}
        {table}
      </React.Fragment>
    );
  }
}

InternationalRevenues.propTypes = {
  match: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  tickers: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  tickers: state.data.tickerList,
});

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => dispatch(navigateToPage(url, true)),
  getList: () => dispatch(getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternationalRevenues);
