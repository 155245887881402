import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import SvgGauge from '../../SvgGauge';
import { zScoreToPercentile } from '../../../../../server/helpers/statistics';

const WINDOW_SIZE_QUARTERS = 12;

const guidanceMap = {
  Raised: 1,
  Lowered: -1,
  Inline: 0,
};

const percentileSumFactors = (factors) => {
  let totalWeight = 0;
  let percentileSum = 0;

  factors.forEach((factor) => {
    const curWeight = factor.weight;
    factor.list.forEach((listItem) => {
      if (listItem.value !== undefined) {
        totalWeight += curWeight;
        percentileSum += curWeight *
          zScoreToPercentile((listItem.value - listItem.mean) / listItem.std);
      }
    });
  });

  return Math.round((100 * percentileSum) / totalWeight);
};

class EarningsGauges extends React.PureComponent {
  render() {
    const {
      benchmark,
      bespokeEarnings,
      tableData,
      // tableDataLoading,
    } = this.props;
    const scores = {
      eps: 0,
      sales: 0,
      reaction: 0,
      bespoke: 0,
    };
    // Calculate the scores
    if (tableData.length > 0) {
      const len = tableData.length;
      const result = tableData.reduce((acc, curr) => {
        if (curr.eps_avse > 0) { acc.epsbeats += 1; }
        if (curr.rev_avse > 0) { acc.revbeats += 1; }
        if (curr.one_day_pct > 0) { acc.reactbeats += 1; }
        return acc;
      }, { epsbeats: 0, revbeats: 0, reactbeats: 0 });
      result.epsbeats /= len;
      result.revbeats /= len;
      result.reactbeats /= len;

      scores.eps = Math.round(result.epsbeats * 100);
      scores.sales = Math.round(result.revbeats * 100);
      scores.reaction = Math.round(result.reactbeats * 100);
      // Calculate bespoke score
      // Take most recent 8 quarters
      const tableSlice = tableData.slice(0, WINDOW_SIZE_QUARTERS);
      const lastXQuarters = tableSlice.reduce((acc, curr) => {
        if (curr.eps_avse > 0) {
          acc.eps_br += 1;
          if (acc.pct_chg_beat === undefined) {
            acc.pct_chg_beat = 0;
          }
          acc.pct_chg_beat += curr.one_day_pct;
        } else if (curr.eps_avse < 0) {
          acc.eps_mr += 1;
          if (acc.pct_chg_miss === undefined) {
            acc.pct_chg_miss = 0;
          }
          acc.pct_chg_miss += curr.one_day_pct;
        }
        if (curr.rev_avse > 0) { acc.sales_br += 1; }
        if (guidanceMap[curr.guidance] !== undefined) {
          acc.issued_guidance += 1;
          if (acc.guidance_spread === undefined) {
            acc.guidance_spread = 0;
          }
          acc.guidance_spread += guidanceMap[curr.guidance];
        }
        if (curr.one_day_pct > 0) { acc.pct_pos += 1; }
        return acc;
      }, {
        eps_br: 0,
        eps_mr: 0,
        sales_br: 0,
        guidance_spread: undefined,
        issued_guidance: 0,
        pct_pos: 0,
        pct_chg_beat: undefined,
        pct_chg_miss: undefined,
      });
      if (lastXQuarters.pct_chg_beat !== undefined) {
        lastXQuarters.pct_chg_beat /= lastXQuarters.eps_br;
      }
      if (lastXQuarters.pct_chg_miss !== undefined) {
        lastXQuarters.pct_chg_miss /= lastXQuarters.eps_mr;
      }
      lastXQuarters.eps_br /= tableSlice.length;
      lastXQuarters.sales_br /= tableSlice.length;
      lastXQuarters.pct_pos /= tableSlice.length;
      if (lastXQuarters.guidance_spread !== undefined) {
        lastXQuarters.guidance_spread /= lastXQuarters.issued_guidance;
      }

      // Calculate Percentile for each
      const factors = [
        {
          weight: 0.5,
          list: [
            {
              value: lastXQuarters.eps_br,
              mean: bespokeEarnings.eps_beat[0],
              std: bespokeEarnings.eps_beat[1],
            },
            {
              value: lastXQuarters.sales_br,
              mean: bespokeEarnings.rev_beat[0],
              std: bespokeEarnings.rev_beat[1],
            },
          ],
        },
        {
          weight: 1,
          list: [
            {
              value: lastXQuarters.guidance_spread,
              mean: bespokeEarnings.guidance[0],
              std: bespokeEarnings.guidance[1],
            },
            {
              value: lastXQuarters.pct_pos,
              mean: bespokeEarnings.pct_pos[0],
              std: bespokeEarnings.pct_pos[1],
            },
            {
              value: lastXQuarters.pct_chg_beat,
              mean: bespokeEarnings.beat_pct_chg[0],
              std: bespokeEarnings.beat_pct_chg[1],
            },
            {
              value: lastXQuarters.pct_chg_miss,
              mean: bespokeEarnings.miss_pct_chg[0],
              std: bespokeEarnings.miss_pct_chg[1],
            },
          ],
        },
      ];
      // Calculate the z score sum of all factors
      scores.bespoke = percentileSumFactors(factors);
    }

    return (
      <React.Fragment>
        <Grid.Row style={{ padding: '10px 0px' }} columns={2} >
          <Grid.Column style={{ padding: '0px 2em' }} >
            <SvgGauge
              title="EPS Beat (%)"
              value={scores.eps}
              tickMark={benchmark.eps_beat_avg * 100}
            />
          </Grid.Column>
          <Grid.Column style={{ padding: '0px 2em' }} >
            <SvgGauge
              title="Sales Beat (%)"
              value={scores.sales}
              tickMark={benchmark.rev_beat_avg * 100}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: '10px 0px' }} columns={2} >
          <Grid.Column style={{ padding: '0px 2em' }} >
            <SvgGauge
              title="1 Day Positive Reaction (%)"
              min={0}
              max={100}
              value={scores.reaction}
              tickMark={benchmark.react_beat_avg * 100}
            />
          </Grid.Column>
          <Grid.Column style={{ padding: '0px 2em' }} >
            <SvgGauge
              title="Bespoke Earnings Score"
              value={scores.bespoke}
              tickMark={50}
            />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

EarningsGauges.propTypes = {
  benchmark: PropTypes.object.isRequired,
  bespokeEarnings: PropTypes.object.isRequired,
  tableData: PropTypes.array.isRequired,
  // tableDataLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  benchmark: state.view.earningsExplorer.benchmark,
  bespokeEarnings: state.view.earningsExplorer.bespokeEarnings,
  tableData: state.view.earningsExplorer.tableData,
  // tableDataLoading: state.view.earningsExplorer.tableDataLoading,
});

export default connect(mapStateToProps, null)(EarningsGauges);
