import {
  USER_STAR_POST,
  USER_UNSTAR_POST,
  USER_SET_PORTFOLIOS,
  USER_SET_TICKER_CALLS,
  USER_SET_SETTINGS,
  USER_HYDRATE,
  ADD_TO_PORTFOLIO,
  REMOVE_FROM_PORTFOLIO,
  LOCK_SCREEN,
} from '../types/actions';

/**
 * Reducer for user property of Redux store
 * @param {object} state
 * @param {object} action
 */
export default (state = {
  tickerCalls: {
    byId: {
      Bullish: [],
      Bearish: [],
    },
    lastFetched: null,
  },
  starred: {
    byId: {},
    lastFetched: null,
  },
  portfolios: {
    byId: {},
    lastFetched: null,
    names: [],
  },
  settings: {
    display: {},
    chartScanner: {
      sortCol: 'ticker',
      sortDir: 'ASC',
      type: 'candle', // can be one of: 'candle', 'line', or 'ohlc'
      colors: {
        line: '#000000',
        up: '#00c200',
        down: '#ff0000',
        dma20: '#15b5c2',
        dma50: '#0096ff',
        dma100: '#850084',
        dma200: '#ff8100',
      },
      indicators: {
        dma20: false,
        dma50: true,
        dma100: false,
        dma200: true,
      },
      range: 0,
    },
  },
  access: null,
  lockScreen: false,
}, action) => {
  let newState;
  let oldMembers;
  switch (action.type) {
    case USER_STAR_POST:
      return {
        ...state,
        starred: {
          ...state.starred,
          byId: {
            ...state.starred.byId,
            [action.id]: true,
          },
        },
      };
    case USER_UNSTAR_POST:
      return {
        ...state,
        starred: {
          ...state.starred,
          byId: {
            ...state.starred.byId,
            [action.id]: undefined,
          },
        },
      };
    case USER_SET_PORTFOLIOS:
      return {
        ...state,
        portfolios: {
          ...action.portfolios,
        },
      };
    case USER_HYDRATE:
      return {
        ...state,
        ...action.payload,
      };
    case USER_SET_TICKER_CALLS: {
      const newTickerCalls = {
        ...state.tickerCalls,
      };
      Object.keys(action.tickerObj).forEach((key) => {
        if (action.tickerObj[key] === 0) {
          // Remove from Bullish
          const bullIdx = newTickerCalls.byId.Bullish.indexOf(key);
          if (bullIdx > -1) {
            newTickerCalls.byId.Bullish.splice(bullIdx, 1);
          }
          // Remove from Bearish
          const bearIdx = newTickerCalls.byId.Bearish.indexOf(key);
          if (bearIdx > -1) {
            newTickerCalls.byId.Bearish.splice(bearIdx, 1);
          }
        } else if (action.tickerObj[key] === 1) {
          // Add to Bullish
          const bullIdx = newTickerCalls.byId.Bullish.indexOf(key);
          if (bullIdx === -1) {
            newTickerCalls.byId.Bullish.push(key);
          }
          // Remove from Bearish
          const bearIdx = newTickerCalls.byId.Bearish.indexOf(key);
          if (bearIdx > -1) {
            newTickerCalls.byId.Bearish.splice(bearIdx, 1);
          }
        } else if (action.tickerObj[key] === 2) {
          // Add to Bearish
          const bearIdx = newTickerCalls.byId.Bearish.indexOf(key);
          if (bearIdx === -1) {
            newTickerCalls.byId.Bearish.push(key);
          }
          // Remove from Bullish
          const bullIdx = newTickerCalls.byId.Bullish.indexOf(key);
          if (bullIdx > -1) {
            newTickerCalls.byId.Bullish.splice(bullIdx, 1);
          }
        }
      });
      return {
        ...state,
        tickerCalls: newTickerCalls,
      };
    }
    case USER_SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case ADD_TO_PORTFOLIO:
      newState = { ...state };
      newState.portfolios = { ...newState.portfolios };
      newState.portfolios.byId = { ...newState.portfolios.byId };
      oldMembers = [...newState.portfolios.byId[action.id].members];
      newState.portfolios.byId[action.id].members = [
        ...new Set([...oldMembers, ...action.tickers])];
      return newState;
    case REMOVE_FROM_PORTFOLIO:
      newState = { ...state };
      newState.portfolios = { ...newState.portfolios };
      newState.portfolios.byId = { ...newState.portfolios.byId };
      oldMembers = [...newState.portfolios.byId[action.id].members];
      newState.portfolios.byId[action.id].members =
        oldMembers.filter(m => !(action.tickers.includes(m)));
      return newState;
    case LOCK_SCREEN:
      return {
        ...state,
        lockScreen: action.status,
      };
    default:
      return state;
  }
};
