import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { isBrowser } from 'react-device-detect';
import CardBase from '../../containers/CardBase';
import Chart from '../interactive/chart';
import Bull from '../../../img/icons/bull.inline.svg';
import Bear from '../../../img/icons/bear.inline.svg';
import { setTickerCalls } from '../../../actions/user';
// import AddToAPortfolioButton from '../interactive/customPortfolios/addToAPortfolioDropdown';


function ChartComponent({
  // dispatch,
  ticker,
  history,
  isLoading,
  historyLoading,
  fullpage,
  iframe,
  chartSettings,
  tickerCalls,
  setCalls,
}) {
  let classes = '';
  let bullNum = 1;
  let bearNum = 2;
  if (ticker && tickerCalls.byId) {
    if (tickerCalls.byId.Bullish && tickerCalls.byId.Bullish.includes(ticker.ticker)) {
      classes = 'bullish';
      bullNum = 0;
    } else if (tickerCalls.byId.Bearish && tickerCalls.byId.Bearish.includes(ticker.ticker)) {
      classes = 'bearish';
      bearNum = 0;
    }
  }
  if (ticker && history && history.length > 0 && !isLoading && !historyLoading) {
    let link = null;
    if (!iframe) {
      link = (
        <Link
          to={(fullpage ? `/ticker/${ticker && ticker.ticker}` : `/chart/${ticker && ticker.ticker}`)}
          style={{ color: 'white' }}
        >
          {(fullpage ?
            <Icon link name="ellipsis vertical" style={{ float: 'right', marginTop: '0px' }} /> :
            <Icon link name="expand arrows alternate" style={{ float: 'right', marginTop: '0px' }} />
          )}
        </Link>);
    }
    const processedHistory = history.map(d => ({ ...d, date: new Date(d.date) })).reverse();
    if (iframe) {
      document.body.style.backgroundColor = '#FFFFFF';
      document.body.style.padding = '40px';
    }
    return (
      <CardBase
        style={(iframe ? { minHeight: '550px', boxShadow: 'none', border: 'none' } : { minHeight: '550px' })}
        className={`chart ${classes} forth-step`}
        isLoading={isLoading}
      >
        <Card.Content style={(iframe ? { padding: '0px', boxShadow: 'none' } : null)}>
          <div className="titleBar" >
            {ticker.company} (${ticker.ticker}) Historical Stock Chart
            {isBrowser ? link : ''}
          </div>
          <div style={{ marginTop: '1rem' }}>

            <Bull onClick={() => setCalls({ [ticker.ticker]: bullNum })} className={`${classes} bullish-icon right floated`} height="20px" style={{ margin: '0px 2px' }} />
            <Bear onClick={() => setCalls({ [ticker.ticker]: bearNum })} className={`${classes} bearish-icon right floated`} height="20px" style={{ margin: '0px 2px' }} />

          </div>
          <br />
          <div width="100%">
            <Chart
              initialData={processedHistory}
              height={(iframe ? (window.innerHeight - 125) : 450)}
              chartSettings={chartSettings}
              rightMargin={40}
              ohlc={isBrowser}
            />
          </div>
          {/* {link} */}
        </Card.Content>
      </CardBase>);
  }
  // Display errors, set a timeout to dispatch an error if loading takes to long.
  // Cancel the timeout if the chart loads successfully.
  // Timeout should start at the begining?
  return (
    <CardBase style={{ minHeight: (iframe ? '100vh' : '550px') }}>
      <Card.Content>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Card.Content>
    </CardBase>);
}

ChartComponent.defaultProps = {
  fullpage: false,
  iframe: false,
  chartSettings: {
    interactive: false,
  },
};

ChartComponent.propTypes = {
  ticker: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  // dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  historyLoading: PropTypes.bool.isRequired,
  fullpage: PropTypes.bool,
  iframe: PropTypes.bool,
  chartSettings: PropTypes.object,
  tickerCalls: PropTypes.object.isRequired,
  setCalls: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ticker: state.content.ticker.ticker,
  history: state.content.ticker.history,
  isLoading: state.content.ticker.isLoading,
  tickerCalls: state.user.tickerCalls,
});

const mapDispatchToProps = dispatch => ({
  setCalls: tickerObj => dispatch(setTickerCalls(tickerObj)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);
