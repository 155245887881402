import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dimmer, Loader, Card, Button, Icon, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
// import { CSVLink } from 'react-csv';
import moment from 'moment';
import queryString from 'query-string-es5';
import { DateInput } from 'semantic-ui-calendar-react';
import { getCSData, getMPData } from '../../../actions/data/tools';
import ChartGrid from '../../presentational/interactive/chartScanner/ChartGridConnected';
import { navigateToPage } from '../../../actions/search';
// import { getHistoricalChange } from '../../../api/tools';

// sort comparison function
const compFunc = (sortCol, sortDir) => (a, b) => {
  let varA;
  let varB;
  switch (sortCol) {
    case 'ticker':
    case 'ticker_name':
    case 'sector':
    case 'company':
      varA = (typeof a[sortCol] === 'string') ?
        a[sortCol].toUpperCase() : a[sortCol];
      varB = (typeof b[sortCol] === 'string') ?
        b[sortCol].toUpperCase() : b[sortCol];
      break;
    case 'price':
      varA = a.price;
      varB = b.price;
      break;
    case 'entry_price':
      varA = a.entry_price;
      varB = b.entry_price;
      break;
    case 'stop_price':
      varA = a.stop_price;
      varB = b.stop_price;
      break;
    case 'weight':
      varA = a.weight;
      varB = b.weight;
      break;
    case 'date_added':
      varA = a.date_added;
      varB = b.date_added;
      break;
    case 'percentage_change':
      varA = a.percentage_change;
      varB = b.percentage_change;
      break;
    case 'fifty_day_pct_mva':
      varA = a.fifty_day_pct_mva;
      varB = b.fifty_day_pct_mva;
      break;
    default:
      return 0;
  }
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (sortDir === 'DESC' ? (comparison * -1) : comparison);
};

const formatDate = (date) => {
  const temp = new Date(date);
  return `${temp.getMonth() + 1}/${temp.getDate()}/${`${temp.getFullYear()}`.slice(2)}`;
};

class ModelPortfolioDetails extends React.Component {
  constructor(props) {
    super(props);

    this.getPath = portfolio_name => portfolio_name.toLowerCase().replaceAll(' ', '-');
    this.calendarChange = (event, { name, value }) => {
      const { portfolio } = props;
      if (name === 'date') {
        const d = moment(value).format('YYYY-MM-DD');
        const path = this.getPath(portfolio.slug);
        props.navigateToPage(`/research/${path}?date=${d}`);
      }
    };

    // this.state = {
    //   historicalChange: [],
    // };
    this.initialLoad = false;
  }

  componentDidMount() {
    const { dispatch, portfolio, location } = this.props;
    const query = queryString.parse(location.search);
    const date = query.date ? query.date : moment().format('YYYY-MM-DD');
    this.props.getCSData(portfolio.id, date);
    dispatch(getMPData(portfolio.id, date));

    // getHistoricalChange().then((results) => {
    //   const data = results.data.map(x => ({
    //     'Add/Remove': x['Add/Remove'],
    //     Company: x.Company,
    //     Date: moment(x.Date).format('YYYY-MM-DD'),
    //     Name: x.Name,
    //     Price: parseFloat(x.Price).toFixed(2),
    //     Sector: x.Sector,
    //     Ticker: x.Ticker,
    //   }));
    //   this.setState({ historicalChange: data });
    // });
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch, portfolio, location,
    } = this.props;
    const query = queryString.parse(location.search);
    const date = query.date ? query.date : moment().format('YYYY-MM-DD');

    if (
      portfolio.id !== prevProps.portfolio.id ||
      !this.initialLoad ||
      prevProps.location.search !== location.search
    ) {
      if (!this.initialLoad) {
        this.initialLoad = true;
      }
      dispatch(getMPData(portfolio.id, date)).then((data) => {
        const tickers = data.data;
        if (tickers.length > 0) {
          const tickersDate = tickers[0].date.split('T')[0];
          if (tickersDate !== date) {
            const path = this.getPath(this.props.portfolio.slug);
            this.props.navigateToPage(`/research/${path}?date=${tickersDate}`);
          } else {
            this.props.getCSData(portfolio.id, date);
          }
        }
      });
    }
  }

  render() {
    const {
      tickers,
      sortCol,
      sortDir,
      handleSortChange,
      isLoading,
      portfolio,
      location,
    } = this.props;
    // console.log(sortCol, sortDir);
    const query = queryString.parse(location.search);
    const date = query.date ? query.date : moment().format('YYYY-MM-DD');
    // let weightCash = 0;
    // const {
    //   cash,
    //   starting_value,
    //   sp500_starting,
    //   starting_value_ytd,
    //   sp500_starting_ytd,
    //   sp500_price,
    //   date,
    // } = tickers.length > 0 ? tickers[0] : 0;
    // let sumCost = 0;
    // if (tickers.length > 0) {
    //   sumCost += cash;
    //   for (let i = 0; i < tickers.length; i += 1) {
    //     sumCost += tickers[i].current_cost;
    //   }
    //   weightCash = (cash / sumCost);
    // }
    const tickersList = [];
    if (!isLoading) {
      const headers = {
        TICKERS: {
          name: 'Ticker',
          priority: 1,
          colname: 'ticker',
          style: { width: '1%', maxWidth: '20px !important' },
        },
        COMPANY: {
          name: 'Company',
          priority: 2,
          colname: 'company',
        },
        SECTOR: {
          name: 'Sector',
          priority: 2,
          colname: 'sector',
        },
        DATE_ADDED: {
          name: 'Date <br /> Added',
          priority: 2,
          colname: 'date_added',
          style: { textAlign: 'center' },
        },
        // WEIGHT: {
        //   name: 'Weight (%)',
        //   priority: 1,
        //   colname: 'weight',
        //   style: { textAlign: 'center' },
        // },
        ENTRY_PRICE: {
          name: 'Entry <br /> Price',
          priority: 1,
          colname: 'entry_price',
          style: { textAlign: 'center' },
        },
        PRICE: {
          name: 'Current <br /> Price',
          priority: 1,
          colname: 'price',
          style: { textAlign: 'center' },
        },
        // STOP_PRICE: {
        //   name: 'Stop Price',
        //   priority: 1,
        //   colname: 'stop_price',
        //   style: { textAlign: 'center' },
        // },
        PERCENTAGE: {
          name: '% <br /> Change',
          priority: 1,
          colname: 'percentage_change',
          style: { textAlign: 'center' },
        },
        FIFTY_DMA: {
          name: '% From <br /> 50-DMA',
          priority: 1,
          colname: 'fifty_day_pct_mva',
          style: { textAlign: 'center' },
        },
      };
      // const prioritySum = Object.values(headers).reduce((total, curr) => total + curr.priority, 0);
      Object.keys(headers).forEach((key) => {
        headers[key] = {
          name: headers[key].name,
          // width: `${((headers[key].priority / (prioritySum + 2)) * 100).toFixed(2)}%`,
          colname: headers[key].colname,
          style: headers[key].style,
        };
      });
      tickers.map(item => (
        tickersList.push({ ticker: item })
      ));
      const header = [
        <Table.Header>
          <Table.Row key="main-header">
            {Object.values(headers).map((h) => {
              let newDir = 'ASC';
              let sortArr = '';
              if (h.colname === sortCol && sortDir === 'ASC') {
                newDir = 'DESC';
              }
              if (h.colname === sortCol) {
                if (newDir === 'ASC') {
                  sortArr = ' ↑';
                } else {
                  sortArr = ' ↓';
                }
              }
              return (
                <Table.HeaderCell
                  key={h.colname}
                  style={{
                    width: h.width,
                    ...h.style,
                    cursor: 'pointer',
                    color: (sortCol === h.colname ? 'darkblue' : undefined),
                    whiteSpace: 'nowrap',
                  }}
                  onClick={(handleSortChange ?
                    () => handleSortChange(h.colname, newDir) :
                    undefined
                  )}
                >
                  <span dangerouslySetInnerHTML={{ __html: handleSortChange ? `${h.name}${sortArr}` : h.name }} />
                </Table.HeaderCell>);
            })}
          </Table.Row>
        </Table.Header>];
      const body = [];
      // sort tickers
      tickers.sort(compFunc(sortCol, sortDir));
      tickers.forEach((ticker) => {
        const cells = [];
        let percentageColor = ticker.percentage_change > 0 ? 'green' : 'red';
        if (ticker.percentage_change === 0) {
          percentageColor = 'blue';
        }
        const fiftyDMAColor = ticker.fifty_day_pct_mva > 0 ? 'green' : 'red';
        if ('TICKERS' in headers) {
          cells.push((
            <Table.Cell>
              <Link to={`/ticker/${ticker.ticker}`}>{ticker.ticker}</Link>
            </Table.Cell>));
        }
        if ('COMPANY' in headers) {
          cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.company}</Table.Cell>);
        }

        if ('SECTOR' in headers) {
          cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.sector}</Table.Cell>);
        }

        // if ('WEIGHT' in headers) {
        //   cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.weight * 100).toFixed(2)}</Table.Cell>);
        // }
        if ('DATE_ADDED' in headers) {
          cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>{formatDate(ticker.date_added)}</Table.Cell>);
        }

        if ('ENTRY_PRICE' in headers) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.entry_price).toFixed(2)}</Table.Cell>);
        }
        if ('PRICE' in headers) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.price).toFixed(2)}</Table.Cell>);
        }
        // if ('STOP_PRICE' in headers) {
        //   cells.push(<Table.Cell style={{ textAlign: 'center' }} >{ticker.stop_price > 0 ? parseFloat(ticker.stop_price).toFixed(2) : '--'}</Table.Cell>);
        // }
        // if ('SHARES' in headers) {
        //   cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>{ticker.shares}</Table.Cell>);
        // }
        if ('PERCENTAGE' in headers) {
          cells.push(<Table.Cell
            style={{
              'white-space': 'nowrap',
              'max-width': '100%',
              textAlign: 'center',
              color: percentageColor,
            }}
            content={parseFloat(ticker.percentage_change * 100).toFixed(2)}
          />);
        }

        if ('FIFTY_DMA' in headers) {
          cells.push(<Table.Cell
            style={{
              'white-space': 'wrap',
              'max-width': '100%',
              textAlign: 'center',
              color: fiftyDMAColor,
            }}
            content={ticker.fifty_day_pct_mva ? ticker.fifty_day_pct_mva.toFixed(2) : 'N/A'}
          />);
        }

        body.push((
          <Table.Row>
            {cells}
          </Table.Row>));
      });
      // const cells = [];
      // if ('TICKERS' in headers) {
      //   cells.push((
      //     <Table.Cell>
      //       <b>Cash</b>
      //     </Table.Cell>));
      // }
      // if ('COMPANY' in headers) {
      //   cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>--</Table.Cell>);
      // }
      //
      // if ('SECTOR' in headers) {
      //   cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>--</Table.Cell>);
      // }
      //
      // if ('PRICE' in headers) {
      //   cells.push(<Table.Cell style={{ textAlign: 'center' }} >--</Table.Cell>);
      // }
      // // if ('WEIGHT' in headers) {
      // //   cells.push(<Table.Cell style={{ textAlign: 'center', fontWeight: 'bold' }} >{parseFloat(weightCash * 100).toFixed(2)}</Table.Cell>);
      // // }
      // if ('ENTRY_PRICE' in headers) {
      //   cells.push(<Table.Cell style={{ textAlign: 'center' }} >--</Table.Cell>);
      // }
      // if ('STOP_PRICE' in headers) {
      //   cells.push(<Table.Cell style={{ textAlign: 'center' }} >--</Table.Cell>);
      // }
      // if ('SHARES' in headers) {
      //   cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>--</Table.Cell>);
      // }
      // if ('DATE_ADDED' in headers) {
      //   cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>--</Table.Cell>);
      // }
      // if ('PERCENTAGE' in headers) {
      //   cells.push(<Table.Cell
      //     style={{
      //       'white-space': 'nowrap',
      //       'max-width': '100%',
      //       textAlign: 'center',
      //       fontWeight: 'bold',
      //       color: 'black',
      //     }}
      //     content="--"
      //   />);
      // }
      //
      // body.push((
      //   <Table.Row>
      //     {cells}
      //   </Table.Row>));

      // const sp500_since = (sp500_price - sp500_starting) / sp500_starting;
      // const sp500_ytd = (sp500_price - sp500_starting_ytd) / sp500_starting_ytd;
      // const mp_since = (sumCost - starting_value) / starting_value;
      // const mp_ytd = (sumCost - starting_value_ytd) / starting_value_ytd;
      // const vs_since = mp_since - sp500_since;
      // const vs_ytd = mp_ytd - sp500_ytd;
      const legacyLink = `${location.pathname}?legacy=true`;

      let responsiveStyle = '.table-scroll { margin:1em 0; }';
      responsiveStyle += '@media screen and (max-width: 768px) {';
      responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow-x:scroll; overflow-y: visible; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
      responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
      responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap; z-index: 2;}';
      // @todo - needs to be autocomputed based on height of first row
      responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; z-index: 2; }';
      responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
      responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
      responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5 !important; }';
      // Remove left border from second col
      responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
      // Row hover z-index;
      responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
      responsiveStyle += '}';

      // let beginDate = '5/29/07';
      // if (portfolio.id === 2) {
      //   beginDate = '7/22/14';
      // }
      // if (portfolio.id === 3) {
      //   beginDate = '7/8/19';
      // }
      const panes = [
        {
          menuItem: `${portfolio.name}`,
          render: () => (
            <Tab.Pane>
              <Card.Content>
                <style>{responsiveStyle}</style>
                <DateInput
                  name="date"
                  value={date}
                  onChange={this.calendarChange}
                  dateFormat="YYYY-MM-DD"
                  iconPosition="left"
                  initialDate={date}
                  size="large"
                  style={{ width: '150px' }}
                  readOnly
                  className="eighth-step"
                  minDate="2021-06-30"
                  maxDate={moment(new Date()).format('YYYY-MM-DD')}
                />
                <span style={{ fontSize: '10px' }}>History begins on 06/30/2021</span>
                <br /><br />
                <div style={{ margin: '0em 0 .5em 0' }} className="titleBar">{portfolio.name} { date ? ` (${moment(date).format('M/D/YY')})` : '' }</div>
                <div className="table-scroll" style={{ margin: '0px' }}>
                  <Table
                    celled
                    unstackable
                    style={{ fontSize: '20px' }}
                    className="left-fixed"
                  >
                    {header}
                    <Table.Body>
                      {body}
                    </Table.Body>
                  </Table>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button
                    color="blue"
                    size="mini"
                    as={Link}
                    to={legacyLink}
                    style={{ marginLeft: '5px' }}
                  >
                    <Icon link name="history" /> Basket History
                  </Button>
                  <br /><br />
                  <span style={{ fontSize: '12px' }}>
                    Bespoke Baskets are lists of stocks and ETFs that cover themes like growth,
                    value, dividend growth, etc.  Bespoke Baskets are meant to be idea generators
                    only and are not meant to be tracked as hypothetical portfolios.  Because
                    Bespoke Baskets are not meant to be tracked as hypothetical portfolios,
                    no performance data is provided on Baskets as a whole.
                    <br /><br />
                    Bespoke Baskets do not represent an actual investment strategy.  Bespoke Baskets
                    are for educational purposes only and are not recommendations to buy or sell
                    specific securities.  Bespoke Baskets are not personalized advice because they
                    do not take into account an investor’s individual needs.  As always, investors
                    should conduct their own research when buying or selling individual securities
                    and seek professional advice when needed.  Readers of the information contained
                    on this site should be aware that any action taken by the viewer/reader based on
                    this information is taken at their own risk. Bespoke representatives or clients
                    may have positions in securities discussed or mentioned in its published
                    content.
                    <br /><br />
                    Bespoke Baskets are updated once per month.  Entry prices and exit prices used
                    for individual securities are based on opening prices on the trading day
                    immediately following the publication of the monthly update.  Historical changes
                    to Bespoke Baskets can be viewed <a href="https://media.bespokepremium.com/uploads/2025/03/bespoke-baskets-historical-changes-031925.xlsx" target="_blank" rel="noopener noreferrer">here</a>
                  </span>
                </div>
              </Card.Content>
            </Tab.Pane>
          ),
        },
        {
          menuItem: 'Chart Scanner',
          render: () => (
            <Tab.Pane>
              <ChartGrid
                defaultText="This Portfolio is empty. Please add some tickers."
                data={Object.values(this.props.chartScannerData.byId)}
              />
            </Tab.Pane>
          ),
        },
      ];

      return (
        <React.Fragment>
          <Tab panes={panes} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </React.Fragment>
    );
  }
}

ModelPortfolioDetails.defaultProps = {
  sortCol: 'ticker',
  sortDir: 'ASC',
  handleSortChange: undefined,
};

ModelPortfolioDetails.propTypes = {
  portfolio: PropTypes.object.isRequired,
  tickers: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  handleSortChange: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  chartScannerData: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  getCSData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tickers: state.data.mpDetails,
  isLoading: state.data.modelPortfolios.isLoading,
  chartScannerData: state.view.chartScanner,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getCSData: (id, date) => dispatch(getCSData('modal-portfolios', id, date)),
  navigateToPage: url => dispatch(navigateToPage(url, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelPortfolioDetails));
