import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon } from 'semantic-ui-react';
import { formatDate } from '../TextFormats';
import { getHTMLText, getHTMLCharts } from '../../HTMLManipulator';
import CardBase from '../../containers/CardBase';

function truncateContent(content, maxLength = 120) {
  if (content.length > maxLength) {
    return `${content.slice(0, maxLength)}...`;
  }
  return content;
}

function PostCard({
  post,
  id,
  style,
  starred,
  toggleStarred,
  featured,
}) {
  const html = getHTMLText(post.content);
  const charts = getHTMLCharts(post.content);
  return (
    <CardBase className={featured ? 'bullish' : ''} id={id} style={style} linkString={`/posts/${post.category_slug[0]}/${post.post_slug}`}>
      <Card.Content>
        <Icon title={`${starred ? 'Unstar' : 'Star'} this report`} className={`right floated${starred ? ' starred' : ''}`} name="star" onClick={(e) => { toggleStarred(); e.stopPropagation(); }} />
        <Card.Header className="postcard">{post.title}</Card.Header>
        <Card.Meta>
          Published {formatDate(post.date_created)}
        </Card.Meta>
        <Card.Description>{truncateContent(html)}</Card.Description><br />
        {charts ? (<img alt="chart" src={charts[0]} />) : <span />}
      </Card.Content>
    </CardBase>
  );
}

PostCard.defaultProps = {
  style: null,
  starred: false,
  featured: false,
};

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
  id: PropTypes.any.isRequired,
  style: PropTypes.object,
  starred: PropTypes.bool,
  toggleStarred: PropTypes.func.isRequired,
  featured: PropTypes.bool,
};

export default PostCard;
