import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { setSeasonalityDates } from '../../../../actions/data/tools';

const generateDefaultDates = () => {
  const today = new Date();
  // const weekBack = new Date();
  // weekBack.setDate(weekBack.getDate() - 7);
  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 7);
  return ({
    smonth: today.getMonth() + 1,
    sday: today.getDate(),
    emonth: nextWeek.getMonth() + 1,
    eday: nextWeek.getDate(),
  });
};

const defaultDates = generateDefaultDates();

class MonthDayInput extends React.PureComponent {
  constructor(props) {
    super(props);
    const { monthTarget, dayTarget } = this.props;
    this.placeholder = '__/__';
    this.validationRegex = /^(\d{1,2})\/(\d{1,2})$/;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      isValidDate: true,
      value: `${defaultDates[monthTarget]}/${defaultDates[dayTarget]}`,
    };
  }
  componentDidMount() {
    const { setDate } = this.props;
    setDate(defaultDates);
  }
  handleInputChange(e, d) {
    const { value } = d;
    let isValidDate = false;
    let month = 0;
    let day = 0;

    const {
      setDate,
      monthTarget,
      dayTarget,
    } = this.props;

    const regexResult = this.validationRegex.exec(value);
    if (regexResult) {
      [, month, day] = regexResult;
    }

    if (month >= 1 && month <= 12) {
      const daysInMonth = 28 + ((0xEEFBB3 >> ((month - 1) * 2)) & 0b11); // eslint-disable-line no-bitwise
      isValidDate = day >= 1 && day <= daysInMonth;
    }

    this.setState({
      value,
      isValidDate,
    });
    if (isValidDate) {
      setDate({ [monthTarget]: month, [dayTarget]: day });
    }
  }

  render() {
    const { value, isValidDate } = this.state;
    // const {
    //   monthTarget,
    //   dayTarget,
    // } = this.props;
    return (
      <Input
        onChange={this.handleInputChange}
        value={value}
        error={!isValidDate}
        className={isValidDate ? 'success' : ''}
        label={{ basic: true, content: this.props.label }}
      />
    );
  }
}

MonthDayInput.propTypes = {
  label: PropTypes.string.isRequired,
  monthTarget: PropTypes.string.isRequired,
  dayTarget: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
  // dates: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setDate: dates => dispatch(setSeasonalityDates(dates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthDayInput);
