import React from 'react';
import { Modal, Icon, Button, Search, Input, Form, Loader, Dimmer, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as tickerActions from '../../../../actions/data/tickers';
import { addCreateMPDetail } from '../../../../actions/data/tools';

class AddModelItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tickersToShow: [],
      searchValue: '',
      isLoading: false,
      error: false,
      errorMessage: '',
      details: this.props.details_form,
    };

    this.click = this.click.bind(this);
    this.close = this.close.bind(this);
    this.handlerFormChange = this.handlerFormChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(tickerActions.getList());
  }

  close() {
    this.setState({
      open: false,
      isLoading: false,
      details: {
        id: '',
        ticker_id: '',
        ticker: '',
        ticker_name: '',
        sector: '',
        entry_price: '',
        stop_price: '',
        shares: '',
        date: '',
      },
    });
  }

  click() {
    this.setState({
      open: true,
      tickersToShow: [],
      searchValue: '',
      isLoading: false,
      error: false,
      errorMessage: '',
      details: this.props.details_form,
    });

    this.props.dispatch(tickerActions.getList());
  }

  handleTickerSearchSelection = (tckr) => {
    const currState = this.state;
    const t = this.props.allTickerMembers.find(x => x.ticker === tckr);
    const newDetails = {
      ...currState.details,
      ticker_id: t.id,
      ticker: t.ticker,
      ticker_name: t.company,
      sector: t.gics_sector,
    };

    this.setState({ details: newDetails });
  };

  handlerFormChange(e, d) {
    const currState = this.state;
    const newDetails = {
      ...currState.details,
      [d.name]: [d.value],
    };

    this.setState({ details: newDetails });
  }

  handleSubmit() {
    this.setState({ isLoading: true });
    // console.log(this.state.details);
    return this.props.addCreateMPDetails(this.props.portfolio.id, this.state.details)
      .then((results) => {
        if (results.byId) {
          this.close();
        }
      })
      .catch(e => this.setState({ error: true, errorMessage: e.message, isLoading: false }));
  }

  render() {
    const {
      open,
      details,
      isLoading,
      error,
      errorMessage,
    } = this.state;
    const {
      disabled,
    } = this.props;

    const titleHeader = 'Add new Ticker to Model Portfolio';
    const textContent = 'Find Ticker and Add it to the Model Portfolio';
    const loader = (<Dimmer active inverted><Loader inverted /></Dimmer>);
    // console.log('DETAILS: ', details);

    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click(), disabled })}
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={open}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
          {...this.props}
        >
          <center>
            <br />
            <h1>{titleHeader}</h1>
            <Modal.Content>
              {isLoading ? loader : null}
              {textContent}
              <br />
              <br />
              <Form error={error}>
                <Message
                  color="red"
                  content={errorMessage}
                  style={{ width: '80%' }}
                  error
                />
                <Form.Field>
                  <Search
                    fluid
                    className={`mini-search ${this.state.isLoading ? 'green' : ''}`}
                    placeholder="Search Ticker..."
                    icon="search"
                    selectFirstResult
                    results={this.state.tickersToShow.map(x => ({ title: x, ticker: x }))}
                    onSearchChange={(event, data) => {
                      if (data.value === '') {
                        this.setState({ tickersToShow: [] });
                      } else {
                        this.setState({
                          tickersToShow: this.props.calculateTickersToShow(
                            data,
                            this.props.allTickerMembers.map(x => x.ticker),
                            this.props.portfolio.members,
                          ),
                        });
                      }
                      this.setState({ searchValue: data.value });
                    }
                    }
                    onResultSelect={
                      (event, data) => {
                        (this.handleTickerSearchSelection(data.result.ticker));
                        this.setState({ searchValue: '' });
                      }}
                    value={this.state.searchValue}
                    style={{
                      textAlign: 'left',
                      width: '80%',
                      display: details.id ? 'none' : '',
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    type="text"
                    placeholder="Ticker..."
                    label="Ticker"
                    style={{ width: '80%' }}
                    value={details.ticker}
                    onChange={this.handlerFormChange}
                    name="ticker"
                    readOnly
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    type="text"
                    placeholder="Ticker Name..."
                    label="Ticker Name"
                    style={{ width: '80%' }}
                    value={details.ticker_name}
                    name="ticker_name"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Sector..."
                    label="Sector"
                    value={details.sector}
                    name="sector"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="0.00"
                    label="Entry Price $"
                    value={details.entry_price}
                    name="entry_price"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="0.00"
                    label="Stop Price $"
                    value={details.stop_price}
                    name="stop_price"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="0"
                    label="Shares"
                    value={details.shares}
                    name="shares"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="YYYY-MM-DD"
                    label="Date"
                    value={details.date}
                    name="date"
                    onChange={this.handlerFormChange}
                  />
                </Form.Field>
              </Form>
              <br />
              <Button color="blue" onClick={this.handleSubmit}>
                <Icon name="save" /> SAVE
              </Button>
              <br />
              <br />
            </Modal.Content>
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

AddModelItemModal.defaultProps = {
  content: (
    <Button color="green" size="mini">
      <Icon link name="plus" />ADD TICKER
    </Button>
  ),
  disabled: false,
  search: null,
  details_form: {
    id: '',
    ticker_id: '',
    ticker: '',
    ticker_name: '',
    sector: '',
    entry_price: '',
    stop_price: '',
    shares: '',
    date: '',
  },
};

const mapStateToProps = state => ({
  allTickerMembers: state.data.tickerList.list,
});

AddModelItemModal.propTypes = {
  allTickerMembers: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.element,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string,
  location: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  portfolio: PropTypes.object.isRequired,
  calculateTickersToShow: PropTypes.func.isRequired,
  details_form: PropTypes.object,
  addCreateMPDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  addCreateMPDetails: (mpId, details) => dispatch(addCreateMPDetail(mpId, details)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddModelItemModal));
