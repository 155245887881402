import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AddModelPortfolio from './addModelPortfolio';

class AddModelPortfolioModal extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;

    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click() })}
        <Modal basic onClose={this.close} open={open} {...this.props} closeIcon>
          <h1 style={{ color: 'white' }}>Create a New Model Portfolio</h1>
          <Modal.Content>
            <p>
            Type in a name and cash value to create a new portfolio.
            </p>
            <AddModelPortfolio submitAction={(result) => { this.props.submitAction(result); this.close(); }} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

AddModelPortfolioModal.defaultProps = {
  submitAction: () => null,
  content: (<Icon link name="plus" color="green" />),
};

AddModelPortfolioModal.propTypes = {
  submitAction: PropTypes.func,
  content: PropTypes.element,

};

export default AddModelPortfolioModal;
