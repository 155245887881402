
import React from 'react';
import PropTypes from 'prop-types';
import { ChartCanvas, Chart } from 'react-stockcharts';
// import { connect } from 'react-redux';
import { CandlestickSeries, BarSeries, LineSeries, BollingerSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { format } from 'd3-format';
import { timeFormat } from 'd3-time-format';
import {
  MouseCoordinateX,
  MouseCoordinateY,
  CrossHairCursor,
  CurrentCoordinate,
} from 'react-stockcharts/lib/coordinates';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';
import { OHLCTooltip, MovingAverageTooltip, BollingerBandTooltip } from 'react-stockcharts/lib/tooltip';
import { fitWidth } from 'react-stockcharts/lib/helper';
import {
  StandardDeviationChannel,
  TrendLine,
  DrawingObjectSelector,
} from 'react-stockcharts/lib/interactive';
import { last, toObject } from 'react-stockcharts/lib/utils';
import { sma, bollingerBand } from 'react-stockcharts/lib/indicator';
import { saveInteractiveNodes, getInteractiveNodes } from './interactiveutils';
import Colors from './colors';

/**
 * Documentation for StockChart chart settings
 * settings: {
 *   indicators: {
 *     indicatorX: {
 *       color,
 *       indicatorParameters,
 *       isVisible: 'true | false'
 *     }
 *   }
 *   type: 'candle | line | OHLC',
 *   interactive: 'true | false',
 *   crosshair: 'true | false',
 *   annotations: 'true | false',
 * }
 */
class StockChart extends React.Component {
  constructor(props) {
    super(props);

    this.onKeyPress = this.onKeyPress.bind(this);
    this.onDrawChannelsComplete = this.onDrawChannelsComplete.bind(this);
    this.onDrawTrendsComplete = this.onDrawTrendsComplete.bind(this);
    this.saveInteractiveNode = this.saveInteractiveNode.bind(this);
    this.saveCanvasNode = this.saveCanvasNode.bind(this);

    this.handleSelection = this.handleSelection.bind(this);

    this.saveInteractiveNodes = saveInteractiveNodes.bind(this);
    this.getInteractiveNodes = getInteractiveNodes.bind(this);

    this.objectMap = {
      StandardDeviationChannel: 'channels',
      TrendLine: 'trends',
    };

    this.state = {
      ...props.chartSettings,
      enableInteractiveObject: true,
      bbToggle: true,
      sma50Toggle: true,
      sma200Toggle: true,
      channels1: [],
      trends1: [],
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyPress);
  }

  onDrawTrendsComplete(trends1) {
    // this gets called on
    // 1. draw complete of drawing object
    // 2. drag complete of drawing object
    this.setState({
      enableInteractiveObject: false,
      trends1,
    });
  }

  onDrawChannelsComplete(channels1) {
    // this gets called on
    // 1. draw complete of drawing object
    // 2. drag complete of drawing object
    this.setState({
      enableInteractiveObject: false,
      channels1,
    });
  }

  onKeyPress(e) {
    const keyCode = e.which;
    switch (keyCode) {
      case 46: {
        // DEL
        const channels1 = this.state.channels1.filter(each => !each.selected);
        const trends1 = this.state.trends1.filter(each => !each.selected);

        this.canvasNode.cancelDrag();
        this.setState({
          channels1,
          trends1,
        });
        break;
      }
      case 27: {
        // ESC
        if (this.node) { this.node.terminate(); }
        if (this.canvasNode) { this.canvasNode.cancelDrag(); }
        this.setState({
          enableInteractiveObject: false,
        });
        break;
      }
      case 67: {
        // C - Enable drawing in-place channels
        break;
      }
      case 68: {
        // D - Enable drawing channels
        this.setState({
          enableInteractiveObject: 'channel',
        });
        break;
      }
      case 84: {
        // T - Enable drawing trends
        this.setState({
          enableInteractiveObject: 'trend',
        });
        break;
      }
      default:
        break;
    }
  }

  handleSelection(interactives) {
    const state = toObject(interactives, each => [`${this.objectMap[each.type]}${each.chartId}`, each.objects]);
    this.setState(state);
  }

  saveCanvasNode(node) {
    this.canvasNode = node;
  }

  saveInteractiveNode(node) {
    this.node = node;
  }

  render() {
    const {
      type,
      initialData,
      width,
      height,
    } = this.props;

    const chartPrefs = this.props.chartSettings;
    const { channels1, trends1 } = this.state;

    const xScaleProvider = discontinuousTimeScaleProvider
      .inputDateAccessor(d => d.date);

    // loop through chartSettings and create all indicators
    const sma50 = sma()
      .options({ windowSize: 50 })
      .merge((d, c) => ({ ...d, sma50: c }))
      .accessor(d => d.sma50)
      .stroke((this.state.sma50Toggle && '#1B9CFC') || 'transparent')
      .fill('#1B9CFC');

    const sma200 = sma()
      .options({ windowSize: 200 })
      .merge((d, c) => ({ ...d, sma200: c }))
      .accessor(d => d.sma200)
      .stroke((this.state.sma200Toggle && '#182C61') || 'transparent')
      .fill('#182C61');

    const bb1 = bollingerBand()
      .options({ windowSize: 50, multiplier: 1 })
      .merge((d, c) => { const e = d; e.bb1 = c; })
      .accessor(d => d.bb1);

    const bb2 = bollingerBand()
      .options({ windowSize: 50, multiplier: 2 })
      .merge((d, c) => { const e = d; e.bb2 = c; })
      .accessor(d => d.bb2);

    // iteratively apply indicators
    const calculatedData = sma200(sma50(bb1(bb2(initialData))));

    const {
      data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(calculatedData);
    const xExtents = [
      xAccessor(last(data)) + 1,
      xAccessor(data[data.length - (chartPrefs.interactive ?
        Math.min(253, data.length) : Math.min(127, data.length))]),
    ];

    let bbStroke = {
      // top: 'red',
      top: 'transparent',
      middle: 'transparent',
      // bottom: 'green',
      bottom: 'transparent',
    };

    let bbFill = '#4682B4';

    if (!this.state.bbToggle) {
      bbStroke = {
        top: 'transparent',
        middle: 'transparent',
        bottom: 'transparent',
      };

      bbFill = 'transparent';
    }
    console.log(initialData);
    return (
      <ChartCanvas
        ref={this.saveCanvasNode}
        height={height}
        width={width + this.props.rightMargin}
        ratio={1}
        margin={{
          left: 53, right: this.props.rightMargin + 5, top: 10, bottom: 0,
        }}
        type={type}
        seriesName="MSFT"
        data={data}
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
      >
        <Chart
          id={1}
          yExtents={[d => [d.high, d.low], sma50.accessor(), sma200.accessor()]}
          height={height * 0.65}
          origin={[0, 50]}
        >
          <XAxis axisAt="bottom" orient="bottom" ticks={6} />
          <YAxis axisAt="left" orient="left" ticks={5} />
          <CandlestickSeries
            fill={d => (d.close > d.open ? Colors.green : Colors.red)}
            stroke={d => (d.close > d.open ? Colors.green : Colors.red)}
            wickStroke={d => (d.close > d.open ? Colors.green : Colors.red)}
          />
          <LineSeries yAccessor={sma50.accessor()} stroke={sma50.stroke()} />
          <LineSeries yAccessor={sma200.accessor()} stroke={sma200.stroke()} />
          {
            chartPrefs.interactive ?
              <React.Fragment>
                <BollingerSeries yAccessor={d => d.bb1} stroke={bbStroke} fill={bbFill} />
                <BollingerBandTooltip
                  origin={[110, -15]}
                  yAccessor={d => d.bb1}
                  options={bb1.options()}
                  onClick={() => {
                      this.setState(prevState => ({ bbToggle: !prevState.bbToggle }));
                    }
                  }
                />
                <BollingerSeries yAccessor={d => d.bb2} stroke={bbStroke} fill={bbFill} />
                <BollingerBandTooltip
                  origin={[110, -30]}
                  yAccessor={d => d.bb2}
                  options={bb2.options()}
                  onClick={() => {
                      this.setState(prevState => ({ bbToggle: !prevState.bbToggle }));
                    }
                  }
                />
              </React.Fragment>
            : null
          }
          <CurrentCoordinate yAccessor={sma50.accessor()} fill={sma50.stroke()} />
          <CurrentCoordinate yAccessor={sma200.accessor()} fill={sma200.stroke()} />
          {this.props.ohlc ? <OHLCTooltip origin={[-40, -50]} /> : ''}
          <MovingAverageTooltip
            origin={[-40, -90]}
            onClick={() => {
                this.setState(prevState => ({ sma50Toggle: !prevState.sma50Toggle }));
              }
            }
            options={[
              {
                yAccessor: sma50.accessor(),
                type: 'SMA',
                stroke: sma50.stroke(),
                windowSize: sma50.options().windowSize,
                echo: ('some echo here'),
              },
            ]}
          />
          <MovingAverageTooltip
            origin={[30, -90]}
            onClick={() => {
                this.setState(prevState => ({ sma200Toggle: !prevState.sma200Toggle }));
              }
            }
            options={[
              {
                yAccessor: sma200.accessor(),
                type: 'SMA',
                stroke: sma200.stroke(),
                windowSize: sma200.options().windowSize,
                echo: 'some echo here',
              },
            ]}
          />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={timeFormat('%Y-%m-%d')}
            style={{ zIndex: 2000 }}
          />
          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format('.2f')}
          />
          {chartPrefs.interactive ?
            <React.Fragment>
              <StandardDeviationChannel
                ref={this.saveInteractiveNodes('StandardDeviationChannel', 1)}
                enabled={this.state.enableInteractiveObject === 'channel'}
                onStart={undefined}
                onComplete={this.onDrawChannelsComplete}
                channels={channels1}
                snapTo={d => [d.close, d.open, d.high, d.low]}
              />
              <TrendLine
                ref={this.saveInteractiveNodes('TrendLine', 1)}
                enabled={this.state.enableInteractiveObject === 'trend'}
                onStart={undefined}
                onComplete={this.onDrawTrendsComplete}
                trends={trends1}
                type="LINE"
                snap
                snapTo={d => [d.close, d.open, d.high, d.low]}
                currentPositionRadius={4}
              />
            </React.Fragment>
          : null }
        </Chart>
        <Chart
          id={2}
          height={(height * 0.25) - 50}
          yExtents={[d => d.volume, 0]}
          origin={[0, (height * 0.75) + 30]}
        >
          <YAxis axisAt="left" orient="left" ticks={4} tickFormat={format('.2s')} />
          <MouseCoordinateY
            at="left"
            orient="left"
            displayFormat={format('.4s')}
          />
          <BarSeries
            yAccessor={d => d.volume}
            fill={d => (d.close > d.open ? Colors.green : Colors.red)}
          />
        </Chart>
        <CrossHairCursor />
        {chartPrefs.interactive ?
          <DrawingObjectSelector
            enabled={!this.state.enableInteractiveObject}
            getInteractiveNodes={this.getInteractiveNodes}
            drawingObjectMap={this.objectMap}
            onSelect={this.handleSelection}
          /> : null
        }
      </ChartCanvas>
    );
  }
}

StockChart.propTypes = {
  initialData: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  rightMargin: PropTypes.number,
  type: PropTypes.oneOf(['svg', 'hybrid']),
  height: PropTypes.number,
  chartSettings: PropTypes.object,
  ohlc: PropTypes.bool,
};

StockChart.defaultProps = {
  type: 'hybrid',
  height: 450,
  chartSettings: {},
  rightMargin: '5px',
  ohlc: true,
};

export default fitWidth(StockChart);
