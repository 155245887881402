import { executeQuery, mainPath, encodeArgs } from './query';

const screenPath = `${mainPath}/api/screens`;

/**
 * Gets a list of all active screens.
 * @return {Screen[]} - A list of all active screens.
 */
export const getAll = () => executeQuery(`${screenPath}`);

export const getScreens = ids => executeQuery(`${screenPath}/screens/?${encodeArgs(ids)}`);

/**
 * Gets all members of a screen.
 * @param  {number} screenId - The screen id to get members for.
 * @return {String[]} - An array of tickers found in the screen.
 */
export const getMembers = screenId => executeQuery(`${screenPath}/${screenId}`);

/**
 * Run a user defined screen.
 * @param  {string} screen - Representation of a user defined screen.
 * @return {string[]}      - A list of tickers that appear in the user defined screen.
 */
export const executeScreen = screen => executeQuery(`${screenPath}/run/${encodeURIComponent(screen)}`);

/* Types below */
/**
  * An object representation of a screen.
  * @typedef {Object} Screen
  * @property {number} id - The unique ID of the screen.
  * @property {string} name - The name of the screen.
  * @property {string} datemade - The date the screen was made.
  * @property {string} screen - A description of screen logic (currently unimplemented!)
  * @property {number} active - Indicates whether or not the current screen is active.
  * - 1 - Screen is active.
  * - 0 - Screen is inactive.
  */
