import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import SignUp from '../presentational/signup';
import BackButton from '../presentational/interactive/backButton';


class ConsumerPulseInfo extends React.PureComponent {
  resizeIframe = (event) => {
    const ifr = event.target;
    ifr.style.height = `${ifr.contentWindow.document.body.scrollHeight}px`;
    ifr.contentDocument.getElementById('main-header').outerHTML = '';
    ifr.contentDocument.getElementById('footer-bottom').outerHTML = '';
    setTimeout(() => { ifr.contentDocument.getElementById('page-container').style = 'padding-top: 0px'; }, 500);
    ifr.contentDocument.getElementsByClassName('et_pb_row et_pb_row_0')[0].style = 'padding: 0px';
    ifr.contentDocument.getElementsByClassName('et_pb_row et_pb_row_1 et_pb_row_fullwidth')[0].style = 'padding: 0px';
    ifr.contentDocument.getElementsByClassName('et_pb_row et_pb_row_2')[0].style = 'padding: 0px';
    ifr.contentDocument.getElementsByClassName('et_pb_text et_pb_module et_pb_bg_layout_light et_pb_text_align_left  et_pb_text_1')[0].style = 'padding-top: 0px !important';
    window.scrollTo(0, 0);
  }

  render() {
    const { access } = this.props;
    if (access === null) {
      return (<SignUp
        message={['You don\'t have access to this post. If you would like to see this post, please log-in or become a member.']}
      />);
    }

    return (
      <Container className="iframe">
        <style type="text/css">
          {'body{ background-color: white; }'}
        </style>
        <div className="account content">
          <BackButton />
          <iframe
            title="Upgrade"
            src="https://www.bespokepremium.com/consumer-pulse-info/"
            frameBorder={0}
            scrolling="no"
            onLoad={this.resizeIframe}
            style={{
              width: '100%',
              minHeight: '20px',
            }}
          />
        </div>
      </Container>);
  }
}

ConsumerPulseInfo.defaultProps = {
  // post: undefined,
};

ConsumerPulseInfo.propTypes = {
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerPulseInfo);
