import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SortableTable from '../SortableTable';

const centerText = ({ textAlign: 'center' });

class InternationalRevenuesTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = { page: 1, rowsPerPage: 100 };

    this.columns = [
      { text: 'Ticker', key: 'ticker' },
      { text: 'Company', key: 'company' },
      { text: 'Sector', key: 'sector' },
      {
        text: 'Most Recent',
        key: 'recentdom',
        styleFunc: () => centerText,
        style: centerText,
      },
      {
        text: '1 Year Ago',
        key: 'year1dom',
        styleFunc: () => centerText,
        style: centerText,
      },
      {
        text: '2 Years Ago',
        key: 'year2dom',
        styleFunc: () => centerText,
        style: centerText,
      },
      {
        text: 'Most Recent',
        key: 'recentint',
        styleFunc: () => centerText,
        style: centerText,
      },
      {
        text: '1 Year Ago',
        key: 'year1int',
        styleFunc: () => centerText,
        style: centerText,
      },
      {
        text: '2 Years Ago',
        key: 'year2int',
        styleFunc: () => centerText,
        style: centerText,
      },
    ];

    this.preHeader = (
      <Table.Row className="pre-header" key="pre-header">
        <Table.HeaderCell />
        <Table.HeaderCell colSpan="2" />
        <Table.HeaderCell style={centerText} colSpan="3">
          Percentage Domestic (%)
        </Table.HeaderCell>
        <Table.HeaderCell style={centerText} colSpan="3">
          Percentage International (%)
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  handlePageChange(e, d) {
    this.setState({
      page: d.activePage,
    });
  }

  render() {
    const {
      data,
      sortChange,
      sortCol,
      sortDir,
      tickers,
    } = this.props;

    const { page, rowsPerPage } = this.state;

    // Define the ticker format func based on tickers prop
    this.columns[0].formatFunc = ticker =>
      (tickers.map(x => x.ticker).includes(ticker) ? (<Link to={`/ticker/${ticker}`}>{ticker}</Link>) : ticker);

    return (
      <SortableTable
        striped
        celled
        style={{ fontSize: '20px' }}
        unstackable
        preHeader={this.preHeader}
        columns={this.columns}
        rows={data}
        handleSort={sortChange}
        sortCol={sortCol}
        sortDir={sortDir}
        paginated
        page={Math.min(page, Math.ceil(data.length / rowsPerPage))}
        rowsPerPage={rowsPerPage}
        handlePageChange={this.handlePageChange}
        responsive="left-fixed"
      />
    );
  }
}

InternationalRevenuesTable.propTypes = {
  data: PropTypes.array.isRequired,
  sortChange: PropTypes.func.isRequired,
  sortCol: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  tickers: PropTypes.array.isRequired,
};

export default InternationalRevenuesTable;
