import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Menu, Segment, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { BlueText, RedText, GreenText } from '../presentational/ColoredText';
import * as api from '../../api';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';


function generateRatingText(text) {
  if (text.toLowerCase().indexOf('hawkish') !== -1) {
    return (<RedText>{text}</RedText>);
  }
  if (text.toLowerCase().indexOf('dovish') !== -1) {
    return (<GreenText>{text}</GreenText>);
  }
  return (<BlueText>{text}</BlueText>);
}

class EconomicMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.cacheExpTime = 900000;
    this.loader = 'Loading';

    this.state = {
      data: {
        eco: [],
        cbm: [],
        fed: [],
      },
      lastFetched: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = 'Bespoke | Economic Monitor';
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.loadData();
    }
  }

  loadData() {
    const { lastFetched } = this.state;
    if (lastFetched === null || Date.now() - this.cacheExpTime > lastFetched) {
      this.setState({
        isLoading: true,
      });
      api.tools.getMonitorData()
        .then(data => this.setState({
          data,
          lastFetched: Date.now(),
        }))
        .finally(this.setState({ isLoading: false }));
    }
  }
  render() {
    const { data, isLoading } = this.state;
    const category = this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.category;
    const items = {
      'eco-us': {
        key: 'eco-us',
        name: 'US Economic Scorecard',
        as: Link,
        to: '/economic-monitors/eco-us',
        active: (category === 'eco-us'),
      },
      'eco-global': {
        key: 'eco-global',
        name: 'Global Economic Scorecard',
        as: Link,
        to: '/economic-monitors/eco-global',
        active: (category === 'eco-global'),
        className: 'third-step',
      },
      fed: {
        key: 'fed',
        name: 'Fedspeak Monitor',
        as: Link,
        to: '/economic-monitors/fed',
        active: (category === 'fed'),
        className: 'forth-step fifth-step',
      },
      cbm: {
        key: 'cbm',
        name: 'Central Bank Monitor',
        as: Link,
        to: '/economic-monitors/cbm',
        active: (category === 'cbm'),
        className: 'sixth-step',
      },
    };
    let content;

    const steps = [
      {
        selector: '.first-step',
        content: 'Our US Economic Scorecard provides users with a complete list of economic indicators and Fed speeches over the next week.',
      },
      {
        selector: '.second-step',
        content: 'Indicators that have already been published are shown in red or green based on the actual number versus expectations. Beats are in green while misses are in red.',
      },
      {
        selector: '.third-step',
        content: 'Our Global Economic Scorecard is a complete list of key economic indicators from around the world (US included) due out within the next week.',
      },
      {
        selector: '.forth-step',
        content: 'Our Fedspeak Monitor helps investors stay on top of commentary from Federal Reserve members over the last month.',
      },
      {
        selector: '.fifth-step',
        content: 'For each event, we show the speaker, the key quote, a summary, whether the commentary was hawkish, dovish, or neutral, and we provide a link to the speech when it is available.',
      },
      {
        selector: '.sixth-step',
        content: 'Our Central Bank Monitor shows upcoming meetings and rate expectations for central banks around the world.',
      },
      {
        selector: '.seventh-step',
        content: 'The Economic Monitors tool is available to Bespoke Premium and Bespoke Institutional members.  No data within this tool represents a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="economicMonitorTouring"
        steps={steps}
      />);

    switch (category) {
      case 'eco-us':
      case 'eco-global':
        content = (
          <React.Fragment>
            <Table
              unstackable
              celled
              striped
              style={{
                fontSize: '20px',
                scrollX: 'true',
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Time</Table.HeaderCell>
                  <Table.HeaderCell>Release</Table.HeaderCell>
                  <Table.HeaderCell>Period</Table.HeaderCell>
                  <Table.HeaderCell>Estimate</Table.HeaderCell>
                  <Table.HeaderCell>Reading</Table.HeaderCell>
                  <Table.HeaderCell>Last</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body className="second-step">
                {data.eco.filter(x => (x.release && (category === 'eco-us' ? (x.color.indexOf('d') !== -1) : true)))
                  .map((x) => {
                  // const time = new Date(`2000/01/01 ${x.time}`);
                  // parse formatting
                  const style = {
                    color: 'black',
                    fontStyle: '',
                  };
                  if (x.color.toUpperCase()[0] === 'I') {
                    style.fontStyle = 'italic';
                  }
                  switch (x.color.toUpperCase()[1]) {
                    case 'B':
                        style.color = 'mediumblue';
                        break;
                    case 'G':
                        style.color = 'green';
                        break;
                    case 'R':
                        style.color = 'red';
                        break;
                    default:
                        style.color = 'black';
                        break;
                  }
                  return (
                    <Table.Row style={style} key={x.id_str}>
                      <Table.Cell>
                        {x.time}
                      </Table.Cell>
                      <Table.Cell>
                        {x.release}
                      </Table.Cell>
                      <Table.Cell>
                        {x.period}
                      </Table.Cell>
                      <Table.Cell>
                        {x.estimate}
                      </Table.Cell>
                      <Table.Cell>
                        {x.reading}
                      </Table.Cell>
                      <Table.Cell>
                        {x.last}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              }
              </Table.Body>
            </Table>
          </React.Fragment>
        );
        break;
      case 'fed':
        content = (
          <React.Fragment>
            <Table celled unstackable striped style={{ fontSize: '20px' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Speaker</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Key Quote</Table.HeaderCell>
                  <Table.HeaderCell>Summary</Table.HeaderCell>
                  <Table.HeaderCell>Rating</Table.HeaderCell>
                  <Table.HeaderCell>Link</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body className="second-step">
                {data.fed.map((x) => {
                  const date = new Date(x.date);
                  return (
                    <Table.Row key={x.id_str}>
                      <Table.Cell>
                        {`${date.getMonth() + 1}/${date.getDate()}`}
                      </Table.Cell>
                      {(x.speaker.indexOf('Note') !== -1 || x.speaker.length >= 24 ?
                        (
                          <Table.Cell colSpan="4">
                            {x.speaker}
                          </Table.Cell>
                        ) :
                        (
                          <React.Fragment>
                            <Table.Cell>
                              {x.speaker}
                            </Table.Cell>
                            <Table.Cell>
                              {x.type}
                            </Table.Cell>
                            <Table.Cell>
                              {x.quote}
                            </Table.Cell>
                            <Table.Cell>
                              {x.summary}
                            </Table.Cell>
                          </React.Fragment>
                        ))}
                      <Table.Cell>
                        {generateRatingText(x.rating)}
                      </Table.Cell>
                      <Table.Cell>
                        {x.link !== '' ? <a href={x.link} rel="noopener noreferrer" target="_blank" >Click Here</a> : 'No link available.'}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              }
              </Table.Body>
            </Table>
          </React.Fragment>
        );
        break;
      case 'cbm':
        content = (
          <React.Fragment>
            <Table celled unstackable striped style={{ fontSize: '20px' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Day</Table.HeaderCell>
                  <Table.HeaderCell>Time (EST)</Table.HeaderCell>
                  <Table.HeaderCell>Bank</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell style={isMobile ? { minWidth: '80vw' } : {}}>Notes</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body className="second-step">
                {data.cbm.map((x) => {
                  const date = new Date(x.date);
                  let { time } = x;
                  if (time !== 'n/a') {
                    time = new Date(x.time);
                  }
                  return (
                    <Table.Row key={x.id_str}>
                      <Table.Cell>
                        {`${date.getMonth() + 1}/${date.getDate()}`}
                      </Table.Cell>
                      <Table.Cell>
                        {x.day}
                      </Table.Cell>
                      <Table.Cell>
                        {(time !== 'n/a' ? `${time.toTimeString().substr(0, 5)}` : time)}
                      </Table.Cell>
                      <Table.Cell>
                        {x.bank}
                      </Table.Cell>
                      <Table.Cell>
                        {x.country}
                      </Table.Cell>
                      <Table.Cell>
                        {x.notes}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              }
              </Table.Body>
            </Table>
          </React.Fragment>
        );
        break;
      default:
        content = <Redirect to="/economic-monitors/eco-us" />;
        break;
    }
    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <h1 className="first-step">Economic Monitors {tour}</h1>
        <Menu
          stackable
          items={Object.values(items)}
          attached
          tabular
        />
        <Segment
          attached
        >
          {(isLoading ? this.loader :
          <React.Fragment>
            <div className="titleBar" >{items[category] && items[category].name}</div>
            <div
              style={{
                width: '100%',
                overflowX: 'scroll',
                overflowY: 'visible',
              }}
            >
              {content}
            </div>
          </React.Fragment>
          )}
        </Segment>
      </React.Fragment>
    );
  }
}


EconomicMonitor.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EconomicMonitor;
