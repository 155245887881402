/**
 * @ignore
 */
function formatGICSAux(sector, group) {
  return `${sector} - ${group}`;
}

/**
 * used to format GICS
 * @param {*} sector
 * @param {*} group
 */
function formatGICS(sector, group) {
  const groupAliases = {
    'Commercial & Professional Serv': 'Commercial & Professional Services',
    'Health Care Equipment & Servic': 'Health Care Equipment & Services',
    'Technology Hardware & Equipmen': 'Technology Hardware & Equipment',
    'Pharmaceuticals, Biotechnology': 'Pharmaceuticals, Biotechnology & Life Sciences',
    'Semiconductors & Semiconductor': 'Semiconductors & Semiconductor Equipment',
  };
  if (sector === group) {
    return sector;
  }
  return formatGICSAux(sector, group in groupAliases ? groupAliases[group] : group);
}

function formatMoney(number) {
  return `$${number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}


function formatBigNumber(number) {
  return Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatDate(string, formats = {}) {
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const date = new Date(string);
  if (now.getMonth() === date.getMonth()
      && now.getFullYear === date.getFullYear) {
    if (now.getDate() === date.getDate()) {
      if ('today' in formats) {
        return formats.today();
      }
      return 'Today';
    }
  }
  if (yesterday.getMonth() === date.getMonth()
  && yesterday.getFullYear === date.getFullYear) {
    if (yesterday.getDate() === date.getDate()) {
      if ('yesterday' in formats) {
        return formats.yesterday();
      }
      return 'Yesterday';
    }
  }
  if (tomorrow.getMonth() === date.getMonth()
  && tomorrow.getFullYear === date.getFullYear) {
    if (tomorrow.getDate() === date.getDate()) {
      if ('tomorrow' in formats) {
        return formats.yesterday();
      }
      return 'Tomorrow';
    }
  }
  // Last Week
  if (now.getTime() - date.getTime() < 604800000 &&
  now.getTime() - date.getTime() > 0) {
    const days = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    if (now.getDay() <= date.getDay()) {
      if ('last_week' in formats) {
        return formats.last_week(days[date.getDay()]);
      }
      return `Last ${days[date.getDay()]}`;
    }
    if ('this_week' in formats) {
      return formats.this_week(days[date.getDay()]);
    }
    return days[date.getDay()];
  }
  // Next Week
  if (now.getTime() - date.getTime() > -604800000 &&
  now.getTime() - date.getTime() < 0) {
    const days = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    if (now.getDay() < date.getDay()) {
      if ('this_week' in formats) {
        return formats.this_week(days[date.getDay()]);
      }
      return days[date.getDay()];
    }
    if ('next_week' in formats) {
      return formats.last_week(days[date.getDay()]);
    }
    return `Next ${days[date.getDay()]}`;
  }
  if ('general' in formats) {
    return formats.general(`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`);
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

const extractPrettyDateFromDatetime = (datetime) => {
  const re = /(\d{4})-(\d{2})-(\d{2})/;
  const [, y, m, d] = re.exec(datetime);
  return `${parseInt(m, 10)}/${parseInt(d, 10)}/${y.substr(-2)}`;
};

export {
  formatGICS,
  formatDate,
  formatMoney,
  formatBigNumber,
  extractPrettyDateFromDatetime,
};
