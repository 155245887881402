import React from 'react';
import PropTypes from 'prop-types';
import ChangeItemsBar from './changeItemsBar';
import * as api from '../../../../api';

export default function deleteItemsBar({ portfolio }) {
  return (
    <ChangeItemsBar
      portfolio={portfolio}
      dataRequest={api.user.deletePortfolioMembers}
      icon="minus circle"
      successIcon="times circle"
      placeHolderText={() => 'Remove Tickers'}
      searchBarClass=""
      successClass="red"
      calculateTickersToShow={(data, all, curr) => curr.filter(ticker =>
        (new RegExp(` ${data.value.toUpperCase()}`)).test(` ${ticker}`))}
    />
  );
}

deleteItemsBar.defaultProps = {
  portfolio: undefined,
};

deleteItemsBar.propTypes = {
  portfolio: PropTypes.object,
};
