import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import SortableTable from '../../SortableTable';
import { setEESort, setEETablePage } from '../../../../actions/data/tools';

/**
 * Formatting helper functions
 */

const guidanceStyle = (val) => {
  if (val === 'Lowered') {
    return { color: 'red' };
  } else if (val === 'Raised') {
    return { color: 'green' };
  }
  return undefined;
};

const numStyle = (val) => {
  if (parseFloat(val) > 0) {
    return { color: 'green' };
  } else if (parseFloat(val) < 0) {
    return { color: 'red' };
  }
  return undefined;
};

const leftAlign = () => ({ textAlign: 'left' });

const formatTicker = ticker => (<Link to={`/earnings-explorer/search/${ticker}`}>{ticker}</Link>);

const formatPercent = num => `${Math.round(num)}%`;

const formatDate = date => date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1');

// const numberWithCommas = x => (!Number.isNaN(Number(x)) && x !== null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A');

const forceTwoDec = x => (!Number.isNaN(Number(x)) && x !== null ? parseFloat(x).toFixed(2) : 'N/A');

const actvsestFormat = (x) => {
  if (!Number.isNaN(Number(x)) && x !== null) {
    if (parseFloat(x) > 0) {
      return 'Beat';
    }

    if (parseFloat(x) < 0) {
      return 'Missed';
    }

    return 'Inline';
  }
  return 'N/A';
};

/**
 * Table sort helper function
 * @todo  Add earnings screener functions
 */

const sortFunc = (col, dir) => (a, b) => {
  const naVal = (dir === 'DESC' ? -Infinity : Infinity);
  let varA;
  let varB;
  switch (col) {
    case 'stock':
    case 'ticker':
    case 'company':
    case 'reportdate':
    case 'date':
    case 'reporttime':
    case 'time_of_day':
    case 'guidance':
      varA = (typeof a[col] === 'string') ?
        a[col].toUpperCase() : a[col];
      varB = (typeof b[col] === 'string') ?
        b[col].toUpperCase() : b[col];
      break;
    case 'quarter': {
      varA = (typeof a[col] === 'string') ?
        a[col].toUpperCase() : a[col];
      varB = (typeof b[col] === 'string') ?
        b[col].toUpperCase() : b[col];
      let comparison = 0;
      let dateComp = 0;

      if (a.date > b.date) {
        dateComp = -1;
      } else if (a.date < b.date) {
        dateComp = 1;
      }

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      } else {
        return dateComp;
      }

      return (dir === 'DESC' ? (comparison * -1) : comparison);
    }
    case 'currenteps':
    case 'revisedeps':
    case 'prioronfile':
    case 'epsbeat':
    case 'revenuebeat':
    case 'avg_gap':
    case 'avg_o2c':
    case 'avg_1day':
    case 'avgabsolute':
    case 'eps_actual':
    case 'eps_avse':
    case 'eps_estimate':
    case 'rev_actual':
    case 'rev_estimate':
    case 'rev_avse':
    case 'gap_pct':
    case 'gap_pt':
    case 'one_day_pct':
    case 'one_day_pt':
    case 'open_to_close_pct':
    case 'open_to_close_pt':
    case 'prior_close':
      varA = ['n/a', 'null', null].includes(a[col]) ? naVal : parseFloat(a[col]);
      varB = ['n/a', 'null', null].includes(b[col]) ? naVal : parseFloat(b[col]);
      break;
    default:
      return 0;
  }

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (dir === 'DESC' ? (comparison * -1) : comparison);
};

// Columns and preheaders

class EarningsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.ecalColumns = [
      {
        key: 'stock',
        text: 'Ticker',
        formatFunc: formatTicker,
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'company',
        text: 'Company',
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'reportdate',
        text: 'Date',
        formatFunc: formatDate,
        width: 100,
      },
      {
        key: 'reporttime',
        text: 'Time',
        width: 100,
      },
      // {
      //   key: 'currenteps',
      //   text: 'EPS ($/Shr)',
      //   styleFunc: numStyle,
      //   formatFunc: forceTwoDec,
      // },
      // {
      //   key: 'revisedeps',
      //   text: 'Sales ($,Mln)',
      //   formatFunc: numberWithCommas,
      //   width: 100,
      // },
      {
        key: 'prioronfile',
        text: 'Reports',
        width: 100,
        style: { display: 'none' },
      },
      {
        key: 'epsbeat',
        text: 'EPS',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'revenuebeat',
        text: 'Sales',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'guidance',
        text: 'Guidance',
        style: { display: 'none' },
        formatFunc: formatPercent,
      },
      {
        key: 'avg_gap',
        text: 'Gap',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_o2c',
        text: 'Open to Close',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_1day',
        text: '1 Day',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avgabsolute',
        text: 'Volatility',
        formatFunc: forceTwoDec,
        width: 100,
      },
    ];
    this.aggColumns = [
      {
        key: 'stock',
        text: 'Ticker',
        formatFunc: formatTicker,
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'company',
        text: 'Company',
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'prioronfile',
        text: 'Reports',
        width: 100,
        style: { display: 'none' },
      },
      {
        key: 'epsbeat',
        text: 'EPS',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'revenuebeat',
        text: 'Sales',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'guidance',
        text: 'Guidance',
        style: { display: 'none' },
        formatFunc: formatPercent,
      },
      {
        key: 'avg_gap',
        text: 'Gap',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_o2c',
        text: 'Open to Close',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_1day',
        text: '1 Day',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avgabsolute',
        text: 'Volatility',
        formatFunc: forceTwoDec,
        width: 100,
      },
    ];
    this.stockColumns = [
      {
        key: 'date',
        text: 'Date',
        formatFunc: formatDate,
      },
      {
        key: 'quarter',
        text: 'Quarter',
        formatFunc: x => `Q${x}`,
      },
      {
        key: 'time_of_day',
        text: 'Time',
      },
      {
        key: 'prior_close',
        text: 'Close',
        formatFunc: forceTwoDec,
      },
      // {
      //   key: 'eps_actual',
      //   text: 'Actual',
      //   formatFunc: forceTwoDec,
      // },
      // {
      //   key: 'eps_estimate',
      //   text: 'Estimate',
      //   formatFunc: forceTwoDec,
      // },
      {
        key: 'eps_avse',
        text: 'EPS',
        styleFunc: numStyle,
        formatFunc: actvsestFormat,
        style: { padding: '5px 25px' },
      },
      // {
      //   key: 'rev_actual',
      //   text: 'Actual',
      //   formatFunc: numberWithCommas,
      // },
      // {
      //   key: 'rev_estimate',
      //   text: 'Estimate',
      //   formatFunc: numberWithCommas,
      // },
      {
        key: 'rev_avse',
        text: 'Revenues',
        styleFunc: numStyle,
        formatFunc: actvsestFormat,
      },
      {
        key: 'guidance',
        text: 'Guidance',
        styleFunc: guidanceStyle,
      },
      {
        key: 'gap_pct',
        text: '%',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'gap_pt',
        text: 'Pt',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'open_to_close_pct',
        text: '%',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'open_to_close_pt',
        text: 'Pt',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'one_day_pct',
        text: '%',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
      {
        key: 'one_day_pt',
        text: 'Pt',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
      },
    ];
    this.screenColumns = [
      {
        key: 'ticker',
        text: 'Ticker',
        styleFunc: leftAlign,
        formatFunc: formatTicker,
        style: { textAlign: 'left' },
      },
      ...this.stockColumns,
    ];

    this.ecalPreHeader = (col, dir) => (
      <Table.Row>
        <Table.HeaderCell colSpan={2} />
        <Table.HeaderCell colSpan={2}>
          Report
        </Table.HeaderCell>
        {/* <Table.HeaderCell colSpan={2}> */}
        {/*  Estimates */}
        {/* </Table.HeaderCell> */}
        <Table.HeaderCell
          rowSpan={2}
          style={{ width: '80px', color: col === 'prioronfile' ? 'darkblue' : undefined, cursor: 'pointer' }}
          onClick={() => this.props.handleSort('prioronfile')}
        >
          {`Prior Reports${col === 'prioronfile' ? `${(dir === 'ASC' ? ' ↓' : ' ↑')}` : ''}`}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Beat Rate
        </Table.HeaderCell>
        <Table.HeaderCell
          rowSpan={2}
          style={{ width: '90px', color: col === 'guidance' ? 'darkblue' : undefined, cursor: 'pointer' }}
          onClick={() => this.props.handleSort('guidance')}
        >
          {`% Raised Guidance${col === 'guidance' ? `${(dir === 'ASC' ? ' ↓' : ' ↑')}` : ''}`}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={4}>
          Average Stock Price % Chg
        </Table.HeaderCell>
      </Table.Row>
    );
    this.aggPreHeader = (col, dir) => (
      <Table.Row>
        <Table.HeaderCell colSpan={2} />
        <Table.HeaderCell
          rowSpan={2}
          style={{ width: '80px', color: col === 'prioronfile' ? 'darkblue' : undefined, cursor: 'pointer' }}
          onClick={() => this.props.handleSort('prioronfile')}
        >
          {`Prior Reports${col === 'prioronfile' ? `${(dir === 'ASC' ? ' ↓' : ' ↑')}` : ''}`}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Beat Rate
        </Table.HeaderCell>
        <Table.HeaderCell
          rowSpan={2}
          style={{ width: '90px', color: col === 'guidance' ? 'darkblue' : undefined, cursor: 'pointer' }}
          onClick={() => this.props.handleSort('guidance')}
        >
          {`% Raised Guidance${col === 'guidance' ? `${(dir === 'ASC' ? ' ↓' : ' ↑')}` : ''}`}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={4}>
          Average Stock Price % Chg
        </Table.HeaderCell>
      </Table.Row>
    );
    this.stockPreHeader = (
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell style={{ 'border-left': 'none' }} />
        <Table.HeaderCell style={{ 'border-left': 'none' }} />
        <Table.HeaderCell style={{ 'border-left': 'none' }} />
        <Table.HeaderCell colSpan={2}>
          Actual vs Estimates
        </Table.HeaderCell>
        {/* <Table.HeaderCell colSpan={1}> */}
        {/*  Quarterly EPS ($/Share) */}
        {/* </Table.HeaderCell> */}
        {/* <Table.HeaderCell colSpan={1}> */}
        {/*  Quarterly Revenues ($, Mil) */}
        {/* </Table.HeaderCell> */}
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={2}>
          Opening Gap
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Open To Close
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Full 1 Day
        </Table.HeaderCell>
      </Table.Row>
    );
    this.screenPreHeader = (
      <Table.Row>
        <Table.HeaderCell colSpan={5} />
        <Table.HeaderCell colSpan={2}>
          Actual vs Estimates
        </Table.HeaderCell>
        {/* <Table.HeaderCell colSpan={1}> */}
        {/*  Quarterly EPS ($/Share) */}
        {/* </Table.HeaderCell> */}
        {/* <Table.HeaderCell colSpan={1}> */}
        {/*  Quarterly Revenues ($, Mil) */}
        {/* </Table.HeaderCell> */}
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={2}>
          Opening Gap
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Open To Close
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Full 1 Day
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  componentDidMount() {
    // get data
  }

  render() {
    const {
      ecal,
      mode,
      page,
      tableData,
      tableDataLoading,
      screenData,
      screenDataLoading,
      sortDir,
      sortCol,
      handleSort,
      handlePageChange,
      endDate,
      filterDate,
      ticker,
      portfolioMembers,
    } = this.props;
    let loading = ecal.isLoading;
    let rows = ecal.data;
    // let downloadName = null;
    let preHeader = this.ecalPreHeader(sortCol, sortDir);
    let columns = this.ecalColumns;
    let description;
    if (mode === 'ecal') {
      if (filterDate) {
        rows.filter(item => item.reportdate); // Filter out ecal entries with no upcoming report
        rows = rows.filter(item => Date.parse(item.reportdate) >= filterDate &&
          Date.parse(item.reportdate) < (filterDate + (1000 * 60 * 60 * 24)));
      } else {
        rows = rows.filter(item =>
          Date.parse(item.reportdate) > (Date.now() - (1000 * 60 * 60 * 24)) &&
            Date.parse(item.reportdate) < (Date.now() + endDate));
      }
      if (filterDate) {
        description = `Estimated Upcoming Earnings (${new Date(filterDate).toLocaleDateString()})`;
        // downloadName = `BespokeEarningsCalendar${new Date(filterDate).toLocaleDateString().replace('/', '_')}.csv`;
      } else {
        description = `Estimated Upcoming Earnings (${endDate === Infinity ? 'All' : `Today${endDate ? ` - ${new Date(Date.now() + endDate).toLocaleDateString()}` : ''}`})`;
        // downloadName = `BespokeEarningsCalendar${new Date(Date.now()).toLocaleDateString().replace('/', '_')}to${endDate ? `${new Date(Date.now() + endDate).toLocaleDateString().replace('/', '_')}` : ''}.csv`;
      }
    } else if (mode === 'portfolio') {
      rows = rows.filter(item =>
        Date.parse(item.reportdate) > (Date.now() - (1000 * 60 * 60 * 24)) &&
          Date.parse(item.reportdate) < (Date.now() + endDate));
      console.log('Portfolio Members');
      rows = rows.filter(item => portfolioMembers.includes(item.stock));
      description = 'Upcoming Earnings Reports';
      // downloadName = 'portfolio_ecal.csv'; // TODO: implement
    } else if (mode === 'no-upcoming-aggs') {
      rows = rows.filter(item => item.reportdate == null);
      rows = rows.filter(item => portfolioMembers.includes(item.stock));
      description = 'Earnings Summaries for Remaining Tickers';
      // downloadName = 'portfolio_not_upcoming.csv';
      preHeader = this.aggPreHeader;
      columns = this.aggColumns;
    } else if (mode === 'single') {
      if (ticker) {
        description = `Historical Earnings Reports for ${ticker.description} (${ticker.title})`;
        // downloadName = `BespokeHistoricalEarnings_${ticker.title}.csv`;
      } else {
        description = '';
        // downloadName = 'BespokeHistoricalEarnings_undefined.csv';
      }
      loading = tableDataLoading;
      rows = tableData;
      preHeader = this.stockPreHeader;
      columns = this.stockColumns;
    } else if (mode === 'screen') {
      description = 'Screen Results';
      rows = screenData;
      // downloadName = 'BespokeEarnings_CUSTOMSCREEN.csv';
      loading = screenDataLoading;
      preHeader = this.screenPreHeader;
      columns = this.screenColumns;
    }

    return (
      <React.Fragment>
        <h1 style={{ fontSize: '16px' }}>{description}</h1>
        <Dimmer.Dimmable dimmed={loading}>
          <Dimmer inverted active={loading} >
            <Loader />
          </Dimmer>
          <SortableTable
            {...this.props}
            style={{
              textAlign: 'center',
              fontSize: '20px',
            }}
            rows={[...rows].sort(sortFunc(sortCol, sortDir))}
            columns={columns}
            sortCol={sortCol}
            sortDir={sortDir}
            handleSort={handleSort}
            preHeader={preHeader}
            paginated
            rowsPerPage={100}
            page={page}
            handlePageChange={handlePageChange}
            responsive="left-fixed"
            // downloadData={rows.length > 0 ? rows : null}
            // downloadName={downloadName}
          />
        </Dimmer.Dimmable>
      </React.Fragment>);
  }
}

EarningsTable.defaultProps = {
  ticker: null,
  filterDate: null,
  portfolioMembers: null,
};

EarningsTable.propTypes = {
  mode: PropTypes.string.isRequired,
  handleSort: PropTypes.func.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  sortCol: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  ecal: PropTypes.object.isRequired,
  endDate: PropTypes.number.isRequired,
  filterDate: PropTypes.number,
  tableData: PropTypes.array.isRequired,
  tableDataLoading: PropTypes.bool.isRequired,
  screenData: PropTypes.array.isRequired,
  screenDataLoading: PropTypes.bool.isRequired,
  ticker: PropTypes.object,
  match: PropTypes.object.isRequired,
  portfolioMembers: PropTypes.array,
};

const mapStateToProps = state => ({
  sortCol: state.view.earningsExplorer.sortCol,
  sortDir: state.view.earningsExplorer.sortDir,
  page: state.view.earningsExplorer.page,
  tableData: state.view.earningsExplorer.tableData,
  screenData: state.view.earningsExplorer.screenData,
  ecal: state.data.ecal,
  tableDataLoading: state.view.earningsExplorer.tableDataLoading,
  screenDataLoading: state.view.earningsExplorer.screenDataLoading,
  ticker: state.view.earningsExplorer.ticker,
});

const mapDispatchToProps = dispatch => ({
  handleSort: col => dispatch(setEESort(col)),
  handlePageChange: (e, { activePage }) => dispatch(setEETablePage(activePage)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EarningsTable));
