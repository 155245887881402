import React from 'react';
import { withRouter } from 'react-router';
import { Button, Form, Header, Image, Segment } from 'semantic-ui-react';
import * as api from '../../api';
import Logo from '../../img/logo_white_icon.png';


class Statoftheweek extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      email: '',
      optIn: false,
      errorEmail: false,
      errorOptIn: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(submit) {
    const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    let error = false;
    this.setState({
      errorEmail: false,
      errorOptIn: false,
    });

    if (this.state.email === '' || !re.test(this.state.email)) {
      this.setState({ errorEmail: true });
      error = true;
    }
    if (!this.state.optIn) {
      this.setState({ errorOptIn: true });
      error = true;
    }

    if (!error && submit) {
      api.mailchimp.subscribeMailchimp('e34c7bcc8b', this.state.email);
      this.setState({ success: true, errorEmail: false });
    }
  }

  render() {
    return (
      <Segment style={{
        marginTop: '1vh',
        marginLeft: '10px',
        padding: '0',
      }}
      >
        {!this.state.success &&
          <div>
            <Header as="h2" style={{ backgroundColor: '#003051', padding: '10px' }}>
              <Header.Content style={{ color: 'white' }}>
                Stat of the Week
                <Header.Subheader style={{ color: 'white' }}>From Bespoke Investment Group</Header.Subheader>
              </Header.Content>
              <Image src={Logo} style={{ marginLeft: '10px', width: '47px' }} />
            </Header>
            <div style={{ padding: '30px' }}>
              <p style={{
            alignSelf: 'center',
            color: '#003051',
            fontSize: '20px',
          }}
              >
                <b>
                  Enter your email below to join Bespoke’s complimentary “Stat of the Week”
                  newsletter!
                </b>
              </p>
              <p style={{
            alignSelf: 'center',
            color: '#003051',
            fontSize: '20px',
          }}
              >
                If you’re interested in becoming a more informed investor, we’ve got just the thing
                for you. Our complimentary weekly email newsletter covers unique stats related to
                financial markets, investing, and everything in between. We believe that knowledge
                is power when it comes to investing, and we’re committed to providing you with
                insights needed to make smarter investment decisions.
              </p>
              <p style={{
            alignSelf: 'center',
            color: '#003051',
            fontSize: '20px',
          }}
              >
                With Bespoke’s “Stat of the Week,” you’ll get one email per week with a statistic
                that may just blow your mind. Whether you’re a seasoned pro or just getting started,
                our “Stat of the Week” is the perfect way to stay up-to-date on some of the latest
                trends and developments in financial markets. So don’t wait any longer – sign up
                today and start receiving your weekly dose of Bespoke’s fascinating market stats.
              </p>
              <br />
              <Form>
                <Form.Input
                  placeholder="Your Email Address"
                  required
                  error={this.state.errorEmail}
                  onBlur={e => this.setState({ email: e.target.value })}
                  style={{ width: '50%' }}
                />
                <br />
                <Form.Checkbox
                  label="Opt in to receive market insights and updates from Bespoke to your email address."
                  required
                  error={this.state.errorOptIn}
                  onChange={() => this.setState({ optIn: !this.state.optIn })}
                  checked={this.state.optIn}
                />
              </Form>
              <br />
              <Button
                positive
                icon="checkmark"
                color="green"
                content="Submit"
                onClick={() => this.handleSubmit(true)}
              />
            </div>
          </div>}
        {this.state.success &&
          <div>
            <div style={{ textAlign: 'center' }}>
              <h1
                style={{
                fontSize: '4rem',
                alignSelf: 'center',
                color: '#003051',
                lineHeight: '6rem',
             }}
              >
                THANK YOU!
              </h1>
              <br />
              <Button
                as="a"
                href="/research/think-big-blog/"
                positive
                color="green"
                content="Go to Research"
              />
            </div>
          </div>}
      </Segment>
    );
  }
}


export default withRouter(Statoftheweek);
