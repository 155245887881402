import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  Pagination,
  Button,
  Input, Modal, Form, Icon, Checkbox,
} from 'semantic-ui-react';
import 'react-virtualized/styles.css';
import { updateCategoryData } from '../../../actions/data/tools';


export class StateCategoryTable {
  searchCategoryShow = [];
  sortCol = 'ticker';
  sortDir = 'ASC';
  displayDataCategory = {
    changeIdCategory: '',
    changeNameCategory: '',
    changeInChartbook: '',
    changeInTrendAnalyzer: '',
    changeIsEtfCat: '',
    changeIsIndex: '',
  };
  page = 1;
  openModalCategory = false;
}

// Todo: Needs header styles + extra header rows
class TableCategoryManagerShow extends React.PureComponent<StateCategoryTable, any> {
  state = new StateCategoryTable();

  handlePageChange = (e, d) => {
    this.setState({
      page: d.activePage,
    });
  }

  handleCategoryForUpdate = (e, d) => {
    const { name, value } = d;
    let valueAlt = '';
    if (name === 'changeIsEtfCat') {
      valueAlt = !this.state.displayDataCategory.changeIsEtfCat;
    }
    if (name === 'changeInChartbook') {
      valueAlt = !this.state.displayDataCategory.changeInChartbook;
    }
    if (name === 'changeInTrendAnalyzer') {
      valueAlt = !this.state.displayDataCategory.changeInTrendAnalyzer;
    }
    if (name === 'changeIsIndex') {
      valueAlt = !this.state.displayDataCategory.changeIsIndex;
    }
    this.setState({
      ...this.state,
      displayDataCategory: {
        ...this.state.displayDataCategory,
        [name]: value === undefined ? valueAlt : value,
      },
    });
  }

  close = () => {
    this.setState({
      openModalCategory: false,
      displayDataCategory: {
        changeIdCategory: '',
        changeNameCategory: '',
        changeInChartbook: '',
        changeInTrendAnalyzer: '',
        changeIsEtfCat: '',
        changeIsIndex: '',
      },
    });
  };

  // eslint-disable-next-line consistent-return
  openCategoryManager = (tickersData) => {
    if (tickersData) {
      this.setState({
        openModalCategory: true,
        displayDataCategory: {
          changeIdCategory: tickersData.id,
          changeNameCategory: tickersData.name,
          changeInChartbook: tickersData.in_chartbook === 1,
          changeInTrendAnalyzer: tickersData.in_trend_analyzer === 1,
          changeIsEtfCat: tickersData.is_etf_cat === 1,
          changeIsIndex: tickersData.is_index === 1,
        },
      });
    }
  }

  sortFunc = col => () => {
    switch (col) {
      case 'name':
      default:
        return 0;
    }
  };

  createTableBody = (rows, cols, paginated) => {
    if (paginated) { // BEING DEPRECATED
      return (
        <React.Fragment>
          {
            this.state.searchCategoryShow.length !== 0 ?
              this.state.searchCategoryShow.map(x => (
                <Table.Row key={x.primary_key} className="third-step search-sixth-step">
                  {cols.map(col =>
                    (
                      col.text === 'Actions' ?
                        <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                          <Button color="yellow" size="mini" icon="edit" onClick={() => this.openCategoryManager(x)} />
                        </Table.Cell>
                        :
                        <Table.Cell
                          key={col.key}
                          style={col.styleFunc && col.styleFunc(x[col.key])}
                        >
                          {col.formatFunc && !(x[col.key] == null) ? col.formatFunc(x[col.key]) : (!(x[col.key] == null) && x[col.key]) || ''}
                        </Table.Cell>
                    ))
                  }
                </Table.Row>
              ))
            :
            rows.map(x => (
              <Table.Row key={x.primary_key} className="third-step search-sixth-step">
                {cols.map(col =>
                (
                  col.text === 'Actions' ?
                    <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                      <Button color="yellow" size="mini" icon="edit" onClick={() => this.openCategoryManager(x)} />
                    </Table.Cell>
                    :
                    <Table.Cell
                      key={col.key}
                      style={col.styleFunc && col.styleFunc(x[col.key])}
                    >
                      {col.formatFunc && !(x[col.key] == null) ? col.formatFunc(x[col.key]) : (!(x[col.key] == null) && x[col.key]) || ''}
                    </Table.Cell>
                ))
              }
              </Table.Row>
          ))
          }
        </React.Fragment>
      );
    }
    let htmlStr = '';
    rows.forEach((row) => {
      htmlStr += '<tr>';
      cols.forEach((col) => {
        htmlStr += '<td';
        htmlStr += `${col.styleFunc ? ` style="${col.styleFunc(row[col.key])}"` : ''}>`;
        htmlStr += col.formatFunc ? col.formatFunc(row[col.key]) : row[col.key];
        htmlStr += '</td>';
      });
      htmlStr += '</tr>';
    });
    return { __html: htmlStr };
  };

  handleSort = (column) => {
    let newDir = 'ASC';
    const { sortCol, sortDir, displayData } = this.state;
    if (sortCol === column) {
      newDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    const sortedData = displayData.sort(this.sortFunc(column, newDir));
    this.setState({
      sortCol: column,
      sortDir: newDir,
      displayData: sortedData,
    });
  }

  updatedCategoryForButton = () => {
    this.props.updatingCategoryData(this.state.displayDataCategory).then(() => {
      this.close();
      window.location.reload(true);
    });
  }

  filterDataCategory = (event, data) => {
    const search = data.value;
    if (search !== '') {
      // eslint-disable-next-line max-len
      const dataF = this.props.rows.filter(filterData => filterData.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
      const limitData = dataF.slice(0, 25);
      this.setState({
        ...this.state,
        searchCategoryShow: limitData,
      });
    } else {
      this.setState({
        ...this.state,
        searchCategoryShow: [],
      });
    }
  }

  render() {
    const {
      rows,
      sortCol,
      sortDir,
      style,
      sortColor,
      striped,
      celled,
      rowsPerPage,
      responsive,
    } = this.props;
    const columns = [
      { text: 'Name Category', key: 'name' },
      { text: 'Actions' },
    ];

    const paginated = true;

    const arr = sortDir === 'DESC' ? `${String.fromCharCode(160)}↑` : `${String.fromCharCode(160)}↓`;

    // If it's paginated process the rows
    let processedRows = rows;
    let totalPages = 1;
    if (paginated) {
      // eslint-disable-next-line max-len
      processedRows = rows.slice((this.state.page - 1) * rowsPerPage, this.state.page * rowsPerPage);
      totalPages = Math.ceil(rows.length / rowsPerPage);
    }

    // Generate responsive style
    let responsiveStyle = null;
    if (responsive === 'split') {
      // dynamically generate CSS based on columns!
      responsiveStyle = '@media screen and (max-width: 768px) {';
      responsiveStyle += 'table.split, .split thead, .split tbody, .split th, .split td, .split tr { display: block !important; }';
      responsiveStyle += '.split thead tr { position: absolute; top: -9999px; left: -9999px; }';
      responsiveStyle += '.split tr { border: 1px solid #ccc !important; }';
      responsiveStyle += '.split td { border: none !important; border-bottom: 1px solid #eee !important; position: relative !important; padding-left: 50% !important; text-align: left !important; }';
      responsiveStyle += '.split td:before { font-weight: bold; text-align: left; position: absolute !important; top: 6px !important; left: 6px !important; width: 45% !important; padding-right: 10px !important; white-space: nowrap; }';
      // Loop through columns
      columns.forEach((x, idx) => {
        responsiveStyle += `.split td:nth-of-type(${idx + 1}):before { content: "${x.text}"; cursor: pointer; }`;
      });
      responsiveStyle += '}';
    } else if (responsive === 'left-fixed') {
      responsiveStyle = '.table-scroll { margin:1em 0; }';
      responsiveStyle += '@media screen and (max-width: 768px) {';
      responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow: scroll; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
      responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
      responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
      // @todo - needs to be autocomputed based on height of first row
      responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
      responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
      responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
      responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
      // Remove left border from second col
      responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
      // Row hover z-index;
      responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
      responsiveStyle += '}';
    }

    let table = (
      <Table
        style={style}
        striped={striped}
        celled={celled}
        className={`${responsive} eighth-step`}
        unstackable
      >
        <Table.Header>
          <Table.Row className="upcoming-sixth-step upcoming-third-step upcoming-forth-step">
            {columns.map(col => (
              <Table.HeaderCell
                key={col.key}
                onClick={this.handleSort && (() => this.handleSort(col.key))}
                style={{
                  cursor: this.handleSort && 'pointer',
                  color: (sortCol === col.key ? sortColor : undefined),
                  ...col.style,
                }}
              >
                {col.key === sortCol ? `${col.text}${arr}` : col.text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.createTableBody(processedRows, columns, true)
          }
        </Table.Body>
      </Table>
    );

    if (responsive === 'left-fixed') {
      table = (<div className="table-scroll">{table}</div>);
    }

    return (
      <React.Fragment>
        {responsive ? <style>{responsiveStyle}</style> : null}
        <div className="row">
          {paginated && totalPages > 1 ?
            (<Pagination
              activePage={this.state.page}
              totalPages={Math.ceil(rows.length / rowsPerPage)}
              size="tiny"
              boundaryRange={0}
              onPageChange={this.handlePageChange}
            />) :
            null}
          <Input
            style={{ width: '40%', float: 'right' }}
            type="text"
            placeholder="Search Category"
            label="Search"
            name="searchCategory"
            onChange={this.filterDataCategory}
          />
        </div>
        {table}
        {paginated && totalPages > 1 ?
          (<Pagination
            activePage={this.state.page}
            totalPages={Math.ceil(rows.length / rowsPerPage)}
            size="tiny"
            boundaryRange={0}
            onPageChange={this.handlePageChange}
          />) :
          null}
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={this.state.openModalCategory}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
        >
          <center>
            <br />
            <br />
            <h1>Modify Category { this.state.displayDataCategory.changeNameCategory }</h1>
            <Modal.Content>
              <Form>
                <Form.Field>
                  <br />
                  <br />
                  <Form.Field>
                    <Input
                      style={{ width: '80%' }}
                      type="text"
                      placeholder="Changes Category"
                      className="m-5"
                      label="Category"
                      defaultValue={this.state.displayDataCategory.changeNameCategory}
                      name="changeNameCategory"
                      onChange={this.handleCategoryForUpdate}
                    />
                  </Form.Field>
                  <Form.Field style={{ width: '80%' }}>
                    <Checkbox
                      toggle
                      defaultChecked={this.state.displayDataCategory.changeInChartbook}
                      name="changeInChartbook"
                      label="In Chart book"
                      onClick={this.handleCategoryForUpdate}
                      style={{ float: 'left', marginBottom: '10px' }}
                    />
                    <Checkbox
                      toggle
                      defaultChecked={this.state.displayDataCategory.changeInTrendAnalyzer}
                      name="changeInTrendAnalyzer"
                      label="In Trend Analyzer"
                      onClick={this.handleCategoryForUpdate}
                      style={{ float: 'right', marginBottom: '10px' }}
                    />
                  </Form.Field>
                  <Form.Field style={{ width: '80%' }}>
                    <Checkbox
                      toggle
                      defaultChecked={this.state.displayDataCategory.changeIsEtfCat}
                      name="changeIsEtfCat"
                      label="Is Etf Category"
                      onClick={this.handleCategoryForUpdate}
                      style={{ float: 'right', marginBottom: '10px' }}
                    />
                    <Checkbox
                      toggle
                      defaultChecked={this.state.displayDataCategory.changeIsIndex}
                      name="changeIsIndex"
                      label="Is Index"
                      onClick={this.handleCategoryForUpdate}
                      style={{ float: 'left', marginBottom: '10px' }}
                    />
                  </Form.Field>
                  <br />
                  <br />
                  <Button color="blue" onClick={this.updatedCategoryForButton}>
                    <Icon name="save" /> Edit Category
                  </Button>
                </Form.Field>
                <br />
              </Form>
            </Modal.Content>
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

TableCategoryManagerShow.defaultProps = {
  style: undefined,
  sortColor: 'darkblue',
  striped: undefined,
  celled: undefined,
  sortCol: '',
  sortDir: '',
  rowsPerPage: 100,
  responsive: '',
};

TableCategoryManagerShow.propTypes = {
  rows: PropTypes.array.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  sortColor: PropTypes.string,
  style: PropTypes.object,
  striped: PropTypes.bool,
  celled: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  responsive: PropTypes.string,
  updatingCategoryData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  updatingCategoryData: dataList => dispatch(updateCategoryData(dataList)),
});

export default connect(null, mapDispatchToProps)(TableCategoryManagerShow);
