import merge from 'lodash/merge';
import uniq from 'lodash/uniq';

import {
  TICKER_SET_DATA,
  TICKER_SET_LIST,
  TICKER_SET_LOADING,
  TICKER_HAS_ERRORED,
  CATEGORIES_SET_DATA,
  CATEGORIES_SET_LOADING,
  CATEGORIES_HAS_ERRORED,
  SCREENS_SET_DATA,
  SCREENS_SET_LOADING,
  SCREENS_HAS_ERRORED,
  ECAL_SET_DATA,
  ECAL_SET_LOADING,
  ECAL_HAS_ERRORED,
  ECALAGG_SET_DATA,
  ECALAGG_SET_LOADING,
  ECALAGG_HAS_ERRORED,
  SZN_SET_DATA,
  SZN_SET_DATES,
  SZN_SET_LOADING,
  SZN_HAS_ERRORED,
  MOVERS_SET_DATA,
  MOVERS_SET_LOADING,
  OBOS_SET_DATA,
  OBOS_SET_LOADING,
  OBOS_SET_TIMEFRAME,
  FIFTY_DMA_SET_DATA,
  FIFTY_DMA_SET_LOADING,
  FIFTY_DMA_SET_TIMEFRAME,
  TWOHUNDRED_DMA_SET_DATA,
  TWOHUNDRED_DMA_SET_LOADING,
  TWOHUNDRED_DMA_SET_TIMEFRAME,
  MP_DATA,
  MP_DETAIL_DATA,
  MP_DETAIL_SET_LOADING,
} from '../types/actions';

/**
 * Reducer for data property of Redux Store.
 * Used to short-term cache general data from the database.
 * @todo  implement ticker error reducer.
 * @param {*} state
 * @param {*} action
 */
export default (state = {
  tickers: {
    byId: {},
    allIds: [],
    isLoading: false,
  },
  tickerList: {
    list: [],
    lastFetched: null,
  },
  categories: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  screens: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  ecal: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  ecalAgg: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  earnings: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  seasonality: {
    data: {
      chartData: [],
      tableData: [],
      compositeData: [],
      years: [],
    },
    dates: {
      smonth: 11,
      sday: 7,
      emonth: 11,
      eday: 14,
      numYears: 10,
    },
    isLoading: false,
    eror: null,
    lastFetched: null,
  },
  movers: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  obos: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  fiftydma: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  twohundreddma: {
    data: [],
    isLoading: false,
    error: null,
    lastFetched: null,
  },
  modelPortfolios: {
    byId: {},
    names: [],
    isLoading: false,
  },
  mpDetails: [],
}, action) => {
  switch (action.type) {
    case TICKER_SET_DATA:
      return {
        ...state,
        tickers: {
          byId: merge({}, state.tickers.byId, action.byId),
          allIds: uniq(state.tickers.allIds.concat(action.allIds)),
          date: action.date,
          isLoading: false,
        },
      };
    case TICKER_SET_LIST:
      return {
        ...state,
        tickerList: {
          list: action.list.slice(),
          lastFetched: action.lastFetched,
        },
      };
    case TICKER_SET_LOADING:
      return {
        ...state,
        tickers: {
          ...state.tickers,
          isLoading: action.loading,
        },
      };
    case TICKER_HAS_ERRORED:
      return {
        ...state,
      };
    case CATEGORIES_SET_DATA:
      return {
        ...state,
        categories: {
          ...state.categories,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case CATEGORIES_SET_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: action.loading,
        },
      };
    case CATEGORIES_HAS_ERRORED:
      return {
        ...state,
        categories: {
          ...state.categories,
          error: action.error,
        },
      };
    case SCREENS_SET_DATA:
      return {
        ...state,
        screens: {
          ...state.screens,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case SCREENS_SET_LOADING:
      return {
        ...state,
        screens: {
          ...state.screens,
          isLoading: action.loading,
        },
      };
    case SCREENS_HAS_ERRORED:
      return {
        ...state,
        screens: {
          ...state.screens,
          error: action.error,
        },
      };
    case ECAL_SET_DATA:
      return {
        ...state,
        ecal: {
          ...state.ecal,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case ECAL_SET_LOADING:
      return {
        ...state,
        ecal: {
          ...state.ecal,
          isLoading: action.loading,
        },
      };
    case ECAL_HAS_ERRORED:
      return {
        ...state,
        ecal: {
          ...state.screens,
          error: action.error,
        },
      };
    case ECALAGG_SET_DATA:
      return {
        ...state,
        ecalAgg: {
          ...state.ecalAgg,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case ECALAGG_SET_LOADING:
      return {
        ...state,
        ecalAgg: {
          ...state.ecalAgg,
          isLoading: action.loading,
        },
      };
    case ECALAGG_HAS_ERRORED:
      return {
        ...state,
        ecalAgg: {
          ...state.ecalAgg,
          error: action.error,
        },
      };
    case SZN_SET_DATA:
      return {
        ...state,
        seasonality: {
          ...state.seasonality,
          data: {
            ...state.seasonality.data,
            ...action.data,
          },
        },
      };
    case SZN_SET_DATES:
      return {
        ...state,
        seasonality: {
          ...state.seasonality,
          dates: action.dates,
        },
      };
    case SZN_SET_LOADING:
      return {
        ...state,
        seasonality: {
          ...state.seasonality,
          isLoading: action.loading,
        },
      };
    case SZN_HAS_ERRORED:
      return {
        ...state,
        seasonality: {
          ...state.seasonality,
          error: action.error,
        },
      };
    case MOVERS_SET_DATA:
      return {
        ...state,
        movers: {
          ...state.movers,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case MOVERS_SET_LOADING:
      return {
        ...state,
        movers: {
          ...state.movers,
          isLoading: action.loading,
        },
      };
    case OBOS_SET_DATA:
      return {
        ...state,
        obos: {
          ...state.obos,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case OBOS_SET_LOADING:
      return {
        ...state,
        obos: {
          ...state.obos,
          isLoading: action.loading,
        },
      };
    case OBOS_SET_TIMEFRAME:
      return {
        ...state,
        obos: {
          ...state.obos,
          timeframe: action.timeframe,
        },
      };
    case FIFTY_DMA_SET_DATA:
      return {
        ...state,
        fiftydma: {
          ...state.fiftydma,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case FIFTY_DMA_SET_LOADING:
      return {
        ...state,
        fiftydma: {
          ...state.fiftydma,
          isLoading: action.loading,
        },
      };
    case FIFTY_DMA_SET_TIMEFRAME:
      return {
        ...state,
        fiftydma: {
          ...state.fiftydma,
          timeframe: action.timeframe,
        },
      };
    case TWOHUNDRED_DMA_SET_DATA:
      return {
        ...state,
        twohundreddma: {
          ...state.twohundreddma,
          data: action.data,
          lastFetched: action.lastFetched,
        },
      };
    case TWOHUNDRED_DMA_SET_LOADING:
      return {
        ...state,
        twohundreddma: {
          ...state.twohundreddma,
          isLoading: action.loading,
        },
      };
    case TWOHUNDRED_DMA_SET_TIMEFRAME:
      return {
        ...state,
        twohundreddma: {
          ...state.twohundreddma,
          timeframe: action.timeframe,
        },
      };
    case MP_DATA:
      return {
        ...state,
        modelPortfolios: {
          ...action.data,
        },
      };
    case MP_DETAIL_SET_LOADING:
      return {
        ...state,
        modelPortfolios: {
          ...state.modelPortfolios,
          isLoading: action.loading,
        },
      };
    case MP_DETAIL_DATA:
      return {
        ...state,
        mpDetails: action.data,
      };
    default:
      return state;
  }
};
