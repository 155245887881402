import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dimmer, Loader, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import DeleteModelPortfolioDetail from './deleteModelPortfolioDetail';
import SellModelPortfolioItem from './sellModelPortfolioItem';
import AddModelItemModal from './addModelItemModal';

// sort comparison function
const compFunc = (sortCol, sortDir) => (a, b) => {
  let varA;
  let varB;
  switch (sortCol) {
    case 'ticker':
    case 'ticker_name':
    case 'sector':
      varA = (typeof a[sortCol] === 'string') ?
        a[sortCol].toUpperCase() : a[sortCol];
      varB = (typeof b[sortCol] === 'string') ?
        b[sortCol].toUpperCase() : b[sortCol];
      break;
    case 'entry_price':
      varA = a.entry_price;
      varB = b.entry_price;
      break;
    case 'stop_price':
      varA = a.stop_price;
      varB = b.stop_price;
      break;
    case 'shares':
      varA = a.shares;
      varB = b.shares;
      break;
    default:
      return 0;
  }
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (sortDir === 'DESC' ? (comparison * -1) : comparison);
};

const formatDate = date => date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1');

function ModelPortfolioDetailTable({
  tickers,
  sortCol,
  sortDir,
  handleSortChange,
  portfolio,
}) {
  if (tickers) {
    const headers = {
      TICKERS: {
        name: 'Ticker',
        priority: 1,
        colname: 'ticker',
        style: { width: '1%', maxWidth: '20px !important' },
      },
      NAME: {
        name: 'Name',
        priority: 2,
        colname: 'ticker_name',
      },
      SECTOR: {
        name: 'Sector',
        priority: 2,
        colname: 'sector',
      },
      ENTRY_PRICE: {
        name: 'Entry Price',
        priority: 1,
        colname: 'entry_price',
        style: { textAlign: 'center' },
      },
      STOP_PRICE: {
        name: 'Stop Price',
        priority: 1,
        colname: 'stop_price',
        style: { textAlign: 'center' },
      },
      SHARES: {
        name: 'Shares',
        priority: 1,
        colname: 'shares',
        style: { textAlign: 'center' },
      },
      DATE: {
        name: 'Date Added',
        priority: 1,
        colname: 'date',
        style: { textAlign: 'center' },
      },
      ACTION: {
        name: 'Action',
        priority: 1,
        style: { textAlign: 'center' },
      },
    };
    // const prioritySum = Object.values(headers).reduce((total, curr) => total + curr.priority, 0);
    Object.keys(headers).forEach((key) => {
      headers[key] = {
        name: headers[key].name,
        // width: `${((headers[key].priority / (prioritySum + 2)) * 100).toFixed(2)}%`,
        colname: headers[key].colname,
        style: headers[key].style,
      };
    });
    const header = [
      <Table.Header>
        <Table.Row key="main-header">
          {Object.values(headers).map((h) => {
            let newDir = 'ASC';
            let sortArr = '';
            if (h.colname === sortCol && sortDir === 'ASC') {
              newDir = 'DESC';
            }
            if (h.colname === sortCol) {
              if (newDir === 'ASC') {
                sortArr = ' ↑';
              } else {
                sortArr = ' ↓';
              }
            }
            return (
              <Table.HeaderCell
                key={h.colname}
                style={{
                  width: h.width,
                  ...h.style,
                  cursor: 'pointer',
                  color: (sortCol === h.colname ? 'darkblue' : undefined),
                  whiteSpace: 'nowrap',
                }}
                onClick={(handleSortChange ? () => handleSortChange(h.colname, newDir) : undefined)}
              >
                {handleSortChange ? `${h.name}${sortArr}` : h.name}
              </Table.HeaderCell>);
          })}
        </Table.Row>
      </Table.Header>];
    const body = [];
    // sort tickers
    tickers.sort(compFunc(sortCol, sortDir));
    tickers.forEach((ticker) => {
      const cells = [];
      if ('TICKERS' in headers) {
        cells.push((
          <Table.Cell>
            <Link to={`/ticker/${ticker.ticker}`}>{ticker.ticker}</Link>
          </Table.Cell>));
      }
      if ('NAME' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.ticker_name}</Table.Cell>);
      }

      if ('SECTOR' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.sector}</Table.Cell>);
      }

      if ('ENTRY_PRICE' in headers) {
        cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.entry_price).toFixed(2)}</Table.Cell>);
      }
      if ('STOP_PRICE' in headers) {
        cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.stop_price).toFixed(2)}</Table.Cell>);
      }
      if ('SHARES' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>{ticker.shares}</Table.Cell>);
      }
      if ('DATE' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>{formatDate(ticker.date)}</Table.Cell>);
      }

      cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}><SellModelPortfolioItem ticker={ticker} /><AddModelItemModal content={(<Button color="yellow" size="mini" icon="edit" />)} details_form={ticker} portfolio={portfolio} /><DeleteModelPortfolioDetail id={ticker.id} name={ticker.ticker_name} /></Table.Cell>);

      body.push((
        <Table.Row>
          {cells}
        </Table.Row>));
    });

    return (
      <div className="table-scroll" style={{ margin: '0px' }}>
        <Table
          celled
          unstackable
          style={{ fontSize: '20px' }}
          className="left-fixed"
        >
          {header}
          <Table.Body>
            {body}
          </Table.Body>
        </Table>
      </div>
    );
  }
  return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
}

ModelPortfolioDetailTable.defaultProps = {
  sortCol: 'ticker',
  sortDir: 'ASC',
  handleSortChange: undefined,
};

ModelPortfolioDetailTable.propTypes = {
  tickers: PropTypes.array.isRequired,
  portfolio: PropTypes.object.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  handleSortChange: PropTypes.func,
};

export default ModelPortfolioDetailTable;
