import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setPageTitle } from '../../actions';
import ToolCard from '../presentational/cards/ToolCard';
import chartScannerImg from '../../img/cardImages/tools/chart-scanner.png';
import trendAnalyzerImg from '../../img/cardImages/tools/trend-analyzer.png';
import SeasonalityImg from '../../img/cardImages/tools/seasonality-tool.png';
import earningsExplorerImg from '../../img/cardImages/tools/earnings-explorer.png';
import intlRevenuesImg from '../../img/cardImages/tools/international-revenues.png';
import economicDatabaseImg from '../../img/cardImages/tools/economic-database.png';
import securityAnalysisImg from '../../img/cardImages/tools/security-analysis.png';
import economicMonitorsImg from '../../img/cardImages/tools/economic-monitors.png';
import triplePlaysImg from '../../img/cardImages/tools/triple-plays.png';
// import placeholderImg from '../../img/cardImages/tools/placeholder.png';
import biggestMoversImg from '../../img/cardImages/tools/biggest-movers.png';

class Tools extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    document.title = 'Bespoke Investment Group | Tools';
    dispatch((setPageTitle('TOOLS')));
  }

  render() {
    // @todo needs uncoupling
    // note using 'teal' for base color enum. Workaround
    const tools = [
      {
        name: 'Trend Analyzer',
        desc: 'Quickly view trend and timing to generate ideas or help you plan an entry or exit.',
        image: trendAnalyzerImg,
        link: '/trend-analyzer',
      },
      {
        name: 'Chart Scanner',
        desc: 'The Chart Scanner is the technician\'s best friend, letting you rapidly scan charts to discover attractive patterns.',
        image: chartScannerImg,
        link: '/chart-scanner',
      },
      {
        name: 'Seasonality Tool',
        desc: 'The Seasonality Tool helps you identify the best and worst time of year to be invested in stocks, ETFs, and global indices',
        image: SeasonalityImg,
        link: '/seasonality-tool',
      },
      {
        name: 'Earnings Explorer',
        desc: '15+ years of historical earnings data for over 3,000 stocks.',
        image: earningsExplorerImg,
        link: '/earnings-explorer',
      },
      {
        name: 'Economic Monitors',
        desc: 'Stay up to date on economic data releases, central bank meetings, and fed sentiment.',
        image: economicMonitorsImg,
        link: '/economic-monitors',
      },
      {
        name: 'Intl. Revenues',
        desc: 'Easily find domestic vs. international revenue exposure for large-cap stocks and sectors.',
        image: intlRevenuesImg,
        link: '/international-revenues',
      },
      {
        name: 'Triple Plays',
        desc: 'See all earnings triple plays in one convenient place.',
        image: triplePlaysImg,
        link: '/triple-plays',
      },
      {
        name: 'Econ. Indicators',
        desc: 'Search 20+ years of historical US economic indicators with actual vs. estimated readings and market reactions.',
        image: economicDatabaseImg,
        link: '/economic-database',
      },
      /*
      {
        name: 'Earnings Calendar',
        desc: 'Use our Earnings Calendar to find upcoming report dates and additional earnings stats for the stocks you follow.',
        image: <Icon.Group size="huge"><Icon color="black" name="certificate" size="big" /><Icon style={{ backgroundColor: 'transparent' }} inverted color="grey" name="calendar alternate outline" /></Icon.Group>,
        link: '/earnings-calendar',
      },
      */
      {
        name: 'Security Analysis',
        desc: 'Get key data points, charts, and analysis you need on any stock or ETF we track.',
        image: securityAnalysisImg,
        link: '/ticker/AAPL?showhelp=true',
      },
      {
        name: 'Biggest Movers',
        desc: 'Sortable list of daily price and volume change for S&P 1500 stocks.',
        image: biggestMoversImg,
        link: '/biggest-movers',
      },
    ];
    return (
      <React.Fragment>
        <h1 className="mobile-hide" style={{ fontSize: '4em' }}>Our Tools</h1>
        <p className="mobile-hide" style={{ fontSize: '1.6em' }}>
          Test out new research ideas and explore our custom
          databases with our suite of interactive tools!
        </p>
        <br />
        <div className="ui five column doubling grid">
          {tools.map(x => (
            <Grid.Column>
              <ToolCard {...x} dispatch={this.props.dispatch} />
            </Grid.Column>
          ))}
        </div>
        <br />
        <br />
        <p style={{ color: '#777' }}>
          {/* © Copyright {(new Date()).getFullYear()},
          Bespoke Investment Group, LLC.<br /> */}
          © Copyright {(new Date()).getFullYear()}, Bespoke Investment Group,
          LLC believes all information in our Tools section to be accurate,
          but we do not guarantee its accuracy.  None of the information in
          our Tools section, including but not limited to our proprietary Trend
          and Timing scores, our Trading Range charts, our Earnings scores,
          and our Seasonality information, constitutes a solicitation of the
          purchase or sale of any securities or commodities.
          As always, past performance is not indicative of or a guarantee of future results.
          Please read our
          <Link to="/legal"> Legal Policy </Link>
          for further information.
        </p>
      </React.Fragment>
    );
  }
}
Tools.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  staticCards: state.content.static,
  portfolios: state.user.portfolios,
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(Tools);
