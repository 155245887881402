import React from 'react';
import { connect } from 'react-redux';
import { Menu, Grid, Dimmer, Loader, Icon, Input, Segment, Pagination, Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-component';
import queryString from 'query-string-es5';
import { getData, getSearchData, setAccessibleCategories } from '../../actions/content/research';
import { setStar } from '../../actions/user';
import PostCard from '../presentational/cards/PostCard';
import UpgradeModal from '../presentational/upgrade';
import SignUp from '../presentational/signup';
import Subscriber from '../presentational/interactive/subscriber';
import ModelPortfolioDetails from './research/modelPortfolioDetails';
import Touring from '../presentational/interactive/tour';

/**
 * @todo  Refactor into smaller components.
 */
class Research extends React.Component {
  static generateMenuItem(x, activeItem, authorizedCategories) {
    return (x[1][1].length === 1 ?
      (
        <Menu.Item
          as={Link}
          style={{ color: '#666' }}
          to={`/research/${x[0]}`}
          key={`${x[0]}`}
          name={`${x[0]}`}
          active={activeItem === x[0]}
        >
          {x[1][0]}
        </Menu.Item>
      ) :
      (
        <Dropdown
          item
          style={{ color: '#666' }}
          text={x[1][0]}
          className={`${x[1][1].includes(activeItem) || x[0] === activeItem ? 'active item' : ''} ${x[1][1].includes('bespoke-baskets') || x[0] === 'bespoke-baskets' ? 'research-seventh-step' : ''}`}
        >
          <Dropdown.Menu >
            {[(
              <Dropdown.Item
                as={Link}
                style={{ color: '#666', display: (x[0] === 'bespoke-baskets') ? 'none' : '' }}
                to={`/research/${x[0]}`}
                key={`${x[0]}`}
                name={`${x[0]}`}
                active={activeItem === x[0]}
              >
                All
              </Dropdown.Item>
              ), ...x[1][1].filter(i => [...authorizedCategories, 'all', 'featured-reports', 'starred-reports', 'search'].includes(i)).map(item => (
                <Dropdown.Item
                  as={Link}
                  style={{ color: '#666' }}
                  to={`/research/${item}`}
                  key={`${item}`}
                  name={`${item}`}
                  active={activeItem === item}
                >
                  {item === 'bespoke-growth-basket' ? 'Growth Basket' : ''}
                  {item === 'bespoke-dividend-income-basket' ? 'Dividend Income Basket' : ''}
                  {item === 'bespoke-best-of-breed' ? 'Best Of Breed' : ''}
                  {item === 'momentum-margins-moat-3m' ? 'Momentum, Margins, & Moat (3M)' : ''}
                  {item === 'ai-infrastructure' ? 'AI Infrastructure' : ''}
                  {item === 'ai-implementation' ? 'AI Implementation' : ''}
                  {item !== 'bespoke-growth-basket' && item !== 'bespoke-dividend-income-basket' && item !== 'bespoke-best-of-breed' && item !== 'momentum-margins-moat-3m' && item !== 'ai-infrastructure' && item !== 'ai-implementation' ? item.split('-') // prettify slugs, special case for ETF to fully capitalize 😬
                    .map(s => (s === 'etf' ? 'ETF' : s.charAt(0).toUpperCase() + s.substring(1)))
                    .join(' ') : ''
                  }
                </Dropdown.Item>
            ))]}
          </Dropdown.Menu>
        </Dropdown>
      )
    );
  }

  constructor(props) {
    super(props);
    let activeItem = props.match && props.match.params && props.match.params.category;
    if (!activeItem) {
      activeItem = 'all';
    }
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.toggleStarredFactory = this.toggleStarredFactory.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleMPDetailsSortChange = this.handleMPDetailsSortChange.bind(this);
    this.lastSelected = (activeItem === 'search' ? 'all' : activeItem);
    this.searchText = '';
    this.state = {
      // format is:
      // slug: [
      //  Title,
      //  Color
      // ]
      mpDetailsSortColumn: 'ticker',
      mpDetailsSortDirection: 'ASC',
      archiveConfig: {
        all: ['All', ['all']],
        'starred-reports': [<Icon style={{ margin: '0px', color: '#ffb70a' }} name="star" />, ['starred-reports']],
        'featured-reports': ['Featured', ['featured-reports']],
        'morning-lineup': ['Morning Lineup', ['morning-lineup']],
        'the-closer': ['The Closer', ['the-closer']],
        'big-tips': ['B.I.G. Tips', ['big-tips']],
        'key-reports': ['Bespoke Report', ['bespoke-report', 'annual-outlook']],
        'chart-of-the-day': ['Chart of The Day', ['chart-of-the-day']],
        'sector-snapshot': ['Sector Snapshot', ['sector-snapshot']],
        'economics-macro': ['Macro', ['economic-indicators', 'global-macro', 'fixed-income-weekly', 'bespoke-briefs']],
        'stock-ideas': ['Stock Ideas', ['bespoke-50', 'conference-call-recaps', 'little-known-stocks', 'stock-scores']],
        // 'stock-scores': 'Stock Scores',
        'bespoke-baskets': ['Bespoke Baskets', ['bespoke-growth-basket', 'bespoke-dividend-income-basket', 'bespoke-best-of-breed', 'momentum-margins-moat-3m', 'ai-infrastructure', 'ai-implementation']],
        'think-big-blog': ['Think B.I.G.', ['think-big-blog']],
        // 'economic-indicators': 'Economic Indicators',
        // 'short-interest-report': 'Short Interest',
        // 'earnings-estimate-revisions': ['Earnings Revisions', '#c9ffc9'],
        // 'daily-triple-plays': 'Triple Plays',
        // 'stock-seasonality': 'Stock Seasonality',
        // 'monthly-headlines': 'Monthly Headlines',
        // 'fixed-income-weekly': ['FI Weekly', '#d1c9ff'],
        // 'idea-generator': 'Idea Generator',
        // 'industry-insights': ['Industry Insights', '#edc9ff'],
        // 'global-macro': ['Macro Dashboard', '#ffc9ed'],
        pulse: ['Pulse', ['pulse']],
        // crypto: ['Crypto', ['crypto']],
        search: [<Icon style={{ margin: '0px' }} name="search" />, ['search']],
      },
    };
  }

  componentDidMount() {
    this.fetchPosts();
    this.props.getAuthorizedCategories();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      this.fetchPosts();
    }
    if (this.props.starredPosts.lastFetched !== prevProps.starredPosts.lastFetched) {
      this.fetchPosts();
    }
  }

  handleMPDetailsSortChange(column, direction) {
    if (column && direction) {
      this.setState({
        mpDetailsSortColumn: column,
        mpDetailsSortDirection: direction,
      });
    }
  }

  toggleStarredFactory(starred, id) {
    const { dispatch } = this.props;
    return () => {
      setStar(dispatch)(id, !starred);
    };
  }

  fetchPosts() {
    const { match, dispatch } = this.props;
    const query = match && match.params && match.params.query;
    let activeItem = match && match.params && match.params.category;
    let page = match && match.params && match.params.page;
    if (!page) {
      page = 1;
    }
    if (!activeItem) {
      activeItem = 'all';
    }
    if (activeItem !== 'search') {
      dispatch(getData(activeItem, page));
      this.lastSelected = activeItem;
    } else if (query) {
      dispatch(getSearchData(query, page));
    }
  }

  searchPosts() {
    // refactor to just push a new route
    const { dispatch } = this.props;
    const query = this.searchText;
    dispatch(push(`/research/search/${query}/1`));
  }

  handlePageChange(event, { activePage }) {
    const { match, dispatch } = this.props;
    let activeItem = match && match.params && match.params.category;
    const query = match && match.params && match.params.query;
    if (!activeItem) {
      activeItem = 'all';
    }
    dispatch(push(`/research/${activeItem}/${activeItem === 'search' ? `${query}/` : ''}${activePage}`));
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleSearchChange(e) {
    this.searchText = e.target.value;
  }

  handleSearchKeyPress(e) {
    if (e.key === 'Enter') {
      this.searchPosts();
    }
  }

  render() {
    const {
      posts,
      match,
      loading,
      error,
      totalPages,
      authorizedCategories,
      access,
      location,
    } = this.props;

    const steps = [
      {
        selector: '.research-first-step',
        content: 'Our Research page contains all of the reports available to you at your current membership level.',
      },
      {
        selector: '[href="/interactive/research/featured-reports"]',
        content: 'Click the "Featured" tab to find reports we consider "must reads".',
      },
      {
        selector: '.ui.huge.secondary.archive-tabs.menu',
        content: 'Click any of the category tabs to filter through to specific reports.',
      },
      {
        selector: '[href="/interactive/research/starred-reports"]',
        content: 'Click the star icon on any report to save it in your starred reports folder.',
      },
      {
        selector: '[href="/interactive/research/search"]',
        content: 'Use the search function to find historical reports.',
      },
      {
        selector: '[href="/interactive/research/all"]',
        content: 'The "All" tab contains all of our reports in chronological order from top to bottom.',
      },
      {
        selector: '.research-seventh-step',
        content: 'Track our three Bespoke Baskets with prices updated daily.',
      },
    ];

    // This kind of a hack but it needs to be done to render the Model Portfolio Section
    const modelPortfolios = {
      'bespoke-growth-basket': { id: 1, name: 'Bespoke Growth Basket', slug: 'bespoke-growth-basket' },
      'bespoke-dividend-income-basket': { id: 2, name: 'Bespoke Dividend Income Basket', slug: 'bespoke-dividend-income-basket' },
      'bespoke-best-of-breed': { id: 3, name: 'Bespoke Best of Breed', slug: 'bespoke-best-of-breed' },
      'momentum-margins-moat-3m': { id: 16, name: 'Momentum, Margins, & Moat (3M)', slug: 'momentum-margins-moat-3m' },
      'ai-infrastructure': { id: 17, name: 'AI Infrastructure', slug: 'ai-infrastructure' },
      'ai-implementation': { id: 18, name: 'AI Implementation', slug: 'ai-implementation' },
      // 'bespoke-asset-allocation-basket': { id: 3, name: 'Bespoke Asset Allocation Basket' },
    };

    const query = queryString.parse(location.search);
    const starredPosts = this.props.starredPosts.byId;
    let activeItem = match && match.params && match.params.category;
    let page = match && match.params && match.params.page;
    if (!activeItem) {
      activeItem = 'all';
    }
    if (!page) {
      page = 1;
    }
    const { archiveConfig } = this.state;
    const archiveEntries = Object.entries(archiveConfig);
    let postCards;
    let nav;
    let content;
    let subscribe;

    const tour = activeItem !== 'think-big-blog' ? (
      <Touring
        cookieName="researchTouring"
        steps={steps}
        hideIcon
      />) : '';

    if (activeItem === 'think-big-blog') {
      subscribe = <Subscriber />;
    }

    // determine nav to display (search or standard)
    if (activeItem !== 'search') {
      if (access === null) {
        const mapSlinks = {
          pulse: 'https://www.bespokepremium.com/consumer-pulse-info/',
          'the-closer': 'https://www.bespokepremium.com/bespoke-membership-options/?level=i',
          // crypto: 'https://www.bespokepremium.com/checkout/?rid=ptgLaP',
        };
        nav = (
          <Menu size="huge" className="archive-tabs" secondary style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
            {archiveEntries.map(x => (x[1][1].filter(i => [...authorizedCategories, 'all', 'featured-reports', 'starred-reports', 'search', 'think-big-blog'].includes(i)).length > 0 ? (
              Research.generateMenuItem(x, activeItem, authorizedCategories)
                ) :
                (
                  <SignUp
                    trigger={
                      <Menu.Item
                        style={{ color: '#444', backgroundColor: '#aaa' }}
                        key={`${x[0]}`}
                        name={`${x[0]}`}
                        to={`/research/${x[0]}`}
                      >
                        {x[1][0]}
                      </Menu.Item>}
                    linkSignup={mapSlinks[x[0]] || undefined}
                  />
                )
            ))}
          </Menu>);
      } else {
        nav = (
          <Menu size="huge" className="archive-tabs" secondary style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
            {archiveEntries.map(x => (x[1][1].filter(i => [...authorizedCategories, 'all', 'featured-reports', 'starred-reports', 'search', 'think-big-blog'].includes(i)).length > 0 ? (
                  Research.generateMenuItem(x, activeItem, authorizedCategories)
                ) :
                (
                  <UpgradeModal
                    trigger={
                      <Menu.Item
                        style={{ color: '#444', backgroundColor: '#aaa' }}
                        key={`${x[0]}`}
                        name={`${x[0]}`}
                        to={`/research/${x[0]}`}
                      >
                        {x[1][0]}
                      </Menu.Item>}
                    /* eslint-disable-next-line no-nested-ternary */
                    link={x[0] === 'pulse' ? '/consumer-pulse-info/' : (x[0] === 'crypto' ? 'https://www.bespokepremium.com/checkout/?rid=ptgLaP' : undefined)}
                  />
                )
            ))}
          </Menu>);
      }
    } else {
      nav = (
        <Menu size="huge" className="archive-tabs" secondary style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
          <Menu.Item as={Link} to={`/research/${this.lastSelected}`} key="search-back" name="search-back" style={{ color: '#666' }}>
            <Icon style={{ margin: '0px' }} name="reply" />
          </Menu.Item>
          <Menu.Item key="search-input" name="search-input">
            <Input
              focus
              icon={<Icon name="search"link onClick={() => this.searchPosts()} />}
              onKeyPress={this.handleSearchKeyPress}
              onChange={this.handleSearchChange}
              style={{ border: 'none' }}
              placeholder="Search..."
            />
          </Menu.Item>
        </Menu>);
    }
    if (!error) {
      if (
        (activeItem === 'bespoke-growth-basket' && !query.legacy) ||
        (activeItem === 'bespoke-dividend-income-basket' && !query.legacy) ||
        (activeItem === 'bespoke-best-of-breed' && !query.legacy) ||
        (activeItem === 'momentum-margins-moat-3m' && !query.legacy) ||
        (activeItem === 'ai-infrastructure' && !query.legacy) ||
        (activeItem === 'ai-implementation' && !query.legacy)
      ) {
        content = (
          <React.Fragment>
            <ModelPortfolioDetails
              portfolio={modelPortfolios[activeItem]}
              sortCol={this.state.mpDetailsSortColumn}
              sortDir={this.state.mpDetailsSortDirection}
              handleSortChange={this.handleMPDetailsSortChange}
            />
          </React.Fragment>
        );
      } else if (posts && posts[0] && !posts[0].restricted) {
        const paginator = (
          <Grid centered columns={1}>
            <Grid.Column textAlign="center">
              <Pagination
                size="mini"
                activePage={page}
                ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </Grid.Column>
          </Grid>);
        postCards = posts
          .map(x => (
            <Grid.Column key={`${x.category_slug}-${x.id}`}>
              <PostCard
                style={{
                  boxShadow: (x.category_slug.indexOf('featured-reports') === -1 ? 'none' : '0px 0px 20px #2ecc71'),
                }}
                post={x}
                key={`${x.id}`}
                id={x.id}
                starred={starredPosts[x.id]}
                toggleStarred={this.toggleStarredFactory(starredPosts[x.id], x.id)}
              />
            </Grid.Column>
          ));
        content = (
          <React.Fragment>
            {page > 1 ? paginator : undefined}
            <Masonry className="ui doubling stackable three column grid">
              {postCards}
            </Masonry>
            {paginator}
          </React.Fragment>
        );
        if (posts && posts.length === 0) {
          if (activeItem === 'starred-reports') {
            content = <h1 style={{ textAlign: 'center' }} key="no-content">You haven&apos;t starred any reports yet! To star a report, click the star icon in the upper righthand corner of a report.</h1>;
          } else {
            content = <h1 style={{ textAlign: 'center' }} key="no-content">No reports found.</h1>;
          }
        }
      }
    } else {
      content = (
        <Segment>
          <h1 style={{ textAlign: 'center' }} key="error">An error has occurred. Please try again, or try another search term.</h1>
          <p style={{ textAlign: 'center' }} key="error-message">Error: {error.message}</p>
        </Segment>);
    }

    const signup = access === null
      && activeItem !== 'think-big-blog'
      && activeItem !== 'featured-reports'
      && activeItem !== 'all' ?
      (<SignUp
        message={['You don\'t have access to this posts. If you would like to see this posts, please log-in or become a member.']}
      />) : '';

    return (
      <React.Fragment>
        {signup}
        {!loading ? subscribe : ''}
        {nav}
        <Dimmer inverted active={loading}>
          <Loader />
        </Dimmer>
        {content}
        {tour}
      </React.Fragment>
    );
  }
}

Research.defaultProps = {
  error: null,
};

Research.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  starredPosts: PropTypes.object.isRequired,
  authorizedCategories: PropTypes.object.isRequired,
  getAuthorizedCategories: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  posts: state.content.research.posts,
  totalPages: state.content.research.totalPages,
  error: state.content.research.error,
  loading: state.content.research.loading,
  starredPosts: state.user.starred,
  authorizedCategories: state.content.research.accessibleCategories,
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getAuthorizedCategories: () => dispatch(setAccessibleCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Research);
