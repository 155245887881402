import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import EditModelPortfolio from './editModelPortfolio';

class EditModelPortfolioModal extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;
    const {
      id,
      name,
      cash,
      starting,
      sp500Starting,
      startingYTD,
      sp500StartingYTD,
    } = this.props;

    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click() })}
        <Modal basic onClose={this.close} open={open} {...this.props} closeIcon>
          <h1 style={{ color: 'white' }}>Edit Model Portfolio</h1>
          <EditModelPortfolio
            submitAction={(result) => { this.props.submitAction(result); this.close(); }}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            id={id}
            name={name}
            cash={cash}
            starting={starting}
            sp500Starting={sp500Starting}
            startingYTD={startingYTD}
            sp500StartingYTD={sp500StartingYTD}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

EditModelPortfolioModal.defaultProps = {
  submitAction: () => null,
  content: (<Icon link name="edit" color="blue" />),
};

EditModelPortfolioModal.propTypes = {
  submitAction: PropTypes.func,
  content: PropTypes.element,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  cash: PropTypes.string.isRequired,
  starting: PropTypes.string.isRequired,
  sp500Starting: PropTypes.string.isRequired,
  startingYTD: PropTypes.string.isRequired,
  sp500StartingYTD: PropTypes.string.isRequired,
};

export default EditModelPortfolioModal;
