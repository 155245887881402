import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { updatePortfolio } from '../../../../actions/user';

class editPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.portfolioName,
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value, error: false, errorMessage: '' });

  handleSubmit = () => this.props.editPortfolioAction(this.state.name, this.props.portfolioId)
    .then(result => this.props.submitAction(result))
    .catch(e => this.setState({ error: true, errorMessage: e.message }));

  render() {
    const { name, error, errorMessage } = this.state;
    return (
      <Form
        error={error}
        onSubmit={this.handleSubmit}
      >
        <Form.Group style={{ width: '320px', textAlign: 'center' }}>
          <Form.Input error={error} placeholder="Edit portfolio..." name="name" value={name} onChange={this.handleChange} />
          <Form.Button content="Edit" />
        </Form.Group>
        <Message
          error
          // header="Error"
          content={errorMessage}
        />
      </Form>);
  }
}

editPortfolio.defaultProps = {
  submitAction: () => null, // to pass in modal close from addPortfolioModal
};

editPortfolio.propTypes = {
  editPortfolioAction: PropTypes.func.isRequired,
  portfolioName: PropTypes.string.isRequired,
  portfolioId: PropTypes.number.isRequired,
  submitAction: PropTypes.func,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  editPortfolioAction: (name, id) => dispatch(updatePortfolio(name, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(editPortfolio);
