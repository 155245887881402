import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EarningsCalendarInput from '../presentational/interactive/earningsCalendarInput';
import EarningsCalendarTable from '../presentational/interactive/earningsCalendarTable';
import { navigateToPage } from '../../actions/search';
import * as api from '../../api';

const sortFunc = (col, dir) => (a, b) => {
  let varA;
  let varB;
  switch (col) {
    case 'stock':
    case 'company':
    case 'reportdate':
    case 'reporttime':
      varA = (typeof a[col] === 'string') ?
        a[col].toUpperCase() : a[col];
      varB = (typeof b[col] === 'string') ?
        b[col].toUpperCase() : b[col];
      break;
    case 'currenteps':
    case 'revisedeps':
    case 'prioronfile':
    case 'epsbeat':
    case 'revenuebeat':
    case 'guidance':
    case 'avg_gap':
    case 'avg_o2c':
    case 'avg_1day':
    case 'avgabsolute':
      varA = parseFloat(a[col]);
      varB = parseFloat(b[col]);
      break;
    default:
      return 0;
  }

  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (dir === 'DESC' ? (comparison * -1) : comparison);
};

const filterFunc = (minDateArg, maxDateArg) => {
  const filterMin = (minDateArg ? Date.parse(minDateArg) : undefined);
  const filterMax = (maxDateArg ? Date.parse(maxDateArg) : undefined);
  return (x) => {
    const date = Date.parse(x.reportdate);
    return (filterMin ? date >= filterMin : true) && (filterMax ? date <= filterMax : true);
  };
};

class EarningsCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);

    this.cacheExpTime = 900000;

    this.state = {
      data: {
        rows: [],
        lastFetched: null,
      },
      isLoading: true,
      minDate: undefined,
      maxDate: undefined,
      sortCol: 'stock',
      sortDir: 'ASC',
      displayData: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    if (!this.state.isLoading) {
      this.fetchData();
    }
  }

  fetchData() {
    if (this.state.data.lastFetched === null ||
      Date.now() - this.state.data.lastFetched > this.cacheExpTime) {
      const {
        minDate,
        maxDate,
        sortCol,
        sortDir,
      } = this.state;
      api.tools.getECData()
        .then(results =>
          this.setState({
            isLoading: false,
            minDate: results.map(x => Date.parse(x.reportdate)).reduce((acc, curr) => {
              if (acc > curr) {
                return curr;
              }
              return acc;
            }, Infinity),
            maxDate: results.map(x => Date.parse(x.reportdate)).reduce((acc, curr) => {
              if (acc < curr) {
                return curr;
              }
              return acc;
            }, -Infinity),
            data: {
              rows: results,
              lastFetched: Date.now(),
            },
            displayData: results
              .filter(filterFunc(minDate, maxDate))
              .sort(sortFunc(sortCol, sortDir)),
          }))
        .catch(() => this.setState({
          isLoading: false,
        }));
    }
  }

  handleSortChange(column) {
    let newDir = 'ASC';
    const { sortCol, sortDir, displayData } = this.state;
    if (sortCol === column) {
      newDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    const sortedData = displayData.sort(sortFunc(column, newDir));
    this.setState({
      sortCol: column,
      sortDir: newDir,
      displayData: sortedData,
    });
  }

  handleDateChange(event, eventData) {
    const { sortCol, sortDir, data } = this.state;
    let dates = eventData.value.split(' - ');
    dates = dates.map(x => (x === '' ? undefined : x));
    const displayData = data.rows.filter(filterFunc(dates[0], dates[1]))
      .sort(sortFunc(sortCol, sortDir));
    this.setState({
      displayData,
    });
  }

  render() {
    const {
      minDate,
      maxDate,
      isLoading,
      displayData,
      sortCol,
      sortDir,
      data,
    } = this.state;
    const { ticker } = this.props.match.params;
    return (
      <React.Fragment>
        <h1>Earnings Calendar</h1>
        <EarningsCalendarInput
          isLoading={isLoading}
          handleDateChange={this.handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          tickerList={data.rows}
          ticker={ticker}
          navigateToPage={this.props.navigateToPage}
        />
        <EarningsCalendarTable
          sortCol={sortCol}
          sortDir={sortDir}
          displayData={displayData}
          sortChange={this.handleSortChange}
        />
      </React.Fragment>);
  }
}

EarningsCalendar.propTypes = {
  match: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => dispatch(navigateToPage(url)),
});

export default connect(null, mapDispatchToProps)(EarningsCalendar);
