import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { initialize, setLockScreen } from '../../actions/user';
// import logo from '../../img/logo_white.png';

// const lockScreen = (
//   <div
//     // className="ui page modals dimmer transition visible active"
//     style={{
//       display: 'flex !important',
//       alignItems: 'center !important',
//       justifyContent: 'center !important',
//       height: '100vh',
//       width: '100vw',
//       zIndex: '20000',
//       position: 'fixed',
//       backgroundColor: '#003051',
//       margin: '0px',
//     }}
//   >
//     <div style={{ marginTop: '30vh', marginLeft: '20%', marginRight: '20%' }}>
//       <img src={logo} alt="bespoke-interactive" style={{ width: '400px', maxWidth: '80%' }} />
//       <h1 style={{ fontSize: '3rem', alignSelf: 'center', color: 'white' }}>
//         Looks like you aren&apos;t logged in!
//       </h1>
//       <h1 style={{ alignSelf: 'center', color: 'white' }}>
//         Let&apos;s Fix That.
//       </h1>
//       <br />
//       <Button
//         onClick={() => { window.location = 'https://www.bespokepremium.com/login/'; }}
//         style={{
//           backgroundColor: 'white',
//           borderRadius: '200px',
//           fontSize: '1.2rem',
//           color: '#003051',
//         }}
//       >
//         Log In
//       </Button>
//     </div>
//   </div>
// );

class UserInit extends React.Component {
  componentDidMount() {
    const {
      initUser,
      lockScreenHandler,
    } = this.props;
    initUser().catch((error) => {
      if (error.message) {
        lockScreenHandler(true);
      }
    });
  }

  render() {
    // return this.props.errored ? lockScreen : null;
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  initUser: () => dispatch(initialize),
  lockScreenHandler: status => dispatch(setLockScreen(status)),
});

UserInit.propTypes = {
  initUser: PropTypes.func.isRequired,
  lockScreenHandler: PropTypes.func.isRequired,
  // errored: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  errored: state.user.lockScreen,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInit);
