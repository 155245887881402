import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { timeWeek } from 'd3-time';
import { connect } from 'react-redux';
import LinearChart from '../linearChart';
// import LineChart from '../../charts/LineChart';

class CompositeChart extends React.PureComponent {
  render() {
    const {
      ticker,
      numYears,
      compositeData,
      isLoading,
    } = this.props;
    return (
      <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="seventh-step">
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div
          className="titleBar"
          style={{
            fontSize: '16px',
            marginBottom: '10px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        >
          {`${ticker} Annual Composite Chart (%): Last ${numYears} Years`}
        </div>
        { (compositeData.length > 0) ?
          <LinearChart
            initialData={compositeData}
            margins={{
              left: 30,
              right: 10,
              top: 10,
              bottom: 30,
            }}
            timeFormatter="%-m/%-d"
            timeTicks={[timeWeek, 6]}
          /> :
          ''
        }
      </Dimmer.Dimmable>);
  }
}

CompositeChart.propTypes = {
  ticker: PropTypes.string.isRequired,
  numYears: PropTypes.number.isRequired,
  compositeData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  compositeData: state.data.seasonality.data.compositeData,
  isLoading: state.data.seasonality.isLoading,
});

export default connect(mapStateToProps, null)(CompositeChart);
