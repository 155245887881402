import React from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Button, Icon } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import 'react-virtualized/styles.css';
import { isBrowser } from 'react-device-detect';

const createTableBody = (rows, cols, paginated) => {
  if (paginated) { // BEING DEPRECATED
    return (
      <React.Fragment>
        {rows.map(x => (
          <Table.Row key={x.primary_key} className="third-step search-sixth-step">
            {cols.map(col =>
              (
                <Table.Cell
                  key={col.key}
                  style={col.styleFunc && col.styleFunc(x[col.key])}
                >
                  {col.formatFunc && !(x[col.key] == null) ? col.formatFunc(x[col.key]) : (!(x[col.key] == null) && x[col.key]) || '-'}
                </Table.Cell>))
            }
          </Table.Row>
        ))}
      </React.Fragment>
    );
  }
  let htmlStr = '';
  rows.forEach((row) => {
    htmlStr += '<tr>';
    cols.forEach((col) => {
      htmlStr += '<td';
      htmlStr += `${col.styleFunc ? ` style="${col.styleFunc(row[col.key])}"` : ''}>`;
      htmlStr += col.formatFunc ? col.formatFunc(row[col.key]) : row[col.key];
      htmlStr += '</td>';
    });
    htmlStr += '</tr>';
  });
  return { __html: htmlStr };
};

// Todo: Needs header styles + extra header rows
class SortableTable extends React.PureComponent {
  render() {
    const {
      preHeader,
      columns,
      rows,
      handleSort,
      sortCol,
      sortDir,
      style,
      sortColor,
      striped,
      celled,
      page,
      paginated,
      rowsPerPage,
      handlePageChange,
      responsive,
      downloadData,
      downloadName,
    } = this.props;

    const arr = sortDir === 'DESC' ? `${String.fromCharCode(160)}↑` : `${String.fromCharCode(160)}↓`;

    // If it's paginated process the rows
    let processedRows = rows;
    let totalPages = 1;
    if (paginated) {
      processedRows = rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
      totalPages = Math.ceil(rows.length / rowsPerPage);
    }

    // Generate responsive style
    let responsiveStyle = null;
    if (responsive === 'split') {
      // dynamically generate CSS based on columns!
      responsiveStyle = '@media screen and (max-width: 768px) {';
      responsiveStyle += 'table.split, .split thead, .split tbody, .split th, .split td, .split tr { display: block !important; }';
      responsiveStyle += '.split thead tr { position: absolute; top: -9999px; left: -9999px; }';
      responsiveStyle += '.split tr { border: 1px solid #ccc !important; }';
      responsiveStyle += '.split td { border: none !important; border-bottom: 1px solid #eee !important; position: relative !important; padding-left: 50% !important; text-align: left !important; }';
      responsiveStyle += '.split td:before { font-weight: bold; text-align: left; position: absolute !important; top: 6px !important; left: 6px !important; width: 45% !important; padding-right: 10px !important; white-space: nowrap; }';
      // Loop through columns
      columns.forEach((x, idx) => {
        responsiveStyle += `.split td:nth-of-type(${idx + 1}):before { content: "${x.text}"; cursor: pointer; }`;
      });
      responsiveStyle += '}';
    } else if (responsive === 'left-fixed') {
      responsiveStyle = '.table-scroll { margin:1em 0; }';
      responsiveStyle += '@media screen and (max-width: 768px) {';
      responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow: scroll; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
      responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
      responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
      // @todo - needs to be autocomputed based on height of first row
      responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
      responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
      responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
      responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
      // Remove left border from second col
      responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
      // Row hover z-index;
      responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
      responsiveStyle += '}';
    }

    let table = (
      <Table
        style={style}
        striped={striped}
        celled={celled}
        className={`${responsive} eighth-step`}
        unstackable
      >
        <Table.Header>
          {preHeader}
          <Table.Row className="upcoming-sixth-step upcoming-third-step upcoming-forth-step">
            {columns.map(col => (
              <Table.HeaderCell
                key={col.key}
                onClick={handleSort && (() => handleSort(col.key))}
                style={{
                  cursor: handleSort && 'pointer',
                  color: (sortCol === col.key ? sortColor : undefined),
                  ...col.style,
                }}
              >
                {col.key === sortCol ? `${col.text}${arr}` : col.text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        {/* \/ IDK why we were doing this... */}
        {/* !paginated ? (
          <Table.Body dangerouslySetInnerHTML={createTableBody(processedRows, columns)} />) : (
            <Table.Body>
              {createTableBody(processedRows, columns, true)}
          </Table.Body>) */}
        <Table.Body>
          {createTableBody(processedRows, columns, true)}
        </Table.Body>
      </Table>
    );

    if (responsive === 'left-fixed') {
      table = (<div className="table-scroll">{table}</div>);
    }

    if (downloadData) {
      for (let i = 0; i < downloadData.length; i += 1) {
        delete downloadData[i].primary_key;
        delete downloadData[i].datemade;
      }
    }
    console.log(downloadData);
    return (
      <React.Fragment>
        {responsive ? <style>{responsiveStyle}</style> : null}

        { downloadData && isBrowser ?
          <Button
            style={{
              margin: '0px 0rem 1rem 0rem',
              padding: '0rem 1rem',
              float: 'right',
              fontSize: '1rem',
              lineHeight: '2.4rem',
              }}
            className="upcoming-fifth-step"
          >
            <CSVLink
              data={downloadData}
              filename={downloadName}
            >
              <Button.Content style={{ padding: '0px', margin: '0px' }}>
                <Icon style={{ margin: '0px' }} name="download" />
                &nbsp;
                Download Table
              </Button.Content>
            </CSVLink>
          </Button> :
          ''
         }
        {paginated && totalPages > 1 ?
          (<Pagination
            activePage={page}
            totalPages={totalPages}
            size="tiny"
            boundaryRange={0}
            onPageChange={handlePageChange}
          />) :
        null}
        {table}
        {paginated && totalPages > 1 ?
          (<Pagination
            activePage={page}
            totalPages={Math.ceil(rows.length / rowsPerPage)}
            size="tiny"
            boundaryRange={0}
            onPageChange={handlePageChange}
          />) :
        null}
      </React.Fragment>
    );
  }
}

SortableTable.defaultProps = {
  preHeader: undefined,
  style: undefined,
  sortColor: 'darkblue',
  striped: undefined,
  celled: undefined,
  handleSort: undefined,
  sortCol: '',
  sortDir: '',
  page: 1,
  paginated: false,
  rowsPerPage: 100,
  handlePageChange: undefined,
  responsive: '',
  downloadData: null,
  downloadName: 'Bespoke.csv',
};

SortableTable.propTypes = {
  preHeader: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    formatFunc: PropTypes.func,
    styleFunc: PropTypes.func,
  })).isRequired,
  rows: PropTypes.array.isRequired,
  handleSort: PropTypes.func,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  sortColor: PropTypes.string,
  style: PropTypes.object,
  striped: PropTypes.bool,
  celled: PropTypes.bool,
  paginated: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handlePageChange: PropTypes.func,
  responsive: PropTypes.string,
  downloadData: PropTypes.object,
  downloadName: PropTypes.string,
};

export default SortableTable;
