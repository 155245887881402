import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Table, Dimmer, Loader } from 'semantic-ui-react';
import SortableTable from '../../SortableTable';

const numStyle = (val) => {
  if (parseFloat(val) > 0) {
    return { color: 'green' };
  } else if (parseFloat(val) < 0) {
    return { color: 'red' };
  }
  return undefined;
};

const leftAlign = () => ({ textAlign: 'left' });

const formatTicker = ticker => (<Link to={`/earnings-explorer/search/${ticker}`}>{ticker}</Link>); // (<Link to={`/ticker/${ticker}`}>{ticker}</Link>);

// const formatTicker = ticker => ticker;
const formatPercent = num => `${Math.round(num)}%`;

const formatDate = date => date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1');

// const numberWithCommas = x => (!Number.isNaN(Number(x)) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A');

const forceTwoDec = x => (!Number.isNaN(Number(x)) ? parseFloat(x).toFixed(2) : 'N/A');

// Helper function for calculating aggregates
const calcScreenAggs = (rows) => {
  const totals = {
    count: 0,
    countWithGuidance: 0,
    numEPSBeat: 0,
    numSalesBeat: 0,
    guidedHigher: 0,
    guidedLower: 0,
    gapSum: 0,
    o2cSum: 0,
    oneDaySum: 0,
    volSum: 0,
  };

  rows.reduce((acc, curr) => {
    acc.count += 1;
    if (curr.guidance !== 'None') { acc.countWithGuidance += 1; }
    if (curr.eps_avse > 0) { acc.numEPSBeat += 1; }
    if (curr.rev_avse > 0) { acc.numSalesBeat += 1; }
    if (curr.guidance === 'Raised') { acc.guidedHigher += 1; }
    if (curr.guidance === 'Lowered') { acc.guidedLower += 1; }
    acc.gapSum += curr.gap_pct;
    acc.o2cSum += curr.open_to_close_pct;
    acc.oneDaySum += curr.one_day_pct;
    acc.volSum += Math.abs(curr.one_day_pct);
    return acc;
  }, totals);
  console.log(`oneDaySum: ${totals.oneDaySum} and totalsCount: ${totals.count}`);
  return [{
    count: totals.count,
    epsbeat: Math.round((100 * totals.numEPSBeat) / totals.count),
    salesbeat: Math.round((100 * totals.numSalesBeat) / totals.count),
    guidanceraised: Math.round((100 * totals.guidedHigher) / totals.count),
    guidancelowered: Math.round((100 * totals.guidedLower) / totals.count),
    avg_gap: (totals.gapSum / totals.count).toFixed(2),
    avg_o2c: (totals.o2cSum / totals.count).toFixed(2),
    avg_1day: (totals.oneDaySum / totals.count).toFixed(2),
    avg_vol: (totals.volSum / totals.count).toFixed(2),
  }];
};

class AggTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ecalColumns = [
      {
        key: 'stock',
        text: 'Ticker',
        formatFunc: formatTicker,
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'company',
        text: 'Company',
        styleFunc: leftAlign,
        width: 100,
        style: { textAlign: 'left' },
      },
      {
        key: 'reportdate',
        text: 'Date',
        formatFunc: formatDate,
        width: 100,
      },
      {
        key: 'reporttime',
        text: 'Time',
        width: 100,
      },
      // {
      //   key: 'currenteps',
      //   text: 'EPS ($/Shr)',
      //   styleFunc: numStyle,
      //   formatFunc: forceTwoDec,
      // },
      // {
      //   key: 'revisedeps',
      //   text: 'Sales ($,Mln)',
      //   formatFunc: numberWithCommas,
      //   width: 100,
      // },
      {
        key: 'prioronfile',
        text: 'Reports',
        width: 100,
        style: { display: 'none' },
      },
      {
        key: 'epsbeat',
        text: 'EPS',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'revenuebeat',
        text: 'Sales',
        formatFunc: formatPercent,
        width: 100,
      },
      {
        key: 'guidance',
        text: 'Guidance',
        formatFunc: formatPercent,
        width: 100,
        style: { display: 'none' },
      },
      {
        key: 'avg_gap',
        text: 'Opening Gap',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_o2c',
        text: 'Open to Close',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avg_1day',
        text: 'Full 1 Day',
        styleFunc: numStyle,
        formatFunc: forceTwoDec,
        width: 100,
      },
      {
        key: 'avgabsolute',
        text: 'Volatility',
        formatFunc: forceTwoDec,
        width: 100,
      },
    ];
    this.screenColumns = [
      {
        key: 'count',
        text: 'Count',
        formatFunc: undefined,
        styleFunc: undefined,
      },
      {
        key: 'epsbeat',
        text: 'EPS',
        formatFunc: formatPercent,
        styleFunc: undefined,
      },
      {
        key: 'salesbeat',
        text: 'Sales',
        formatFunc: formatPercent,
        styleFunc: undefined,
      },
      {
        key: 'guidanceraised',
        text: 'Raised',
        formatFunc: formatPercent,
        styleFunc: undefined,
      },
      {
        key: 'guidancelowered',
        text: 'Lowered',
        formatFunc: formatPercent,
        styleFunc: undefined,
      },
      // Averages
      {
        key: 'avg_gap',
        text: 'Opening Gap',
        formatFunc: forceTwoDec,
        styleFunc: numStyle,
      },
      {
        key: 'avg_o2c',
        text: 'Open to Close',
        formatFunc: forceTwoDec,
        styleFunc: numStyle,
      },
      {
        key: 'avg_1day',
        text: 'Full 1 Day',
        formatFunc: forceTwoDec,
        styleFunc: numStyle,
      },
      {
        key: 'avg_vol',
        text: 'Volatility',
        formatFunc: forceTwoDec,
        styleFunc: numStyle,
      },
    ];
    this.ecalPreHeader = (
      <Table.Row>
        <Table.HeaderCell colSpan={2} />
        <Table.HeaderCell colSpan={2}>
          Report
        </Table.HeaderCell>
        {/* <Table.HeaderCell colSpan={2}> */}
        {/*  Estimates */}
        {/* </Table.HeaderCell> */}
        <Table.HeaderCell rowSpan={2} style={{ width: '80px' }}>
          Prior Reports
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Beat Rate
        </Table.HeaderCell>
        <Table.HeaderCell rowSpan={2} style={{ width: '80px' }}>
          % Raised Guidance
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={4}>
          Average Stock Price % Chg
        </Table.HeaderCell>
      </Table.Row>
    );
    this.screenPreHeader = (
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={2}>
          Beat Rate
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>
          Guidance
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={4}>
          Average Stock Price % Chg
        </Table.HeaderCell>
      </Table.Row>
    );
  }
  render() {
    const {
      mode,
      tickers,
      ecal,
      screenData,
      screenDataLoading,
      tableDataLoading,
      tickerList,
    } = this.props;
    let rows = [];
    let columns;
    let preHeader;
    let loading = false;
    let description = '';
    if (mode === 'ecal') {
      return null;
    }

    if (mode === 'single') {
      if (tickers.length > 0 && tickers[0] != null) {
        description = `Estimated Upcoming Earnings and Aggregate Earnings Data for ${tickers[0].description} (${tickers[0].title})`;
        rows = ecal.data.filter(x => x.stock === tickers[0].title);
        rows = rows.filter(row => !(row.reportdate == null));
        if (rows.length === 0) {
          return (
            <React.Fragment>
              <h1 style={{ fontSize: '16px' }} >{description}</h1>
              <center style={{ margin: '30px 0px', color: 'grey' }} ><b>No Upcoming Reports Found for {tickers[0].title}</b></center>
            </React.Fragment>
          );
        }
      }
      columns = this.ecalColumns;
      preHeader = this.ecalPreHeader;
      loading = tableDataLoading;
    } else if (mode === 'portfolio') {
      const tickerNames = tickers.map(x => x.title);
      description = null;
      const getCompanyForTicker = (ticker) => {
        const filtered = tickerList.filter(t => t.ticker === ticker);
        if (filtered.length > 0) {
          return filtered[0].company;
        }
        return null;
      };
      // rows = ecal.data.filter(x => x.stock === tickers[0].title);
      rows = ecal.data.filter(x => tickerNames.includes(x.stock));
      rows = rows.map(row => ({
        ...row, company: row.company != null ? row.company : getCompanyForTicker(row.stock),
      }));
      // rows = rows.map(row => ({ ...row, stock: (<b>STOCK</b>) }));
      if (rows.length === 0) {
        return <center><b>No Upcoming Reports</b></center>;
      }
      columns = this.ecalColumns;
      preHeader = this.ecalPreHeader;
      loading = tableDataLoading;
    } else if (mode === 'screen') {
      description = 'Aggregate Stats';
      columns = this.screenColumns;
      // calculate aggregates
      rows = calcScreenAggs(screenData);
      preHeader = this.screenPreHeader;
      loading = screenDataLoading;
    }
    return (
      <React.Fragment>
        {description ? <h1 style={{ fontSize: '16px' }} >{description}</h1> : null }
        <Dimmer.Dimmable style={{ marginTop: '20px', borderRadius: '4px' }} dimmed={loading}>
          <Dimmer inverted active={loading} >
            <Loader />
          </Dimmer>
          <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }} className="search-forth-step screen-seventh-step">
            <SortableTable
              {...this.props}
              style={{
                textAlign: 'center',
                fontSize: '20px',
                marginTop: '0px',
              }}
              rows={rows}
              columns={columns}
              preHeader={preHeader}
            />
          </div>
        </Dimmer.Dimmable>
      </React.Fragment>);
  }
}

AggTable.defaultProps = {
  tickers: null,
  // screenDataLoading: false,
  // tableDataLoading: false,
  tickerList: [],
};

AggTable.propTypes = {
  mode: PropTypes.string.isRequired,
  tickers: PropTypes.array,
  ecal: PropTypes.object.isRequired,
  screenData: PropTypes.array.isRequired,
  screenDataLoading: PropTypes.bool.isRequired,
  tableDataLoading: PropTypes.bool.isRequired,
  tickerList: PropTypes.array,
};

const mapStateToProps = state => ({
  // mode: state.view.earningsExplorer.mode,
  // ticker: state.view.earningsExplorer.ticker,
  ecal: state.data.ecal,
  screenData: state.view.earningsExplorer.screenData,
  tickerList: state.data.tickerList.list,
  screenDataLoading: state.view.earningsExplorer.screenDataLoading,
  tableDataLoading: state.view.earningsExplorer.tableDataLoading,
});

export default withRouter(connect(mapStateToProps, null)(AggTable));
