import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { Button, Modal, Form } from 'semantic-ui-react';
import * as api from '../../../api';

const showSubscription = cookie.load('showedSubscription');

class Subscriber extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      email: '',
      optIn: false,
      errorEmail: false,
      errorOptIn: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (!cookie.load('showedSubscription')) {
      this.saveCookie();
    }
  }

  close = () => {
    this.saveCookie();
    this.setState({ open: false });
  };

  saveCookie = (days) => {
    let d = 7;
    if (days !== undefined) {
      d = days;
    }

    const expDays = new Date();
    expDays.setDate(expDays.getDate() + d);

    cookie.save('showedSubscription', true, { expires: expDays });
  };

  handleSubmit(submit) {
    const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    let error = false;
    this.setState({
      errorEmail: false,
      errorOptIn: false,
    });

    if (this.state.email === '' || !re.test(this.state.email)) {
      this.setState({ errorEmail: true });
      error = true;
    }
    if (!this.state.optIn) {
      this.setState({ errorOptIn: true });
      error = true;
    }

    if (!error && submit) {
      api.mailchimp.subscribeMailchimp('11fe2bb876', this.state.email);
      this.saveCookie(182);
      this.setState({ open: false, errorEmail: false });
    }
  }

  render() {
    const { open } = this.state;
    const { access, title, buttonText } = this.props;
    if (access || showSubscription) {
      return '';
    }

    return (
      <Modal
        dimmer="blurring"
        open={open}
        onClose={this.close}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <h1>{title}</h1>
            <p>(But we do ask for an email address)</p>
            <Form>
              <Form.Input
                placeholder="Your Email Address"
                required
                error={this.state.errorEmail}
                onBlur={e => this.setState({ email: e.target.value })}
                style={{ width: '50%' }}
              />
              <Form.Checkbox
                label="Opt in to receive market insights and updates from Bespoke to your email address."
                required
                error={this.state.errorOptIn}
                onChange={() => this.setState({ optIn: !this.state.optIn })}
                checked={this.state.optIn}
              />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            color="green"
            content="Submit"
            onClick={() => this.handleSubmit(true)}
          />
          <Button
            labelPosition="right"
            color="gray"
            content={buttonText}
            onClick={this.close}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

Subscriber.propTypes = {
  access: PropTypes.object.isRequired,
  title: PropTypes.string,
  buttonText: PropTypes.string,
};

Subscriber.defaultProps = {
  title: 'Bespoke\'s Think B.I.G. Blog is Free',
  buttonText: 'Still Too Pricey',
};

const mapStateToProps = state => ({
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});


export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);
