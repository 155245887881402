import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToPortfolio, removeFromPortfolio } from '../../../../actions';
import AddPortfolioModal from './addPortfolioModal';

const removePrivateProperties = (props, privateKeys) => {
  const res = {};
  Object.keys(props).forEach((key) => {
    if (!privateKeys.includes(key)) {
      res[key] = props[key];
    }
  });
  return res;
};

class addToAPortfolioDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  removeTickerWithLoad(pid) {
    // console.log(pid);
    // console.log(this.props.tickerSymbol);
    this.setState({ loading: true });
    this.props.removeTickerFromPortfolio(pid, this.props.tickerSymbol)
      .then(this.setState({ loading: false }));
  }

  addTickerWithLoad(pid) {
    // console.log(pid);
    // console.log(this.props.tickerSymbol);
    this.setState({ loading: true });
    this.props.addTickerToPortfolio(pid, this.props.tickerSymbol)
      .then(this.setState({ loading: false }));
  }

  render() {
    const options = [];
    const {
      portfolios,
      tickerSymbol,
    } = this.props;
    if (portfolios && portfolios.byId) {
      Object.values(portfolios.byId).forEach(p =>
        options.push(p.members && p.members.includes(tickerSymbol) ?
          <Dropdown.Item
            onClick={() => this.removeTickerWithLoad(p.id)}
          >
            <span className="green text">
              <Icon name="check circle outline" />
              {p.name}
            </span>
          </Dropdown.Item> :
          <Dropdown.Item
            onClick={() => this.addTickerWithLoad(p.id)}
          >
            <span>
              <Icon name="times circle outline" />
              {p.name}
            </span>
          </Dropdown.Item>));
    }
    const addPortfolioModalContent = (
      <span>
        <Icon name="plus circle" style={{ margin: '0px 4px 0px 0px' }} />
        New Portfolio
      </span>
    );
    const customPortfoliosLink = (
      <span>
        <Icon name="ellipsis horizontal" style={{ margin: '0px 4px 0px 0px' }} />
        Manage Portfolios
      </span>
    );
    const safeProps = removePrivateProperties(this.props, ['portfolios', 'tickerSymbol', 'addTickerToPortfolio', 'removeTickerFromPortfolio']);
    return (
      <Dropdown
        title={`Add or remove ${tickerSymbol} from your custom portfolios.`}
        closeOnChange
        icon="folder open"
        className="icon seventh-step"
        disabled={this.state.loading}
        loading={this.state.loading}
        direction="left"
        style={{
          ...safeProps.style,
          height: '24px',
          fontSize: '12px',
          lineHeight: '24px',
          padding: '0px 10px',
        }}
        {...removePrivateProperties(safeProps, ['style', 'className'])}
      >

        <Dropdown.Menu >
          <Dropdown.Header content="Custom Portfolios" style={{ margin: '.5rem', fontSize: '.8rem' }} />
          <Dropdown.Divider />
          {options}
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => null}
          >
            <AddPortfolioModal
              content={addPortfolioModalContent}
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => null}
          >
            <Link to="/portfolio">
              {customPortfoliosLink}
            </Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

addToAPortfolioDropdown.defaultProps = {
  style: {},
};

addToAPortfolioDropdown.propTypes = {
  portfolios: PropTypes.object.isRequired,
  style: PropTypes.object,
  tickerSymbol: PropTypes.string.isRequired,
  addTickerToPortfolio: PropTypes.func.isRequired,
  removeTickerFromPortfolio: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  portfolios: state.user.portfolios,
});
const mapDispatchToProps = dispatch => ({
  addTickerToPortfolio: (portfolioID, tickerName) =>
    dispatch(addToPortfolio(portfolioID, [tickerName])),
  removeTickerFromPortfolio: (portfolioID, tickerName) =>
    dispatch(removeFromPortfolio(portfolioID, [tickerName])),
});

export default connect(mapStateToProps, mapDispatchToProps)(addToAPortfolioDropdown);

//-----------------------------------------------------------------------

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Dropdown, Icon } from 'semantic-ui-react';
// import { connect } from 'react-redux';
// import { addToPortfolio, removeFromPortfolio } from '../../../../actions';

// const addToAPortfolioDropdown = (props) => {
//   const options = [];
//   const {
//     portfolios,
//     tickerSymbol,
//     addTickerToPortfolio,
//     removeTickerFromPortfolio,
//   } = props;
//   console.log(tickerSymbol);
//   if (portfolios && portfolios.byId) {
//     Object.values(portfolios.byId).forEach(p =>
//       options.push(p.members && p.members.includes(tickerSymbol) ?
//         <Dropdown.Item
//           onClick={() => removeTickerFromPortfolio(p.id, tickerSymbol)}
//         >
//           <span className="green text">
//             <Icon name="check circle outline" />
//             {p.name}
//           </span>
//         </Dropdown.Item> :
//         <Dropdown.Item
//           onClick={() => addTickerToPortfolio(p.id, tickerSymbol)}
//         >
//           <span>
//             <Icon name="times circle outline" />
//             {p.name}
//           </span>
//         </Dropdown.Item>));
//   }

//   return (
//     <Dropdown
//       closeOnChange
//       text="Add to a Portfolio"
//       button
//       disabled
//       loading
//       style={{
//         ...props.style,
//         height: '24px',
//         fontSize: '12px',
//         lineHeight: '24px',
//         padding: '0px 10px',
//       }}
//     >
//       <Dropdown.Menu>
//         {options}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

// addToAPortfolioDropdown.defaultProps = {
//   style: {},
// };

// addToAPortfolioDropdown.propTypes = {
//   portfolios: PropTypes.object.isRequired,
//   style: PropTypes.object,
//   tickerSymbol: PropTypes.string.isRequired,
//   addTickerToPortfolio: PropTypes.func.isRequired,
//   removeTickerFromPortfolio: PropTypes.func.isRequired,
// };

// const mapStateToProps = state => ({
//   portfolios: state.user.portfolios,
// });
// const mapDispatchToProps = dispatch => ({
//   addTickerToPortfolio: (portfolioID, tickerName) =>
//     dispatch(addToPortfolio(portfolioID, [tickerName])),
//   removeTickerFromPortfolio: (portfolioID, tickerName) =>
//     dispatch(removeFromPortfolio(portfolioID, [tickerName])),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(addToAPortfolioDropdown);
