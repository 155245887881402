import React from 'react';
import PropTypes from 'prop-types';

const CatName = ({ text }) => (
  <span key="catname" style={{ position: 'absolute', transform: 'translateX(calc(-50% + 15px)) rotate(-90deg) translateX(-50%)' }} >{text}</span>
);

CatName.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CatName;
