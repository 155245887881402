import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import * as Cards from '../presentational/cards';
import { getTicker } from '../../actions/content/ticker';
import { getSearchData } from '../../actions/content/research';
import { setStar } from '../../actions/user';
import { setPageTitle, setPosts } from '../../actions';
import { getChartData } from '../../actions/data/tools';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';

class TickerPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleStarredFactory = this.toggleStarredFactory.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    let tickerName = match && match.params && match.params.ticker;
    if (match && match.params && match.params.extra) {
      tickerName = `${tickerName}/${match.params.extra}`;
    }
    window.scrollTo(0, 0);
    dispatch((setPageTitle(`$${tickerName}`)));
    document.title = `Bespoke | ${tickerName} Stock Summary`;
    // Grab + set ticker information
    dispatch(getTicker(tickerName));
    dispatch(getChartData(tickerName));
    // Grab and set posts
    dispatch(getSearchData(`(${tickerName})`, 1, 10));
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      const { match, dispatch } = this.props;
      let tickerName = match && match.params && match.params.ticker;
      if (match && match.params && match.params.extra) {
        tickerName = `${tickerName}/${match.params.extra}`;
      }
      window.scrollTo(0, 0);
      dispatch((setPageTitle(`$${tickerName}`)));
      document.title = `Bespoke | ${tickerName} Stock Summary`;
      dispatch(setPosts([]));
      // Grab + set ticker information
      dispatch(getTicker(tickerName));
      dispatch(getChartData(tickerName));
      // Grab and set posts
      dispatch(getSearchData(`(${tickerName})`, 1, 10));
    }
  }

  toggleStarredFactory(starred, id) {
    const { dispatch } = this.props;
    return () => {
      setStar(dispatch)(id, !starred);
    };
  }

  render() {
    const { starredPosts } = this.props;
    const steps = [
      {
        selector: '.first-step',
        content: 'Our Security Analysis page gives users key info and charts for most individual US-listed companies and ETFs.',
      },
      {
        selector: '.ticker-second-step',
        content: 'Start typing in a stock ticker or company name in the Search bar at the top of the page, and select the ticker you\'re looking for once the search populates.',
      },
      {
        selector: '.ticker.third-step',
        content: 'Key analysis is included for the security including our Bespoke trend and timing score and upcoming earnings information if the stock is set to report within the next month.',
      },
      {
        selector: '.forth-step',
        content: 'Monitor the security\'s price chart and add it to your Bullish or Bearish charts by clicking the "Bull" or "Bear" icon.  Click the "Expand" icon for a larger version of the chart.',
      },
      {
        selector: '.fifth-step',
        content: 'For individual securities, historical earnings and revenue information is included as well.  Click the "Leave" icon to go directly to the company\'s Earnings Explorer page.',
      },
      {
        selector: '.sixth-step',
        content: 'On the right side of the page, any recent reports that we\'ve published mentioning the security are shown.',
      },
      {
        selector: '.seventh-step',
        content: 'Click the "Folder" icon to add or remove the security from a Custom Portfolio.',
      },
      {
        selector: '.eighth-step',
        content: 'We\'ll be adding new security information to our Security Analysis page over time.',
      },
      {
        selector: '.ninth-step',
        content: 'Our Security Analysis page is available to Bespoke Institutional members only.  Information on this page is meant to be a starting point for further research and is NOT a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="securityAnalysisTouring"
        steps={steps}
        update={this.props.posts.length}
      />);

    return (
      // <div style={{ overflow: 'hidden', padding: '1rem', margin: '-1rem -1.8rem 0rem -1.8rem' }}>
      <React.Fragment>
        <style>{'body{overflow-x: hidden;}'}</style>
        <UpgradeConnected tool whitelist={[4]} />
        <Grid style={{ overflowX: 'hidden' }} stackable>
          <Grid.Column width={10}>
            <Cards.Summary
              trend
              timing
              earnings
              screens
              tour={tour}
            />
            <Cards.Chart />
            {this.props.earningsChartData.length > 0 ? <Cards.EarningsGauges /> : ''}
            {/* <Cards.Screens /> */}
            {/* <Cards.TrendAnalyzer /> */}
          </Grid.Column>
          <Grid.Column width={6} className="sixth-step">
            <CSSTransitionGroup
              transitionName="cardsFade"
              transitionEnterTimeout={0}
              transitionLeaveTimeout={0}
            >
              {this.props.posts.map(card => (
                <Cards.Post
                  post={card}
                  key={`${card.id}`}
                  id={card.id}
                  starred={starredPosts[card.id]}
                  toggleStarred={this.toggleStarredFactory(starredPosts[card.id], card.id)}
                />))}
            </CSSTransitionGroup>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

TickerPage.propTypes = {
  posts: PropTypes.array.isRequired,
  starredPosts: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  earningsChartData: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  posts: state.content.research.posts,
  starredPosts: state.user.starred.byId,
  earningsChartData: state.view.earningsExplorer.chartData,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TickerPage);
