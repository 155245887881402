import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Cards from '../presentational/cards';
import { getTicker } from '../../actions/content/ticker';
import { setPageTitle } from '../../actions';
import Subscriber from '../presentational/interactive/subscriber';

class ChartPage extends React.Component {
  componentDidMount() {
    const { match, dispatch } = this.props;
    const tickerName = match && match.params && match.params.ticker;
    window.scrollTo(0, 0);
    dispatch((setPageTitle(`$${tickerName}`)));
    document.title = `Bespoke | ${tickerName} Stock Summary`;
    // Grab + set ticker information
    dispatch(getTicker(tickerName));
  }

  render() {
    const chartSettings = {
      interactive: true,
    };
    return (
      <React.Fragment>
        <Subscriber title="Stay in the Know" buttonText="No thanks" />
        <Cards.Chart chartSettings={chartSettings} fullpage iframe={this.props.iframe} />
      </React.Fragment>
    );
  }
}

ChartPage.defaultProps = {
  iframe: false,
};

ChartPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  iframe: PropTypes.bool,
};

export default connect(null, dispatch => ({ dispatch }))(ChartPage);
