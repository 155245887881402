import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { interpolateLab } from 'd3-interpolate';
import './Points.css';

class Points extends React.PureComponent {
  render() {
    const {
      scales,
      data,
      handleClick,
      maxValue,
      colorStart,
      colorEnd,
    } = this.props;
    const { xScale, yScale } = scales;
    const colorScale = scaleLinear()
      .domain([0, maxValue])
      .range([colorStart, colorEnd])
      .interpolate(interpolateLab);
    const points = (
      data.map(datum => (
        <circle
          className="Point"
          key={datum.date}
          cx={xScale(new Date(datum.date))}
          cy={yScale(datum.value)}
          r={3}
          stroke="black"
          strokeWidth="1"
          fill={colorScale(datum.value)}
          onClick={handleClick ? () => handleClick(datum.time) : undefined}
          cursor={handleClick ? 'pointer' : undefined}
        />)));

    return (
      <g>{points}</g>
    );
  }
}

Points.defaultProps = {
  colorStart: '#2ecc71',
  colorEnd: '#003051',
  handleClick: undefined,
};

Points.propTypes = {
  colorStart: PropTypes.string,
  colorEnd: PropTypes.string,
  handleClick: PropTypes.func,
  scales: PropTypes.shape({
    xScale: PropTypes.func,
    yScale: PropTypes.func,
  }).isRequired,
  data: PropTypes.array.isRequired,
  maxValue: PropTypes.number.isRequired,
};

export default Points;
