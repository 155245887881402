import React from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import BiggestMovers from '../interactive/biggestMovers';
import CardBase from '../../containers/CardBase';

/**
 * Pass in a category object with property name (a string) and tickers (an array of ticker object)
 * @param {*} param0
 */
class BiggestMoversCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortColumn: 'price',
      sortDirection: 'ASC',
    };
    this.handleBiggestMoversSortChange = this.handleBiggestMoversSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handleBiggestMoversSortChange(column, direction) {
    if (column && direction) {
      this.setState({
        sortColumn: column,
        sortDirection: direction,
      });
    }
  }

  handlePageChange(x, { activePage }) {
    this.setState({
      page: activePage,
    });
  }

  render() {
    const {
      name,
      tickers,
      loading,
      height,
      handleCalendarChange,
      date,
    } = this.props;
    let responsiveStyle = '.table-scroll { margin:1em 0; }';
    responsiveStyle += '@media screen and (max-width: 768px) {';
    responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow-x:scroll; overflow-y: visible; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
    responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
    responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap; z-index: 2;}';
    // @todo - needs to be autocomputed based on height of first row
    responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; z-index: 2; }';
    responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
    responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
    responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5 !important; }';
    // Remove left border from second col
    responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
    // Row hover z-index;
    responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
    responsiveStyle += '}';
    if (tickers && !loading) {
      return (
        <CardBase>
          <Card.Content >
            <React.Fragment>
              <style>{responsiveStyle}</style>
              <div style={{ margin: '0em 0 .5em 0' }} className="titleBar" >{ name ? `S&P 1500 Biggest Movers: ${name}` : 'S&P 1500 Biggest Movers' }</div>
              <div
                style={{
                  height,
                  padding: '0px',
                }}
              >
                <p style={{ display: isMobile ? 'block' : 'none', textAlign: 'right', fontSize: '11px' }} >Swipe table to scroll*</p>
                <BiggestMovers
                  sortCol={this.state.sortColumn}
                  sortDir={this.state.sortDirection}
                  handleSortChange={this.handleBiggestMoversSortChange}
                  tickers={tickers}
                  page={this.state.page}
                  handlePageChange={this.handlePageChange}
                  handleCalendarChange={handleCalendarChange}
                  date={date}
                  paginated
                />
              </div>
            </React.Fragment>
          </Card.Content>
        </CardBase>);
    }
    return (
      <CardBase style={{ height: '450px' }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </CardBase>);
  }
}

BiggestMoversCard.defaultProps = {
  tickers: [],
  height: '450px',
  name: '',
};

BiggestMoversCard.propTypes = {
  height: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  tickers: PropTypes.array,
  name: PropTypes.string,
  handleCalendarChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

export default BiggestMoversCard;
