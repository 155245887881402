import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isMobile } from 'react-device-detect';

const heightObj = isMobile ? {} : { height: '500px' };

const HomeCard = ({
  image,
  header,
  meta,
  description,
  link,
  dispatch,
  style,
}) => (
  <Card className="inverted" style={{ ...style, ...heightObj, overflow: 'hidden' }}>
    <style>
      {'.homecard-image {cursor: pointer; border-top-right-radius: 8px; border-top-left-radius: 8px;} .homecard-image:hover {filter: brightness(70%);}'}
    </style>
    <Image
      src={image}
      className="homecard-image"
      onClick={() => dispatch(push(link))}
    />
    <Card.Content>
      <Card.Header><Link to={link} style={{ color: 'white' }}>{header}</Link></Card.Header>
      <Card.Meta>{meta}</Card.Meta>
      <Card.Description>
        {description}
      </Card.Description>
    </Card.Content>
  </Card>
);

HomeCard.defaultProps = {
  style: {},
};

HomeCard.propTypes = {
  image: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  meta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default HomeCard;
