import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { isBrowser } from 'react-device-detect';
import SortableTable from '../../../presentational/SortableTable';

const sortFunc = (col, dir) => (a, b) => {
  const naVal = (dir === 'DESC' ? -Infinity : Infinity);
  let varA;
  let varB;
  switch (col) {
    case 'ticker':
    case 'sector':
    case 'name':
    case 'pfNames':
      varA = (typeof a[col] === 'string') ?
        a[col].toUpperCase() : a[col];
      varB = (typeof b[col] === 'string') ?
        b[col].toUpperCase() : b[col];
      break;
    case 'pct_pos':
    case 'med':
    case 'avg':
    default: {
      varA = parseFloat(a[col]);
      varB = parseFloat(b[col]);
      if (Number.isNaN(varA)) {
        varA = naVal;
      }
      if (Number.isNaN(varB)) {
        varB = naVal;
      }
    }
  }
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (dir === 'DESC' ? (comparison * -1) : comparison);
};

// @todo: Loading, PreHeader, Table Title, Multi Tables? - see why 2008 has 0 data
class SeasonalityTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.rowsPerPage = 100;
    this.handleSort = this.handleSort.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.columnGen = this.columnGen.bind(this);
    this.preHeaderGen = this.preHeaderGen.bind(this);

    this.state = {
      sortCol: 'med',
      sortDir: 'DESC',
      page: 1,
    };
  }

  columnGen() {
    const { match } = this.props;
    switch (match && match.params && match.params.category) {
      case 'global':
        return [
          {
            key: 'name',
            text: 'Index',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'sector',
            text: 'Country',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'med',
            text: 'Median',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'avg',
            text: 'Average',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'pct_pos',
            text: '% Positive',
          },
        ];
      case 'equities':
        return [
          {
            key: 'ticker',
            text: 'Ticker',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'sector',
            text: 'Sector',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'med',
            text: 'Median',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'avg',
            text: 'Average',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'pct_pos',
            text: '% Positive',
          },
        ];
      case 'custom':
        return [
          {
            key: 'ticker',
            text: 'Ticker',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'pfNames',
            text: 'Portfolio Name',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
            formatFunc: val => `${val && val.join(', ')}`,
          },
          {
            key: 'med',
            text: 'Median',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'avg',
            text: 'Average',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'pct_pos',
            text: '% Positive',
          },
        ];
      case 'etfs':
        return [
          {
            key: 'ticker',
            text: 'Ticker',
            style: { textAlign: 'left' },
            styleFunc: () => ({ textAlign: 'left' }),
          },
          {
            key: 'med',
            text: 'Median',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'avg',
            text: 'Average',
            styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
          },
          {
            key: 'pct_pos',
            text: '% Positive',
          },
        ];
      default:
        return [];
    }
  }

  preHeaderGen(col, dir, years) {
    const { match } = this.props;
    let emptyStartingCells = 1;
    switch (match && match.params && match.params.category) {
      case 'equities':
      case 'global':
      case 'custom':
        emptyStartingCells = 2;
        break;
      case 'etfs':
      default:
        break;
    }

    return (
      <Table.Row>
        <Table.HeaderCell colSpan={emptyStartingCells} />
        <Table.HeaderCell colSpan="2">
          % Change
        </Table.HeaderCell>
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={years.length}>
          Percent Change by Year
        </Table.HeaderCell>
      </Table.Row>
    );
  }

  handleSort(col) {
    const {
      sortCol,
      sortDir,
    } = this.state;

    let newDir = 'ASC';
    if (col === sortCol && sortDir === 'ASC') {
      newDir = 'DESC';
    }

    this.setState({
      sortCol: col,
      sortDir: newDir,
    });
  }

  handlePageChange(x, { activePage }) {
    this.setState({
      page: activePage,
    });
  }

  render() {
    // @todo: modify columns + preHeader based on numYears in props.
    const { columnGen, preHeaderGen, rowsPerPage } = this;
    const { sortDir, sortCol, page } = this.state;
    const { data } = this.props;

    const yearCols = [];

    const columns = columnGen();
    const preHeader = preHeaderGen(sortCol, sortDir, data.years);

    data.years.forEach((year) => {
      yearCols.push({
        key: `year_${year}`,
        text: `${year}`,
        formatFunc: val => (val === undefined ? ' - ' : val),
        styleFunc: val => (parseFloat(val) < 0 ? ({ color: 'red' }) : undefined),
      });
    });

    const sortedData = data.tableData.sort(sortFunc(sortCol, sortDir)).map(d => ({ ...d, ticker: <Link to={`/ticker/${d.ticker}`}>{d.ticker}</Link> }));
    // const sortedData = data.tableData;
    return (
      <SortableTable
        striped
        paginated
        page={Math.min(page, Math.ceil(sortedData.length / rowsPerPage))}
        style={{
          fontSize: '20px',
          textAlign: 'center',
        }}
        preHeader={preHeader}
        columns={columns.concat(yearCols)}
        handleSort={this.handleSort}
        handlePageChange={this.handlePageChange}
        rowsPerPage={rowsPerPage}
        rows={sortedData}
        sortCol={sortCol}
        sortDir={sortDir}
        responsive="left-fixed"
        downloadData={isBrowser && data && data.tableData && data.tableData.length > 0 ?
          data.tableData : null}
        downloadName={isBrowser && data && data.years &&
          data.years.length && data.years.length > 0 ?
          `BespokeSeasonalityData${data.years[data.years.length - 1]}to${data.years[0]}.csv` : null}
      />
    );
  }
}

SeasonalityTable.propTypes = {
  data: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: state.data.seasonality.data,
});

export default withRouter(connect(mapStateToProps, null)(SeasonalityTable));
