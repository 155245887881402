import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import CardBase from '../../containers/CardBase';
import Summary from '../Summary';
import { formatGICS, formatDate } from '../TextFormats';
import AddToAPortfolioButton from '../interactive/customPortfolios/addToAPortfolioDropdown';

function SummaryCard(props) {
  if (props.ticker) {
    const header = [
      <AddToAPortfolioButton
        className="right floated"
        button
        style={{
          float: 'right',
          paddingRight: '20px',
          fontSize: '18px',
          lineHeight: '20px',
          }}
        tickerSymbol={props.ticker.ticker}
      />,
      props.ticker.company,
      ' ($', props.ticker.ticker, ')',
      props.tour,
    ];
    const candle = props.ticker.current_candle;
    const priceString = ['$', (candle.close && candle.close.toFixed(2)) || ''];
    const meta = [
      <b>{formatGICS(props.ticker.gics_sector, props.ticker.gics_group)}</b>,
      <br />,
      <b>{priceString}</b>,
      '/share | Updated ',
      formatDate(candle.date),
    ];
    // must pass props to summary
    const description = (props.isLoading && !props.ticker ?
      null :
      [<Summary{...props} />]);
    return (
      <CardBase
        isLoading={props.isLoading}
        fluid
        // id={props.id}
        header={header}
        meta={meta}
        description={description}
      />
    );
  }
  return (
    <CardBase
      fluid
      style={{ minHeight: '120px' }}
    >
      <div>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </div>
    </CardBase>
  );
}

SummaryCard.defaultProps = {
  expandViewHandler: () => null,
  tour: null,
};

SummaryCard.propTypes = {
  ticker: PropTypes.object.isRequired,
  expandViewHandler: PropTypes.any,
  id: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tour: PropTypes.any,
  tableData: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  ticker: state.content.ticker.ticker,
  isLoading: state.content.ticker.isLoading,
  tableData: state.view.earningsExplorer.tableData,
});

export default connect(mapStateToProps)(SummaryCard);
