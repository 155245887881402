import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import { interpolateLab } from 'd3-interpolate';

class Bars extends React.PureComponent {
  render() {
    const {
      scales,
      // margins,
      data,
      // svgDimensions,
      handleClick,
      colorStart,
      colorEnd,
      colorMap,
      showValue,
    } = this.props;
    const { xScale, yScale } = scales;
    // const { height } = svgDimensions;
    let colorScale = colorMap;
    let values;
    const yExtent = extent(data, d => parseFloat(d.value));
    if (!colorScale) {
      colorScale = scaleLinear()
        .domain(yExtent)
        .range([colorStart, colorEnd])
        .interpolate(interpolateLab);
    }
    if (yExtent[0] > 0) {
      yExtent[0] = 0;
    }
    const bars = (
      data.map(datum => (
        <rect
          key={datum.label}
          x={xScale(datum.label)}
          y={datum.value > 0 ? yScale(datum.value) : yScale(Math.max(yExtent[0], 0))}
          height={Math.abs(yScale(datum.value) - yScale(Math.max(yExtent[0], 0)))}
          width={xScale.bandwidth()}
          fill={colorScale(datum.value)}
          onClick={handleClick ? () => handleClick(datum) : undefined}
          cursor={handleClick ? 'pointer' : undefined}
        />)));

    if (showValue) {
      values = data.map(datum => (
        <text
          key={datum.label}
          textAnchor="middle"
          x={xScale(datum.label) + (xScale.bandwidth() / 2)}
          y={(datum.value > 0 ? yScale(datum.value) : yScale(Math.max(yExtent[0], 0))) - 3}
          fontSize="12px"
        >
          {datum.value}
        </text>
      ));
    }

    return (
      <g>
        {bars}
        {values}
      </g>
    );
  }
}

Bars.defaultProps = {
  colorStart: '#003051',
  colorEnd: '#2ecc71',
  handleClick: undefined,
  colorMap: undefined,
};

Bars.propTypes = {
  colorMap: PropTypes.func,
  colorStart: PropTypes.string,
  colorEnd: PropTypes.string,
  handleClick: PropTypes.func,
  scales: PropTypes.shape({
    xScale: PropTypes.func,
    yScale: PropTypes.func,
  }).isRequired,
  data: PropTypes.array.isRequired,
  showValue: PropTypes.bool.isRequired,
  // margins: PropTypes.shape({
  //   left: PropTypes.number,
  //   right: PropTypes.number,
  //   top: PropTypes.number,
  //   bottom: PropTypes.number,
  // }).isRequired,
  // svgDimensions: PropTypes.shape({
  //   width: PropTypes.number,
  //   height: PropTypes.number,
  // }).isRequired,
};

export default Bars;
