import React from 'react';
import PropTypes from 'prop-types';

class CrossHairCursor extends React.PureComponent {
  render() {
    const {
      width,
      height,
      xPadding,
    } = this.props;
    return (
      <g
        className="CrossHairCursor"
        clipPath="url(#clip)"
        display="none"
      >
        <line
          opacity={0.5}
          y1={0}
          y2={height}
          stroke="black"
          strokeWidth={0.5}
          pointerEvents="none"
          style={{ strokeDasharray: 6 }}
          className="verticalLine"
        />
        <line
          opacity={0.5}
          x1={xPadding}
          x2={width + xPadding}
          stroke="black"
          strokeWidth={0.5}
          pointerEvents="none"
          style={{ strokeDasharray: 6 }}
          className="horizontalLine"
        />
      </g>
    );
  }
}

CrossHairCursor.defaultProps = {
  xPadding: 0,
};

CrossHairCursor.propTypes = {
  xPadding: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default CrossHairCursor;
