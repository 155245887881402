import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

// text color helper
const textColorHelper = (bgColor) => {
  let [r, g, b] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bgColor);
  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);

  [r, g, b] = [r, g, b].map((x) => {
    let c = x;
    c /= 255.0;
    if (c <= 0.03928) {
      c /= 12.92;
    } else {
      c = ((c + 0.055) / 1.055) ** 2.4;
    }
    return c;
  });
  const lum = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);
  if (lum > 0.179) {
    return '#000000';
  }
  return '#FFFFFF';
};

const ColorPicker = (WrappedComponent) => {
  class ComposedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showPicker: false,
      };

      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleClick() {
      const { showPicker } = this.state;
      this.setState({
        showPicker: !showPicker,
      });
    }

    handleClose() {
      this.setState({
        showPicker: false,
      });
    }

    // add handleChange and handleChangeComplete events

    render() {
      const popover = {
        position: 'absolute',
        zIndex: '2',
      };

      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      };

      const {
        buttonStyle,
        text,
        color,
        handleChange,
        handleChangeComplete,
      } = this.props;
      const { showPicker } = this.state;

      return (
        <div
          style={{
            display: 'block',
            margin: '5px',
          }}
        >
          <Button
            onClick={this.handleClick}
            style={{
              color: textColorHelper(color),
              backgroundColor: color,
              ...buttonStyle,
            }}
          >
            {text}
          </Button>
          { showPicker ?
            <div style={popover}>
              <div
                role="presentation"
                style={cover}
                onClick={this.handleClose}
              />
              <WrappedComponent
                color={color}
                onChange={handleChange}
                onChangeComplete={handleChangeComplete}
              />
            </div> : null }
        </div>
      );
    }
  }

  // Prop Types
  ComposedComponent.defaultProps = {
    buttonStyle: undefined,
    text: '',
    color: '#000000',
  };

  ComposedComponent.propTypes = {
    buttonStyle: PropTypes.object,
    text: PropTypes.string,
    color: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleChangeComplete: PropTypes.func.isRequired,
  };

  return ComposedComponent;
};

export default ColorPicker;
