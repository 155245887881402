import React from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { format } from 'd3-format';
import { timeYear } from 'd3-time';
import CardBase from '../../containers/CardBase';
import LineChart from '../charts/LineChart';
import Colors from '../interactive/colors';

function OBOSChartCard(props) {
  const {
    data,
    loading,
  } = props;

  if (data && !loading) {
    return (
      <CardBase>
        <Card.Content >
          <React.Fragment>
            <div className="titleBar" >S&P 500 OB and OS Stocks (%)</div>
            <div width="100%">
              <LineChart
                data={data.map(x => ({
                  label: x.date,
                  value: [(100 * (x.ob)).toFixed(2), (100 * (x.os)).toFixed(2)],
                }))}
                size={{ width: 300, height: 250 }}
                margins={{
                  top: 20,
                  right: 20,
                  bottom: 30,
                  left: 36,
                }}
                timeFormatter="%-b %Y"
                timeTicks={[timeYear, 2]}
                labels={['Beat Rate', 'Avg']}
                yAxisPadding={[0, 0]}
                multi
                colors={[Colors.red, Colors.green]}
                zoomEnabled
                crossHairCursor
                currentCoordinate
                movingAverageTooltip
                movingTooltipOrigins={[[5, 0], [85, 0]]}
                movingToolTipTexts={['OB (%)', 'OS (%)']}
                movingToolTipFormat={format('.2f')}
                timeFormatterAuto
                mouseCoordinateX
                style={{ marginTop: 20 }}
              />
            </div>
          </React.Fragment>
        </Card.Content>
      </CardBase>);
  }
  return (
    <CardBase style={{ height: '260px' }}>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </CardBase>);
}

OBOSChartCard.defaultProps = {
  data: [],
};

OBOSChartCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
};

export default OBOSChartCard;
