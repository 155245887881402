import React from 'react';
import { withRouter } from 'react-router';
import { Button, Form, Segment } from 'semantic-ui-react';
import * as api from '../../api';


class Markets101newsletter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      email: '',
      optIn: false,
      errorEmail: false,
      errorOptIn: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(submit) {
    const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
    let error = false;
    this.setState({
      errorEmail: false,
      errorOptIn: false,
    });

    if (this.state.email === '' || !re.test(this.state.email)) {
      this.setState({ errorEmail: true });
      error = true;
    }
    if (!this.state.optIn) {
      this.setState({ errorOptIn: true });
      error = true;
    }

    if (!error && submit) {
      api.mailchimp.subscribeMailchimp('86646d394e', this.state.email);
      this.setState({ success: true, errorEmail: false });
    }
  }

  render() {
    return (
      <Segment style={{
        marginTop: '1vh',
        marginLeft: '10px',
        padding: '30px',
      }}
      >
        {!this.state.success &&
          <div>
            <div style={{ textAlign: 'center' }}>
              <h1
                style={{
                  fontSize: '3rem',
                  alignSelf: 'center',
                  color: '#003051',
                  lineHeight: '6rem',
                }}
              >
                LEARN THE MARKET WITH BESPOKE
                - JOIN OUR &quot;MARKETS 101&quot; FREE NEWSLETTER
              </h1>
            </div>
            <br />
            <p style={{
              alignSelf: 'center',
              color: '#003051',
              fontSize: '20px',
            }}
            >
              New to the stock market or want to learn more? Receive regular emails focused on
              investor education covering stocks, markets, the economy, sentiment and more. Decode
              the noise and avoid common pitfalls! Let Bespoke&#39;s team help you lay a strong
              foundation for investing success.
            </p>
            <p style={{
              alignSelf: 'center',
              color: '#003051',
              fontSize: '20px',
            }}
            >
              Join 10,000+ free Bespoke newsletter subscribers by entering your email below.
              Unsubscribe anytime.
            </p>
            <br />
            <Form>
              <Form.Input
                placeholder="Your Email Address"
                required
                error={this.state.errorEmail}
                onBlur={e => this.setState({ email: e.target.value })}
                style={{ width: '50%' }}
              />
              <br />
              <Form.Checkbox
                label="Opt in to receive Investor education Insights and updates from Bespoke to your email address."
                required
                error={this.state.errorOptIn}
                onChange={() => this.setState({ optIn: !this.state.optIn })}
                checked={this.state.optIn}
              />
            </Form>
            <br />
            <Button
              positive
              icon="checkmark"
              color="green"
              content="Submit"
              onClick={() => this.handleSubmit(true)}
            />
          </div>}
        {this.state.success &&
          <div>
            <div style={{ textAlign: 'center' }}>
              <h1
                style={{
                  fontSize: '4rem',
                  alignSelf: 'center',
                  color: '#003051',
                  lineHeight: '6rem',
                }}
              >
                THANK YOU!
              </h1>
              <br />
              <Button
                as="a"
                href="/research/think-big-blog/"
                positive
                color="green"
                content="Go to Research"
              />
            </div>
          </div>}
      </Segment>
    );
  }
}


export default withRouter(Markets101newsletter);
