import React from 'react';
import { Header } from 'semantic-ui-react';

export const Privacy = () => (
  <React.Fragment>
    <Header as="h1">Legal Policy</Header>
    <p>
      By using our site (this “Site”), you are agreeing to comply
      with and be bound by the following terms and conditions.
      If you do not agree to the following terms and conditions, you may not use this Site.
      The terms “Bespoke” , “us”, “we” or “our” refer to Bespoke Investment Group, LLC.
      The term “Service” includes,
      but is not limited to, the text, content, graphics, audio or video
      produced by Bespoke and appearing on this Site.
      The terms “you” and “your” refer to the user of the Service or viewer of this Site.
    </p>
    <Header as="h3">Acceptance of Agreement</Header>
    <p>
      By using this Site, you represent to us that you have read and agree to the terms and
      conditions set forth in this legal statement, which constitutes a legal and binding
      agreement between you and us (the “Agreement”). This Agreement constitutes the only
      and entire agreement between you and us, and supersedes all prior or contemporaneous
      agreements, representations, warranties and understandings with respect to your use
      of the Site and the Service. This Agreement may be amended at any time by us, and from
      time to time, without any notice to you. The latest Agreement will be posted on the
      Site, and you should review the Agreement prior to using or viewing this Site or the
      Service. You also agree to comply with U.S. law regarding the transmission of any
      information or data obtained from the Site or the Service in accordance with this
      Agreement. You also agree to not disrupt or interfere with the Site or the Service
      or use the Site or the Service for illegal purposes.
    </p>
    <Header as="h3">Copyrights and Trademarks</Header>
    <p>
      The works of authorship contained in the Service, including but not limited to all data,
      design, text, images, charts, audio, video or other data compilations or collective works,
      and all trademarks, tradenames, service marks and other intellectual property, are owned,
      except as otherwise expressly stated, by Bespoke or one of our data providers, and may not
      be copied, reproduced, transmitted, displayed, performed, distributed, rented,
      sublicensed,
      altered, stored for subsequent use or otherwise used in
      whole or in part in any manner without
      the prior written consent of Bespoke in each instance.The securities mentioned in this Site
      This Site and the Service are protected
      by copyrights, trademarks, service marks, international treaties, and/or other proprietary
      rights and laws of the United States. The Site
      and the Service are also protected as collective works
      or compilations under U.S. copyright and other laws and treaties.
      All individual articles, columns,
      and other elements making up the Site or
      the Service are also copyrighted works. You agree to abide
      by all additional copyright notices or restrictions contained in the Site or the Service.
    </p>
    <Header as="h3">Disclaimer and Limitations of Liability</Header>
    <p>
      All information, data and analysis provided
      by the Site or the Service is for informational and
      educational purposes only and is not a
      recommendation to buy or sell a security or basket of
      securities, including but not limited to
      equities, options and other derivative products, fixed
      income products, and ETFs.  The Service
      is produced without regard to individual levels of
      sophistication or investment experience, and without regard to the suitability
      of individuals who may access the Service. You understand and agree that the Service does
      not constitute a specific recommendation
      of any particular investment, security, portfolio,
      transaction, or strategy, nor that any specific course of action is
      suitable for any specific person.  You understand and agree that no Bespoke analysts or other
      personnel shall advise you with regard to your investments, and you therefore agree that you
      shall not attempt to contact Bespoke or any Bespoke personnel seeking personalized investment
      advice. You further understand and agree that all content in the Service is impersonal and not
      tailored to any specific investment needs or suitability requirements, including yours.
      <br />
      <br />
      You understand that performance data is supplied by sources believed to be reliable, that the
      calculations herein are made using such data, and that such calculations are not guaranteed by
      these sources, the information providers,
      or any other person or entity, and may not be complete.
      All content on the Site is presented only as of the date published or indicated,
      and may be superseded by subsequent market events or for other reasons. In addition,
      you are responsible for setting the cache settings on your browser
      to ensure you are receiving the most recent data.
      <br />
      <br />
      All information, data and analysis contained in the Site or the Service are provided “AS IS”
      and without warranty of any kind, either
      expressed or implied. Bespoke believes all information,
      data and analysis contained in the Service
      to be accurate, but does not guarantee its accuracy.
      Under no circumstances, including, but not
      limited to, negligence, shall Bespoke, any of Bespoke´s
      affiliates, employees, or other third party
      data providers be liable to you for direct, indirect,
      consequential, incidental, special, punitive
      or exemplary damages even if an authorized Bespoke
      representative has been advised specifically
      of the possibility of such damages, arising from the
      use of or inability to use the Site or the Service,
      such as, but not limited to, losses,
      loss of revenue, anticipated profits or lost
      business. Applicable law may not allow the limitation
      or exclusion of liability or incidental or
      consequential damages. In no event shall Bespoke´s total
      liability to you for all damages, losses, and
      causes and action (whether in contract or tort,
      including but not limited to negligence) exceed
      the amount paid by you, if any, for accessing this
      Site or using the Service. All opinions expressed
      on this Site are subject to change without notice,
      and you should always obtain current information
      and perform appropriate due diligence before making
      trades or investment decisions. Bespoke, any of
      Bespoke´s affiliates, employees, or other third party
      data providers may have long or short positions in
      the securities discussed in the Service and may
      purchase or sell such securities without notice.
      Bespoke uses various methods to evaluate investments
      which may, at times, produce contradictory
      recommendations with respect to the same securities.
      When evaluating the results of prior Bespoke
      recommendations or Bespoke performance rankings,
      you should also consider that Bespoke may
      modify the methods it uses to evaluate investment
      opportunities from time to time. For this
      and for many other reasons, the performance of Bespoke´s
      past recommendations or investments is
      not a guarantee of future results.
      <br />
      <br />
      The securities mentioned in this Site or in the Service may
      not be eligible for sale in some states or countries, nor be
      suitable for all types of investors; their value and income
      they produce may fluctuate and/or be adversely affected by
      exchange rates, interest rates or other factors.
      <br />
      <br />
      Please view
      Bespoke´s <a href="https://media.bespokepremium.com/uploads/2024/04/Bespoke-Form-ADV-Part-2A-041924.pdf">Form ADV Part 2</a> and <a href="https://media.bespokepremium.com/uploads/2023/03/Bespoke-Form-CRS-03072023.pdf">Form CRS</a> for information
      regarding Bespoke´s advisory services.
    </p>
    <Header as="h3">Links</Header>
    <p>
      The Site may from time to time display links to other websites or
      resources sponsored by third parties as
      a convenience to you.  We are not responsible or liable
      for, and do not endorse, the content or
      operation of such third-party websites, including but
      not limited to the advertising, products,
      services or other materials on or available from such
      websites or resources. Various third-party
      websites may provide links to this Site.
      We have not reviewed any or all of the
      third-party websites linked to this Site. We are not responsible
      for the content of any off-site pages or
      any other websites linked to this Site.
      Your linking to this Site, off-site pages
      or other websites is at your own risk.
    </p>
    <Header as="h3">Termination of Service</Header>
    <p>
      Either you or Bespoke may terminate this Agreement
      with or without cause at any time and effective
      immediately. You may terminate the Agreement by
      discontinuing use of the Service and destroying all
      materials obtained from the Site or the Service.
      This Agreement will terminate immediately without
      notice from Bespoke if you, in Bespoke´s sole
      discretion, fail to comply with any provision of this
      Agreement. Upon termination by you or upon notice
      of termination by Bespoke, you must destroy promptly
      all materials obtained from the Site or the Service
      and any copies. The Copyrights and Trademarks and
      Disclaimer and Limitations of Liability provisions of
      this Agreement shall survive any termination of this Agreement.
    </p>
    <Header as="h3">Governing Law</Header>
    <p>
      This Agreement and your use of the Site and Service
      will be governed by the laws of the State of New York,
      as applied to contracts entered into between New York
      residents and performed in New York.
      Any dispute arising out of this Agreement or your use
      of the Site or the Service will be heard only in the
      state or federal courts located in Westchester County, New York USA,
      and you consent and submit to the personal jurisdiction of such courts.
    </p>
    <Header as="h3">Site Operation</Header>
    <p>
      This Site is controlled and operated from the
      United States of America and we make no representation
      that any information, data or analysis contained on this
      Site or in the Service are appropriate for
      use in other locations. If you use this Site from other
      locations you are responsible for compliance with applicable local laws.
    </p>
    <Header as="h3">Pricing and Cancellations</Header>
    <p>
      Bespoke reserves the right to change the prices
      of any products or subscriptions provided by the Service.
      Unless otherwise specified, all subscriptions to the
      Service will be automatically renewed when they come
      up for renewal using the payment information we have
      on file. Subscriptions may be cancelled at any time
      and are non-refundable. All payments made to Bespoke are made through a third party service.
      Bespoke is not liable for any monies lost during the transaction process,
      non-payment issues or declination of any credit card.
    </p>
    <Header as="h3">Other</Header>
    <p>
      The Site or the Service may be temporarily
      unavailable from time to time due to required maintenance,
      telecommunications interruptions, or other disruptions.
      Although Bespoke makes every reasonable effort to minimize
      such downtime, Bespoke does not guarantee full availability of the Site or the Service.
    </p>
    <Header as="h3">Interpretation</Header>
    <p>
      If any provision of this Agreement is found
      invalid or unenforceable, that provision will be enforced to
      the maximum extent permissible, and the other
      provisions of the Agreement will remain in force.
      This Agreement states the entire agreement between you and us
      relating to use of the Site or the Service.
      This Agreement may not be amended except as provided above.
    </p>
    <Header as="h3">Contact</Header>
    <p>
      If you have any questions,
      concerns or comments, please contact us using <a href="https://www.bespokepremium.com/about-us#contactForm">this form</a>.
    </p>
    <Header as="h3">Privacy Policy</Header>
    <p>
      The following information discloses the privacy
      policy of Bespoke Investment Group, LLC (“Bespoke”).
      <br />
      <br />
      Other websites you may access via Bespoke or any
      of its affiliates have different privacy policies
      and are not subject to this privacy policy.
      We suggest that you read each of their privacy statements
      to understand how they protect your personal information.
      <br />
      <br />
      We collect only relevant lawful information about you
      and our other readers and clients in order to
      conduct our business in a way to properly service you.
      Sources of financial and personal information
      include information (such as your name, address,
      telephone and fax numbers, email address, etc.) provided
      by you through contact and request forms as well as oral,
      written or email communication and limited
      credit card and bank information obtained
      from your transactions with us and our affiliates.
      <br />
      <br />
      Visiting sections of our website does not require
      supplying any personal information, and any personal
      information supplied is done so on a strictly
      voluntary basis. Information used to determine your identity
      is only information provided by you. When you
      visit sites in the Bespoke network, our servers may also
      collect web site usage information and
      information about your computer, including your computer´s
      operating system, internet browser type,
      your computer´s IP address, and if you arrived at one of
      Bespoke´s sites via another website, the
      URL of the linking page. Additionally, once you subscribe
      to the member section of Bespoke’s website,
      Bespoke collects information on the sections or pages of
      Bespoke’s website that you visit in order to
      improve your membership experience over time.  Bespoke also
      collects your email preferences for the purpose
      of sending you the specific reports you wish to receive via
      email in a timely manner.  Once a Bespoke member,
      you may also set up “custom portfolios” of stocks, ETFs,
      or other securities that you wish to monitor in
      Bespoke’s Trend Analyzer tool, Chart Scanner tool, and other
      interactive sections of our website.  Bespoke stores
      such information in order to ensure that the information
      is updated each time you visit these sections of our website.
      Data collected by Bespoke is also used to
      optimize your experience on Bespoke’s site and to assist
      in answering questions that you may have about
      your subscriptions.  BESPOKE DOES NOT SELL YOUR INFORMATION OR USER DATA TO ANYONE.
      Bespoke maintains
      records of all of our present and prior clients
      including contact info, financial transactions, usernames,
      and passwords, however, user passwords are encrypted and thus
      not accessible by Bespoke. Once information is
      provided to us, such as contact information for subscription services,
      we may keep such information
      indefinitely; provided that upon request by a user,
      Bespoke will delete such user’s data and information.
      <br />
      <br />
      A “Web Cookie” is a string of information that a
      website stores on a user’s computer, and that the user’s
      browser provides to the website each time the user
      submits a query to the site. We use Web Cookies to track
      the pages that users visit during each session, both to
      help us improve users’ experiences and to help us
      understand how Bespoke’s website is being used. As with
      other non-Personal Information gathered from users
      of the site, we analyze in aggregated form information
      gathered using Web Cookies, so as to help us better
      understand how the site is being used. We do not attempt
      to link information gathered using Web Cookies to
      personal information. USERS WHO DO NOT WISH TO HAVE WEB
      COOKIES PLACED ON THEIR COMPUTERS SHOULD SET THEIR
      BROWSERS TO REFUSE WEB COOKIES BEFORE ACCESSING THE SITE,
      WITH THE UNDERSTANDING THAT CERTAIN FEATURES OF
      THE SITE MAY NOT FUNCTION PROPERLY WITHOUT THE AID OF WEB
      COOKIES. USERS WHO REFUSE WEB COOKIES ASSUME ALL
      RESPONSIBILITY FOR ANY RESULTING LOSS OF FUNCTIONALITY.
      <br />
      <br />
      A “Web Beacon” is an object that is embedded in a
      web page that is usually invisible to the user and allows
      website operators to check whether a user has viewed a
      particular web page or an email. We may use Web Beacons
      on Bespoke’s website and in emails to count users who
      have visited particular pages, viewed emails, and to
      deliver services. Web Beacons are not used to access
      users’ personal information; they are a technique we may
      use to compile aggregated statistics about site usage.
      Web Beacons collect only a limited set of information
      including a Web Cookie number, time and date of a page
      or email view, and a description of the page or email
      on which the Web Beacon resides. You may not decline Web
      Beacons, however, they can be rendered ineffective by
      declining all Web Cookies or modifying your browser setting
      to notify you each time a Web Cookie is tendered
      and permit you to accept or decline Web Cookies on an individual basis.
      <br />
      <br />
      We do not disclose any nonpublic personal information
      about our readers or clients (both present and past)
      to anyone, except as permitted by law. As the law
      permits, we may share information about you with third parties
      who customarily provide services for your accounts in the ordinary
      course of Bespoke’s business, including Bespoke’s
      server company, subscription-processing company, and email client.
      This information includes, but is not
      limited to information provided by you through forms, applications,
      and oral, written or email communication;
      and information derived from your transactions with us such
      as, but not limited to: amount of payment,
      method of payment, and user name. When any information is
      provided to third parties, it is only done so on
      the condition that such third parties keep your information confidential.
      We do not sell our subscriber or
      mailing lists or any data related to these lists to any third parties.
      If you believe we have shared your
      information inappropriately, please contact us. We do not otherwise
      share information about any of our clients,
      readers, or subscribers with any third party unless we have the consent
      of that person. We will only divulge
      information as necessary to comply with state and federal laws and regulations.
      Whenever possible,
      a client may choose to not disclose information.
      <br />
      <br />
      Personal information you submit to Bespoke through
      our website is secure once it reaches our servers.
      The information you submit is not secure as it travels
      from you to our servers. Email communication is
      often non-secure, however, and clients should
      be aware of this when providing personal or
      confidential information in email. We will never require that
      clients provide any information to us through email
      communication, and will gladly take any relevant information over the phone or by mail.
      <br />
      <br />
      We have implemented security policies, rules and
      technical measures to protect and safeguard the personal
      information under our control from unauthorized access,
      improper use or disclosure, unauthorized modification,
      unlawful destruction or accidental loss.  We store information
      you provide us on our secure servers, and the
      information you provide when making a purchase is encrypted.
      Access to your nonpublic personal information is limited
      to our employees and service providers with a reason to have
      such access. All of our employees with access to client
      information are expected to and held to the utmost standards in
      respecting the privacy and confidentiality of our
      clients´ personal information.  While we do our best to protect
      your personal information, we cannot guaranty or
      warrant the security of any information you transmit to us, and
      you do so at your own risk.  In the event that we
      become aware of any unauthorized access to your personal
      information, you will be notified
      in accordance with applicable law.
      <br />
      <br />
      Clients can have information corrected or changed
      at any time and as often as necessary by contacting us.
      Unless otherwise specified, all subscriptions will
      be automatically renewed using the payment information
      provided at the time of subscribing.  Please note,
      however, that Bespoke does not accept or store complete
      payment information. Such information is processed
      and stored by Bespoke’s third party subscription processing providers.
      Payment information available to Bespoke is limited to
      the type of credit card used for payment, the last four digits of
      the card, and the expiration date of such card.
      Subscriptions may be cancelled at any time, and are non-refundable.
      Users may opt out of Bespoke’s services, and request deletion
      of their data and information, by unsubscribing from Bespoke
      e-mailing lists, cancelling their Bespoke subscription,
      and/or contacting Bespoke to request that
      their information be deleted.  Finally, other contracts
      for service may be cancelled in accordance
      with the terms of such contract.
    </p>
    <p>
      Privacy policy is as of February 11, 2021. Please contact us using <a href="https://www.bespokepremium.com/about-us#contactForm"> this form </a>
      with questions.
    </p>
  </React.Fragment>
);

export default Privacy;
