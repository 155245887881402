import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Search } from 'semantic-ui-react';
import * as tickerActions from '../../../../actions/data/tickers';
import * as userActions from '../../../../actions/user';

class changeItemsBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tickersToShow: [], searchValue: '', isLoading: false };
  }
  componentDidMount() {
    this.props.dispatch(tickerActions.getList());
  }

  modifyTicker(ticker, dataRequest) {
    this.setState({ isLoading: true });
    dataRequest(this.props.portfolio.id, [ticker])
      .then(() => this.props.dispatch(userActions.getPortfolios(0)))
      .then(() => this.setState({ isLoading: false }));
  }
  render() {
    if (this.props.allTickerMembers && this.props.portfolio) {
      return (
        <React.Fragment>
          <Search
            fluid
            className={`mini-search ${this.props.searchBarClass} ${this.state.isLoading ? this.props.successClass : ''}`}
            placeholder={this.props.placeHolderText(this.props.portfolio.name)}
            icon={this.state.isLoading ?
              this.props.successIcon : this.props.icon}
            selectFirstResult
            results={this.state.tickersToShow.map(x => ({ title: x, ticker: x }))}
            onSearchChange={(event, data) => {
              if (data.value === '') {
                this.setState({ tickersToShow: [] });
              } else {
                this.setState({
                  tickersToShow: this.props.calculateTickersToShow(
                    data,
                    this.props.allTickerMembers.map(x => x.ticker),
                    this.props.portfolio.members,
                  ),
                });
              }
              this.setState({ searchValue: data.value });
            }
            }
            onResultSelect={
              (event, data) => {
                (this.modifyTicker(
                  data.result.ticker,
                  this.props.dataRequest,
                ));
                this.setState({ searchValue: '' });
              }}
            value={this.state.searchValue}
            size="mini"
            style={{ textAlign: 'left' }}
          />
        </React.Fragment>

      );
    }
    return (<p>Loading...</p>);
  }
}

changeItemsBar.defaultProps = {
  icon: null,
  portfolio: undefined,
  searchBarClass: '',
  placeHolderText: portfolioName => portfolioName,
  successIcon: null,
  successClass: '',
};

changeItemsBar.propTypes = {
  portfolio: PropTypes.object,
  allTickerMembers: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  dataRequest: PropTypes.func.isRequired,
  icon: PropTypes.string,
  successIcon: PropTypes.string,
  searchBarClass: PropTypes.string,
  placeHolderText: PropTypes.func,
  calculateTickersToShow: PropTypes.func.isRequired,
  successClass: PropTypes.string,
};

const mapStateToProps = state => ({
  allTickerMembers: state.data.tickerList.list,
});
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(changeItemsBar);
