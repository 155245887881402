import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as EarningsExplorer from '../presentational/interactive/earningsExplorer';
import UpgradeConnected from '../presentational/upgradeConnected';
import { navigateToPage } from '../../actions/search';
import Touring from '../presentational/interactive/tour';
// import * as api from '../../api';

const getTitle = (match, ticker) => {
  if (match && match.params && match.params.ticker &&
    ticker && match.params.ticker === ticker.title) {
    // const ticker = match && match.params && match.params.ticker;
    const link = (
      <Link
        to={(`../../ticker/${ticker.title}`)}
      >

        {`${ticker.description} (${ticker.title}) `}
        <Icon name="external" />
      </Link>);
    return ['Earnings Explorer', isMobile ? <br /> : ' - ', link];
  }
  return 'Earnings Explorer';
};

class EarningExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.checkRoute = this.checkRoute.bind(this);
    this.agg_data = undefined;
  }

  componentDidMount() {
    this.checkRoute();
    console.log('agg_quarterly:');
    // api.tools.getEEQuarterlyAggregateData('AAPL').then((x) => { this.agg_data = x; }).then(x => console.log(x));
  }

  componentDidUpdate() {
    this.checkRoute();
  }

  checkRoute() {
    if (this.props.match.params.tab === undefined) {
      this.props.navigateToPage('/earnings-explorer/upcoming');
    }

    if (this.props.match.params.tab === 'search' && !this.props.match.params.ticker) {
      this.props.navigateToPage('/earnings-explorer/search/AAPL');
    }
  }

  render() {
    const {
      match,
      ticker,
      mode,
      endDate,
    } = this.props;
    let steps = null;
    let cookieName = 'earningsExplorerTour';

    if (match.params.tab === 'upcoming') {
      cookieName = 'earningsExplorerUpcomingTour';
      steps = [
        {
          selector: '.earningExplorer-first-step',
          content: 'Our Earnings Calendar provides users with the upcoming earnings report dates for US-listed companies expected to report within the next month.',
        },
        {
          selector: '.upcoming-second-step',
          content: 'Our earnings bar chart shows the number of earnings reports by day over the next month.  Click on a bar or on one of the time frame tabs to narrow your search.  The results are populated in the table below.',
        },
        {
          selector: '.upcoming-third-step',
          content: 'For each stock listed in the calendar, we show its estimated report date and time and its current consensus EPS and sales estimates.',
        },
        {
          selector: '.upcoming-forth-step',
          content: 'We also provide a summary of historical quarterly reports for each stock, including the number of prior earnings reports in our database, the percentage of the time it has beaten EPS and sales estimates, and the percentage of the time it has raised guidance.  We also show the average one-day price change the stock has experienced on past earnings report days.  The "Gap" shows the stock\'s average percentage change from its last closing price before its earnings report to its opening price the next trading day.  The "Open to Close" column shows the stock\'s average percentage change from its first opening price following earnings to its closing price that day.  The "1 Day" column shows the stock\'s average percentage change from its last closing price before its earnings report to its next closing price (the sum of the gap and the open to close).  The "Volatility" column shows the stock\'s average absolute 1-day percentage change on earnings reaction days.',
        },
        {
          selector: '.upcoming-fifth-step',
          content: 'If you\'d like to import the calendar into an Excel or CSV file, simply click the "Download Table" button.',
        },
        {
          selector: '.upcoming-sixth-step',
          content: 'You can sort the table by clicking on any of the column headers to find, for example, stocks with upcoming earnings reports that beat earnings estimates the most often, or stocks that average the biggest gains on their earnings reaction days.',
        },
        {
          selector: '.upcoming-seventh-step',
          content: 'Our "Beat Rates" charts provide a summary of how all companies are reporting relative to estimates over time.  The EPS Beat Rate shows the percentage of stocks that have beaten consensus EPS estimates over the prior three months on a rolling basis.  The Sales Beat Rate shows the percentage of stocks that have beaten consensus revenue estimates over the prior three months on a rolling basis.',
        },
        {
          selector: '.upcoming-eighth-step',
          content: 'The Guidance Spread chart shows the percentage of companies that have raised guidance over the prior three months on a rolling basis.',
        },
        {
          selector: '.upcoming-ninth-step',
          content: 'The Stock Price Reaction Chart shows the median one-day percentage change that stocks are experiencing on their earnings reaction days over the prior three months on a rolling basis.',
        },
        {
          selector: '.upcoming-tenth-step',
          content: 'Click the time frame tabs at the bottom of the charts to view these readings over shorter or longer time frames.',
        },
        {
          selector: '.upcoming-eleventh-step',
          content: 'The Earnings Calendar portion of our Earnings Explorer tool is available to Bespoke Premium and Bespoke Institutional members.  The Stock Search and Stock Screen sections are available to Bespoke Institutional members only.  None of the information included in our Earnings Explorer tool is a recommendation to buy, sell, or hold a specific security.',
        },
      ];
    }

    if (match.params.tab === 'search') {
      cookieName = 'earningsExplorerSearchTour';
      steps = [
        {
          selector: '.earningExplorer-first-step',
          content: 'Our Stock Search feature allows users to analyze historical quarterly earnings results for nearly all US-listed companies.  Our database of earnings results contains quarterly reports for individual stocks dating back to 2001.',
        },
        {
          selector: '.search-second-step',
          content: 'Our four earnings gauges show the percentage of the time the stock has beaten consensus EPS estimates, the percentage of the time the stock has beaten consensus sales estimates, the percentage of the time the stock\'s share price has had a one-day positive reaction to its quarterly earnings report, and our proprietary Bespoke Earnings Score.  The Bespoke Earnings Score is a reading based on how the stock\'s share price typically reacts to EPS beats and misses.  The higher the number, the better.',
        },
        {
          selector: '.search-third-step',
          content: 'Charts of the stock\'s historical quarterly EPS and sales versus estimates are included dating back as far as 2001.',
        },
        {
          selector: '.search-forth-step',
          content: 'Aggregate earnings data for the stock is included showing its historical beat rates and its average one-day price change on earnings reaction days.',
        },
        {
          selector: '.search-fifth-step',
          content: 'Each quarterly earnings report for the stock is listed in our bottom table.  For each report, we show the stock\'s actual vs. estimated EPS and sales, any guidance that was issued, and its share price change in reaction to the report broken out by opening gap and open to close change.',
        },
        {
          selector: '.search-sixth-step',
          content: 'Use this tool to monitor how positive or negative recent earnings reports have been for the stocks you care about most.',
        },
        {
          selector: '.search-seventh-step',
          content: 'The Stock Search tool is available to Bespoke Institutional members only.  None of the information included in our Stock Search tool is a recommendation to buy, sell, or hold a specific security.',
        },
      ];
    }

    if (match.params.tab === 'screen') {
      cookieName = 'earningsExplorerScreenTour';
      steps = [
        {
          selector: '.earningExplorer-first-step',
          content: 'Our Stock Screen feature allows users to run in-depth earnings report screens and easily filter more than 150,000 individual quarterly reports for US stocks since 2001.',
        },
        {
          selector: '.screen-second-step',
          content: 'When entering this tool, all quarterly earnings reports over the past three months are populated in the "Screen Results" table.  You can click "Download Table" to analyze the results in an Excel or CSV file.',
        },
        {
          selector: '.screen-third-step',
          content: 'To search for earnings results over a specific time frame, simply adjust the date range.',
        },
        {
          selector: '.screen-forth-step',
          content: 'Use our Filter tool to screen for all companies that have beaten, missed, or reported inline EPS and/or sales results.  You can also screen for companies that raised, lowered, or reported inline guidance.',
        },
        {
          selector: '.screen-fifth-step',
          content: 'To find the stocks that reacted the most positively or negatively to their earnings reports over a specified time frame, use the Gap, Open to Close, and/or 1-Day % Change filters.',
        },
        {
          selector: '.screen-sixth-step',
          content: 'You can also filter for earnings results by quarter.',
        },
        {
          selector: '.screen-seventh-step',
          content: 'The "Aggregate Stats" table provides a summary analysis of your screen, showing the number of results, their EPS and sales beat rates, guidance, and average one-day stock price percentage change.',
        },
        {
          selector: '.screen-eighth-step',
          content: 'This tool is an excellent way to both monitor broad earnings trends over various time frames and screen for individual stocks based on your desired earnings outcomes.',
        },
        {
          selector: '.screen-ninth-step',
          content: 'The Stock Screen tool is available to Bespoke Institutional members only.  None of the information included in our Stock Screen tool is a recommendation to buy, sell, or hold a specific security.',
        },
      ];
    }

    const tour = steps ? (
      <Touring
        cookieName={cookieName}
        steps={steps}
      />) : '';

    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4, 10]} />
        <h1>{getTitle(match, ticker)}{tour}</h1>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column className="earningExplorer-first-step">
              <EarningsExplorer.Input />
            </Grid.Column>
            <Grid.Column className="search-third-step">
              <EarningsExplorer.Charts />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <EarningsExplorer.AggTable
                tickers={[ticker]}
                mode={mode}
                celled
                striped
              />
            </Grid.Column>
          </Grid.Row>
          { mode === 'single' &&
            <Grid.Row columns={1}>
              <Grid.Column>
                <EarningsExplorer.QuarterlyTable
                  celled
                  striped
                />
              </Grid.Column>
            </Grid.Row>
          }

          <Grid.Row columns={1}>
            <Grid.Column className="search-fifth-step screen-second-step">
              <EarningsExplorer.Table
                mode={mode}
                celled
                striped
                endDate={endDate}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

EarningExplorer.propTypes = {
  // membership: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  ticker: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  endDate: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  membership: state.user.access,
  ticker: state.view.earningsExplorer.ticker,
  mode: state.view.earningsExplorer.mode,
  endDate: state.view.earningsExplorer.endDate,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  navigateToPage: url => dispatch(navigateToPage(url, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EarningExplorer));
