import {
  TICKER_SET_DATA,
  TICKER_SET_LIST,
  TICKER_SET_LOADING,
  TICKER_HAS_ERRORED,
  TICKER_SET_CREATE,
  TICKER_SET_DISABLED,
  TICKER_SET_CATEGORY,
  TICKER_SET_UPDATE,
} from '../../types/actions';
import * as api from '../../api';

export const setData = ({ byId, allIds }) => ({
  type: TICKER_SET_DATA,
  byId,
  allIds,
});

export const createDataTicker = ({ listData }) => ({
  type: TICKER_SET_CREATE,
  listData,
});

export const dataSelectTickerCategory = ({ listData }) => ({
  type: TICKER_SET_CATEGORY,
  listData,
});

export const setList = ({ list, lastFetched }) => ({
  type: TICKER_SET_LIST,
  list,
  lastFetched,
});

export const setLoading = loading => ({
  type: TICKER_SET_LOADING,
  loading,
});

export const hasErrored = error => ({
  type: TICKER_HAS_ERRORED,
  error,
});

export const disabledTicker = ({ listData }) => ({
  type: TICKER_SET_DISABLED,
  listData,
});

export const modifyTickerUpdate = ({ listData }) => ({
  type: TICKER_SET_UPDATE,
  listData,
});

export const saveTickerNew = listData => (dispatch) => {
  const promise = api.ticker.createNewTicker(listData)
    .then((result) => {
      dispatch(createDataTicker(listData));
      return result;
    });
  return promise;
};

export const editTickerManager = listData => (dispatch) => {
  const promise = api.ticker.editTickerManager(listData.sendData.modifyData.id, listData.sendData)
    .then((result) => {
      dispatch(modifyTickerUpdate(listData));
      return result;
    });
  return promise;
};

export const getList = (onlyTickers = false, cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of all tickers
  const { tickerList } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (tickerList.lastFetched === null || (currentTime - tickerList.lastFetched) > cacheExpTime) {
    promise = api.ticker.getAll({ with_names: true })
      .then((result) => {
        dispatch(setList({ list: result, lastFetched: currentTime }));
        return result;
      });
  } else {
    promise = Promise.resolve(tickerList.list);
  }
  return promise.then(result => (onlyTickers ? result.map(x => x.ticker) : result));
};

export const selectTickerCategory = listData => (dispatch) => {
  const promise = api.ticker.selectTickerCategory(listData)
    .then((result) => {
      dispatch(dataSelectTickerCategory(result));
      return result;
    });
  return promise;
};

export const disabledTickerActive = listData => (dispatch) => {
  const promise = api.ticker.disabledActiveTicker(listData.idTicker, listData.valueChange)
    .then((result) => {
      dispatch(disabledTicker(listData));
      return result;
    });
  return promise;
};

// eslint-disable-next-line max-len
export const getListAllTickers = (onlyTickers = false, cacheExpTime = 900000) => (dispatch, getState) => {
  // Check cache for list of all tickers
  const { tickerList } = getState().data;
  const currentTime = Date.now();
  let promise;
  // If non-existent, retrieve it and update cache
  if (tickerList.lastFetched === null || (currentTime - tickerList.lastFetched) > cacheExpTime) {
    promise = api.ticker.getAllTickers({ with_names: true })
      .then((result) => {
        dispatch(setList({ list: result, lastFetched: currentTime }));
        return result;
      });
  } else {
    promise = Promise.resolve(tickerList.list);
  }
  return promise.then(result => (onlyTickers ? result.map(x => x.ticker) : result));
};

export const getSummaryData = (
  tickersToFetch,
  cacheExpTime = 900000,
  date = null,
) => (dispatch, getState) => {
  const { tickers } = getState().data;
  const partialResults = [];
  const needToFetch = [];
  if (tickers.length === 0) {
    return Promise.resolve([]);
  }

  // Check cache for valid ticker entries, and construct a partial result.
  const currentTime = Date.now();
  for (let i = 0; i < tickersToFetch.length; i += 1) {
    const ticker = tickersToFetch[i];
    let result;
    if (tickers.allIds.indexOf(ticker) !== -1 &&
      tickers.byId &&
      tickers.byId[ticker] &&
      tickers.byId[ticker].lastFetched &&
      (currentTime - tickers.byId[ticker].lastFetched) < cacheExpTime &&
      (date && date === tickers.byId[ticker].date)) {
      // It may be in the cache
      result = tickers.byId[ticker];
    } else {
      needToFetch.push(ticker);
    }
    partialResults[i] = result;
  }
  if (needToFetch.length === 0) {
    return Promise.resolve(partialResults);
  }
  dispatch(setLoading(true));
  return api.ticker.getSummary(needToFetch, 'ticker', 'ASC', date)
    .then((results) => {
      const normalizedResult = {
        byId: {},
        allIds: [],
      };
      for (let i = 0; i < results.length; i += 1) {
        normalizedResult.byId[results[i].ticker] = {
          ...results[i],
          lastFetched: currentTime,
        };
        normalizedResult.allIds.push(results[i].ticker);
      }
      for (let i = 0; i < partialResults.length; i += 1) {
        if (partialResults[i] === undefined) {
          partialResults[i] = results.shift();
        }
      }
      if (results.length > 0) {
        partialResults.push(...results);
      }
      // @todo store the results
      normalizedResult.date = date;
      dispatch(setData(normalizedResult));
      return ({
        date: partialResults && partialResults[0] ? partialResults[0].date : null,
        tickers: partialResults,
      });
    });
};
