import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Grid } from 'semantic-ui-react';
import { getOBOSChart, getFiftyDmaChart, getTwohundredDmaChart } from '../../actions/data/tools';
import UpgradeConnected from '../presentational/upgradeConnected';
import OBOSChartCard from '../presentational/cards/OBOSChartCard';
import FiftyDmaChartCard from '../presentational/cards/FiftyDmaChartCard';
import TwohundredDmaChartCard from '../presentational/cards/TwohundredDmaChartCard';

const timeFrames = clickHandler => ([
  {
    key: '6months',
    name: '6 Months',
    onClick: clickHandler,
    time: '6-months',
  },
  {
    key: '1year',
    name: '1 Year',
    onClick: clickHandler,
    time: '1-year',
  },
  {
    key: '5years',
    name: '5 Years',
    onClick: clickHandler,
    time: '5-years',
  },
  {
    key: '10years',
    name: '10 Years',
    onClick: clickHandler,
    time: '10-years',
  },
  {
    key: 'all',
    name: 'All',
    onClick: clickHandler,
    time: 'all',
  },
]);

class BespokeCharts extends React.Component {
  componentDidMount() {
    // if the url
    document.title = 'Bespoke | Bespoke Charts';

    // Getting today's Movers
    this.props.getOBOSChart();
    this.props.getFiftyDmaChart();
    this.props.getTwohundredDmaChart();

    this.panelChangeHandler = this.panelChangeHandler.bind(this);
  }
  panelChangeHandler(e, d) {
    this.props.getOBOSChart(this.props.category, d.time);
    this.props.getFiftyDmaChart(this.props.category, d.time);
    this.props.getTwohundredDmaChart(this.props.category, d.time);
  }

  render() {
    const {
      OBOSCharts,
      FiftyDmaCharts,
      TwohundredDmaCharts,
    } = this.props;

    const menuItems = timeFrames(this.panelChangeHandler)
      .map(x => ({ ...x, active: (x.time === OBOSCharts.timeframe) }));
    const menu = (
      <Menu
        size="tiny"
        widths={menuItems.length}
        attached="bottom"
        items={menuItems}
      />);

    let responsiveStyle = '@media only screen and (max-width: 991px) and (min-width: 768px) {';
    responsiveStyle += 'div.titleBar { font-size: 16px; }';
    responsiveStyle += '}';

    return (
      <React.Fragment>
        <style>{responsiveStyle}</style>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <style>{this.responsiveStyle}</style>
        <h1>Bespoke Charts</h1>
        {menu}
        <br />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <OBOSChartCard
                data={OBOSCharts.data}
                loading={OBOSCharts.isLoading}
              />
            </Grid.Column>
            <Grid.Column>
              <FiftyDmaChartCard
                data={FiftyDmaCharts.data}
                loading={FiftyDmaCharts.isLoading}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <TwohundredDmaChartCard
                data={TwohundredDmaCharts.data}
                loading={TwohundredDmaCharts.isLoading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

BespokeCharts.propTypes = {
  category: PropTypes.string,
  OBOSCharts: PropTypes.object.isRequired,
  getOBOSChart: PropTypes.func.isRequired,
  FiftyDmaCharts: PropTypes.object.isRequired,
  getFiftyDmaChart: PropTypes.func.isRequired,
  TwohundredDmaCharts: PropTypes.object.isRequired,
  getTwohundredDmaChart: PropTypes.func.isRequired,
};

BespokeCharts.defaultProps = {
  category: '28',
};

const mapStateToProps = state => ({
  OBOSCharts: state.data.obos,
  FiftyDmaCharts: state.data.fiftydma,
  TwohundredDmaCharts: state.data.twohundreddma,
});
const mapDispatchToProps = dispatch => ({
  getOBOSChart: (category, timeframe) => dispatch(getOBOSChart(category, timeframe)),
  getFiftyDmaChart: (category, timeframe) => dispatch(getFiftyDmaChart(category, timeframe)),
  getTwohundredDmaChart: (category, timeframe) =>
    dispatch(getTwohundredDmaChart(category, timeframe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BespokeCharts);
