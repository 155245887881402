import React from 'react';
import PropTypes from 'prop-types';
import Axis from './Axis';

const Axes = ({
  scales,
  margins,
  svgDimensions,
  xIsTime,
  timeFormatter,
  timeTicks,
  rotateX,
  zoomTransform,
  timeFormatterAuto,
}) => {
  const { height, width } = svgDimensions;

  const xProps = {
    orient: 'Bottom',
    scale: scales.xScale,
    translate: `translate(0, ${height - margins.bottom})`,
    tickSize: height - margins.top - margins.bottom,
    isTime: xIsTime,
    timeFormatter,
    timeTicks,
    rotateX,
    zoomTransform,
    timeFormatterAuto,
  };

  const yProps = {
    orient: 'Left',
    scale: scales.yScale,
    translate: `translate(${margins.left}, 0)`,
    tickSize: width - margins.left - margins.right,
    zoomTransform,
    timeFormatterAuto,
  };

  return (
    <g>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  );
};

Axes.defaultProps = {
  xIsTime: false,
  timeFormatter: undefined,
  timeTicks: undefined,
  rotateX: 0,
  zoomTransform: undefined,
  timeFormatterAuto: false,
};

Axes.propTypes = {
  scales: PropTypes.object.isRequired,
  margins: PropTypes.object.isRequired,
  svgDimensions: PropTypes.object.isRequired,
  xIsTime: PropTypes.bool,
  timeFormatter: PropTypes.string,
  timeTicks: PropTypes.array,
  rotateX: PropTypes.number,
  zoomTransform: PropTypes.object,
  timeFormatterAuto: PropTypes.bool,
};

export default Axes;
