
import React from 'react';
import PropTypes from 'prop-types';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { LineSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { fitWidth } from 'react-stockcharts/lib/helper';
// import { last } from 'react-stockcharts/lib/utils';
// import { format } from 'd3-format';
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale';

/**
 * Documentation for StockChart chart settings
 * settings: {
 *   indicators: {
 *     indicatorX: {
 *       color,
 *       indicatorParameters,
 *       isVisible: 'true | false'
 *     }
 *   }
 *   type: 'candle | line | OHLC',
 *   interactive: 'true | false',
 *   crosshair: 'true | false',
 *   annotations: 'true | false',
 * }
 */
const monthFormatter = (day) => {
  const monthToDisplayIndex = new Date((day + 1) * 86400000).getMonth();
  const monthMap = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  };
  return monthMap[monthToDisplayIndex];
};

class LinearChart extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    // console.log(monthFormatter(334));
    const {
      type,
      initialData,
      width,
      height,
      margins,
    } = this.props;

    const iData = initialData.map(d => ({ ...d, date: new Date(d.date) }));

    const xScaleProvider = discontinuousTimeScaleProvider
      .inputDateAccessor(d => d.date);
    const {
      data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(iData);

    // const xExtents = [
    //   xAccessor(data[data.length - Math.min(365, data.length)]),
    //   xAccessor(last(data)),
    // ];
    // console.log('xExtents');
    // console.log(xExtents);
    // console.log(initialData);
    // console.log(data);
    return (
      <ChartCanvas
        height={height}
        width={width}
        ratio={1}
        margin={margins}
        type={type}
        seriesName="MSFT"
        data={data}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        // xExtents={xExtents}
        zoomEvent={false}
        panEvent={false}
      >
        <Chart
          id={1}
          yExtents={d => d.value}
          height={height * 0.88}
          origin={[0, 0]}
        >
          <XAxis axisAt="bottom" orient="bottom" ticks={6} tickFormat={d => monthFormatter(d)} />
          <YAxis axisAt="left" orient="left" stroke="#000" ticks={5} />
          <LineSeries yAccessor={d => d.value} stroke="#497CB7" />
        </Chart>
      </ChartCanvas>
    );
  }
}

LinearChart.propTypes = {
  initialData: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['svg', 'hybrid']),
  height: PropTypes.number,
  margins: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired,
};

LinearChart.defaultProps = {
  type: 'hybrid',
  height: 341,
};

export default fitWidth(LinearChart);
