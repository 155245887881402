import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form, Header, Message, Modal } from 'semantic-ui-react';
import { sellMPortfolioDetail } from '../../../../actions/data/tools';

class SellModelPortfolioItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      shares: props.ticker.shares,
      price: props.price,
    };
  }

  close = () => {
    this.setState({
      open: false,
      shares: 0,
      price: '',
    });
  };

  click = () => {
    this.setState({
      open: true,
      shares: this.props.ticker.shares,
      price: this.props.price,
    });
  };

  handleChange = (e, { name, value }) => {
    console.log(e);
    this.setState({
      [name]: value,
      error: false,
      errorMessage: '',
    });
  };

  handleSubmit = () =>
    this.props.sellModelPortfolioDetailAction(
      this.props.ticker.id,
      this.props.ticker,
      this.state.shares,
      this.state.price,
    ).then(this.close())
      .catch(e => this.setState({ error: true, errorMessage: e.message }));

  render() {
    const {
      open,
      shares,
      price,
      error,
      errorMessage,
    } = this.state;
    const name = this.props.ticker.ticker_name;
    return (
      <React.Fragment>
        <Button color="blue" size="mini" onClick={this.click} icon="toggle up" />
        <Modal basic open={open} {...this.props} onClose={this.close}>
          <Header icon="warning sign" content={`WARNING: About to Sell "${name}" from this Model Portfolio`} />
          <Modal.Content>
            <p>
              This action is not reversible. Are you sure you want to sell {name}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Form
              error={error}
              onSubmit={this.handleSubmit}
            >
              <Form.Group style={{ width: '320px', textAlign: 'center' }}>
                <Form.Input
                  error={error}
                  placeholder="Number of shares"
                  name="shares"
                  value={shares}
                  onChange={this.handleChange}
                />
                <Form.Input error={error} placeholder="Price per share (0.00)" name="price" value={price} onChange={this.handleChange} />
                <Form.Button content="Sell" />
              </Form.Group>
              <Message
                error
                content={errorMessage}
              />
            </Form>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

SellModelPortfolioItem.defaultProps = {
  submitAction: () => null,
  price: '',
};

SellModelPortfolioItem.propTypes = {
  sellModelPortfolioDetailAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
  ticker: PropTypes.object.isRequired,
  price: PropTypes.string,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  sellModelPortfolioDetailAction:
    (id, ticker, shares, price) => dispatch(sellMPortfolioDetail(id, ticker, shares, price)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SellModelPortfolioItem);
