import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import { actions } from '../../types';

export default (state = {
  posts: [],
  cache: {
    byId: {},
    allIds: [],
  },
  error: null,
  totalPages: 0,
  loading: false,
  accessibleCategories: [],
}, action) => {
  switch (action.type) {
    case actions.RESEARCH_SET_ACCESSIBLE_CATEGORIES:
      return {
        ...state,
        accessibleCategories: action.categories,
      };
    case actions.RESEARCH_SET_POSTS:
      return {
        ...state,
        posts: action.posts,
        totalPages: action.totalPages,
        error: null, // @todo this seems questionable.
      };
    case actions.RESEARCH_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.RESEARCH_HAS_ERRORED:
      return {
        ...state,
        error: action.error,
      };
    case actions.RESEARCH_INSERT_CACHE:
      return {
        ...state,
        cache: {
          byId: merge({}, state.cache.byId, action.byId),
          allIds: uniq(state.cache.allIds.concat(action.allIds)),
        },
      };
    case actions.RESEARCH_REMOVE_CACHE: {
      const newById = state.cache.byId;
      for (let i = 0; i < action.ids.length; i += 1) {
        newById[action.ids[i]] = undefined;
      }
      return {
        ...state,
        cache: {
          byId: newById,
          allIds: state.cache.allIds.filter(item => action.ids.indexOf(item) === -1),
        },
      };
    }
    default:
      return state;
  }
};
