import * as React from 'react';
import { connect } from 'react-redux';
import {
  Breadcrumb,
  Button,
  Form,
  Icon, Input,
  Modal,
  Select,
  Tab,
  Card,
  Checkbox,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setPageTitle } from '../../../actions';
import { navigateToPage } from '../../../actions/search';
import { getListAllTickers, saveTickerNew } from '../../../actions/data/tickers';
import { getCategories, saveCategoryNew } from '../../../actions/data/tools';
import TableTickersManagerShow from './TableTickersManagerShow';
import TableCategoryManagerShow from './TableCategoryManagerShow';
// import * as api from '../../../api';

export class DataComponent {
  openModal = false;
  openModalCategory = false;
  searchTickers = [];
  isLoading = true;
  data: {
    lastFetched: null,
    rows: [],
    aggregates: {},
  };
  displayData = [];
  listSelectDataCategory = [];
  buttonDisableCreateTickers = false;
  messageTickerCreateDuplicate = '';
  secType: false;
  dataCategoryManager = {
    nameCategory: '',
    inChartbook: false,
    inTrendAnalyzer: false,
    isEtfCat: false,
    isIndex: false,
  };
  dataTickerManager = {
    ticker: '',
    groupName: '',
    nameTicker: '',
    sector: '',
    sharesOutstanding: 0,
  };
}

class TickersManager extends React.Component {
  state = new DataComponent();

  componentDidMount = () => {
    const { dispatch } = this.props;
    // Get list of all tickers for links
    this.props.getListAllTickers();
    this.props.getCategories();
    document.title = 'Bespoke Investment Group | Admin';
    dispatch((setPageTitle('ADMIN')));
  }

  openModalAddNew = () => {
    this.setState({
      openModal: true,
    });
  }

  openModalAddNewCategory = () => {
    this.setState({
      openModalCategory: true,
    });
  }

  handleChange = (e, d) => {
    const { name, value } = d;
    this.setState({
      dataTickerManager: {
        ...this.state.dataTickerManager,
        [name]: value,
      },
    });
  }

  handleChangeCheckbox = () => {
    const value = !this.state.secType;
    this.setState({
      ...this.state,
      secType: value,
    });
  }

  handleChangeCategory = (e, d) => {
    const { name, value } = d;
    let valueAlt = '';
    if (name === 'isEtfCat') {
      valueAlt = !this.state.dataCategoryManager.isEtfCat;
    }
    if (name === 'inChartbook') {
      valueAlt = !this.state.dataCategoryManager.inChartbook;
    }
    if (name === 'inTrendAnalyzer') {
      valueAlt = !this.state.dataCategoryManager.inTrendAnalyzer;
    }
    if (name === 'isIndex') {
      valueAlt = !this.state.dataCategoryManager.isIndex;
    }
    this.setState({
      ...this.state,
      dataCategoryManager: {
        ...this.state.dataCategoryManager,
        [name]: value === undefined ? valueAlt : value,
      },
    });
  }

  handleSelectChange = (e, d) => {
    const { name, value } = d;
    const data = this.state.listSelectDataCategory
      .filter(x => Number(x.category) === Number(value));
    if (data.length === 0) {
      this.setState({
        ...this.state,
        listSelectDataCategory: [...this.state.listSelectDataCategory, { [name]: value }],
        messageTickerCreateDuplicate: '',
      });
    } else {
      this.setState({
        ...this.state,
        messageTickerCreateDuplicate: 'It is already added to the list.',
      });
    }
  }

  close = () => {
    this.setState({
      openModal: false,
      openModalCategory: false,
      listSelectDataCategory: [],
      messageTickerCreateDuplicate: '',
      dataCategoryManager: {
        nameCategory: '',
        inChartbook: false,
        inTrendAnalyzer: false,
        isEtfCat: false,
        isIndex: false,
      },
    });
  }

  createDataTickers = () => {
    this.props.createTickerNew({
      ticker: this.state.dataTickerManager.ticker,
      company: this.state.dataTickerManager.nameTicker,
      shortname: this.state.dataTickerManager.nameTicker,
      gics_sector: this.state.dataTickerManager.sector,
      gics_group: this.state.dataTickerManager.groupName,
      sharesOutstanding: this.state.dataTickerManager.sharesOutstanding,
      sec_type: this.state.dataTickerManager.secType,
      is_active: 1,
      category: this.state.listSelectDataCategory,
    }).then(() => {
      this.close();
      window.location.reload(true);
    });
  }

  createDataCategory = () => {
    this.props.createCategoryNew({
      nameCategory: this.state.dataCategoryManager.nameCategory,
      inChartbook: this.state.dataCategoryManager.inChartbook,
      inTrendAnalyzer: this.state.dataCategoryManager.inTrendAnalyzer,
      isEtfCat: this.state.dataCategoryManager.isEtfCat,
      isIndex: this.state.dataCategoryManager.isIndex,
    }).then(() => {
      this.close();
      window.location.reload(true);
    });
  }

  removeCategoryTickers = (tickers) => {
    const dataRemove = this.state.listSelectDataCategory
      .filter(item => item.category !== tickers.category);
    this.setState({
      ...this.state,
      listSelectDataCategory: dataRemove,
    });
  }

  dataForSelect = (dataSelect) => {
    const dataReturned = [];
    // eslint-disable-next-line array-callback-return
    dataSelect.map((data) => {
      dataReturned.push({
        key: data.id,
        value: data.id,
        text: data.name,
      });
    });
    return dataReturned;
  }

  isValidateInput = (): boolean => !!((
    this.state.dataTickerManager.ticker !== '' &&
    this.state.dataTickerManager.groupName !== '' &&
    this.state.dataTickerManager.nameTicker !== '' &&
    this.state.dataTickerManager.sector !== ''
    && this.state.listSelectDataCategory.length !== 0));

  render() {
    const { tickers, categories } = this.props;
    const { openModal, openModalCategory } = this.state;

    const table = (
      <TableTickersManagerShow
        striped
        celled
        unstackable
        responsive="left-fixed"
        rows={tickers.list}
        editCategoriesList={categories}
        style={{ fontSize: '20px' }}
        rowsPerPage={50}
        page={Math.min(1, Math.ceil(tickers.list / 50))}
      />
    );
    const tableCategory = (
      <TableCategoryManagerShow
        striped
        celled
        unstackable
        responsive="left-fixed"
        rows={categories.data}
        style={{ fontSize: '20px' }}
        rowsPerPage={50}
        page={Math.min(1, Math.ceil(categories.data / 50))}
      />
    );
    const Tab1 = (
      <React.Fragment>
        <div className="row" style={{ textAlign: 'right' }}>
          <Button color="green" size="mini" onClick={this.openModalAddNew}>
            <Icon link name="plus" />ADD TICKER
          </Button>
        </div>
        <br />
        <div className="row">
          {
            table
          }
        </div>
      </React.Fragment>
    );
    const Tab2 = (
      <React.Fragment>
        <div className="row" style={{ textAlign: 'right' }}>
          <Button color="green" size="mini" onClick={this.openModalAddNewCategory}>
            <Icon link name="plus" />ADD Category
          </Button>
        </div>
        <br />
        <div className="row">
          {
            tableCategory
          }
        </div>
      </React.Fragment>
    );
    // @todo needs uncoupling
    // note using 'teal' for base color enum. Workaround
    const panes = [
      { menuItem: 'Tickers', render: () => <Tab.Pane>{ Tab1 }</Tab.Pane> },
      { menuItem: 'Categories', render: () => <Tab.Pane>{ Tab2 }</Tab.Pane> },
    ];

    return (
      <React.Fragment>
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={openModal}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
        >
          <center>
            <br />
            <h1>Add new ticker</h1>
            <Modal.Content>
              <br />
              <br />
              <Form>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="YYDD"
                    label="Ticker"
                    name="ticker"
                    valueDefault={this.state.dataTickerManager.ticker}
                    onChange={this.handleChange}
                    maxLength="8"
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Ticker Name"
                    label="Ticker Name"
                    name="nameTicker"
                    valueDefault={this.state.dataTickerManager.nameTicker}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Sector ..."
                    label="Sector"
                    name="sector"
                    onChange={this.handleChange}
                    valueDefault={this.state.dataTickerManager.sector}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Group Name ..."
                    label="Group Name"
                    name="groupName"
                    onChange={this.handleChange}
                    valueDefault={this.state.dataTickerManager.groupName}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="number"
                    placeholder="Shares Outstanding"
                    name="sharesOutstanding"
                    label="Shares Outstanding"
                    onChange={this.handleChange}
                    valueDefault={this.state.dataTickerManager.sharesOutstanding}
                  />
                </Form.Field>
                <Form.Field style={{ width: '80%' }}>
                  <Checkbox
                    toggle
                    defaultChecked={this.state.secType}
                    name="secType"
                    label="Sec Type"
                    onClick={this.handleChangeCheckbox}
                    style={{ float: 'left', marginBottom: '10px' }}
                  />
                </Form.Field>
                <Form.Field>
                  <Select
                    style={{ width: '80%' }}
                    options={this.dataForSelect(categories.data)}
                    placeholder="Select your category"
                    name="category"
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Card.Group style={{ width: '80%' }}>
                    {
                          this.state.listSelectDataCategory.length === 0 ? '' :
                            this.state.listSelectDataCategory.map(item => (
                              <Button
                                icon
                                labelPosition="left"
                                onClick={() => this.removeCategoryTickers(item)}
                                style={{ margin: '5px' }}
                              >
                                <Icon name="trash alternate" />
                                { categories.data.find(data => data.id === item.category).name }
                              </Button>
                            ))
                   }
                  </Card.Group>
                </Form.Field>
                {
                  this.state.messageTickerCreateDuplicate !== '' ?
                    <Form.Field>
                      <Card.Group style={{
                        width: '80%',
                        textAlign: 'center',
                        alignContent: 'center',
                        display: 'block',
                        color: 'red',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                      >
                        { this.state.messageTickerCreateDuplicate }
                      </Card.Group>
                    </Form.Field> : ''
                }
              </Form>
              <br />
              <hr style={{ width: '80%' }} className="ui divider" />
              <Button
                color="blue"
                onClick={this.createDataTickers}
                disabled={!this.isValidateInput()}
              >
                <Icon name="save" /> Save Ticker
              </Button>
              <br />
              <br />
            </Modal.Content>
          </center>
        </Modal>
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={openModalCategory}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
        >
          <center>
            <br />
            <h1>Add new Category</h1>
            <Modal.Content>
              <br />
              <br />
              <Form>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Name category"
                    label="Category"
                    name="nameCategory"
                    valueDefault={this.state.dataCategoryManager.nameCategory}
                    onChange={this.handleChangeCategory}
                  />
                </Form.Field>
                <Form.Field style={{ width: '80%' }}>
                  <Checkbox
                    toggle
                    defaultChecked={this.state.dataCategoryManager.inChartbook}
                    name="inChartbook"
                    label="in Chart book"
                    onClick={this.handleChangeCategory}
                    style={{ float: 'left', marginBottom: '10px' }}
                  />
                  <Checkbox
                    toggle
                    defaultChecked={this.state.dataCategoryManager.isEtfCat}
                    name="isEtfCat"
                    label="is Etf Cat"
                    onClick={this.handleChangeCategory}
                    style={{ float: 'right', marginBottom: '10px' }}
                  />
                </Form.Field>
                <Form.Field style={{ width: '80%' }}>
                  <Checkbox
                    toggle
                    defaultChecked={this.state.dataCategoryManager.isIndex}
                    name="isIndex"
                    label="is Index"
                    onClick={this.handleChangeCategory}
                    style={{ float: 'right', marginBottom: '10px' }}
                  />
                  <Checkbox
                    toggle
                    defaultChecked={this.state.dataCategoryManager.inTrendAnalyzer}
                    name="inTrendAnalyzer"
                    label="in Trend Analyzer"
                    onClick={this.handleChangeCategory}
                    style={{ float: 'left', marginBottom: '10px' }}
                  />
                </Form.Field>
              </Form>
              <br />
              <hr style={{ width: '80%' }} className="ui divider" />
              <Button color="blue" onClick={this.createDataCategory}>
                <Icon name="save" /> Save Category
              </Button>
              <br />
              <br />
            </Modal.Content>
          </center>
        </Modal>
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/admin" content="Admin" />
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section content="Tickers Manager" active />
        </Breadcrumb>
        <br />
        <br />
        <Tab panes={panes} />
      </React.Fragment>
    );
  }
}
TickersManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  // navigateToPage: PropTypes.func.isRequired,
  getListAllTickers: PropTypes.func.isRequired,
  createTickerNew: PropTypes.func.isRequired,
  createCategoryNew: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  tickers: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  tickers: state.data.tickerList,
  categories: state.data.categories,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  navigateToPage: url => dispatch(navigateToPage(url)),
  getListAllTickers: () => dispatch(getListAllTickers()),
  getCategories: () => dispatch(getCategories()),
  createTickerNew: dataList => dispatch(saveTickerNew(dataList)),
  createCategoryNew: dataList => dispatch(saveCategoryNew(dataList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TickersManager);
