import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Tab, Card, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
// import * as Cards from '../presentational/cards';
// import * as api from '../../api';
import { navigateToPage } from '../../actions/search';
import { getSummaryData } from '../../actions/data/tickers';
import TrendAnalyzerCard from '../presentational/cards/TrendAnalyzerCard';
// import PortfolioModifierCard from '../presentational/interactive/customPortfolios/PortfolioModifierCard';
import ChartGrid from '../presentational/interactive/chartScanner/ChartGridConnected';
import { getCSData, getEcal } from '../../actions/data/tools';
import { togglePortfolioCustomReport } from '../../actions/user';
import AddPortfolio from '../presentational/interactive/customPortfolios/addPortfolio';
import AddPortfolioModal from '../presentational/interactive/customPortfolios/addPortfolioModal';
import DeletePortfolio from '../presentational/interactive/customPortfolios/deletePortfolio';
import UpgradeConnected from '../presentational/upgradeConnected';
import SharePortfolioModal from '../presentational/interactive/customPortfolios/sharePortfolioModal';
import AddItems from '../presentational/interactive/customPortfolios/addItems';
import DeleteItems from '../presentational/interactive/customPortfolios/deleteItems';
import * as api from '../../api';
import CardBase from '../containers/CardBase';
import EditPortfolioModal from '../presentational/interactive/customPortfolios/editPorfolioModal';
import { Table } from '../presentational/interactive/earningsExplorer';
import ToggleReportModal from '../presentational/interactive/customPortfolios/toggleReportModal';

const parsePath = path => path.split('/').reduce((args, item) => (item === '' ? args : [...args, item]), []);

const sorted = (array) => { array.sort(); return array; };

class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.addedPortfolioAction = this.addedPortfolioAction.bind(this);
    this.updatedPortfolioAction = this.updatedPortfolioAction.bind(this);
  }

  componentDidMount() {
    console.log(this.props.toggleCustomReport); // TESTING
    const { dispatch, portfolios } = this.props;
    // const pfID = parseInt(parsePath(this.props.pathname)[1], 10);
    const { match, getEcalData } = this.props;
    if (match && match.path.includes('copy') && match.params.id) {
      api.user.copyPortfolio(match.params.id)
        .then((result) => {
          this.props.navigateToPage(`/portfolio/${result.id}`);
          window.location.reload();
        });
    }

    if (!(match && match.params && match.params.id)
      && portfolios && portfolios.byId) {
      if (sorted(Object.keys(portfolios.byId))[0] !== undefined) {
        this.props.navigateToPage(`/portfolio/${sorted(Object.keys(portfolios.byId))[0]}`);
      }
    } else if (match && match.params && match.params.id) {
      // pfId = parseInt(match && match.params && match.params.id, 10);
      const pfID = parseInt(parsePath(this.props.pathname)[1], 10);
      const portfolio = this.props.portfolios.byId[pfID];
      document.title = 'Bespoke | My Portfolios';
      if (portfolio && portfolio.members) {
        const tickersToGet = [];
        for (let i = 0; i < portfolio.members.length; i += 1) {
          if (this.props.portfolios.byId[portfolio.members[i]] === undefined) {
            tickersToGet.push(portfolio.members[i]);
          }
        }
        if (tickersToGet.length > 0) {
          dispatch(getSummaryData(tickersToGet));
        }
      }
      if (pfID) {
        this.props.getCSData(pfID);
      }
    }
    getEcalData();
  }

  componentDidUpdate(prevProps) {
    const { dispatch, portfolios } = this.props;
    const pfID = parseInt(parsePath(this.props.pathname)[1], 10);
    // this.props.getCSData(pfID);
    const portfolio = portfolios.byId[pfID];
    if (prevProps.portfolios !== this.props.portfolios && !portfolio) {
      // Url has changed
      this.props.navigateToPage(`/portfolio/${sorted(Object.keys(portfolios.byId))[0]}`);
    }
    if (portfolio && portfolio.members) {
      const tickersToGet = [];
      for (let i = 0; i < portfolio.members.length; i += 1) {
        if (this.props.portfolios.byId[portfolio.members[i]] === undefined) {
          tickersToGet.push(portfolio.members[i]);
        }
      }
      if (tickersToGet.length > 0 &&
        (prevProps.portfolios !== this.props.portfolios ||
          this.props.pathname !== prevProps.pathname)) {
        dispatch(getSummaryData(tickersToGet));
      }
    }
    // const { match } = this.props;
    if (prevProps.portfolios !== this.props.portfolios ||
      this.props.pathname !== prevProps.pathname) {
      // Url has changed
      if (pfID) {
        this.props.getCSData(pfID);
      }
    }

    if (this.props.pathname === '/portfolio' && portfolios && portfolios.byId) {
      this.props.navigateToPage(`/portfolio/${sorted(Object.keys(portfolios.byId))[0]}`);
    }
  }

  addedPortfolioAction = (portfolios) => {
    this.props.navigateToPage(`/portfolio/${sorted(Object.keys(portfolios.byId))[Object.keys(portfolios.byId).length - 1]}`);
  };

  updatedPortfolioAction = (id) => {
    this.props.navigateToPage(`/portfolio/${id}`);
  }

  render() {
    const pfID = parseInt(parsePath(this.props.pathname)[1], 10);
    const portfolios = Object.entries(this.props.portfolios.byId);
    const portfolio = this.props.portfolios.byId[pfID];
    const tickers = [];
    const menuItems = [];
    const { isLoading } = this.props;
    // Loop through and add tickers into the array
    if (portfolio && portfolio.members && portfolio.members.length > 0) {
      for (let i = 0; i < portfolio.members.length; i += 1) {
        const item = this.props.tickers.byId[portfolio.members[i]];
        if (item !== undefined) {
          tickers.push(item);
        }
      }
    }
    for (let i = 0; i < portfolios.length; i += 1) {
      menuItems.push({
        key: portfolios[i][0],
        name: portfolios[i][1].name,
        active: (parseInt(portfolios[i][0], 10) === pfID),
        include_in_report: portfolios[i][1].include_in_report,
      });
    }

    // const earningsTickers = tickers.map(x => ({ title: x.ticker, description: 'boom' }));
    const earningsTickers = tickers.map(x => x.ticker);
    console.log(earningsTickers);

    // Generate menu
    const menu = (
      <Menu>
        <Menu.Menu position="left" style={{ overflowX: 'auto' }}>
          {menuItems.map(x => (
            <Link key={x.key} to={`/portfolio/${x.key}`}>
              <Menu.Item
                name={x.name}
                active={x.active}
                className={x.include_in_report && 'include-report'}
              >
                {x.name}
              </Menu.Item>
            </Link>
          ))}
        </Menu.Menu>
        <Menu.Menu position="right">
          <Menu.Item style={{ textAlign: 'center' }}>
            <AddPortfolioModal submitAction={this.addedPortfolioAction} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>);
    const panes = [
      {
        menuItem: 'Trend Analyzer',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <TrendAnalyzerCard
              height="auto"
              loading={isLoading}
              name={portfolio && portfolio.name}
              tickers={tickers}
            />
          </Tab.Pane>),
      },
      {
        menuItem: 'Chart Scanner',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <ChartGrid
              // can be object or array, set up for polymorphic data
              defaultText="This Portfolio is empty. Please add some tickers."
              data={Object.values(this.props.chartScannerData.byId)}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Earnings Explorer',
        render: () => (
          <Tab.Pane loading={isLoading}>
            <Table
              portfolioMembers={earningsTickers}
              mode="portfolio"
              celled
              striped
              endDate={Date.now() + (1000 * 60 * 60 * 24)}
            />
            {/* Not Implemented show historical aggs for non-upcoming reports */}
            {/* <Table
              portfolioMembers={earningsTickers}
              mode="no-upcoming-aggs"
              celled
              striped
              endDate={Date.now() + (1000 * 60 * 60 * 24)}
            /> */}
          </Tab.Pane>
        ),
      },
      // {
      //   menuItem: 'Seasonality',
      //   render: () => (
      //     <Tab.Pane loading={isLoading}>
      //       Coming Soon
      //     </Tab.Pane>
      //   ),
      // },
      // {
      //   menuItem: 'Earnings Calendar',
      //   render: () => (
      //     <Tab.Pane loading={isLoading}>
      //       Coming Soon
      //     </Tab.Pane>
      //   ),
      // },
    ];
    const noPortfolios = (
      <React.Fragment>
        <h1>No Portfolios found</h1>
        <p>Create a new portfolio here</p>
        <AddPortfolio />
      </React.Fragment>
    );
    return portfolios.length > 0 ? (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        {/* <style>{'.ui.menu{ border-radius: 2rem}'}</style> */}
        {/*
        <h1 style={{ fontSize: '4em' }}>{portfolio && portfolio.name}</h1>
        <p style={{ fontSize: '1.6em' }}>A Custom Portfolio</p> */}
        {menu}

        <CardBase>
          <Card.Content>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <h1>
                    {portfolio && portfolio.name}&nbsp;
                    <span style={{ fontSize: '16px' }}>
                      <EditPortfolioModal
                        name={portfolio ? portfolio.name : null}
                        id={portfolio ? portfolio.id : null}
                        submitAction={this.updatedPortfolioAction}
                      />
                      <ToggleReportModal
                        name={portfolio ? portfolio.name : null}
                        includeReport={portfolio && portfolio.include_in_report}
                        setIncludeReport={
                          portfolio && portfolio.id ?
                            include => this.props.toggleCustomReport(portfolio.id, include)
                            : () => null
                        }
                        submitAction={() => null}
                      />
                    </span>
                  </h1>
                </Grid.Column>
                <Grid.Column className="right aligned column">
                  <Grid>
                    <Grid.Row columns="4">
                      <Grid.Column width={5} style={{ 'padding-left': '0px', 'padding-right': '0.5em' }}>
                        <AddItems portfolio={portfolio} />
                      </Grid.Column>
                      <Grid.Column width={5} style={{ 'padding-left': '0px', 'padding-right': '0.5em' }}>
                        <DeleteItems portfolio={portfolio} />
                      </Grid.Column>
                      <Grid.Column width={3} style={{ 'padding-left': '0px', 'padding-right': '0.5em', 'padding-top': '0.3em' }}>
                        <SharePortfolioModal id={pfID} />
                      </Grid.Column>
                      <Grid.Column width={3} style={{ 'padding-left': '0px', 'padding-right': '0.5em', 'padding-top': '0.3em' }}>
                        <DeletePortfolio
                          id={portfolio && portfolio.id}
                          name={portfolio && portfolio.name}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="1">
                <Grid.Column>
                  <Tab menu={{ attached: false, tabular: false }} panes={panes} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </CardBase>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <div style={{ alignSelf: 'center' }}>{noPortfolios}</div>
      </React.Fragment>
    );
  }
}

Portfolio.propTypes = {
  portfolios: PropTypes.object.isRequired,
  chartScannerData: PropTypes.object.isRequired,
  tickers: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getCSData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  getEcalData: PropTypes.func.isRequired,
  toggleCustomReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  portfolios: state.user.portfolios,
  tickers: state.data.tickers,
  isLoading: state.data.tickers.isLoading,
  chartScannerData: state.view.chartScanner,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  getEcalData: () => dispatch(getEcal()),
  navigateToPage: url => dispatch(navigateToPage(url)),
  getCSData: id => dispatch(getCSData('custom-portfolios', id)),
  toggleCustomReport: (id, include) => dispatch(togglePortfolioCustomReport(id, include)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Portfolio));
