import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Upgrade from './upgrade';
import SignUp from './signup';

// const membershipsAsString = (mem_levels) => {
//   const membershipLookup = {
//     1: 'Free',
//     2: 'Newsletter',
//     3: 'Premium',
//     4: 'Institutional',
//     5: 'Trial',
//     7: 'Chart of the Day',
//     8: 'Premium Plus FI',
//     9: 'Pulse',
//     10: 'Earnings Package',
//   };
//   const memberships = mem_levels.map(ml => (membershipLookup[ml] ? membershipLookup[ml] : 'Logged Out'));
//   if (memberships.length > 1) {
//     const lastItem = memberships.pop();
//     const mostItems = memberships.join(', ');
//     return memberships.length === 1 ? `${mostItems} or ${lastItem}` : `${mostItems}, or ${lastItem}`;
//   } else if (memberships.length === 1) {
//     return memberships[0];
//   }
//   return '';
// };

const membershipsAsString = (mem_levels, bold) => {
  const membershipLookup = {
    1: 'Free',
    2: 'Newsletter',
    3: 'Premium',
    4: 'Institutional',
    5: 'Trial',
    7: 'Chart of the Day',
    8: 'Premium Plus FI',
    9: 'Pulse',
    10: 'Earnings Package',
  };

  let memberships = mem_levels.map(ml => (membershipLookup[ml] ? membershipLookup[ml] : 'Logged Out'));

  if (bold) {
    memberships = mem_levels.map(ml => (membershipLookup[ml] ? <b>{membershipLookup[ml]}</b> : 'Logged Out'));
  }

  const output = [];
  memberships.forEach((m) => {
    output.push(m);
    output.push(', ');
  });
  if (memberships.length > 2) {
    output.pop();
    const lastItem = output.pop();
    output.push('or ');
    output.push(lastItem);
    return output;
  } else if (memberships.length === 2) {
    return [memberships[0], ' or ', memberships[1]];
  } else if (memberships.length === 1) {
    return memberships;
  }
  return '';
};

const UpgradeConnected = (props) => {
  const { access, whitelist, tool } = props;
  let message = null;
  if (tool) {
    message = ["You don't have access to this tool. If you would like to use this tool, please upgrade to the ", ...membershipsAsString(whitelist, true), ' membership.'];
  }

  if (whitelist.includes(access && access.mem_level)) {
    return '';
  } else if (access) {
    return <Upgrade message={message} />;
  }

  message = ['You don\'t have access to this tool. If you would like to use this tool, please log-in or become a member.'];
  return <SignUp message={message} memLevel={membershipsAsString([whitelist[0]], false)} />;
};

UpgradeConnected.defaultProps = {
  tool: false,
};

UpgradeConnected.propTypes = {
  access: PropTypes.object.isRequired,
  whitelist: PropTypes.array.isRequired,
  tool: PropTypes.bool,
};

const mapStateToProps = state => ({
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeConnected);
