import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RemoveReport = ({
  onClick,
  name,
}) => (
  <React.Fragment>
    <h1 style={{ color: 'white' }}>Remove from Daily Portfolio Highlights</h1>
    <p style={{ color: 'white' }}>
      Removing this portfolio from your <b>daily portfolio highlights</b> will mean that securities
      in this portfolio will no longer be tracked in your daily portfolio highlights email.
      Do you still want to remove <b>{name}</b>?
    </p>
    <Modal.Actions>
      <Button color="orange" size="mini" onClick={onClick}>
        <Icon link name="edit" />Remove from Custom Report
      </Button>
    </Modal.Actions>
  </React.Fragment>
);

RemoveReport.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemoveReport;
