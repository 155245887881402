import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';


class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errored: false, errorLocation: null };
  }

  componentDidUpdate() {
    const { errored, errorLocation } = this.state;
    if (errored && errorLocation !== this.props.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ errored: false });
    }
  }

  componentDidCatch(error) {
    const { location } = this.props;
    console.log(error.name);
    console.log(error.message);
    this.setState({ errored: true, errorLocation: location });
    ReactGA.event({
      category: 'Error',
      action: `${error.name}: ${error.message}`,
      label: `Triggered on page: ${location.pathname + location.search}`,
    });
  }

  render() {
    const { errored } = this.state;
    const { history, children } = this.props;
    if (errored) {
      return (
        <div style={{ marginTop: '26vh', marginLeft: '10px' }}>
          <h1
            style={{
              fontSize: '8rem',
              alignSelf: 'center',
              color: '#003051',
              lineHeight: '6rem',
            }}
          >
              Uh oh...
          </h1>
          <h1 style={{ alignSelf: 'center', color: '#003051' }}>
            Looks like something went wrong.
          </h1>
          <br />
          <Button
            onClick={() => { this.setState({ errored: false }); history.push('/'); }}
            style={{
              backgroundColor: '#003051',
              borderRadius: '200px',
              fontSize: '1.2rem',
              color: 'white',
            }}
          >
            Go Home
          </Button>
        </div>
      );
    }
    return children;
  }
}

Error.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default withRouter(Error);
