import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const FloatingScrollTop = ({ color, position }) => (
  <React.Fragment>
    <style>
      {'@media screen and (max-width: 768px) { .floatingScrollTop { display:block !important; } }'}
    </style>
    <Button
      className="floatingScrollTop"
      color={color}
      style={{
        display: 'none',
        position: 'fixed',
        ...position,
        opacity: 0.75,
        zIndex: 10,
        color: '#333',
        padding: '1em',
      }}
      onClick={() => window.scrollTo(0, 0)}
    >
      <Icon style={{ margin: 0 }} name="arrow up" />
    </Button>
  </React.Fragment>
);

FloatingScrollTop.defaultProps = {
  color: 'grey',
  position: {
    bottom: '10px',
    right: '10px',
  },
};

FloatingScrollTop.propTypes = {
  color: PropTypes.string,
  position: PropTypes.object,
};

export default FloatingScrollTop;
