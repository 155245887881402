import { combineReducers } from 'redux';
import content from './content/';
import data from './data';
import search from './search';
import user from './user';
import view from './view';

/**
 * Full reducer
 */
export default combineReducers({
  content,
  user,
  search,
  view,
  data,
});
