import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Screens from '../interactive/screens';
import CardBase from '../../containers/CardBase';
import { interactiveContent } from '../../../types';
// import Summary from '../Summary';
/**
 * Pass in a category object with property name (a string) and tickers (an array of ticker object)
 * @param {*} param0
 */
function ScreensCard({ cardState }) {
  return (
    <CardBase
      style={{ minHeight: '200px' }}
      isLoading={cardState ? cardState.isLoading : false}
    >
      <Card.Content>
        <div className="titleBar"> Bespoke Screens </div>
        <br />
        <Screens />
      </Card.Content>
    </CardBase>
  );
}

ScreensCard.defaultProps = {
  cardState: null,
};
ScreensCard.propTypes = {
  cardState: PropTypes.object,
};

const mapStateToProps = state => ({
  cardState: state.content.ticker.cards[interactiveContent.SCREENS],
});

export default connect(mapStateToProps)(ScreensCard);
