import React from 'react';
import PropTypes from 'prop-types';
import ChangeItemsBar from './changeItemsBar';
import * as api from '../../../../api';

export default function addItemsBar({ portfolio }) {
  return (
    <ChangeItemsBar
      portfolio={portfolio}
      dataRequest={api.user.addPortfolioMembers}
      icon="plus circle"
      successIcon="check circle"
      placeHolderText={() => 'Add Tickers'}
      searchBarClass=""
      successClass="green"
      calculateTickersToShow={(data, all, curr) => all.filter(ticker =>
        (new RegExp(` ${data.value.toUpperCase()}`)).test(` ${ticker}`) && !curr.includes(ticker))}
    />
  );
}

addItemsBar.defaultProps = {
  portfolio: undefined,
};

addItemsBar.propTypes = {
  portfolio: PropTypes.object,
};
