import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader, Menu, Segment } from 'semantic-ui-react';
import { scaleLinear } from 'd3-scale';
import { interpolateHsl } from 'd3-interpolate';
import { extent } from 'd3-array';
import { navigateToPage } from '../../../../actions/search';
import BarChart from '../../../presentational/charts/BarChart';
import Colors from '../colors';

class SeasonalityChart extends React.PureComponent {
  getTitle() {
    const { match } = this.props;
    let title = '';
    if (match && match.params && match.params.category) {
      switch (match.params.category) {
        case 'etfs':
          title = 'ETFs';
          break;
        case 'equities':
          title = 'US Equities';
          break;
        case 'custom':
          title = 'Custom Portfolios';
          break;
        case 'global':
          title = 'Global Equity Indices';
          break;
        default:
          break;
      }
    }

    return title;
  }

  catToIndex() {
    let defaultActiveIndex = 0;
    const { match } = this.props;
    switch (match && match.params && match.params.category) {
      case 'custom':
        defaultActiveIndex = 3;
        break;
      case 'etfs':
        defaultActiveIndex = 2;
        break;
      case 'global':
        defaultActiveIndex = 1;
        break;
      case 'equities':
      default:
        break;
    }

    return defaultActiveIndex;
  }

  render() {
    const { data, isLoading } = this.props.seasonalityData;
    const title = this.getTitle();

    const yExtent = extent(data.chartData.map(d => parseFloat(d.value)));
    let extents;
    let colors;
    if (yExtent[0] > 0) {
      extents = [0, yExtent[1]];
      colors = [Colors.yellow, Colors.green];
    } else if (yExtent[1] < 0) {
      extents = [yExtent[0], 0];
      colors = [Colors.red, Colors.yellow];
    } else {
      extents = [yExtent[0], 0, yExtent[1]];
      colors = [Colors.red, '#DBAF00', Colors.green];
    }

    const colorMap = scaleLinear()
      .domain(extents)
      .range(colors)
      .interpolate(interpolateHsl);

    return (
      <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="sixth-step">
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>

        <div
          className="titleBar"
          style={{
            fontSize: '16px',
            marginBottom: '10px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        >
          {`Seasonality, ${title}: Top 5 Best & Worst (%)`}
        </div>
        <Menu
          style={{ marginTop: '0px' }}
          defaultActiveIndex={this.catToIndex()}
          items={[
            { key: 'equities', content: 'US Equities' },
            // { key: 'global', content: 'Global Equity Indices' },
            { key: 'etfs', content: 'ETFs' },
            { key: 'custom', content: 'Custom Portfolios' },
          ].map(x => ({
            ...x,
            onClick: () => this.props.navigateToPage(`/seasonality-tool/${x.key}`),
          }))}
        />
        <BarChart
          showValue
          size={{
            height: 280,
          }}
          margins={{
            left: 30,
            right: 0,
            top: 15,
            bottom: 30,
          }}
          colorMap={colorMap}
          data={data.chartData}
          rotateX={45}
        />
      </Dimmer.Dimmable>);
  }
}

SeasonalityChart.propTypes = {
  navigateToPage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  seasonalityData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  seasonalityData: state.data.seasonality,
});

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => dispatch(navigateToPage(url)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeasonalityChart));
