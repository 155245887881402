import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header, Icon } from 'semantic-ui-react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share';
import { replacePDFLinks } from '../HTMLManipulator';
import InlineLoader from '../presentational/InlineLoader';
import { getSinglePost } from '../../actions/content/research';
import { setStar } from '../../actions/user';
import BackButton from '../presentational/interactive/backButton';
import Upgrade from '../presentational/upgrade';
import SignUp from '../presentational/signup';


const parsePath = path => path.split('/').reduce((args, item) => (item === '' ? args : [...args, item]), []);
class Posts extends React.Component {
  componentDidMount() {
    document.title = 'Bespoke | My Research';
    const [, categorySlug, postSlug] = parsePath(this.props.pathname);
    this.props.dispatch(getSinglePost(categorySlug, postSlug));
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      const [, categorySlug, postSlug] = parsePath(this.props.pathname);
      this.props.dispatch(getSinglePost(categorySlug, postSlug));
    }

    const dispSpecial = document.getElementsByClassName('disp_special');

    if (dispSpecial.length > 0) {
      fetch('/display_special.php?type=btn')
        .then(res => res.text())
        .then((html) => {
          dispSpecial[0].innerHTML = html;
        });
    }
  }

  getSharingLinks = () => {
    const { post } = this.props;
    if (post && post.category_slug) {
      const link = window.location.href;
      const links = [];
      const addLink = (Component, url, icon, color, props = {}) => {
        // links.push(<Component {...props} url={url} style={{ float: 'right' }}><Icon name={icon} /></Component>);
        links.push(<Component {...props} url={url} style={{ float: 'right' }}><Icon style={{ color }} name={icon} /></Component>);
      };
      addLink(EmailShareButton, link, 'mail', '#A8A78D', { subject: post.title });
      if (post.category_slug.includes('think-big-blog') ||
        post.category_slug.includes('morning-lineup') ||
        post.category_slug.includes('the-closer')) {
        const tbbLink = `https://www.bespokepremium.com/think-big-blog/${post.post_slug}`; // legacy site link
        const tbbLink2 = link.replace(/posts\/.*\//g, 'posts/think-big-blog/'); // new site link
        // Note: the new site link doesn't have OGP meta tags for dynamic content preview :(
        // Linking to legacy site for now for platforms using dynamic content preview
        addLink(RedditShareButton, tbbLink, 'reddit', '#FF5700', { title: post.title });
        addLink(LinkedinShareButton, tbbLink, 'linkedin', '#2867B2', { title: post.title, source: 'Bespoke Investment Group' });
        addLink(TwitterShareButton, tbbLink2, 'twitter', '#00acee', { title: post.title, via: 'bespokeinvest' });
        addLink(FacebookShareButton, tbbLink, 'facebook', '#4267B2', { quote: post.title });
      }
      return links;
    }
    return null;
  }

  toggleStarred() {
    const { dispatch, post, starredPosts } = this.props;
    return setStar(dispatch)(post.id, !starredPosts[post.id]);
  }

  render() {
    let starred = false;
    if (this.props.post) {
      starred = this.props.starredPosts[this.props.post.id];
    }
    if (this.props.post && this.props.post.restricted) {
      if (this.props.access === null) {
        return (<SignUp
          message={['You don\'t have access to this post. If you would like to see this post, please log-in or become a member.']}
        />);
      }
      return <Upgrade />;
    }

    const DATE_OPTIONS = {
      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
    };
    return (
      <Container text className="postcontainer">
        <style type="text/css">
          {'body{ background-color: white; }'}
        </style>
        {this.props.post && !this.props.loading ?
        (
          <div className="research content">
            <BackButton />
            <Icon link title={`${starred ? 'Unstar' : 'Star'} this report`} style={{ float: 'right' }} className={`${starred ? ' starred' : ''}`} name="star" onClick={(e) => { this.toggleStarred(); e.stopPropagation(); }} />
            {this.getSharingLinks()}
            <Header as="h1">{this.props.post.title}</Header>
            <p>
              {
                new Date(this.props.post.date_created).toLocaleDateString('en-US', DATE_OPTIONS)
              }
            </p>
            <div className="postcontent" dangerouslySetInnerHTML={{ __html: replacePDFLinks(this.props.post.content) }} />
          </div>) :
          <InlineLoader />
        }
      </Container>);
  }
}

Posts.defaultProps = {
  post: undefined,
};

Posts.propTypes = {
  post: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  starredPosts: PropTypes.object.isRequired,
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  post: state.content.research.posts[0],
  loading: state.content.research.loading,
  pathname: state.router.location.pathname,
  starredPosts: state.user.starred.byId,
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
