import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

class MouseCoordinateX extends React.PureComponent {
  render() {
    const {
      displayFormat,
      top,
    } = this.props;

    return (
      <Label
        className="mouseCoordinateX"
        color="black"
        dateFormat={displayFormat}
        style={{
          left: 0,
          top,
          opacity: 0,
          position: 'absolute',
          zIndex: 1001,
          whiteSpace: 'nowrap',
        }}
      >
        2010-01-01
      </Label>
    );
  }
}

MouseCoordinateX.defaultProps = {
  displayFormat: '%Y-%m-%d',
  top: 290,
};

MouseCoordinateX.propTypes = {
  displayFormat: PropTypes.string,
  top: PropTypes.number,
};

export default MouseCoordinateX;
