export const ADD_TICKER_CARDS = 'ADD_TICKER_CARDS';
export const UPDATE_CARD_DATA = 'UPDATE_CARD_DATA';
export const SET_VIEW = 'SET_VIEW';
export const CARD_VIEW = 'CARD_VIEW';
export const CARDS_WITH_HEADER_VIEW = 'CARDS_WITH_HEADER_VIEW';
export const UPDATE_TICKER_DATA = 'UPDATE_TICKER_DATA';
export const SET_VIEW_TICKER = 'SET_VIEW_TICKER';
export const SET_VIEW_META = 'SET_VIEW_META';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_PAGE = 'SET_PAGE';
export const SET_TICKER = 'SET_TICKER';
export const CLEAR_STATIC_CARDS = 'CLEAR_STATIC_CARDS';
export const SET_POSTS = 'SET_POSTS';
export const SET_LOADING_STATES = 'SET_LOADING_STATE';
export const SET_PORTFOLIO = 'SET_PORTFOLIO';
export const ADD_TO_PORTFOLIO = 'ADD_TO_PORTFOLIO';
export const REMOVE_FROM_PORTFOLIO = 'REMOVE_FROM_PORTFOLIO';
// Content action types
export const CONTENT_SET_TICKER = 'CONTENT_SET_TICKER';
export const CONTENT_SET_TICKER_LOADING = 'CONTENT_SET_TICKER_LOADING';
export const CONTENT_SET_TICKER_ERROR = 'CONTENT_SET_TICKER_ERROR';
export const CONTENT_SET_HISTORY = 'CONTENT_SET_HISTORY';
export const CONTENT_SET_HISTORY_ERROR = 'CONTENT_SET_HISTORY_ERROR';
export const CONTENT_SET_HISTORY_LOADING = 'CONTENT_SET_HISTORY_LOADING';
// Data action types
export const TICKER_SET_DATA = 'TICKER_SET_DATA';
export const TICKER_SET_LIST = 'TICKER_SET_LIST';
export const TICKER_SET_LOADING = 'TICKER_SET_LOADING';
export const TICKER_SET_CREATE = 'TICKER_SET_CREATE';
export const TICKER_SET_UPDATE = 'TICKER_SET_UPDATE';
export const TICKER_SET_DISABLED = 'TICKER_SET_DISABLED';
export const TICKER_SET_CATEGORY = 'TICKER_SET_CATEGORY';
export const TICKER_HAS_ERRORED = 'TICKER_HAS_ERRORED';
export const CATEGORIES_SET_DATA = 'CATEGORIES_SET_DATA';
export const CATEGORIES_SET_LIST = 'CATEGORIES_SET_LIST';
export const CATEGORIES_SET_LOADING = 'CATEGORIES_SET_LOADING';
export const CATEGORIES_HAS_ERRORED = 'CATEGORIES_HAS_ERRORED';
export const CATEGORIES_CREATE = 'CATEGORIES_CREATE';
export const CATEGORIES_SET_UPDATE = 'CATEGORIES_SET_UPDATE';
export const SCREENS_SET_DATA = 'SCREENS_SET_DATA';
export const SCREENS_SET_LOADING = 'SCREENS_SET_LOADING';
export const SCREENS_HAS_ERRORED = 'SCREENS_HAS_ERRORED';
export const ECAL_SET_DATA = 'ECAL_SET_DATA';
export const ECAL_SET_LOADING = 'ECAL_SET_LOADING';
export const ECAL_HAS_ERRORED = 'ECAL_HAS_ERRORED';
export const ECALAGG_SET_DATA = 'ECALAGG_SET_DATA';
export const ECALAGG_SET_LOADING = 'ECALAGG_SET_LOADING';
export const ECALAGG_HAS_ERRORED = 'ECALAGG_HAS_ERRORED';
// Research action types
export const RESEARCH_SET_POSTS = 'RESEARCH_SET_POSTS';
export const RESEARCH_INSERT_CACHE = 'RESEARCH_INSERT_CACHE';
export const RESEARCH_REMOVE_CACHE = 'RESEARCH_REMOVE_CACHE';
export const RESEARCH_SET_LOADING = 'RESEARCH_SET_LOADING';
export const RESEARCH_HAS_ERRORED = 'RESEARCH_HAS_ERRORED';
export const RESEARCH_SET_ACCESSIBLE_CATEGORIES = 'RESEARCH_GET_ACCESSIBLE_CATEGORIES';
// Search action types
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_RENDER_SUGGESTIONS = 'SEARCH_RENDER_SUGGESTIONS';
export const SEARCH_FOCUS_ON = 'SEARCH_FOCUS_ON';
export const SEARCH_FOCUS_OFF = 'SEARCH_FOCUS_OFF';
export const SEARCH_RENDER = 'SEARCH_RENDER';
// User action types
export const USER_STAR_POST = 'USER_STAR_POST';
export const USER_UNSTAR_POST = 'USER_UNSTAR_POST';
export const USER_HYDRATE = 'USER_HYDRATE';
export const USER_SET_PORTFOLIOS = 'USER_SET_PORTFOLIOS';
export const USER_SET_TICKER_CALLS = 'USER_SET_TICKER_CALLS';
export const USER_SET_SETTINGS = 'USER_SET_SETTINGS';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
// View action types
// Earnings explorer
export const EE_SET_END_DATE = 'EE_SET_END_DATE';
export const EE_SET_FILTER_DATE = 'EE_SET_FILTER_DATE';
export const EE_SET_ECAL_AGG_FILTER_DATE = 'EE_SET_ECAL_AGG_FILTER_DATE';
export const EE_SET_CHART_DATA = 'EE_SET_CHART_DATA';
export const EE_SET_CHART_DATA_LOADING = 'EE_SET_CHART_DATA_LOADING';
export const EE_SET_QUARTERLY_DATA = 'EE_SET_QUARTERLY_DATA';
export const EE_SET_QUARTERLY_DATA_LOADING = 'EE_SET_QUARTERLY_DATA_LOADING';
export const EE_SET_TABLE_DATA = 'EE_SET_TABLE_DATA';
export const EE_SET_TABLE_DATA_LOADING = 'EE_SET_TABLE_DATA_LOADING';
export const EE_SET_SCREEN_DATA = 'EE_SET_SCREEN_DATA';
export const EE_SET_SCREEN_DATA_LOADING = 'EE_SET_SCREEN_DATA_LOADING';
export const EE_SET_SORT = 'EE_SET_SORT';
export const EE_SET_MODE = 'EE_SET_MODE';
export const EE_SET_TICKER = 'EE_SET_TICKER';
export const EE_SET_TABLE_PAGE = 'EE_SET_TABLE_PAGE';
export const EE_SET_ERROR = 'EE_SET_ERROR';
// Chart Scanner
export const CS_SET_DATA = 'CS_SET_DATA';
export const CS_SET_LOADING = 'CS_SET_LOADING';
export const CS_HAS_ERRORED = 'CS_HAS_ERRORED';
// Seasonality
export const SZN_SET_DATA = 'SZN_SET_DATA';
export const SZN_SET_DATES = 'SZN_SET_DATES';
export const SZN_SET_LOADING = 'SZN_SET_LOADING';
export const SZN_HAS_ERRORED = 'SZN_HAS_ERRORED';

// TopMenu action types
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';

// LockScreen action types
export const LOCK_SCREEN = 'LOCK_SCREEN';

// Biggest Movers
export const MOVERS_SET_DATA = 'MOVERS_SET_DATA';
export const MOVERS_SET_LOADING = 'MOVERS_SET_LOADING';

// Bespoke Charts
export const OBOS_SET_DATA = 'OBOS_SET_DATA';
export const OBOS_SET_LOADING = 'OBOS_SET_LOADING';
export const OBOS_SET_TIMEFRAME = 'OBOS_SET_TIMEFRAME';

export const FIFTY_DMA_SET_DATA = 'FIFTY_DMA_SET_DATA';
export const FIFTY_DMA_SET_LOADING = 'FIFTY_DMA_SET_LOADING';
export const FIFTY_DMA_SET_TIMEFRAME = 'FIFTY_DMA_SET_TIMEFRAME';

export const TWOHUNDRED_DMA_SET_DATA = 'TWOHUNDRED_DMA_SET_DATA';
export const TWOHUNDRED_DMA_SET_LOADING = 'TWOHUNDRED_DMA_SET_LOADING';
export const TWOHUNDRED_DMA_SET_TIMEFRAME = 'TWOHUNDRED_DMA_SET_TIMEFRAME';


// Bespoke Baskets
export const MP_DATA = 'MP_DATA';
export const MP_DETAIL_DATA = 'MP_DETAIL_DATA';
export const MP_DETAIL_SET_LOADING = 'MP_DETAIL_SET_LOADING';
export const MP_SET_ERROR = 'MP_SET_ERROR';

