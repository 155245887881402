import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Button, Grid, Input, Label, Dropdown } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { getScreenData, setEETablePage } from '../../../../actions/data/tools';

// @todo: Move screen into redux store
class Screener extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changeInputState = this.changeInputState.bind(this);
    this.calendarChange = this.calendarChange.bind(this);

    this.state = {
      eps: {
        beat: false,
        miss: false,
        inline: false,
      },
      rev: {
        beat: false,
        miss: false,
        inline: false,
      },
      guidance: {
        lowered: false,
        raised: false,
        inline: false,
        none: false,
      },
      fromDate: new Date(Date.now() - (1000 * 60 * 60 * 24 * 90)).toISOString().split('T')[0],
      toDate: new Date(Date.now()).toISOString().split('T')[0],
      quarters: {
        Q1: false,
        Q2: false,
        Q3: false,
        Q4: false,
      },
      gap: {
        value: '',
        comparator: '=',
      },
      o2c: {
        value: '',
        comparator: '=',
      },
      oneDay: {
        value: '',
        comparator: '=',
      },
    };

    this.neutralButtonStyle = {
      color: '#FFF',
      backgroundColor: '#888',
    };
    this.quarterButtonSize = 'small';
  }

  componentDidMount() {
    // Run screen
    this.props.getEEScreenData(this.state);
  }

  componentDidUpdate() {
    // run screen
    this.props.getEEScreenData(this.state);
  }

  calendarChange(event, { name, value }) {
    const curVal = this.state[name];
    this.setState({
      [name]: curVal === value ? undefined : value,
    });
  }

  changeInputState(key, val) {
    const curVal = this.state[key];
    switch (key) {
      case 'fromDate':
      case 'toDate':
        this.setState({
          [key]: curVal === val ? undefined : val,
        });
        break;
      case 'quarters':
      case 'guidance':
      case 'rev':
      case 'eps':
        this.setState({
          [key]: {
            ...this.state[key],
            [val]: !this.state[key][val],
          },
        });
        break;
      case 'gap.value':
      case 'gap.comparator':
      case 'o2c.value':
      case 'o2c.comparator':
      case 'oneDay.value':
      case 'oneDay.comparator': {
        const [topLevel, secondLevel] = key.split('.');
        this.setState({
          [topLevel]: {
            ...this.state[topLevel],
            [secondLevel]: val,
          },
        });
        break;
      }
      default:
        break;
    }
  }

  render() {
    const {
      rev,
      eps,
      guidance,
      quarters,
      fromDate,
      toDate,
      gap,
      o2c,
      oneDay,
    } = this.state;
    const numCompOptions = [
      { key: '=', text: '=', value: '=' },
      { key: '>=', text: '>=', value: '>=' },
      { key: '<=', text: '<=', value: '<=' },
    ];
    return (
      <React.Fragment>
        <Grid
          className="compact screener"
          verticalAlign="middle"
          textAlign="center"
        >
          <Grid.Row key="dates" columns={4} className="screen-third-step">
            <Grid.Column>
              <h1 style={{ fontSize: '14px', textAlign: isMobile ? 'left' : 'right' }} >Date Range: </h1>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateInput
                name="fromDate"
                value={fromDate}
                onChange={this.calendarChange}
                dateFormat="YYYY-MM-DD"
                iconPosition="left"
                initialDate={fromDate}
                readOnly
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <h1 style={{ fontSize: '14px', textAlign: 'center' }} >-</h1>
            </Grid.Column>
            <Grid.Column width={5}>
              <DateInput
                name="toDate"
                value={toDate}
                onChange={this.calendarChange}
                dateFormat="YYYY-MM-DD"
                iconPosition="left"
                initialDate={toDate}
                readOnly
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          className="compact screener screen-forth-step"
          verticalAlign="middle"
          textAlign="center"
          style={{ marginTop: '0px' }}
          stackable
        >
          <Grid.Row key="eps" columns="4">
            <Grid.Column>
              <h1 style={{ fontSize: '14px', textAlign: isMobile ? 'left' : 'right' }} >EPS: </h1>
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={eps.beat ? 'toggled' : ''}
                color="green"
                content="Beat"
                onClick={() => this.changeInputState('eps', 'beat')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={eps.miss ? 'toggled' : ''}
                color="red"
                content="Miss"
                onClick={() => this.changeInputState('eps', 'miss')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={eps.inline ? 'toggled' : ''}
                color="blue"
                content="Inline"
                onClick={() => this.changeInputState('eps', 'inline')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row key="rev" columns="4">
            <Grid.Column>
              <h1 style={{ fontSize: '14px', textAlign: isMobile ? 'left' : 'right' }} >Sales: </h1>
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={rev.beat ? 'toggled' : ''}
                color="green"
                content="Beat"
                onClick={() => this.changeInputState('rev', 'beat')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={rev.miss ? 'toggled' : ''}
                color="red"
                content="Miss"
                onClick={() => this.changeInputState('rev', 'miss')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={rev.inline ? 'toggled' : ''}
                color="blue"
                content="Inline"
                onClick={() => this.changeInputState('rev', 'inline')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row key="guidance">
            <Grid.Column width={4}>
              <h1 style={{ fontSize: '14px', textAlign: isMobile ? 'left' : 'right' }} >Guidance: </h1>
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={guidance.raised ? 'toggled' : ''}
                color="green"
                content="Raised"
                onClick={() => this.changeInputState('guidance', 'raised')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={guidance.lowered ? 'toggled' : ''}
                color="red"
                content="Lowered"
                onClick={() => this.changeInputState('guidance', 'lowered')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={guidance.inline ? 'toggled' : ''}
                color="blue"
                content="Inline"
                onClick={() => this.changeInputState('guidance', 'inline')}
                style={{ marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                fluid
                size={this.quarterButtonSize}
                className={guidance.none ? 'toggled' : ''}
                content="None"
                onClick={() => this.changeInputState('guidance', 'none')}
                style={{ ...this.neutralButtonStyle, marginTop: isMobile ? '-1.5rem' : '0' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid
          className="compact screener screen-fifth-step"
          verticalAlign="middle"
          textAlign="center"
          style={{ marginTop: '0px' }}
        >
          <Grid.Row key="gap">
            <Grid.Column width="4">
              <h1 style={{ fontSize: '14px', textAlign: 'right' }} >Gap %: </h1>
            </Grid.Column>
            <Grid.Column width="12">
              <Input
                labelPosition="right"
                type="number"
                placeholder="Enter a % value"
                value={gap.value}
                onChange={e => this.changeInputState('gap.value', e.target.value)}
                fluid
              >
                <Label>
                  <Dropdown
                    options={numCompOptions}
                    defaultValue="="
                    onChange={(e, d) => this.changeInputState('gap.comparator', d.value)}
                  />
                </Label>
                <input />
                <Label basic>%</Label>
              </Input>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row key="o2c">
            <Grid.Column width="4">
              <h1 style={{ fontSize: '14px', textAlign: 'right' }} >Open to Close %: </h1>
            </Grid.Column>
            <Grid.Column width="12">
              <Input
                labelPosition="right"
                type="number"
                placeholder="Enter a % value"
                value={o2c.value}
                onChange={e => this.changeInputState('o2c.value', e.target.value)}
                fluid
              >
                <Label>
                  <Dropdown
                    options={numCompOptions}
                    defaultValue="="
                    onChange={(e, d) => this.changeInputState('o2c.comparator', d.value)}
                  />
                </Label>
                <input />
                <Label basic>%</Label>
              </Input>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row key="oneDay">
            <Grid.Column width="4">
              <h1 style={{ fontSize: '14px', textAlign: 'right' }} >1-Day %: </h1>
            </Grid.Column>
            <Grid.Column width="12">
              <Input
                labelPosition="right"
                type="number"
                placeholder="Enter a % value"
                value={oneDay.value}
                onChange={e => this.changeInputState('oneDay.value', e.target.value)}
                fluid
              >
                <Label>
                  <Dropdown
                    options={numCompOptions}
                    defaultValue="="
                    onChange={(e, d) => this.changeInputState('oneDay.comparator', d.value)}
                  />
                </Label>
                <input />
                <Label basic>%</Label>
              </Input>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row key="quarters" className="screen-sixth-step">
            <Grid.Column width="4">
              <h1 style={{ fontSize: '14px', textAlign: 'right' }} >Quarters: </h1>
            </Grid.Column>
            <Grid.Column width="3">
              <Button
                fluid
                size={this.quarterButtonSize}
                className={quarters.Q1 ? 'toggled' : ''}
                content="Q1"
                onClick={() => this.changeInputState('quarters', 'Q1')}
                style={this.neutralButtonStyle}
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Button
                fluid
                size={this.quarterButtonSize}
                className={quarters.Q2 ? 'toggled' : ''}
                content="Q2"
                onClick={() => this.changeInputState('quarters', 'Q2')}
                style={this.neutralButtonStyle}
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Button
                fluid
                size={this.quarterButtonSize}
                className={quarters.Q3 ? 'toggled' : ''}
                content="Q3"
                onClick={() => this.changeInputState('quarters', 'Q3')}
                style={this.neutralButtonStyle}
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Button
                fluid
                size={this.quarterButtonSize}
                className={quarters.Q4 ? 'toggled' : ''}
                content="Q4"
                onClick={() => this.changeInputState('quarters', 'Q4')}
                style={this.neutralButtonStyle}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

Screener.propTypes = {
  getEEScreenData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getEEScreenData: (screenState) => {
    dispatch(getScreenData(screenState));
    dispatch(setEETablePage(1));
  },
});

export default connect(null, mapDispatchToProps)(Screener);
