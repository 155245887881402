import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { createModelPortfolio } from '../../../../actions/data/tools';

class addModelPortfolio extends React.Component {
  state = {
    name: '',
    cash: '',
    starting: '',
    sp500Starting: '',
    startingYTD: '',
    sp500StartingYTD: '',
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value, error: false, errorMessage: '' })

  handleSubmit = () => this.props.createPortfolioAction({
    name: this.state.name,
    cash: this.state.cash,
    starting: this.state.starting,
    sp500Starting: this.state.sp500Starting,
    startingYTD: this.state.startingYTD,
    sp500StartingYTD: this.state.sp500StartingYTD,
  }).then(result => this.props.submitAction(result))
    .catch(e => this.setState({ error: true, errorMessage: e.message }));

  render() {
    const {
      name,
      cash,
      error,
      errorMessage,
      starting,
      sp500Starting,
      startingYTD,
      sp500StartingYTD,
    } = this.state;
    return (
      <Form
        error={error}
        onSubmit={this.handleSubmit}
        inverted
        style={{ width: '320px' }}
      >
        <Form.Input
          error={error}
          label="Name"
          placeholder="Create a new model portfolio..."
          name="name"
          value={name}
          onChange={this.handleChange}
          required
        />
        <Form.Input
          error={error}
          label="Cash"
          placeholder="0.00"
          name="cash"
          value={cash}
          onChange={this.handleChange}
          required
        />
        <Form.Group>
          <Form.Input
            error={error}
            label="Starting Value"
            placeholder="0.00"
            name="starting"
            value={starting}
            onChange={this.handleChange}
            required
          />
          <Form.Input
            error={error}
            label="S&P Starting Value"
            placeholder="0.00"
            name="sp500Starting"
            value={sp500Starting}
            onChange={this.handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={error}
            label="Starting Value YTD"
            placeholder="0.00"
            name="startingYTD"
            value={startingYTD}
            onChange={this.handleChange}
            required
          />
          <Form.Input
            error={error}
            label="S&P Starting Value YTD"
            placeholder="0.00"
            name="sp500StartingYTD"
            value={sp500StartingYTD}
            onChange={this.handleChange}
            required
          />
        </Form.Group>
        <Form.Button content="Create" />
        <Message
          error
          // header="Error"
          content={errorMessage}
        />
      </Form>);
  }
}

addModelPortfolio.defaultProps = {
  submitAction: () => null, // to pass in modal close from addPortfolioModal
};

addModelPortfolio.propTypes = {
  createPortfolioAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  createPortfolioAction: body => dispatch(createModelPortfolio(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(addModelPortfolio);
