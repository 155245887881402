import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Menu, Dimmer, Loader, Grid, Button, Icon } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
import { getBiggestMovers } from '../../actions/data/tools';
import UpgradeConnected from '../presentational/upgradeConnected';
import BiggestMoversCard from '../presentational/cards/BiggestMoversCard';
import Touring from '../presentational/interactive/tour';

class BiggestMoversPage extends React.Component {
  componentDidMount() {
    // if the url
    document.title = 'Bespoke | Biggest Movers';

    // Getting today's Movers
    this.props.getMovers();

    this.calendarChange = this.calendarChange.bind(this);
  }

  calendarChange(event, { name, value }) {
    if (name === 'date') {
      this.props.getMovers(value);
    }
  }

  render() {
    const { movers } = this.props;
    const steps = [
      {
        selector: '.first-step',
        content: 'Our Biggest Movers tool shows the daily percentage change for every stock in the S&P 1500 at the end of each trading day.',
      },
      {
        selector: '.second-step',
        content: 'You can click any column header to sort the S&P 1500 by sector, price, one-day price change, or volume.',
      },
      {
        selector: '.third-step',
        content: 'Easily track the day\'s biggest winners and losers using the "Price Change" column.',
      },
      {
        selector: '.forth-step',
        content: 'You can also sort the index to find the most overbought or oversold stocks at the end of each trading day.',
      },
      {
        selector: '.fifth-step',
        content: 'Finally, you can enter prior dates to access historical one-day price change information dating back to March 2018.',
      },
    ];
    const tour = (
      <Touring
        cookieName="biggestMoversTouring"
        steps={steps}
      />);

    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <style>{this.responsiveStyle}</style>
        <h1 className="first-step">Biggest Movers {tour}</h1>
        <BiggestMoversCard
          height="auto"
          date={movers.data.date}
          tickers={movers.data.tickers}
          loading={movers.isLoading}
          handleCalendarChange={this.calendarChange}
        />
      </React.Fragment>
    );
  }
}

BiggestMoversPage.propTypes = {
  movers: PropTypes.object.isRequired,
  getMovers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  movers: state.data.movers,
});
const mapDispatchToProps = dispatch => ({
  getMovers: date => dispatch(getBiggestMovers(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BiggestMoversPage);
