export default {
  red: '#ff6b6b',
  yellow: '#ffdd59',
  green: '#22B573',
  primary: '#003051',
  blue: '#2f9ced',
};

// @primaryColor : #003051 /*#114875 #073B57*/;
// @pageBackground : #f5f5f5;
// @blue : #2F9CED;
// /* @red : #FF7A64; */
// @red: #FF6B6B;
// @green: #22B573;
// @yellow: #F2A73B;
// @grey: #C5D1D3;
