import {
  CONTENT_SET_TICKER,
  CONTENT_SET_TICKER_LOADING,
  CONTENT_SET_TICKER_ERROR,
  CONTENT_SET_HISTORY,
  CONTENT_SET_HISTORY_ERROR,
  CONTENT_SET_HISTORY_LOADING,
} from '../../types/actions';
import { getSummaryData } from '../data/tickers';
import * as tickerAPI from '../../api/ticker';

export const setTicker = ticker => ({
  type: CONTENT_SET_TICKER,
  ticker,
});

export const setLoading = isLoading => ({
  type: CONTENT_SET_TICKER_LOADING,
  isLoading,
});

export const setError = error => ({
  type: CONTENT_SET_TICKER_ERROR,
  error,
});

export const setHistory = history => ({
  type: CONTENT_SET_HISTORY,
  history,
});

export const setHistoryLoading = isLoading => ({
  type: CONTENT_SET_HISTORY_LOADING,
  isLoading,
});

export const setHistoryError = error => ({
  type: CONTENT_SET_HISTORY_ERROR,
  error,
});

// get history and summary
export const getTicker = tickerName => (dispatch) => {
  const tckrName = tickerName.toUpperCase();
  dispatch(setLoading(true));
  dispatch(getSummaryData([tckrName]))
    .then((summary) => {
      const [result] = summary.tickers;
      // parse and dispatch
      dispatch(setTicker(result));
    })
    .catch(err => dispatch(setError(err)))
    .finally(() => dispatch(setLoading(false)));
  dispatch(setHistoryLoading(true));
  const today = new Date(Date.now()).toISOString().split('T')[0];
  tickerAPI.getHistorical([tckrName], '1985-01-01', today)
    .then((results) => {
      const history = results[tckrName];
      dispatch(setHistory(history));
    })
    .catch(err => dispatch(setHistoryError(err)))
    .finally(() => dispatch(setHistoryLoading(false)));
};
