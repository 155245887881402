import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Table, Dimmer, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { GreenText, YellowText, RedText } from '../ColoredText';
import Colors from './colors';

function getColor(positive, neutral, negative, value, trend = 1, change = false) {
  const hexParse = (hexCode) => {
    const v = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/.exec(hexCode)[1];
    let rgb = null;
    if (v.length === 3) {
      rgb = /([A-Fa-f0-9]{1})([A-Fa-f0-9]{1})([A-Fa-f0-9]{1})/.exec(v);
      return { r: rgb[1], g: rgb[2], b: rgb[3] };
    } else if (v.length === 6) {
      rgb = /([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})/.exec(v);
    }
    if (rgb) {
      return [rgb[1], rgb[2], rgb[3]].map(x => parseInt(x, 16));
    }
    return null;
  };
  const interpolate = (a, b, val) => Math.round(((a - b) * val) + b);
  let output = '';
  if (value > 0) {
    for (let i = 0; i < 3; i += 1) {
      output += interpolate(hexParse(positive)[i], hexParse(neutral)[i], value).toString(16);
    }
  } else if (value < 0) {
    for (let i = 0; i < 3; i += 1) {
      if (change) {
        output += interpolate(hexParse(negative)[i], hexParse(neutral)[i], -1 * value).toString(16);
      } else {
        output += interpolate(hexParse(negative)[i], hexParse(neutral)[i], 1 + value).toString(16);
      }
    }
  } else if (trend < 0) {
    return negative;
  } else {
    return neutral;
  }
  return `#${output}`;
}

// normalize between -1 and 1, with 0 being avg.
// const normalize1to1 = (max, min, value) => 2 * ((value - ((max + min) / 2)) / (max - min));
const normalize = (max, min, value) => (value - min) / (max - min);

// sort comparison function
const compFunc = (sortCol, sortDir) => (a, b) => {
  let varA;
  let varB;
  switch (sortCol) {
    case 'ticker':
    case 'company':
    case 'shortname':
      varA = (typeof a[sortCol] === 'string') ?
        a[sortCol].toUpperCase() : a[sortCol];
      varB = (typeof b[sortCol] === 'string') ?
        b[sortCol].toUpperCase() : b[sortCol];
      break;
    case 'price':
      varA = a.current_candle && a.current_candle.close;
      varB = b.current_candle && b.current_candle.close;
      break;
    case 'ytd_pct':
    case 'cur_range':
    case 'five_day_pct':
    case 'fifty_day_pct_mva':
    case 'trend':
    case 'timing':
      varA = a.current_trend && a.current_trend[sortCol];
      varB = b.current_trend && b.current_trend[sortCol];
      break;
    default:
      return 0;
  }
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (sortDir === 'DESC' ? (comparison * -1) : comparison);
};

const TRADING_RANGE_WIDTH_PCT = 20;
const TRADING_RANGE_MIN_WIDTH_PX = 120;
const PCT_CHANGE_WIDTH_PCT = 20;
const PCT_CHANGE_MIN_WIDTH_PX = 150;

/**
 * Pass in a category object with property name (a string) and tickers (an array of ticker object)
 * @param {*} param0
 */
function TrendAnalyzer({
  tickers,
  sortCol,
  sortDir,
  handleSortChange,
}) {
  if (tickers) {
    const headers = {
      TICKERS: {
        name: 'Tickers',
        priority: 1,
        colname: 'ticker',
        style: { width: '1%', maxWidth: '20px !important' },
      },
      NAME: {
        name: 'Name',
        priority: 2,
        colname: 'shortname',
      },
      PRICE: {
        name: 'Price',
        priority: 1,
        colname: 'price',
        style: { textAlign: 'center' },
      },
      YTD_CHANGE: {
        name: 'YTD',
        priority: 1,
        colname: 'ytd_pct',
        style: { textAlign: 'center', width: `${PCT_CHANGE_WIDTH_PCT / 3}%`, minWidth: `${PCT_CHANGE_MIN_WIDTH_PX / 3}px` },
      },
      FIVE_DAY_CHANGE: {
        name: '5-Day',
        priority: 1,
        colname: 'five_day_pct',
        style: { textAlign: 'center', width: `${PCT_CHANGE_WIDTH_PCT / 3}%`, minWidth: `${PCT_CHANGE_MIN_WIDTH_PX / 3}px` },
      },
      FIFTY_DAY_MA_CHANGE: {
        name: '50-DMA',
        priority: 1,
        colname: 'fifty_day_pct_mva',
        style: { textAlign: 'center', width: `${PCT_CHANGE_WIDTH_PCT / 3}%`, minWidth: `${PCT_CHANGE_MIN_WIDTH_PX / 3}px` },
      },
      TREND: {
        name: 'Trend',
        priority: 1,
        colname: 'trend',
        style: { textAlign: 'center' },
      },
      TIMING: {
        name: 'Timing',
        priority: 1,
        colname: 'timing',
        style: { textAlign: 'center' },
      },
      CUR_TRADING_RANGE: {
        name: 'Current',
        priority: 1,
        colname: 'cur_range',
        style: { textAlign: 'center' },
      },
      // ONE_WEEK_AGE_TRADING_RANGE: '1-Week Ago Trading Range',
    };
    // const prioritySum = Object.values(headers).reduce((total, curr) => total + curr.priority, 0);
    Object.keys(headers).forEach((key) => {
      headers[key] = {
        name: headers[key].name,
        // width: `${((headers[key].priority / (prioritySum + 2)) * 100).toFixed(2)}%`,
        colname: headers[key].colname,
        style: headers[key].style,
      };
    });
    const header = [
      <Table.Header key="pre-header">
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell style={{ 'border-left': 'none' }} />
          <Table.HeaderCell style={{ 'border-left': 'none' }} />
          <Table.HeaderCell style={{ textAlign: 'center' }} colSpan="3">
            % Change
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2" />
          <Table.HeaderCell className="seventh-step" style={{ textAlign: 'center' }} colSpan="7">
            Trading Range
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row className="third-step" key="main-header">
          {Object.values(headers).map((h) => {
            let newDir = 'ASC';
            let sortArr = '';
            if (h.colname === sortCol && sortDir === 'ASC') {
              newDir = 'DESC';
            }
            if (h.colname === sortCol) {
              if (newDir === 'ASC') {
                sortArr = ' ↑';
              } else {
                sortArr = ' ↓';
              }
            }

            let className = '';
            switch (h.colname) {
              case 'trend':
                className = 'forth-step';
                break;
              case 'timing':
                className = 'fifth-step';
                break;
              default:
                break;
            }
            return (
              <Table.HeaderCell
                key={h.colname}
                style={{
                  width: h.width,
                  ...h.style,
                  cursor: 'pointer',
                  color: (sortCol === h.colname ? 'darkblue' : undefined),
                  whiteSpace: 'nowrap',
                }}
                onClick={(handleSortChange ? () => handleSortChange(h.colname, newDir) : undefined)}
                className={className}
              >
                {handleSortChange ? `${h.name}${sortArr}` : h.name}
              </Table.HeaderCell>);
          })}
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="OS"
            colSpan="2"
          >
            OS
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="50"
            colSpan="2"
          >
            50-DMA
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="OB"
            colSpan="2"
          >
            OB
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>];
    const body = [];
    const max = { ytd_pct: null, five_day_pct: null, fifty_day_pct_mva: null };
    const min = { ytd_pct: null, five_day_pct: null, fifty_day_pct_mva: null };
    // sort tickers
    tickers.sort(compFunc(sortCol, sortDir));
    tickers.forEach((ticker) => {
      const trend = ticker.current_trend;
      Object.keys(max).forEach((key) => {
        if (max[key] === null || trend[key] > max[key]) {
          max[key] = trend[key];
        }
        if (min[key] === null || trend[key] < min[key]) {
          min[key] = trend[key];
        }
      });
    });

    tickers.forEach((ticker) => {
      const cells = [];
      if ('TICKERS' in headers) {
        cells.push((
          <Table.Cell>
            <Link to={`/ticker/${ticker.ticker}`}>{ticker.ticker}</Link>
          </Table.Cell>));
      }
      if ('NAME' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.shortname}</Table.Cell>);
      }
      if ('PRICE' in headers) {
        cells.push(<Table.Cell style={{ textAlign: 'center' }} >{parseFloat(ticker.current_candle.close).toFixed(2)}</Table.Cell>);
      }
      const getStyle = (value, trend = 1, change = false) => ({
        backgroundColor: getColor(Colors.green, Colors.yellow, Colors.red, value, trend, change),
        color: 'white',
        fontWeight: 'normal',
        textAlign: 'center',
      });
      if ('YTD_CHANGE' in headers) {
        let ytdColorTrend = 1;
        let t_1to1 = true;
        if (ticker.current_trend.ytd_pct < 0) {
          ytdColorTrend = -1;
        }

        if ((max.ytd_pct > 0 && min.ytd_pct > 0) || (max.ytd_pct < 0 && min.ytd_pct < 0)) {
          t_1to1 = false;
        }

        cells.push((
          <Table.Cell
            style={
              getStyle(
                ytdColorTrend * Math.abs(normalize(
                ytdColorTrend < 0 && t_1to1 ? 0 : max.ytd_pct,
                ytdColorTrend > 0 && t_1to1 ? 0 : min.ytd_pct,
                  ticker.current_trend.ytd_pct,
                )),
                ytdColorTrend,
                )
            }
          >
            {ticker.current_trend.ytd_pct ? ticker.current_trend.ytd_pct.toFixed(2) : '0.00'}
          </Table.Cell>));
      }
      if ('FIVE_DAY_CHANGE' in headers) {
        let fiveColorTrend = 1;
        let t_1to1 = true;
        if (ticker.current_trend.five_day_pct < 0) {
          fiveColorTrend = -1;
        }

        if (
          (max.five_day_pct > 0 && min.five_day_pct > 0) ||
          (max.five_day_pct < 0 && min.five_day_pct < 0)
        ) {
          t_1to1 = false;
        }
        cells.push((
          <Table.Cell
            style={getStyle(
              fiveColorTrend * Math.abs(normalize(
              fiveColorTrend < 0 && t_1to1 ? 0 : max.five_day_pct,
              fiveColorTrend > 0 && t_1to1 ? 0 : min.five_day_pct,
              ticker.current_trend.five_day_pct,
            )),
              fiveColorTrend,
            )}
          >
            {ticker.current_trend.five_day_pct ? ticker.current_trend.five_day_pct.toFixed(2) : 'N/A'}
          </Table.Cell>));
      }
      if ('FIFTY_DAY_MA_CHANGE' in headers) {
        let fiftyColorTrend = 1;
        let t_1to1 = true;
        if (ticker.current_trend.fifty_day_pct_mva < 0) {
          fiftyColorTrend = -1;
        }

        if (
          (max.fifty_day_pct_mva > 0 && min.fifty_day_pct_mva > 0) ||
          (max.fifty_day_pct_mva < 0 && min.fifty_day_pct_mva < 0)
        ) {
          t_1to1 = false;
        }
        cells.push((
          <Table.Cell
            style={getStyle(
              fiftyColorTrend * Math.abs(normalize(
              fiftyColorTrend < 0 && t_1to1 ? 0 : max.fifty_day_pct_mva,
              fiftyColorTrend > 0 && t_1to1 ? 0 : min.fifty_day_pct_mva,
              ticker.current_trend.fifty_day_pct_mva,
            )),
              fiftyColorTrend,
            )}
          >
            {ticker.current_trend.fifty_day_pct_mva ? ticker.current_trend.fifty_day_pct_mva.toFixed(2) : 'N/A'}
          </Table.Cell>));
      }
      const { trend } = ticker.current_trend;
      if ('TREND' in headers) {
        if (trend === 1) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} positive><GreenText><Icon name="chevron circle up" style={{ display: 'inline' }} /></GreenText></Table.Cell>);
        } else if (trend === 0) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} warning><YellowText><Icon name="chevron circle right" style={{ display: 'inline' }} /></YellowText></Table.Cell>);
        } else if (trend === -1) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} negative><RedText><Icon name="chevron circle down" style={{ display: 'inline' }} /></RedText></Table.Cell>);
        } else {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} warning><YellowText><Icon name="chevron circle right" style={{ display: 'inline' }} /></YellowText></Table.Cell>);
        }
      }
      const { timing } = ticker.current_trend;
      if ('TIMING' in headers) {
        if (timing === 0) {
          cells.push(<Table.Cell style={{ ...getStyle(-1, 1, true), textAlign: 'center' }}> <b>Bad</b> </Table.Cell>);
        } else if (timing === 1) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} ><RedText>Poor</RedText></Table.Cell>);
        } else if (timing === 2) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} ><YellowText>Neutral</YellowText></Table.Cell>);
        } else if (timing === 3) {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} ><GreenText>Good</GreenText></Table.Cell>);
        } else if (timing === 4) {
          cells.push(<Table.Cell style={{ ...getStyle(1, 1, true), textAlign: 'center' }}><b>Perfect</b></Table.Cell>);
        } else {
          cells.push(<Table.Cell style={{ textAlign: 'center' }} ><YellowText>Neutral</YellowText></Table.Cell>);
        }
      }
      const sdToPercent = (sd) => {
        if (sd < -3) {
          return 0;
        } else if (sd > 3) {
          return 100;
        }
        return ((sd + 3) / 6) * 100;
      };
      const curRange = ticker.current_trend.cur_range;
      const lastRange = ticker.current_trend.last_range;
      if ('CUR_TRADING_RANGE' in headers) {
        if (curRange > 2) {
          cells.push((
            <Table.Cell style={{
              ...getStyle(-1, 1, true),
              textAlign: 'center',
              'white-space': 'nowrap',
              'max-width': '100%',
            }}
            >
              <b>Extreme OB</b>
            </Table.Cell>));
        } else if (curRange > 1) {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><RedText>Overbought</RedText></Table.Cell>));
        } else if (curRange < -2) {
          cells.push((<Table.Cell style={{ ...getStyle(1, 1, true), textAlign: 'center' }}><b>Extreme OS</b></Table.Cell>));
        } else if (curRange < -1) {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><GreenText>Oversold</GreenText></Table.Cell>));
        } else {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><YellowText>Neutral</YellowText></Table.Cell>));
        }
      }
      if ('ONE_WEEK_AGE_TRADING_RANGE' in headers) {
        if (lastRange > 2) {
          cells.push((<Table.Cell style={getStyle(-1, 1, true)}><b>Extreme OB</b></Table.Cell>));
        } else if (lastRange > 1) {
          cells.push((<Table.Cell><RedText>Overbought</RedText></Table.Cell>));
        } else if (lastRange < -2) {
          cells.push((<Table.Cell style={getStyle(1, 1, true)}><b>Extreme OS</b></Table.Cell>));
        } else if (lastRange < -1) {
          cells.push((<Table.Cell><GreenText>Oversold</GreenText></Table.Cell>));
        } else {
          cells.push((<Table.Cell><YellowText>Neutral</YellowText></Table.Cell>));
        }
      }

      let style = {};
      let myClass = '';
      if (curRange > lastRange) {
        myClass = 'higher';
        style = {
          left: `${sdToPercent(lastRange).toFixed(2)}%`,
          width: `${(sdToPercent(curRange) - sdToPercent(lastRange)).toFixed(2)}%`,
          zIndex: 1,
        };
      }
      if (curRange < lastRange) {
        myClass = 'lower';
        style = {
          left: `${sdToPercent(curRange).toFixed(2)}%`,
          width: `${(sdToPercent(lastRange) - sdToPercent(curRange)).toFixed(2)}%`,
          zIndex: 1,
        };
      }
      cells.push((
        <Table.Cell
          colSpan="6"
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
            width: `${TRADING_RANGE_WIDTH_PCT}%`,
            minWidth: `${TRADING_RANGE_MIN_WIDTH_PX}px`,
            borderLeftColor: 'rgb(34,168,108)',
          }}
          className="traderange"
        >
          <div style={style} className={`line ${myClass}`} />
        </Table.Cell>));
      body.push((
        <Table.Row className="sixth-step">
          {cells}
        </Table.Row>));
    });

    return (
      <div className="table-scroll" style={{ margin: '0px' }}>
        <Table
          celled
          unstackable
          style={{ fontSize: '20px' }}
          className="left-fixed"
        >
          {header}
          <Table.Body>
            {body}
          </Table.Body>
        </Table>
      </div>
    );
  }
  return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
}

TrendAnalyzer.defaultProps = {
  sortCol: 'ticker',
  sortDir: 'ASC',
  handleSortChange: undefined,
};

TrendAnalyzer.propTypes = {
  tickers: PropTypes.array.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  handleSortChange: PropTypes.func,
};

export default TrendAnalyzer;
