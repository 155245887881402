export const avg = arr => arr.reduce((acc, curr) => acc + parseFloat(curr), 0) / arr.length;

export const std = (arr, average) => {
  let precalcAvg = average;
  if (precalcAvg === undefined) {
    precalcAvg = avg(arr);
  }
  const squareDiffs = arr.map((val) => { const diff = (val - precalcAvg); return diff * diff; });
  return Math.sqrt(squareDiffs.reduce((acc, curr) => acc + curr, 0) /
            squareDiffs.length);
};

export const med = (arr) => {
  arr.sort((a, b) => parseFloat(a) - parseFloat(b));
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2) {
    return parseFloat(arr[half]);
  }
  return (parseFloat(arr[half - 1]) + parseFloat(arr[half])) / 2.0;
};

// // Z Score to percentile helper
export const zScoreToPercentile = (z) => {
  // z == number of standard deviations from the mean

  // if z is greater than 6.5 standard deviations from the mean the
  // number of significant digits will be outside of a reasonable range

  if (z < -6.5) {
    return 0.0;
  }

  if (z > 6.5) {
    return 1.0;
  }

  let factK = 1;
  let sum = 0;
  let term = 1;
  let k = 0;
  const loopStop = Math.exp(-23);

  while (Math.abs(term) > loopStop) {
    term = ((((0.3989422804 * (((-1) ** k) * (z ** (k)))) /
      ((2 * k) + 1)) / (2 ** (k))) * (z ** (k + 1))) / factK;
    sum += term;
    k += 1;
    factK *= k;
  }

  sum += 0.5;

  return sum;
};
