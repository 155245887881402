import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { navigateToPage } from '../../../actions/search';
import SmallChart from '../interactive/smallChart';
import CardBase from '../../containers/CardBase';
import Bull from '../../../img/icons/bull.inline.svg';
import Bear from '../../../img/icons/bear.inline.svg';
import { setTickerCalls } from '../../../actions/user';

function ChartScannerCard(props) {
  const {
    chartSize,
    ticker,
    history,
    tickerCalls,
    // navToPage,
    setCalls,
    style,
    range,
  } = props;
  let classes = '';
  let bullNum = 1;
  let bearNum = 2;
  if (tickerCalls.byId) {
    if (tickerCalls.byId.Bullish && tickerCalls.byId.Bullish.includes(ticker.ticker)) {
      classes = 'bullish';
      bullNum = 0;
    } else if (tickerCalls.byId.Bearish && tickerCalls.byId.Bearish.includes(ticker.ticker)) {
      classes = 'bearish';
      bearNum = 0;
    }
  }
  return (
    <CardBase style={style} className={`cs-chart ${classes} forth-step`}>
      <Card.Content>
        {/* <i className="right floated like icon" />
        <i className="right floated star icon" /> */}
        <div className="fifth-step seventh-step eighth-step" style={{ float: 'right', display: 'inline-block' }}>
          <Bull onClick={() => setCalls({ [ticker.ticker]: bullNum })} className={`${classes} bullish-icon right floated`} height="20px" style={{ margin: '0px 2px' }} />
          <Bear onClick={() => setCalls({ [ticker.ticker]: bearNum })} className={`${classes} bearish-icon right floated`} height="20px" style={{ margin: '0px 2px' }} />
        </div>
        <Card.Header style={{ fontSize: '1.2em' }}>
          {ticker.ticker}
          {'\u00A0'}
          <span style={{ fontSize: '.73em', color: '#999', fontWeight: 'normal' }}>
            ${
              ticker.current_candle &&
              ticker.current_candle.close &&
              ticker.current_candle.close.toFixed(2)
            }
          </span>
        </Card.Header>
        <Card.Meta style={{ fontSize: '.8em' }}><Link style={{ color: '#2f9ced' }} to={`/ticker/${ticker.ticker}`}>{ticker.company}</Link></Card.Meta>
        <SmallChart
          initialData={history}
          // width={chartSize && chartSize.width}
          height={chartSize && chartSize.height}
          range={range}
        />
      </Card.Content>
    </CardBase>
  );
}

ChartScannerCard.defaultProps = {
  chartSize: { width: 330, height: 250 },
  style: undefined,
  range: 0,
};

ChartScannerCard.propTypes = {
  chartSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  style: PropTypes.object,
  ticker: PropTypes.object.isRequired,
  tickerCalls: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
  // navToPage: PropTypes.func.isRequired,
  setCalls: PropTypes.func.isRequired,
  range: PropTypes.number,
};

const mapStateToProps = state => ({
  tickerCalls: state.user.tickerCalls,
});

const mapDispatchToProps = dispatch => ({
  navToPage: url => dispatch(navigateToPage(url)),
  setCalls: tickerObj => dispatch(setTickerCalls(tickerObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartScannerCard);
