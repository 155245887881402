import ChartCard from './ChartCard';
import EarningsCard from './EarningsCard';
import TrendAnalyzerCard from './TrendAnalyzerCard';
import PostCard from './PostCard';
import ScreensCard from './ScreensCard';
import SummaryCard from './SummaryCard';
import EarningsGaugesCard from './earningGaugesCard';

export const Chart = ChartCard;
export const Earnings = EarningsCard;
export const TrendAnalyzer = TrendAnalyzerCard;
export const Post = PostCard;
export const Screens = ScreensCard;
export const Summary = SummaryCard;
export const EarningsGauges = EarningsGaugesCard;
