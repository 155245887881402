import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Container, Image, Dropdown, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SearchBar from './SearchBar';
import { renderSearch, toggleMenu } from '../../actions';
import { navigateToPage, toggleSearch } from '../../actions/search';
import { types } from '../../actions/search/search_commands';
import logo from '../../img/logo_white_icon.png';
import logoText from '../../img/logo_white_text.png';
import SignUp from '../presentational/signup';
// import profilePic from '../../img/profile_pic_placeholder.png';
/**
  *@ignore
  */
// const searchStyle = {
//   width: 'calc(70% - 80px)',
// };
// const userName = 'BespokUpgradeConnectede';
const flexAdjust = {
  flexFlow: 'column',
  width: '60px',
};
const fontSize = {
  fontSize: '11px',
};
const trigger = (
  <React.Fragment>
    <Icon.Group>
      <Icon style={{ marginRight: '0px' }} name="circle" size="big" />
      <Icon color="teal" name="cog" />
    </Icon.Group>
    <p style={{ ...fontSize, margin: '0' }}>Account</p>
  </React.Fragment>
);

const options = [
  {
    key: 'user', text: 'Account', icon: 'user', as: Link, to: '/account/',
  },
  {
    key: 'settings', text: 'Settings', icon: 'settings', as: Link, to: '/account/',
  },
  {
    key: 'sign-out', text: 'Sign Out', icon: 'sign out', as: 'a', href: 'https://www.bespokepremium.com/logout-2/',
  },
];

// function handleMessage(input) {
//   console.log(input.target.value);
// }

// <Input fluid className="icon" icon="search" placegholder="Search..." onChange={handleMessage} />

/**
  *Top Navigation bar menu
  */
class TopMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.searchInput = null;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      this.props.searchActive !== prevProps.searchActive &&
      this.props.searchActive
    ) {
      this.searchInput.focus();
    }
  }

  render() {
    const {
      // toggleMenuHandler,
      searchBarInFocus,
      searchActive,
      handleToggleSearch,
      pageNav,
      access,
    } = this.props;
    // const { searchActive } = this.props.searchActive;
    const classOption = searchBarInFocus ? 'priority-to-search' : '';
    return (
      <Menu className="overlay-1 hide-on-print" fixed="top" secondary inverted style={{ maxWidth: '100%' }}>
        <style>
          {'.ui.button.login { border-radius: 4px !important; align-self: center; height: 30px; white-space: nowrap;'}
          {'background-color: #ffdd59 !important; color: black !important; padding: 8px 12px !important; font-weight: bold !important;}'}
          {'.ui.button.login:hover {background-color:#ddb228 !important;color:white !important;}'}
        </style>
        <Container style={{ justifyContent: 'right' }} className={`topMenu  ${searchActive ? 'show' : ''}`}>
          <Menu.Item className={`left ${classOption}`} style={{ height: '60px' }} onClick={() => pageNav('/')}>
            <Image src={logo} style={{ maxHeight: '45px' }} />
            <Image className="logo-text" src={logoText} style={{ maxHeight: '45px' }} />
          </Menu.Item>
          {
            (access === null) ?
              (
                <SignUp
                  trigger={(
                    <Menu.Item className={`search-bar-container ${classOption}`}>
                      <SearchBar
                        input={{ ref: (el) => { this.searchInput = el; } }}
                        // onBlur={() => this.setSearch(false)}
                        onBlur={() => {
                          setTimeout(() => {
                            // this.setState({ searchActive: false });
                            handleToggleSearch(false);
                          }, 100);
                        }}
                      />
                    </Menu.Item>
                  )}
                />
              ) :
                (
                  <Menu.Item className={`search-bar-container ${classOption}`}>
                    <SearchBar
                      input={{ ref: (el) => { this.searchInput = el; } }}
                      // onBlur={() => this.setSearch(false)}
                      onBlur={() => {
                        setTimeout(() => {
                          // this.setState({ searchActive: false });
                          handleToggleSearch(false);
                        }, 100);
                      }}
                    />
                  </Menu.Item>
                )
            }
          <Menu.Item className={`${classOption} search-toggle`} position="right" style={flexAdjust} onClick={() => handleToggleSearch(true)}>
            <Icon.Group>
              <Icon style={{ marginRight: '0px' }} name="circle" size="big" />
              <Icon color="teal" name="search" />
            </Icon.Group>
            <p style={fontSize}>Search</p>
          </Menu.Item>

          <Menu.Item className={classOption} position="right" style={flexAdjust} onClick={() => pageNav('/research')}>
            <Icon.Group>
              <Icon style={{ marginRight: '0px' }} name="circle" size="big" />
              <Icon color="teal" name="book" />
            </Icon.Group>
            <p style={fontSize}>Research</p>
          </Menu.Item>

          <Menu.Item className={classOption} position="right" style={flexAdjust} onClick={() => pageNav('/tools')}>
            <Icon.Group>
              <Icon style={{ marginRight: '0px' }} name="circle" size="big" />
              <Icon color="teal" name="wrench" />
            </Icon.Group>
            <p style={fontSize}>Tools</p>
          </Menu.Item>

          <Menu.Item className={classOption} position="right" style={flexAdjust} onClick={() => pageNav('/portfolio')}>
            <Icon.Group>
              <Icon style={{ marginRight: '0px' }} name="circle" size="big" />
              <Icon color="teal" name="folder open" />
            </Icon.Group>
            <p style={fontSize}>Portfolios</p>
          </Menu.Item>
          <Button
            className={`login ${classOption}`}
            position="right"
            onClick={() => { window.location = 'https://www.bespokepremium.com/login/'; }}
            style={(access === null) ? { display: 'block', marginLeft: '10px' } : { display: 'none' }}
          >
            Log In
          </Button>
          <Dropdown
            className={classOption}
            style={(access === null) ? { display: 'none' } : flexAdjust}
            trigger={trigger}
            options={options}
            item
            pointing="top right"
            icon={null}
            ref={(el) => {
              if (el) {
                const e = el;
                e.ref.style.setProperty('margin-left', '0px', 'important');
              }
            }}
          />
        </Container>
      </Menu>
    );
  }
}

TopMenu.propTypes = {
  // toggleMenuHandler: PropTypes.func.isRequired,
  pageNav: PropTypes.func.isRequired,
  searchBarInFocus: PropTypes.bool.isRequired,
  searchActive: PropTypes.bool.isRequired,
  handleToggleSearch: PropTypes.func.isRequired,
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  searchBarInFocus: state.search.inFocus,
  searchActive: state.search.toggleSearch,
  access: state.user.access,
});

/**
 * @todo: implement toggleMenuHandler
 * @param {} dispatch
 */
const mapDispatchToProps = dispatch => ({
  homeRedirectHandler: () => dispatch(renderSearch({ type: types.HOME })),
  toggleMenuHandler: () => dispatch(toggleMenu()),
  pageNav: url => dispatch(navigateToPage(url)),
  handleToggleSearch: status => dispatch(toggleSearch(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
