import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

export default (ChartComponent) => {
  class ResponsiveChart extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        containerWidth: null,
      };

      this.fitParentContainer = throttle(this.fitParentContainer, 16).bind(this);
    }

    componentDidMount() {
      this.fitParentContainer();
      window.addEventListener('resize', this.fitParentContainer);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer);
    }

    fitParentContainer() {
      const { containerWidth } = this.state;

      const currentContainerWidth = this.chartContainer
        .getBoundingClientRect().width;

      const shouldResize = containerWidth !== currentContainerWidth;

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
        });
      }
    }

    renderChart() {
      const parentWidth = this.state.containerWidth;

      return (
        <ChartComponent {...this.props} parentWidth={parentWidth} />
      );
    }

    render() {
      const { containerWidth, containerHeight } = this.state;
      const shouldRenderChart = containerWidth !== null && containerHeight !== null;

      return (
        <div
          ref={(el) => { this.chartContainer = el; }}
          className="Responsive-wrapper"
          style={this.props.centered ?
            {
              display: 'flex',
              justifyContent: 'center',
              ...this.props.style,
            } : this.props.style
          }
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      );
    }
  }

  ResponsiveChart.defaultProps = {
    centered: false,
    style: undefined,
  };

  ResponsiveChart.propTypes = {
    centered: PropTypes.bool,
    style: PropTypes.object,
  };

  return ResponsiveChart;
};
