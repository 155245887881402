import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { getColoredCellStyle as getStyle } from '../presentational/interactive/colorHelpers';
import { GreenText, YellowText, RedText } from '../presentational/ColoredText';
import { extractPrettyDateFromDatetime as extractDateText } from '../presentational/TextFormats';
import * as api from '../../api';
import { getList } from '../../actions/data/tickers';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';

// const extractDateText = (datetime) => {
//   const re = /(\d{4})-(\d{2})-(\d{2})/;
//   const [, y, m, d] = re.exec(datetime);
//   return `${parseInt(m, 10)}/${parseInt(d, 10)}/${y.substr(-2)}`;
// };

/**
 * goes through an array of objects
 * returns object with properties as keys with 2-item arrays describing [min, max] values for corresponding prop
 */
// const getPropertyExtents = (data, properties) => {
//   const out = {};
//   properties.forEach((prop) => { out[prop] = [0, 0]; });
//   data.forEach(item =>
//     properties.forEach((prop) => {
//       out[prop] = [Math.min(out[prop][0], item[prop]), Math.max(out[prop][1], item[prop])];
//     }));
//   return out;
// };

const colorText = (text) => {
  switch (text) {
    case 'Raised':
      return <GreenText>{text}</GreenText>;
    case 'Lowered':
      return <RedText>{text}</RedText>;
    case 'Inline':
      return <YellowText>{text}</YellowText>;
    default:
      return text;
  }
};

const colorNumber = (number) => {
  if (number > 0) {
    return <GreenText>{number}</GreenText>;
  } else if (number < 0) {
    return <RedText>{number}</RedText>;
  }
  return number;
};

const colorTextEPS = (t) => {
  if (t === 'Beat') {
    return <GreenText>{t}</GreenText>;
  } else if (t === 'Missed') {
    return <RedText>{t}</RedText>;
  }
  return t;
};

const actvsestFormat = (x) => {
  if (!Number.isNaN(Number(x)) && x !== null) {
    if (parseFloat(x) > 0) {
      return 'Beat';
    }

    if (parseFloat(x) < 0) {
      return 'Missed';
    }

    return 'Inline';
  }
  return 'N/A';
};

class TriplePlays extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      // extents: {},
    };
  }

  componentDidMount() {
    this.props.getList();
    api.tools.getRecentTriplePlays()
      .then(data => this.setState({
        data,
        // extents: getPropertyExtents(data, [
        //   'eps_actual',
        //   'eps_estimate',
        //   'eps_avse',
        //   'rev_actual',
        //   'rev_estimate',
        //   'rev_avse',
        //   'one_day_pct',
        // ]),
      }));
  }

  render() {
    let responsiveStyle = '.table-scroll { margin:1em 0; }';
    responsiveStyle += '@media screen and (max-width: 768px) {';
    responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow:scroll; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
    responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
    responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
    // @todo - needs to be autocomputed based on height of first row
    responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
    responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
    responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
    responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
    // Remove left border from second col
    responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
    // Row hover z-index;
    responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
    responsiveStyle += '}';
    const { data } = this.state;
    const { tickers } = this.props;
    const steps = [
      {
        selector: '.first-step',
        content: 'A stock reports an Earnings Triple Play when it beats consensus analyst EPS estimates, beats revenue estimates, and raises forward guidance.',
      },
      {
        selector: '.second-step',
        content: 'On this page you\'ll find the 100 most recent Earnings Triple Plays along with relevant info from each stock\'s earnings report.',
      },
      {
        selector: '.third-step',
        content: 'This tool is available to Bespoke Premium and Bespoke Institutional members.',
      },
    ];
    const tour = (
      <Touring
        cookieName="tripleplayTouring"
        steps={steps}
      />);
    const preheader = (
      <Table.Row key="pre-header">
        <Table.HeaderCell colSpan={5} />
        <Table.HeaderCell style={{ textAlign: 'center' }} colSpan={2}>
          Actual vs Estimates
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={1} />
        <Table.HeaderCell style={{ textAlign: 'center' }} colSpan={3}>
          1-Day Price Reaction (%)
        </Table.HeaderCell>
        <Table.HeaderCell colSpan={3} />
      </Table.Row>);
    const mainheader = (
      <Table.Row key="main-header">
        <Table.HeaderCell>Stock</Table.HeaderCell>
        <Table.HeaderCell >Company</Table.HeaderCell>
        <Table.HeaderCell >Sector</Table.HeaderCell>
        <Table.HeaderCell >Report Date</Table.HeaderCell>
        <Table.HeaderCell >Time</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>EPS</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>Revenue</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>Guidance</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>Gap</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>Open to Close</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>Full 1-Day</Table.HeaderCell>
        <Table.HeaderCell style={{ textAlign: 'center' }}>S&amp;P 500</Table.HeaderCell>
      </Table.Row>
    );
    const header = <Table.Header>{preheader}{mainheader}</Table.Header>;
    const bodyrows = data.map(row => (
      <Table.Row>
        <Table.Cell>{tickers.list.map(x => x.ticker).includes(row.stock) ? <Link to={`/ticker/${row.stock}`}>{row.stock}</Link> : row.stock }</Table.Cell>
        <Table.Cell>{row.company}</Table.Cell>
        <Table.Cell>{row.sector}</Table.Cell>
        <Table.Cell>{extractDateText(row.date)}</Table.Cell>
        <Table.Cell>{row.time_of_day}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorTextEPS(actvsestFormat(row.rev_actual))}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorTextEPS(actvsestFormat(row.rev_avse))}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorText(row.guidance)}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorNumber(parseFloat(row.gap).toFixed(2))}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorNumber(parseFloat(row.open_to_close).toFixed(2))}</Table.Cell>
        <Table.Cell style={{ textAlign: 'center' }}>{colorNumber(parseFloat(row.one_day_pct).toFixed(2))}</Table.Cell>
        {/* <Table.Cell
          style={{ fontWeight: 'bold', ...getStyle(extents.one_day_pct[1], extents.one_day_pct[0], row.one_day_pct) }}
        >
          {row.one_day_pct}
        </Table.Cell> */}
        <Table.Cell>{row.sp_500 ? <Link to={`/ticker/${row.sp_500}`}>{row.sp_500}</Link> : ''}</Table.Cell>
      </Table.Row>
    ));
    return (
      <React.Fragment>
        <style>{responsiveStyle}</style>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <h1 className="first-step">Earnings Triple Plays {tour}</h1>
        <div className="titleBar">100 Most Recent Earnings Triple Plays</div>
        <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }}>
          <Table
            striped
            celled
            unstackable
            style={{ fontSize: '20px', scrollX: 'true', height: '100%' }}
            className="left-fixed second-step third-step"
          >
            {header}
            <Table.Body>
              {bodyrows}
            </Table.Body>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

TriplePlays.propTypes = {
  tickers: PropTypes.object.isRequired,
  getList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tickers: state.data.tickerList,
});

const mapDispatchToProps = dispatch => ({
  getList: () => dispatch(getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TriplePlays);
