import React from 'react';
import PropTypes from 'prop-types';
import { interpolateLab } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';
import throttle from 'lodash/throttle';
import Colors from './interactive/colors';

class SvgGauge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // value: props.value,
      width: null,
      height: null,
    };

    this.thiccFactor = 2.3;

    this.fitSvg = throttle(this.fitSvg, 16).bind(this);
  }

  componentDidMount() {
    this.fitSvg();
    window.addEventListener('resize', this.fitSvg);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.fitSvg);
  }

  fitSvg() {
    const {
      width,
      height,
    } = this.state;

    const boundingRect = this.svg
      .getBoundingClientRect();

    const shouldResize = width !== boundingRect.width ||
      height !== boundingRect.height;

    if (shouldResize) {
      this.setState({
        width: boundingRect.width,
        height: boundingRect.height,
      });
    }
    // set state to bounding box
  }

  render() {
    const {
      min,
      max,
      colors,
      title,
      value,
      tickMark,
      titleSize,
    } = this.props;

    const titlePadding = 5;

    const {
      width,
      height,
    } = this.state;

    const rWidth = width / (2 + (1 / this.thiccFactor));
    const rHeight = (height - ((2 * titlePadding) + titleSize)) /
      (1 + (1 / (2 * this.thiccFactor)));

    const r = Math.min(rWidth, rHeight);

    const tickSpot = Math.floor(Math.PI * r * (1 - ((tickMark - min) / (max - min))));
    const thickness = r / this.thiccFactor;

    const colorScale = scaleLinear()
      .domain([min, Math.floor((max + min) / 2), max])
      .range(colors)
      .interpolate(interpolateLab);

    return (
      <svg
        ref={(el) => { this.svg = el; }}
        width="100%"
        height="100%"
        style={{
          transform: 'rotateX(180deg)',
        }}
        // onClick={() => this.setState({ value: Math.floor((Math.random() * (max - min)) + min) })}
      >
        <g style={{
            transform: `translateY(-${(height / 2) - 1}px)`,
          }}
        >
          <text
            textAnchor="middle"
            y={
              -Math.floor(height / 2)
              - Math.floor(r - thickness)
              - Math.floor(r - thickness)
              - titleSize
              - titlePadding
            }
            x={width / 2}
            style={{
              fontWeight: 'bold',
              fontSize: `${titleSize}px`,
              transform: 'rotateX(180deg)',
            }}
          >
            {title}
          </text>
          { r > 0 ?
            (
              <React.Fragment>
                <circle
                  r={r}
                  cx="50%"
                  cy="50%"
                  stroke={colorScale(value)}
                  strokeWidth={`${thickness}px`}
                  fill="none"
                  strokeDasharray={`${Math.floor(Math.PI * r)}, ${Math.floor(2 * Math.PI * r)}`}
                  style={{
                    transition: 'stroke 1s ease',
                    // transform: `translateY(-${r - (thickness / 2)}px)`,
                  }}
                />
                <circle
                  r={r}
                  cx="50%"
                  cy="50%"
                  stroke="#e9e9e9"
                  strokeWidth={`${thickness + 1}px`}
                  fill="none"
                  strokeDasharray={`${Math.floor(Math.PI * r * (1 - ((value - min) / (max - min))))}, ${Math.floor(2 * Math.PI * r)}`}
                  style={{
                    transition: 'stroke-dasharray 1s ease',
                  }}
                />
                { tickMark ? (
                  <circle
                    r={r}
                    cx="50%"
                    cy="50%"
                    stroke="rgba(0,0,0,0.6)"
                    strokeWidth={`${thickness - 1}px`}
                    fill="none"
                    strokeDasharray={`2, ${Math.floor(2 * Math.PI * r)}`}
                    strokeDashoffset={-tickSpot}
                    style={{
                      transition: 'stroke-dasharray 1s ease, stroke-dashoffset 1s ease',
                    }}
                  />) : null}
              </React.Fragment>
            ) : null}
          <text
            x={Math.floor(width / 2)}
            y={-Math.floor(height / 2)}
            color="black"
            textAnchor="middle"
            style={{
              position: 'relative',
              fontWeight: 'bold',
              fontSize: `${Math.max(18, Math.floor(r - thickness) - 7)}px`,
              transform: 'rotateX(180deg)',
            }}
            className="third-step forth-step"
          >
            {value}
          </text>
          { tickMark ? (
            <text
              x={Math.floor(width / 2)}
              y={-Math.floor(height / 2) - Math.floor(r - thickness)}
              color="black"
              textAnchor="middle"
              style={{
                position: 'relative',
                fontWeight: 'bold',
                fontSize: '10px',
                transform: 'rotateX(180deg)',
              }}
            >
              {`Avg ${Math.round(tickMark)}`}
            </text>
            ) : null}
        </g>
      </svg>
    );
  }
}

SvgGauge.defaultProps = {
  colors: [Colors.red, Colors.yellow, Colors.green],
  min: 0,
  max: 100,
  value: 50,
  tickMark: undefined,
  // thickness: 30,
  title: 'Title',
  titleSize: 13,
};

SvgGauge.propTypes = {
  colors: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  tickMark: PropTypes.number,
  // thickness: PropTypes.number,
  title: PropTypes.string,
  titleSize: PropTypes.number,
};

export default SvgGauge;
