/* Error handling defined here */
const checkError = (res, json) => {
  if (res.status >= 400 || json.error !== undefined) {
    if (json.error) {
      throw new Error(json.error);
    } else {
      throw new Error('Server error.');
    }
  } else {
    return json;
  }
};

export const mainPath = `${process.env.DOMAIN_ROOT}${process.env.URL_PREFIX}`;
// export const mainPath = 'https://www.bespokepremium.com/interactive';

export const encodeArgs = (array, paramName) => array.reduce((ret, x) => `${ret}&${paramName}[]=${x}`, '').slice(1);
export const executeQuery = (queryString, method = 'GET', body = undefined) => fetch(queryString, {
  method,
  body: body ? JSON.stringify(body) : undefined,
  headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' },
  credentials: 'include',
  withCredentials: 'true',
}).then(res => Promise.all([res, res.json()])).then(([res, json]) => checkError(res, json));
