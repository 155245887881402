import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Dimmer, Loader, Icon, Grid } from 'semantic-ui-react';
import { isBrowser } from 'react-device-detect';
import CardBase from '../../containers/CardBase';
import EarningsGauges from '../interactive/earningsExplorer/earningsGauges';
import LineChart from '../charts/LineChart';

const EarningGaugesCard = (props) => {
  const { ticker, isLoading, data } = props;
  if (ticker && !isLoading) {
    let link = null;
    link = (
      <Link
        to={(`/earnings-explorer/search/${ticker && ticker.ticker}`)}
        style={{ color: 'white' }}
      >
        <Icon link name="external alternate" style={{ float: 'right', marginTop: '0px' }} />
      </Link>);

    return (
      <CardBase
        style={{ minHeight: '400px' }}
        // className={`chart ${classes}`}
        isLoading={isLoading}
        className="fifth-step"
      >
        <Card.Content>
          <div className="titleBar" >
            {ticker.company} (${ticker.ticker}) Historical Earnings Snapshot
            {isBrowser ? link : ''}
          </div>
          <br />
          <div width="100%">
            <Grid stackable>
              <EarningsGauges />
            </Grid>
            <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '3rem 0 0 0' }}>
              EPS <span style={{ color: 'red' }}>Actual</span> and <span style={{ color: 'black' }} >Estimates</span> ($ / Share)
            </h1>
            <LineChart
              data={data.map(x => ({
                label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1'),
                value: [parseFloat(x.eps_actual), parseFloat(x.eps_estimate)],
              }))}
              multi
              colors={['red', 'black']}
              size={{ width: 300, height: 165 }}
              margins={{
                top: 10,
                right: 20,
                bottom: 30,
                left: 60,
              }}
              timeFormatter="%-m/%y"
            />
            <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '2rem 0 0 0' }}>
              Sales <span style={{ color: 'red' }}>Actual</span> and <span style={{ color: 'black' }} >Estimates</span> ($, MM)
            </h1>
            <LineChart
              data={data.map(x => ({
                label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1'),
                value: [parseFloat(x.rev_actual), parseFloat(x.rev_estimate)],
              }))}
              multi
              colors={['red', 'black']}
              size={{ width: 300, height: 165 }}
              margins={{
                top: 10,
                right: 20,
                bottom: 30,
                left: 60,
              }}
              className="ecalBarChart"
              timeFormatter="%-m/%y"
            />
          </div>
          {/* {link} */}
        </Card.Content>
      </CardBase>);
  }
  return (
    <CardBase style={{ minHeight: '400px' }}>
      <Card.Content>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Card.Content>
    </CardBase>);
};


EarningGaugesCard.defaultProps = {
  // expandViewHandler: () => null,
  isLoading: false, /* TODO: implement load functionality */
};

EarningGaugesCard.propTypes = {
  ticker: PropTypes.object.isRequired,
  // expandViewHandler: PropTypes.any,
  // id: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  data: Object.isRequired,
  // handleEESearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ticker: state.content.ticker.ticker,
  data: state.view.earningsExplorer.chartData,
});

export default connect(mapStateToProps)(EarningGaugesCard);
