import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { editModelPortfolio } from '../../../../actions/data/tools';

class EditModelPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      cash: props.cash,
      starting: props.starting,
      sp500Starting: props.sp500Starting,
      startingYTD: props.startingYTD,
      sp500StartingYTD: props.sp500StartingYTD,
    };
  }

  handleChange = (e, { name, value }) => {
    console.log(e);
    this.setState({
      [name]: value,
      error: false,
      errorMessage: '',
    });
  };

  handleSubmit = () =>
    this.props.editModelPortfolioAction({
      id: this.props.id,
      name: this.state.name,
      cash: this.state.cash,
      starting: this.state.starting,
      sp500Starting: this.state.sp500Starting,
      startingYTD: this.state.startingYTD,
      sp500StartingYTD: this.state.sp500StartingYTD,
    })
      .then(result => this.props.submitAction(result))
      .catch(e => this.setState({ error: true, errorMessage: e.message }));

  render() {
    const {
      name,
      cash,
      error,
      errorMessage,
      starting,
      sp500Starting,
      startingYTD,
      sp500StartingYTD,
    } = this.state;
    return (
      <Form
        error={error}
        onSubmit={this.handleSubmit}
        inverted
        style={{ width: '320px' }}
      >
        <Form.Input
          error={error}
          label="Name"
          placeholder="Create a new model portfolio..."
          name="name"
          value={name}
          onChange={this.handleChange}
        />
        <Form.Input
          error={error}
          label="Cash"
          placeholder="0.00"
          name="cash"
          value={cash}
          onChange={this.handleChange}
        />
        <Form.Group>
          <Form.Input
            error={error}
            label="Starting Value"
            placeholder="0.00"
            name="starting"
            value={starting}
            onChange={this.handleChange}
          />
          <Form.Input
            error={error}
            label="S&P Starting Value"
            placeholder="0.00"
            name="sp500Starting"
            value={sp500Starting}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            error={error}
            label="Starting Value YTD"
            placeholder="0.00"
            name="startingYTD"
            value={startingYTD}
            onChange={this.handleChange}
          />
          <Form.Input
            error={error}
            label="S&P Starting Value YTD"
            placeholder="0.00"
            name="sp500StartingYTD"
            value={sp500StartingYTD}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Button content="Edit" />
        <Message
          error
          // header="Error"
          content={errorMessage}
        />
      </Form>);
  }
}

EditModelPortfolio.defaultProps = {
  submitAction: () => null, // to pass in modal close from addPortfolioModal
};

EditModelPortfolio.propTypes = {
  editModelPortfolioAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  cash: PropTypes.string.isRequired,
  starting: PropTypes.string.isRequired,
  sp500Starting: PropTypes.string.isRequired,
  startingYTD: PropTypes.string.isRequired,
  sp500StartingYTD: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  editModelPortfolioAction: body => dispatch(editModelPortfolio(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModelPortfolio);
