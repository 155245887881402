import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Menu, Card, Grid, Label, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
// import * as Cards from '../presentational/cards';
// import * as api from '../../api';
import { navigateToPage } from '../../../actions/search';
import AddPortfolio from '../../presentational/interactive/customPortfolios/addPortfolio';
import DeleteModelPortfolio from '../../presentational/interactive/admin/deleteModelPortfolio';
import * as api from '../../../api';
import CardBase from '../../containers/CardBase';
// import EditPortfolioModal from '../../presentational/interactive/customPortfolios/editPorfolioModal';
import AddModelPortfolioModal from '../../presentational/interactive/admin/addModelPortfolioModal';
import AddModelItemModal from '../../presentational/interactive/admin/addModelItemModal';
import { getModelPortfolios, getMPDetails } from '../../../actions/data/tools';
import ModelPortfolioDetailTable from '../../presentational/interactive/admin/ModelPortfolioDetailTable';
import EditModelPortfolioModal from '../../presentational/interactive/admin/editModelPortfolioModal';

const parsePath = path => path.split('/').reduce((args, item) => (item === '' ? args : [...args, item]), []);

const sorted = (array) => { array.sort(); return array; };

class ModelPortfolios extends React.Component {
  constructor(props) {
    super(props);

    this.addedModelPortfolioAction = this.addedModelPortfolioAction.bind(this);
    this.updatedModelPortfolioAction = this.updatedModelPortfolioAction.bind(this);
    this.props.getMPortfolios();
  }

  componentDidMount() {
    const { dispatch, portfolios } = this.props;
    // const pfID = parseInt(parsePath(this.props.pathname)[2], 10);
    const { match } = this.props;
    if (match && match.path.includes('copy') && match.params.id) {
      api.user.copyPortfolio(match.params.id)
        .then((result) => {
          this.props.navigateToPage(`/admin/bespoke-baskets/${result.id}`);
          window.location.reload();
        });
    }

    if (!(match && match.params && match.params.id)
      && portfolios && portfolios.byId) {
      if (sorted(Object.keys(portfolios.byId))[0] !== undefined) {
        this.props.navigateToPage(`/admin/bespoke-baskets/${sorted(Object.keys(portfolios.byId))[0]}`);
      }
    } else if (match && match.params && match.params.id) {
      // pfId = parseInt(match && match.params && match.params.id, 10);
      const pfID = parseInt(parsePath(this.props.pathname)[2], 10);
      const portfolio = this.props.portfolios.byId[pfID];
      document.title = 'Bespoke | My Portfolios';
      if (portfolio && portfolio.members) {
        const tickersToGet = [];
        for (let i = 0; i < portfolio.members.length; i += 1) {
          if (this.props.portfolios.byId[portfolio.members[i]] === undefined) {
            tickersToGet.push(portfolio.members[i]);
          }
        }
        if (tickersToGet.length > 0) {
          dispatch(getMPDetails(portfolio.id));
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, portfolios } = this.props;
    const pfID = parseInt(parsePath(this.props.pathname)[2], 10);
    // this.props.getCSData(pfID);
    const portfolio = portfolios.byId[pfID];
    if (prevProps.portfolios !== this.props.portfolios && !portfolio) {
      // Url has changed
      this.props.navigateToPage(`/admin/bespoke-baskets/${sorted(Object.keys(portfolios.byId))[0]}`);
    }
    if (portfolio && portfolio.members) {
      const tickersToGet = [];
      for (let i = 0; i < portfolio.members.length; i += 1) {
        if (this.props.portfolios.byId[portfolio.members[i]] === undefined) {
          tickersToGet.push(portfolio.members[i]);
        }
      }
      if (tickersToGet.length > 0 &&
        (prevProps.portfolios !== this.props.portfolios ||
          this.props.pathname !== prevProps.pathname)) {
        dispatch(getMPDetails(portfolio.id));
      }
    }
    // const { match } = this.props;
    if (prevProps.portfolios !== this.props.portfolios ||
      this.props.pathname !== prevProps.pathname) {
      // Url has changed
    }

    if (this.props.pathname === '/admin/bespoke-baskets' && portfolios && portfolios.byId) {
      this.props.navigateToPage(`/admin/bespoke-baskets/${sorted(Object.keys(portfolios.byId))[0]}`);
    }
  }

  addedModelPortfolioAction = (portfolios) => {
    this.props.navigateToPage(`/admin/bespoke-baskets/${Object.keys(portfolios.byId)[Object.keys(portfolios.byId).length - 1]}`);
  };

  updatedModelPortfolioAction = (id) => {
    this.props.navigateToPage(`/admin/bespoke-baskets/${id}`);
  };

  render() {
    const pfID = parseInt(parsePath(this.props.pathname)[2], 10);
    const portfolios = Object.entries(this.props.portfolios.byId);
    const portfolio = this.props.portfolios.byId[pfID];
    const tickers = [];
    const menuItems = [];
    const breadcrumb = (
      <Breadcrumb>
        <Breadcrumb.Section as={Link} to="/admin" content="Admin" />
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section content="Bespoke Baskets" active />
      </Breadcrumb>
    );

    // const { isLoading } = this.props;
    // Loop through and add tickers into the array
    if (portfolio && portfolio.members && portfolio.members.length > 0) {
      for (let i = 0; i < portfolio.members.length; i += 1) {
        const item = this.props.tickers.byId[portfolio.members[i]];
        if (item !== undefined) {
          tickers.push(item);
        }
      }
    }
    for (let i = 0; i < portfolios.length; i += 1) {
      menuItems.push({
        key: portfolios[i][0],
        name: portfolios[i][1].name,
        active: (parseInt(portfolios[i][0], 10) === pfID),
      });
    }
    // Generate menu
    const menu = (
      <Menu>
        {menuItems.map(x => (
          <Link key={x.key} to={`/admin/bespoke-baskets/${x.key}`}>
            <Menu.Item
              name={x.name}
              active={x.active}
            >
              {x.name}
            </Menu.Item>
          </Link>
        ))}
        <Menu.Menu position="right">
          <Menu.Item style={{ textAlign: 'center' }}>
            <AddModelPortfolioModal submitAction={this.addedModelPortfolioAction} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>);

    const noPortfolios = (
      <React.Fragment>
        <h1>No Portfolios found</h1>
        <p>Create a new Model Portfolio here</p>
        <AddPortfolio />
      </React.Fragment>
    );
    return portfolios.length > 0 ? (
      <React.Fragment>
        {breadcrumb}
        {menu}
        <CardBase>
          <Card.Content>
            <Grid>
              <Grid.Row columns="2">
                <Grid.Column>
                  <h1>
                    {portfolio && portfolio.name}&nbsp;
                    <span style={{ fontSize: '16px' }}>
                      <EditModelPortfolioModal
                        name={portfolio ? portfolio.name : null}
                        cash={portfolio ? portfolio.cash : null}
                        starting={portfolio ? portfolio.starting : null}
                        sp500Starting={portfolio ? portfolio.sp500Starting : null}
                        startingYTD={portfolio ? portfolio.startingYTD : null}
                        sp500StartingYTD={portfolio ? portfolio.sp500StartingYTD : null}
                        id={portfolio ? portfolio.id : null}
                        submitAction={this.updatedPortfolioAction}
                      />
                    </span>
                  </h1>
                </Grid.Column>
                <Grid.Column className="right aligned column">
                  <Grid>
                    <Grid.Row columns="1">
                      <Grid.Column className="right aligned column" style={{ 'padding-left': '0px', 'padding-right': '0.5em', 'padding-top': '0.3em' }}>
                        <AddModelItemModal
                          portfolio={portfolio}
                          calculateTickersToShow={(data, all, curr) => all.filter(ticker =>
                            (new RegExp(` ${data.value.toUpperCase()}`)).test(` ${ticker}`) && !curr.includes(ticker))}
                        />
                        <DeleteModelPortfolio
                          id={portfolio && portfolio.id}
                          name={portfolio && portfolio.name}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Label
                color="blue"
                style={{ marginLeft: '1rem' }}
              >
                Cash $
                <Label.Detail>{portfolio && parseFloat(portfolio.cash).toFixed(2)}</Label.Detail>
              </Label>
              {portfolio && portfolio.members.length > 0 ? (
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <ModelPortfolioDetailTable tickers={this.props.details} portfolio={portfolio} />
                  </Grid.Column>
                </Grid.Row>
                )
                : null }
            </Grid>
          </Card.Content>
        </CardBase>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {breadcrumb}
        <div style={{ alignSelf: 'center' }}>{noPortfolios}</div>
      </React.Fragment>
    );
  }
}

ModelPortfolios.propTypes = {
  portfolios: PropTypes.object.isRequired,
  tickers: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  // isLoading: PropTypes.bool.isRequired,
  getMPortfolios: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  navigateToPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  portfolios: state.data.modelPortfolios,
  tickers: state.data.tickers,
  isLoading: state.data.tickers.isLoading,
  chartScannerData: state.view.chartScanner,
  details: state.data.mpDetails,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  navigateToPage: url => dispatch(navigateToPage(url)),
  getMPortfolios: () => dispatch(getModelPortfolios()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModelPortfolios));
