import React from 'react';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { updateSearch, renderSearch, focusOffSearch, focusOnSearch } from '../../actions/search/';

function SearchBar({
  isLoading,
  onSearchChangeHandler,
  resultsFromStore,
  onResultSelectHandler,
  valueFromStore,
  onFocusHandler,
  onBlurHandler,
  onBlur,
  input,
}) {
  return (
    <Search
      input={input}
      category
      fluid
      showNoResults
      noResultsMessage="🤔 No results found."
      noResultsDescription={['Try typing ', <i key="1">home</i>, ' or ', <i key="2">$MSFT</i>]}
      selectFirstResult
      loading={isLoading}
      results={resultsFromStore}
      value={valueFromStore}
      onFocus={() => onFocusHandler()}
      onBlur={() => onBlurHandler(onBlur)}
      onSearchChange={(event, data) => onSearchChangeHandler(data.value)}
      onResultSelect={(event, data) => onResultSelectHandler(data.result)}
      className="ticker-second-step"
    />
  );
}

SearchBar.defaultProps = {
  onBlur: undefined,
  input: undefined,
};

SearchBar.propTypes = {
  onSearchChangeHandler: PropTypes.func.isRequired,
  onResultSelectHandler: PropTypes.func.isRequired,
  resultsFromStore: PropTypes.array.isRequired,
  valueFromStore: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFocusHandler: PropTypes.func.isRequired,
  onBlurHandler: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  input: PropTypes.object,
};

/**
 * @ignore
 * @param {*} state
 */
const mapStateToProps = state => ({
  resultsFromStore: state.search.suggestions,
  valueFromStore: state.search.text,
  isLoading: state.search.isLoading,
});

/**
 * @ignore
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => ({
  onSearchChangeHandler: value => (dispatch(updateSearch(value))),
  onResultSelectHandler: selection => (dispatch(renderSearch(selection))),
  onFocusHandler: () => dispatch(focusOnSearch()),
  onBlurHandler: (onBlurFunc) => { dispatch(focusOffSearch()); if (onBlurFunc) { onBlurFunc(); } },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
