import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'semantic-ui-react';
import { push } from 'connected-react-router';

const handleClick = (link, dispatch) => {
  dispatch(push(link));
};

class ToolCard extends React.Component {
  state = {};

  handleShow = () => this.setState({ active: true });
  handleHide = () => this.setState({ active: false });

  dimmerStyle = active => ({
    transition: '0.2s all ease',
    opacity: (active ? 1 : 0),
    backgroundColor: 'rgba(0,0,0,.85)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: '2em',
    borderRadius: '8px 8px 0px 0px',
  });

  render() {
    const {
      name,
      desc,
      image,
      dispatch,
      link,
    } = this.props;
    const { active } = this.state;
    return (
      <Card
        className="tool-card"
        onMouseEnter={() => this.handleShow()}
        onMouseLeave={() => this.handleHide()}
        onClick={() => handleClick(link, dispatch)}
      >
        <div style={{
          textAlign: 'center',
          padding: '0px',
          position: 'relative',
          overflow: 'hidden',
        }}
        >
          <Image
            src={image}
            className="homecard-image"
            onClick={() => dispatch(push(link))}
            style={{ minWidth: '100%' }}
          />
          <div style={this.dimmerStyle(active)}>
            <p style={{ color: '#cfcfcf' }}>
              {desc}
            </p>
          </div>
        </div>
        <Card.Content>
          <Card.Header style={{ fontSize: '17px', whiteSpace: 'no-wrap' }}>
            {name}
          </Card.Header>
        </Card.Content>
      </Card>);
  }
}

ToolCard.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default ToolCard;
