
import { push, replace } from 'connected-react-router';
import { commands, searchGeneral } from './search_commands';
import {
  SEARCH_START,
  SEARCH_RENDER_SUGGESTIONS,
  SEARCH_FOCUS_ON,
  SEARCH_FOCUS_OFF,
  TOGGLE_SEARCH,
} from '../../types/actions';

const cmdRgx = /^(\w+:|\$)\s*(.*)$/m;
// const argRgx = /"([^"]*)"|'([^'])*'|(\S+)/mg;
// const quoteRemover = /^(['"])(.*)\1$/m;

const searchParse = (string) => {
  // implicit 'general search' command
  const parsedString = string.match(cmdRgx);
  const command = (parsedString ? parsedString[1].replace(':', '') : 'GS');
  const args = (parsedString ? parsedString[2] : string);
  return {
    command,
    args,
  };
};

const getSearchSuggestions = (dispatch, string) => {
  let promise;
  if (string === '') {
    return Promise.resolve(undefined);
  }
  const { command, args } = searchParse(string);
  let commandInfo = null;
  if (command !== 'GS') {
    commandInfo = commands[command];
    if (!commandInfo) {
      throw new Error(`Invalid command: ${command}`);
    }
    if (commandInfo.suggestionFunc) {
      promise = commandInfo.suggestionFunc(dispatch, args);
    }
  } else {
    promise = searchGeneral(dispatch, args);
  }
  if (promise === undefined) {
    throw new Error(`No search suggestion function provided for command: ${command}`);
  }
  return promise;
};

function last(fn) {
  const lastToken = {
    cancel() {},
    setCancel(cancel) { this.cancel = cancel; },
  }; // start with no op
  return (...args) => {
    lastToken.cancel();
    return fn.apply(this, [...args, lastToken]);
  };
}

/**
 * Begins search using the search text.
 * @param {boolean} status - text to be searched.
 */
export function toggleSearch(status) {
  return {
    type: TOGGLE_SEARCH,
    status,
  };
}

/**
 * Begins search using the search text.
 * @param {string} text - text to be searched.
 */
export function startSearch(text) {
  return {
    type: SEARCH_START,
    text,
  };
}

/**
 * Updates the search suggestions with results, and triggers
 * results rendering.
 * @param {*} results
 */
export function renderSearchSuggestions(results) {
  return {
    type: SEARCH_RENDER_SUGGESTIONS,
    results,
  };
}

export function focusOnSearch() {
  return { type: SEARCH_FOCUS_ON };
}

export function focusOffSearch() {
  return { type: SEARCH_FOCUS_OFF };
}

export function navigateToPage(target, redirect = false) {
  return (dispatch, getState) => {
    if (getState().router.location.pathname !== target) {
      if (redirect) {
        dispatch(replace(target));
      } else {
        dispatch(push(target));
      }
    } else {
      dispatch(replace(target));
    }
  };
}

/**
 * Gets real-time search suggestions.
 * returns a thunk that intiates the search,
 * updates the search corpus, then renders search suggestions.
 * @todo: cache data in the store.
 * @param {string} string -string to search
 */
function updateSearchWithToken(string, token) {
  return (dispatch) => {
    dispatch(startSearch(string));
    const promise = new Promise((resolve, reject) => {
      getSearchSuggestions(dispatch, string)
        .then(suggestions => resolve(dispatch(renderSearchSuggestions(suggestions))));
      token.setCancel(() => reject(new Error('SearchAbort')));
    });
    return promise.catch((err) => {
      if (err.message !== 'SearchAbort') {
        throw err;
      }
    });
  };
}

export const updateSearch = last(updateSearchWithToken);

/**
 *
 * @param {*} data
 */
export function renderSearch(data) {
  return (dispatch) => {
    switch (data.type) {
      case 'HOME':
        dispatch(navigateToPage('/'));
        break;
      case 'TICKER':
        dispatch(navigateToPage(`/ticker/${data.ticker.ticker}`));
        break;
      case 'POST':
      case 'PAGE':
        dispatch(navigateToPage(data.url));
        break;
      case 'HELP':
        break;
      default:
        throw new Error('Bad search data type!');
    }
  };
}
