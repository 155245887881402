import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

class EarningsCalendarInfo extends React.PureComponent {
  render() {
    const { tickerInfo } = this.props;
    return (
      <Table style={{ fontSize: '20px' }} >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="4" style={{ textAlign: 'center' }} >
              {`${tickerInfo.company} (${tickerInfo.ticker})`}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row style={{ textAlign: 'center' }} >
            <Table.HeaderCell>
              Next Report
            </Table.HeaderCell>
            <Table.HeaderCell>
              Time of Day
            </Table.HeaderCell>
            <Table.HeaderCell>
              EPS EST ($,Shr)
            </Table.HeaderCell>
            <Table.HeaderCell>
              Revenue Est. ($,Mln)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row style={{ textAlign: 'center' }} >
            <Table.Cell>
              {new Date(tickerInfo.nextReport).toLocaleDateString()}
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.timeOfDay}
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.epsEst}
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.revEst}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Reports on File
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.priorReports}
            </Table.Cell>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Avg. % Chg on Report Days
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.avgChange}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Beat EPS Estimate
            </Table.Cell>
            <Table.Cell>
              {`${tickerInfo.beatEPS}%`}
            </Table.Cell>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Avg. Absolute % Chg (Volatility)
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.avgVolatility}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Beat Revenues
            </Table.Cell>
            <Table.Cell>
              {`${tickerInfo.beatRev}%`}
            </Table.Cell>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Avg. % Chg on EPS Beat
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.avgBeat}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Guides Higher
            </Table.Cell>
            <Table.Cell>
              {`${tickerInfo.guidesHigher}%`}
            </Table.Cell>
            <Table.Cell style={{ fontWeight: 'bold' }} >
              Avg. % Chg on EPS Miss
            </Table.Cell>
            <Table.Cell>
              {tickerInfo.avgMiss}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2" style={{ fontWeight: 'bold' }} >
              Avg. % Open to Close When Gaps Up
            </Table.Cell>
            <Table.Cell colSpan="2">
              {tickerInfo.avgO2CUp}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2" style={{ fontWeight: 'bold' }} >
              Avg. % Open to Close When Gaps Down
            </Table.Cell>
            <Table.Cell colSpan="2">
              {tickerInfo.avgO2CDown}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

EarningsCalendarInfo.propTypes = {
  tickerInfo: PropTypes.object.isRequired,
};

export default EarningsCalendarInfo;
