import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Button } from 'semantic-ui-react';

const pageNotFound = ({ history }) => (
  <div style={{ marginTop: '26vh', marginLeft: '10px' }}>
    <h1
      style={{
        fontSize: '8rem',
        alignSelf: 'center',
        color: '#003051',
        lineHeight: '6rem',
      }}
    >
        404
    </h1>
    <h1 style={{ alignSelf: 'center', color: '#003051' }}>
      Sorry, but we can&apos;t find the page you&apos;re looking for.
    </h1>
    <br />
    <Button
      onClick={() => { history.push('/'); }}
      style={{
        backgroundColor: '#003051',
        borderRadius: '200px',
        fontSize: '1.2rem',
        color: 'white',
      }}
    >
      Go Home
    </Button>
  </div>
);

pageNotFound.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(pageNotFound);
