import { executeQuery, mainPath } from './query';

const tickerPath = `${mainPath}/api/tickers`;
/**
 * @param  {Object} [options] - Which tickers to get.
 * @param {number} [options.sec_type=0] - Determines whether or not the list received
 *   is comprised of equities or indices.
 * @param {number} [options.is_active] - Determines whether or not the list received
 *   is comprised of active or inactive tickers.
 * @param {number} [options.with_names] - If this option is truthy, the returned array
 * will instead be an array of Objects containing tickers and companies.
 * @return {Array<string>|Object} An array of tickers.
 * @property {string} ticker - The ticker.
 * @property {string} company - Company name.
 */
export function getAll(options) {
  const optArr = [];
  if (options) {
    if (options.sec_type) {
      optArr.push(['sec_type', options.sec_type]);
    }
    if (options.is_active) {
      optArr.push(['is_active', options.is_active]);
    }
    if (options.with_names) {
      optArr.push(['with_names', options.with_names]);
    }
  }
  return executeQuery(`${tickerPath}/${optArr ? `?${optArr.map(x => `${x[0]}=${x[1]}`).join('&')}` : ''}`);
}

export function createNewTicker(body) {
  return executeQuery(`${tickerPath}/new-ticker`, 'POST', { body });
}

export function disabledActiveTicker(idTicker, body) {
  return executeQuery(`${tickerPath}/disabled-ticker/${idTicker}`, 'PUT', { body });
}

export function editTickerManager(idTicker, body) {
  return executeQuery(`${tickerPath}/edit-ticker/${idTicker}`, 'PUT', { body });
}

export function selectTickerCategory(idTicker) {
  return executeQuery(`${tickerPath}/select-ticker-category/${idTicker}`);
}
export function createNewCategory(body) {
  return executeQuery(`${tickerPath}/new-category`, 'POST', { body });
}

export function getAllTickers(options) {
  const optArr = [];
  if (options) {
    if (options.sec_type) {
      optArr.push(['sec_type', options.sec_type]);
    }
    if (options.is_active) {
      optArr.push(['is_active', options.is_active]);
    }
    if (options.with_names) {
      optArr.push(['with_names', options.with_names]);
    }
  }
  return executeQuery(`${tickerPath}/list-all-tickers/${optArr ? `?${optArr.map(x => `${x[0]}=${x[1]}`).join('&')}` : ''}`);
}

/**
  *  Gets ordered reference data for an array of tickers.
  *  @param {string[]} tickers - An array of tickers to get reference data for.
  *  @param {string} colName - The column name to sort results by
  *  @param {string} direction - The direction of the sort. Valid options are "ASC" or "DESC"
  *    for ascending or descending sort, respectively.
  *  @return {Ticker[]} An array of {@link Ticker} objects.
  */
export function getReference(tickers, colName = 'ticker', direction = 'ASC', date = null) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/reference?${tickerQueryString}&column=${colName}&direction=${direction}&date=${date}`);
}

/**
  *  Gets historical data for an array of tickers.
  *  @param {string[]} tickers - An array of tickers to get historical data for.
  *  @param {string} startDate - The start date (inclusive) in YYYY-MM-DD format.
  *  @param {string} endDate - The end date (inclusive) in YYYY-MM-DD format.
  *  @return {Object<string, HistoryData[]>} A dictionary mapping tickers to their
  *   respective {@link HistoryData} arrays. Recent data is first.
  */
export function getHistorical(tickers, startParam, endParam, movAvgs = false) {
  // define defaults if undefined
  const now = Date.now();
  let startDate = startParam;
  let endDate = endParam;
  if (!startDate) {
    startDate = `${now.getFullYear() - 1}-${now.getMonth()}-${now.getDate()}`;
  }
  if (!endDate) {
    endDate = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
  }
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/historical/?${tickerQueryString}&start=${startDate}&end=${endDate}&ma=${movAvgs}`);
}

export function getNHistorical(tickers, n = 126, movAvgs = false) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/nhistorical/?${tickerQueryString}&n=${n}&ma=${movAvgs}`);
}
/**
 *  Gets earnings data for an array of tickers by date.
 *  @param {string[]} tickers - An array of tickers to get historical data for.
 *  @param {string} startDate - The start date (inclusive) in YYYY-MM-DD format.
 *  @param {string} endDate - The end date (inclusive) in YYYY-MM-DD format.
 *  @return {Object<string, EarningsData[]>} A dictionary mapping tickers to their
 *    respective {@link EarningsData} arrays. Recent data is first.
 */
export function getEarningsByDate(tickers, startParam, endParam) {
  // define defaults if undefined
  const now = Date.now();
  let startDate = startParam;
  let endDate = endParam;
  if (!startDate) {
    startDate = `${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate()}`;
  }
  if (!endDate) {
    endDate = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
  }
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/earnings/?${tickerQueryString}&start=${startDate}&end=${endDate}`);
}

/**
 *  Get the most recent N earnings reports for an array of tickers.
 *  @param {string[]} tickers - An array of tickers to get historical data for.
 *  @param {number} [n=1] - The number of reports to get per ticker. Optional, defaults to 1.
 *  @return {Object<string, EarningsData[]>} A dictionary mapping tickers to their
 *    respective {@link EarningsData} arrays. Recent data is first.
 */
export function getEarnings(tickers, n = 1) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/earnings/?${tickerQueryString}&n=${n}`);
}

/**
 * Get the earnings calendar data for the array of tickers.
 * @param  {string[]} tickers - An array of tickers to get earnings calendar data for.
 * @return {Object<string, EarningsData[]>} A dictionary mapping tickers to their
 *    respective {@link EarningsCalendar} arrays.
 */
export function getEarningsCalendar(tickers) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/ecal/?${tickerQueryString}`);
}

/**
 *  Get data from the international revenues database for the provided tickers.
 *  @param {string[]} tickers - an array of equity tickers.
 *  @return {Object<string, IRevData} An object mapping tickers to their respective
 *                                    {@link IRevData} objects.
 */
export function getIRevData(tickers) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/ir/?${tickerQueryString}`);
}

/**
 * Get 1 week, 1 month, and 3 month seasonality for provided tickers.
 * @param  {string[]} tickers - Equity tickers to get seasonality for
 * @return {Object<string, SeasonalityData>} An object mapping tickers to their
 *                                           {@link SeasonalityData} objects.
 */
export function getSeasonality(tickers) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/seasonality/?${tickerQueryString}`);
}

/**
 *  Get a list of screens that each tickers shows up in.
 *  @param {string[]} tickers - An array of tickers to get historical data for.
 *  @example <caption>Below is a formatted example response for tickers = ['FB', 'ADNT']</caption>
 *  {
 *    "FB":[
 *      {
 *        "id":1, // id of the screen
 *        "name":"52 Week Highs", // name of the screen
 *        "date":"2018-07-23", // date the ticker appeared in the screen
 *        "screen":"", // screen representation for user generated screens.
 *        "active":1 // 1 indicates the screen is currently active, 0 is inactive
 *      }
 *    ],
 *    "ADNT":[
 *      {
 *        "id":2,
 *        "name":"52 Week Lows",
 *        "date":"2018-07-23",
 *        "screen":"",
 *        "active":1
 *      }
 *    ]
 *  }
 *  @return {Object<string, Object[]>} - A dictionary mapping tickers to an array
 *    of the screens they show up in.
 */
export function getScreens(tickers) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/screens/?${tickerQueryString}`);
}

export function getSummary(tickers, colName = 'ticker', direction = 'ASC', date = null) {
  const promises = [];
  promises.push(getReference(tickers, colName, direction, date));
  promises.push(getEarnings(tickers, 1));
  promises.push(getScreens(tickers));
  promises.push(getEarningsCalendar(tickers));
  return Promise.all(promises)
    .then((data) => {
      const [reference, earnings, screens, ecal] = data;
      return reference.map(item => ({
        ...item,
        current_earnings: earnings[item.ticker][0],
        current_screens: screens[item.ticker],
        earnings_calendar: ecal[item.ticker],
      }));
    });
}

export function getSuggestions(tickers) {
  let tickerQueryString = '';
  tickers.forEach((ticker) => { tickerQueryString += `&tickers[]=${ticker}`; });
  tickerQueryString = tickerQueryString.slice(1);
  return executeQuery(`${tickerPath}/suggestions/?${tickerQueryString}`);
}

/**
 * Get the datetime of last update for a given database table.
 * @param {string} [table] - The table to check recent updates for.
 * @return {Object<string, string>} - returns an object containing the date of last update
 *  for the specified table, or across tables if none are specified.
 */
export function getLastUpdate(table) {
  return fetch(`${tickerPath}/lastupdate/?table=${table}`)
    .then(res => res.json().lastupdate);
}

/* Types below */
/**
  * An object representation of a ticker.
  * @typedef {object} Ticker
  * @property {string} ticker - symbolic representation of the security.
  * @property {number} id - The unique ID of the security in the database.
  * @property {number} sec_type - The security type.
  *   - 0 = Equity
  *   - 1 = Index
  * @property {string} company - The name of the equity or index.
  * @property {string} shortname - The shortened name of the equity or index.
  * @property {string} gics_sector - The GICS Sector of the equity.
  *   May be a country or "N/A" for indices.
  * @property {string} gics_group - The GICS Group of the equity. "N/A" for indices.
  * @property {number} is_active - The status of the security in the database.
  *   - 0 is inactive (no longer tracked)
  *   - 1 is active (actively tracked)
  * @property {string} datemade - The date the ticker was added to the database.
  * @property {HistoryData} current_candle - A {@link HistoryData} object containing
  *   The most recent open, high, low, close, and volume.
  * @property {TrendData} current_trend A {@link TrendData} object containing
  *   The most recent trend data.
  */

/**
  * An object which represents one day of historical data for a ticker.
  * @typedef {object} HistoryData
  * @property {string} date - Date in YYYY-MM-DD format.
  * @property {number} open - Daily opening price.
  * @property {number} high - Daily high.
  * @property {number} low - Daily low.
  * @property {number} close - Daily closing price.
  * @property {number} volume - The daily volume.
  */

/**
  * An object which represents one day of trend data for a ticker.
  * @typedef {object} TrendData
  * @property {string} date - Date of trend analysis in YYYY-MM-DD format.
  * @property {number} price - Most recent closing price at the time the trend analysis was run.
  * @property {number} ytd_pct - Year to date percent change.
  * @property {number} five_day_pct - Five day percent change.
  * @property {number} fifty_day_pct_mva - Percent from 50-day moving average.
  * @property {number} trend - The 6 month trend.
  *   - 1 = up-trend
  *   - 0 = sideways-trend
  *   - -1 = down-trend.
  *   - -99 = not enough historical data to calculate trend.
  * @property {number} timing - The Bespoke timing.
  *   - 0 = Bad
  *   - 1 = Poor
  *   - 2 = Neutral
  *   - 3 = Good
  *   - 4 = Perfect
  * @property {number} cur_range - The current trading range, defined as the standard
  *   deviation of the current price from the 50 day moving average.
  * @property {number} last_range - The trading range 5 trading days ago.
  */

/**
  * An object which represents one Bespoke earnings report for a ticker.
  * @typedef {object} EarningsData
  * @property {number} primary_key - Unique Id in earnings database table.
  * @property {string} date - Date in YYYY-MM-DD format.
  * @property {string} time_of_day - The time of day of the earnings report.
  *   - AM = before the open
  *   - PM = before the close
  *   - D = during the trading day.
  * @property {number} prior_close - The closing price before earnings were released.
  * @property {number} eps_actual - The reported earnings per share.
  * @property {number} eps_estimate - The estimated earnings per share going into the report.
  * @property {number} eps_avse - The difference between reported eps and estimated eps.
  * @property {number} rev_actual - The reported revenue.
  * @property {number} rev_estimate - The estimated revenue going into the report.
  * @property {number} rev_avse - The difference between reported and estimated revenue.
  * @property {string} guidance - The reported guidance. May be any of the following:
  *   - Inline
  *   - Lowered
  *   - Raised
  *   - None
  * @property {number} gap_pct - The percent change at the opening of trading following an
  *   earnings release as compared to the prior close.
  * @property {number} gap_pt - The point change at the opening of trading following an
  *   earnings release as compared to the prior close.
  * @property {number} open_to_close_pct - The percent change from the open of trading to
  *   the close of trading following an earnings release.
  * @property {number} open_to_close_pt - The point change from the open of trading to the
  *   close of trading following an earnings release.
  * @property {number} one_day_pct - The full one day percent change following an earnings release.
  * @property {number} one_day_pt - The full one day point change following an earnings release.
  * @property {number} one_day_abs - Absolute value of the full day percent change.
  */

/**
 * An object which represents a row of earnings calendar data for a ticker.
 * @typedef {object} EarningsCalendar
 * @property {string} stock - The stock.
 * @property {string} company - The company.
 * @property {string} reportdate - Report date in YYYY-MM-DD format.
 * @property {string} reporttime - Report time of day (AM, PM, or D for intraday).
 * @property {number} currenteps - Current estimated EPS.
 * @property {number} currentrev - Current estimated revenue.
 * @property {number} prioronfile - # of prior reports on file.
 * @property {number} epsbeat - % of the time eps beat.
 * @property {number} revenuebeat - % of the time revenue beat.
 * @property {number} guidance - % of the time guidance was raised.
 * @property {number} avg_gap - Average gap percent across all earnings reports for the stock.
 * @property {number} avg_o2c - Average open to close across all earnings reports for the stock.
 * @property {number} avg_1day - Average one day change across all earnings reports for the stock.
 * @property {number} avgabsolute - Average absolute % change across all earnings reports for the
 *                                stock.
 * @property {string} datemade - The date the row was inserted into the database.
 */

/**
 * An object which represents a row of Internation Revenues data for a ticker.
 * Note that this is from a legacy database, which means that some information
 * (for example: company, sector) may be out of sync with the metadata retrieved from
 * the getReference function.
 * @typedef {object}  IRevData
 * @property {string} ticker - The ticker.
 * @property {string} company - The company.
 * @property {string} sector - The sector.
 * @property {number} recentdom - The most recent data for % domestic revenue.
 * @property {number} year1dom - % Domestic revenue data for 1 year ago.
 * @property {number} year1dom - % Domestic revenue data for 2 years ago.
 * @property {number} recentint - The most recent data for % international revenue.
 * @property {number} year1int - % Internaional revenue data for 1 year ago.
 * @property {number} year1int - % International revenue data for 2 years ago.
 * @property {string} datemade - When the row was inserted into the database.
 */

/**
 * An object representing 1 week, 1 month, and 3 month seasonality for a ticker.
 * @typedef {object} SeasonalityData
 * @property {number} one_week - One week percent change.
 * @property {number} one_month - One month percent change.
 * @property {number} three_month - Three month percent change.
 * @property {number} years - Number of years the calculation looks back
 */
