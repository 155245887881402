import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import ChartScannerCard from '../../../presentational/cards/ChartScannerCard';
import { loadSettings } from '../../../../actions/user';

class ChartGrid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.chartGridStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    };

    this.chartItemStyle = {};
  }

  componentDidMount() {
    this.props.loadSettings();
  }

  render() {
    const {
      data,
      chartSize,
      csSettings,
    } = this.props;
    // Create a flexbox grid to display all the charts
    return (
      <React.Fragment>
        <style>
          {`.chart-series { display: none; } .chart-series.${csSettings.type}{ display: block; }`}
          {`.chart-series.line { stroke: ${csSettings.colors.line}; } .chart-series.candle .down { stroke: ${csSettings.colors.down}; fill: ${csSettings.colors.down};}`}
          {`.chart-series.candle .up { stroke: ${csSettings.colors.up}; fill: ${csSettings.colors.up};}`}
          {`.chart-series.ohlc .up { stroke: ${csSettings.colors.up}; } .chart-series.ohlc .down { stroke: ${csSettings.colors.down}; }`}
          {`.sma20 { stroke: ${csSettings.colors.dma20}; display: ${csSettings.indicators.dma20 ? 'inline' : 'none'}; }`}
          {`.sma50 { stroke: ${csSettings.colors.dma50}; display: ${csSettings.indicators.dma50 ? 'inline' : 'none'}; }`}
          {`.sma100 { stroke: ${csSettings.colors.dma100}; display: ${csSettings.indicators.dma100 ? 'inline' : 'none'}; }`}
          {`.sma200 { stroke: ${csSettings.colors.dma200}; display: ${csSettings.indicators.dma200 ? 'inline' : 'none'}; }`}
        </style>

        <div style={this.chartGridStyle}>
          <Dimmer inverted active={this.props.chartScannerData.loading}>
            <Loader />
          </Dimmer>
          { (data.length > 0) ?
            data.map(item => (
              <ChartScannerCard
                key={item && item.ticker && item.ticker.ticker}
                ticker={item.ticker}
                history={item.history}
                chartSize={chartSize}
                range={csSettings.range}
              />
          )) :
          this.props.defaultText
          }
        </div>
      </React.Fragment>);
  }
}

ChartGrid.defaultProps = {
  chartSize: { width: 330, height: 250 },
  defaultText: 'Please select something.',
};

ChartGrid.propTypes = {
  data: PropTypes.array.isRequired,
  chartSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  defaultText: PropTypes.string,
  chartScannerData: PropTypes.object.isRequired,
  csSettings: PropTypes.object.isRequired,
  loadSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  csSettings: state.user.settings.chartScanner,
  chartScannerData: state.view.chartScanner,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  loadSettings: () => dispatch(loadSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartGrid);
