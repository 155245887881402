import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tab, Dimmer, Loader, Menu } from 'semantic-ui-react';
import { timeMonth, timeWeek, timeYear } from 'd3-time';
import LineChart from '../../charts/LineChart';
import BarChart from '../../charts/BarChart';
import { getEcalAgg, setEcalAggFilterDate } from '../../../../actions/data/tools';

const generateEcalAggChartBottomMenuItems = clickHandler => ([
  {
    key: '6months',
    name: '6 Months',
    onClick: clickHandler,
    time: 15552000000,
  },
  {
    key: '1year',
    name: '1 Year',
    onClick: clickHandler,
    time: 31536000000,
  },
  {
    key: '5years',
    name: '5 Years',
    onClick: clickHandler,
    time: 157680000000,
  },
  {
    key: 'all',
    name: 'All',
    onClick: clickHandler,
    time: Infinity,
  },
]);

class EarningsExplorerCharts extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.changeEcalAggChartRange = this.changeEcalAggChartRange.bind(this);
  }

  componentDidMount() {
    // Get ecal agg data
    this.props.getAggData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match !== prevProps.match) {
      this.setActiveIndex(0);
    }
  }

  setActiveIndex(newIndex) {
    this.setState({ activeIndex: newIndex });
  }

  changeEcalAggChartRange(e, d) {
    this.props.setEcalAggFilterDate(d.time);
  }

  generateEcalPanes() {
    const { benchmark, ecalAggFilterDate } = this.props;
    const intervalFromFilterDate = {
      Infinity: [timeYear, 2],
      157680000000: [timeMonth, 6],
      31536000000: [timeMonth, 2],
      15552000000: [timeWeek, 3],
    };
    const timeInterval = intervalFromFilterDate[ecalAggFilterDate];

    const data = this.props.ecalAgg.data
      .filter(x => Date.parse(x.date) >= Date.now() - ecalAggFilterDate);
    // Filter the data
    const bottomMenuItems = generateEcalAggChartBottomMenuItems(this.changeEcalAggChartRange)
      .map(x => ({ ...x, active: (x.time === ecalAggFilterDate) }));
    const bottomMenu = (
      <Menu
        size="tiny"
        widths={bottomMenuItems.length}
        attached="bottom"
        items={bottomMenuItems}
        className="upcoming-tenth-step"
      />);
    const panes = [
      {
        menuItem: { content: 'Beat Rates', className: 'upcoming-seventh-step' },
        render: () => (
          <React.Fragment>
            <Tab.Pane attached>
              <h1
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginBottom: '0px',
                  float: 'left',
                }}
              >
                EPS Beat Rate (%), 3 Mo. Rolling
              </h1>
              {data[data.length - 1] ? (
                <h1
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0px',
                    float: 'right',
                  }}
                >
                  <span style={{ color: '#497CB7' }}>{`Current: ${(100 * (data[data.length - 1].eps_beats / data[data.length - 1].num_reports)).toFixed(2)}`}</span>
                  {' | '}
                  <span style={{ color: 'red' }}>Avg: {`${(benchmark.eps_beat_avg * 100).toFixed(2)}`}</span>
                </h1>)
              : null}
              <LineChart
                data={data.map(x => ({
                  date: x.date,
                  value: (100 * (x.eps_beats / x.num_reports)).toFixed(2),
                }))}
                size={{ width: 300, height: 153 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 36,
                }}
                timeFormatter="%-m/%y"
                timeTicks={timeInterval}
                waterLine={benchmark.eps_beat_avg * 100}
                labels={['Beat Rate', 'Avg']}
                yAxisPadding={[-2, 2]}
              />
              <h1
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                  float: 'left',
                }}
              >
                Sales Beat Rate (%), 3 Mo. Rolling
              </h1>
              {data[data.length - 1] ? (
                <h1
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0px',
                    float: 'right',
                  }}
                >
                  <span style={{ color: '#497CB7' }}>{`Current: ${(100 * (data[data.length - 1].rev_beats / data[data.length - 1].num_reports)).toFixed(2)}`}</span>
                  {' | '}
                  <span style={{ color: 'red' }}>Avg: {`${(benchmark.rev_beat_avg * 100).toFixed(2)}`}</span>
                </h1>)
              : null}
              <LineChart
                data={data.map(x => ({
                  date: x.date,
                  value: (100 * (x.rev_beats / x.num_reports)).toFixed(2),
                }))}
                size={{ width: 300, height: 153 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 36,
                }}
                timeFormatter="%-m/%y"
                timeTicks={timeInterval}
                waterLine={benchmark.rev_beat_avg * 100}
                labels={['Beat Rate', 'Avg']}
                yAxisPadding={[-2, 2]}
              />
            </Tab.Pane>
            {bottomMenu}
          </React.Fragment>),
      },
      {
        menuItem: { content: 'Guidance Spread', className: 'upcoming-eighth-step' },
        render: () => (
          <React.Fragment>
            <Tab.Pane attached>
              <h1
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                  float: 'left',
                }}
              >
                Guidance Spread (%), 3 Mo. Rolling
              </h1>
              {data[data.length - 1] ? (
                <h1
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0px',
                    float: 'right',
                  }}
                >
                  <span style={{ color: '#497CB7' }}>{`Current: ${(100 * ((data[data.length - 1].guided_higher - data[data.length - 1].guided_lower) / data[data.length - 1].issued_guidance)).toFixed(2)}`}</span>
                  {' | '}
                  <span style={{ color: 'red' }}>Avg: {`${(benchmark.guidance_avg * 100).toFixed(2)}`}</span>
                </h1>)
              : null}
              <LineChart
                data={data.map(x => ({
                  date: x.date,
                  value: (100 * ((x.guided_higher - x.guided_lower) / x.issued_guidance))
                    .toFixed(2),
                }))}
                size={{ width: 300, height: 326 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 36,
                }}
                timeFormatter="%-m/%y"
                timeTicks={timeInterval}
                waterLine={benchmark.guidance_avg * 100}
                labels={['Guidance Spread', 'Avg']}
                yAxisPadding={[-2, 2]}
              />
            </Tab.Pane>
            {bottomMenu}
          </React.Fragment>),
      },
      {
        menuItem: { content: 'Stock Price Reaction', className: 'upcoming-ninth-step' },
        render: () => (
          <React.Fragment>
            <Tab.Pane attached>
              <h1
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  margin: '0px',
                  float: 'left',
                }}
              >
                1 Day Median Change (%), 3 Mo. Rolling
              </h1>
              {data[data.length - 1] ? (
                <h1
                  style={{
                    fontSize: '12px',
                    textAlign: 'center',
                    margin: '0px',
                    float: 'right',
                  }}
                >
                  <span style={{ color: '#497CB7' }}>{`Current: ${data[data.length - 1].median_1_day.toFixed(2)}`}</span>
                  {' | '}
                  <span style={{ color: 'red' }}>Avg: {`${(benchmark.reaction_avg).toFixed(2)}`}</span>
                </h1>)
              : null}
              <LineChart
                data={data.map(x => ({
                  date: x.date,
                  value: x.median_1_day.toFixed(2),
                }))}
                size={{ width: 300, height: 326 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 36,
                }}
                timeFormatter="%-m/%y"
                timeTicks={timeInterval}
                waterLine={benchmark.reaction_avg}
                labels={['Median Reaction', 'Avg']}
                yAxisPadding={[-0.2, 0.2]}
              />
            </Tab.Pane>
            {bottomMenu}
          </React.Fragment>),
      },
    ];
    return panes;
  }

  generateStockPanes() {
    const data = this.props.chartData;
    const { ticker } = this.props;
    const panes = [
      {
        menuItem: 'EPS Actual vs. Estimates',
        active: true,
        render: () => {
          if (data.length === 0) {
            if (ticker) {
              return (
                <Tab.Pane
                  attached="bottom"
                  style={{
                    height: '409px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '70%' }} >
                    <h1 style={{ fontSize: '20px', textAlign: 'center', marginTop: '-2em' }}>
                      {`No earnings data found for ${ticker.description}.`}
                    </h1>
                  </div>
                </Tab.Pane>);
            }
            return (
              <Tab.Pane
                attached="bottom"
                style={{
                  height: '409px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ fontSize: '20px', textAlign: 'center', marginTop: '-2em' }}>Search for a ticker.</h1>
              </Tab.Pane>);
          }
          return (
            <Tab.Pane attached="bottom">
              <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '0px' }}>
                EPS <span style={{ color: 'red' }}>Actual</span> and <span style={{ color: 'black' }} >Estimates</span> ($ / Share)
              </h1>
              <LineChart
                data={data.map(x => ({
                  label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1'),
                  value: [parseFloat(x.eps_actual), parseFloat(x.eps_estimate)],
                }))}
                multi
                colors={['red', 'black']}
                size={{ width: 300, height: 165 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 60,
                }}
                timeFormatter="%-m/%y"
                timeTicks={[timeYear, 3]}
              />
              <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '5px 0 0 0' }}>
                EPS Actual vs Estimates ($ / Share)
              </h1>
              <BarChart
                data={data.map(x => ({
                  label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$1'),
                  value: parseFloat(x.eps_avse),
                })).reverse()}
                size={{ width: 300, height: 164 }}
                margins={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 60,
                }}
                colorMap={(value) => {
                  if (value > 0) { return 'green'; }
                  if (value === 0) { return 'black'; }
                  return 'red';
                }}
              />
            </Tab.Pane>);
        },
      },
      {
        menuItem: 'Sales Actual vs. Estimates',
        render: () => {
          if (data.length === 0) {
            if (ticker) {
              return (
                <Tab.Pane
                  attached="bottom"
                  style={{
                    height: '409px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '70%' }} >
                    <h1 style={{ fontSize: '20px', textAlign: 'center', marginTop: '-2em' }}>
                      {`No earnings data found for ${ticker.description}.`}
                    </h1>
                  </div>
                </Tab.Pane>);
            }
            return (
              <Tab.Pane
                attached="bottom"
                style={{
                  height: '409px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h1 style={{ fontSize: '20px', textAlign: 'center', marginTop: '-2em' }}>Search for a ticker.</h1>
              </Tab.Pane>);
          }
          return (
            <Tab.Pane attached="bottom">
              <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '0px' }}>
                Sales <span style={{ color: 'red' }}>Actual</span> and <span style={{ color: 'black' }} >Estimates</span> ($, MM)
              </h1>
              <LineChart
                data={data.map(x => ({
                  label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3/$1'),
                  value: [parseFloat(x.rev_actual), parseFloat(x.rev_estimate)],
                }))}
                multi
                colors={['red', 'black']}
                size={{ width: 300, height: 165 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 60,
                }}
                className="ecalBarChart"
                timeFormatter="%-m/%y"
                timeTicks={[timeYear, 3]}
              />
              <h1 style={{ fontSize: '14px', textAlign: 'center', margin: '5px 0 0 0' }}>
                Sales Actual vs Estimates ($, MM)
              </h1>
              <BarChart
                data={data.map(x => ({
                  label: x.date.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$1'),
                  value: parseFloat(x.rev_avse),
                })).reverse()}
                size={{ width: 300, height: 164 }}
                margins={{
                  top: 10,
                  right: 20,
                  bottom: 30,
                  left: 60,
                }}
                colorMap={(value) => {
                  if (value > 0) { return 'green'; }
                  if (value === 0) { return 'black'; }
                  return 'red';
                }}
                className="ecalBarChart"
              />
            </Tab.Pane>);
        },
      },
    ];
    return panes;
  }

  render() {
    const { match, ecalAgg, chartDataLoading } = this.props;
    const { activeIndex } = this.state;
    // generate beat rates
    let panes = [];
    let { isLoading } = ecalAgg;
    let widths = 3;
    switch (match.params.tab) {
      case 'screen':
      case 'upcoming':
        panes = this.generateEcalPanes();
        break;
      case 'search':
        isLoading = chartDataLoading;
        panes = this.generateStockPanes();
        widths = 2;
        break;
      default:
        panes = this.generateEcalPanes();
        break;
    }
    return (
      <Dimmer.Dimmable dimmed={isLoading}>
        <Dimmer inverted style={{ borderRadius: '2px' }} active={isLoading} >
          <Loader />
        </Dimmer>
        <Tab
          activeIndex={activeIndex}
          onTabChange={(e, d) => this.setActiveIndex(d.activeIndex)}
          menu={{
            attached: 'top',
            // stackable: true,
            widths,
          }}
          panes={panes}
        />
      </Dimmer.Dimmable>);
  }
}

EarningsExplorerCharts.defaultProps = {
  ticker: null,
};

EarningsExplorerCharts.propTypes = {
  benchmark: PropTypes.object.isRequired,
  ecalAgg: PropTypes.object.isRequired,
  ecalAggFilterDate: PropTypes.number.isRequired,
  setEcalAggFilterDate: PropTypes.func.isRequired,
  chartData: PropTypes.array.isRequired,
  chartDataLoading: PropTypes.bool.isRequired,
  getAggData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  ticker: PropTypes.object,
};

const mapStateToProps = state => ({
  benchmark: state.view.earningsExplorer.benchmark,
  ecalAgg: state.data.ecalAgg,
  ecalAggFilterDate: state.view.earningsExplorer.ecalAggFilterDate,
  chartData: state.view.earningsExplorer.chartData,
  chartDataLoading: state.view.earningsExplorer.chartDataLoading,
  ticker: state.view.earningsExplorer.ticker,
});

const mapDispatchToProps = dispatch => ({
  getAggData: () => dispatch(getEcalAgg()),
  setEcalAggFilterDate: date => dispatch(setEcalAggFilterDate(date)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EarningsExplorerCharts));
