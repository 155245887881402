// Modal that prompts user to upgrade
// Use it with a trigger to show modal instead of linking to content,
// or use directly on blocked content without a trigger
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

// const UpgradeModal = props => (
//   <Modal basic closeOnDimmerClick={false} {...props}>
//     <Header icon="lock" content="Restricted Content" />
//     <Modal.Content>
//       <p>
//         You are seeing this message because this content is restricted for your current
//         membership level.
//         If you would like to see this content, please upgrade your membership level.
//       </p>
//     </Modal.Content>
//     <Modal.Actions>
//       <Button basic color="white" inverted onClick={props.history.goBack}>
//         <Icon name="remove" /> No Thanks
//       </Button>
//       <Button color="yellow" inverted>
//         <Icon name="checkmark" /> Upgrade
//       </Button>
//     </Modal.Actions>
//   </Modal>
// );

// converted class component for open/close state control
// @todo change upgrade link to correct location
class UpgradeModal extends React.PureComponent {
  state = this.props.launcher ? { open: false } : { open: true };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;
    const {
      history,
      launcher,
      message,
      link,
    } = this.props;
    return (
      <React.Fragment>
        {launcher ? React.cloneElement(this.props.launcher, { onClick: this.click }) : <span />}
        <Modal dimmer="blurring" basic open={open} closeOnDimmerClick={false} {...this.props}>
          <Header icon="lock" content="Restricted Content" />
          <Modal.Content>
            <p>
              {message}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="white" inverted onClick={launcher ? this.close : history.goBack}>
              <Icon name="remove" /> No Thanks
            </Button>
            <Link to={link.includes('http') ? { pathname: link } : link} target="_blank" >
              <Button color="yellow" inverted>
                <Icon name="checkmark" /> Upgrade
              </Button>
            </Link>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

const upgrade = props => (
  props.trigger ?
    <UpgradeModal {...props} trigger={null} launcher={props.trigger} />
    :
    <UpgradeModal defaultOpen {...props} />
);

UpgradeModal.propTypes = {
  launcher: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

upgrade.defaultProps = {
  trigger: null,
  message: 'You are seeing this message because this content is restricted for your current membership level. If you would like to see this content, please upgrade your membership level.',
  link: '/upgrade/',
};

upgrade.propTypes = {
  trigger: PropTypes.any,
  history: PropTypes.object.isRequired,
  message: PropTypes.string,
  link: PropTypes.string,
};

export default withRouter(upgrade);
