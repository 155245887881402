import React from 'react';
import PropTypes from 'prop-types';

export function GreenText(props) {
  return <span className="green text"> { props.children }</span>;
}

export function YellowText(props) {
  return (<span className="yellow text"> { props.children }</span>);
}

export function RedText(props) {
  return (<span className="red text"> { props.children }</span>);
}

export function BlueText(props) {
  return (<span className="blue text"> { props.children }</span>);
}

export function BlueLink(props) {
  return (<span {...props} className="blue link" style={{ cursor: 'pointer' }}> { props.children }</span>);
}

GreenText.propTypes = {
  children: PropTypes.node.isRequired,
};
YellowText.propTypes = {
  children: PropTypes.node.isRequired,
};
RedText.propTypes = {
  children: PropTypes.node.isRequired,
};
BlueText.propTypes = {
  children: PropTypes.node.isRequired,
};
BlueLink.propTypes = {
  children: PropTypes.node.isRequired,
};
