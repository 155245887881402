import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const AddReport = ({
  onClick,
  name,
}) => (
  <React.Fragment>
    <h1 style={{ color: 'white' }}>Add To Daily Portfolio Highlights</h1>
    <p style={{ color: 'white' }}>
      Your <b>daily portfolio highlights</b> is a personalized, daily email
      sent at 7PM ET each trading day with a summary of notable events in your custom portfolios
      including upcoming earnings, changing trends, 52-week highs and lows,
      and price movements above 5%. Click below to
      add <b>{name}</b> to your <b>daily portfolio highlights</b>.
    </p>
    <Modal.Actions>
      <Button color="orange" size="mini" onClick={onClick}>
        <Icon link name="edit" />Add to Custom Report
      </Button>
    </Modal.Actions>
  </React.Fragment>
);

AddReport.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AddReport;
