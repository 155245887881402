import React from 'react';

class ResearchInit extends React.Component {
  componentDidMount() {

  }

  render() {
    return null;
  }
}

export default ResearchInit;
