import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tab, Menu, Dimmer, Loader, Grid } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import StockSearch from '../../../containers/StockSearch';
import BarChart from '../../../presentational/charts/BarChart';
import Screener from './screener';
import GaugeRows from './earningsGauges';
import {
  getEcal,
  setEndDate,
  setFilterDate,
  getChartData,
  getQuarterlyData,
  setEEMode,
  setEETicker,
  setEETablePage,
} from '../../../../actions/data/tools';
import { navigateToPage } from '../../../../actions/search';
import Upgrade from '../../upgrade';

// const WrapInRestricted = trigger => (<Upgrade trigger={trigger} />);

const inputPaneItems = ecalDateSelectHandler => ([
  {
    key: 'daily',
    name: 'Today',
    onClick: ecalDateSelectHandler,
    time: 0,
  },
  {
    key: 'week',
    name: 'Next Week',
    onClick: ecalDateSelectHandler,
    time: 604800000,
  },
  {
    key: 'month',
    name: 'Next Month',
    onClick: ecalDateSelectHandler,
    time: 2592000000,
  },
  {
    key: 'all',
    name: 'Show All',
    onClick: ecalDateSelectHandler,
    time: Infinity,
  },
]);

class EarningsExplorerInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tabToMode = this.tabToMode.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const tab = props.match && props.match.params && props.match.params.tab;
    this.tabToMode(tab);
    if (props.match && props.match.params && props.match.params.ticker) {
      this.searchForTicker(props.match.params.ticker);
    } else {
      props.resetEETickerData();
    }
    // get data based on current tab
    this.props.getEcalData();
    // Check search params
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.match !== prevProps.match) {
      const tab = props.match && props.match.params && props.match.params.tab;
      this.tabToMode(tab);
    }
    if (props.tickerList.list.length > 0) {
      const prevTicker = prevProps.match && prevProps.match.params && prevProps.match.params.ticker;
      const ticker = props.match && props.match.params && props.match.params.ticker;
      if (ticker !== prevTicker ||
        (ticker && props.tickerList.list !== prevProps.tickerList.list)) {
        this.searchForTicker(ticker);
      }
      if (!ticker) {
        props.resetEETickerData();
      }
    }
  }

  // Set the mode based on tab
  tabToMode(tab) {
    let mode;
    const { setMode } = this.props;
    switch (tab) {
      case 'search':
        mode = 'single';
        break;
      case 'screen':
        mode = 'screen';
        break;
      case 'upcoming':
      default:
        mode = 'ecal';
        break;
    }
    setMode(mode);
  }

  searchForTicker(ticker) {
    const {
      tickerList,
      handleEESearch,
    } = this.props;

    const item = tickerList.list.find(el => el.ticker === ticker);
    if (item) {
      handleEESearch(item);
    }
  }

  render() {
    const {
      ecalDateSelectHandler,
      handleResultSelect,
      barChartClickHandler,
      ecal,
      endDate,
      match,
      membership,
      location,
    } = this.props;

    const tab = match && match.params && match.params.tab;
    let activeIndex = 0;
    switch (tab) {
      case 'search':
        activeIndex = 1;
        break;
      case 'screen':
        activeIndex = 2;
        break;
      case 'upcoming':
      default:
        break;
    }
    const dateComparator = (a, b) => {
      const a_split = a.label.split('/');
      const b_split = b.label.split('/');
      const a_raw = (Number(a_split[0]) * 35) + Number(a_split[1]);
      const b_raw = (Number(b_split[0]) * 35) + Number(b_split[1]);
      if (a_raw > b_raw) {
        return 1;
      }
      if (a_raw < b_raw) {
        return -1;
      }
      return 0;
    };
    const filteredData = Object.entries(ecal.data.filter(x =>
      Date.parse(x.reportdate) > Date.now() - 86400000 &&
      Date.parse(x.reportdate) <= (Date.now() + Math.max(endDate, 604800000)))
      .reduce((acc, curr) => {
        acc[curr.reportdate] = (acc[curr.reportdate] || 0) + 1;
        return acc;
      }, {}))
      .map(([key, val]) => ({
        label: key.replace(/\d{2}(\d+)-0?(\d+)-0?(\d+).*/gi, '$2/$3'),
        value: val,
        time: Date.parse(key),
      }))
      .sort(dateComparator);

    const panes = [
      {
        menuItem: { content: 'Calendar', as: (Link), to: '/earnings-explorer/upcoming' },
        tabName: 'upcoming',
        render: () => (
          <React.Fragment>
            <Tab.Pane attached className="upcoming-second-step">
              <style>
                {'.ecalBarChart rect:hover {opacity: 0.7;}'}
              </style>
              <h1 style={{ fontSize: '14px', textAlign: 'center', margin: 0 }}># of Earnings Reports by Day</h1>
              <BarChart
                data={filteredData}
                size={{ width: 300, height: 322 }}
                margins={{
                  top: 10,
                  right: 10,
                  bottom: 30,
                  left: 36,
                }}
                className="ecalBarChart"
                handleClick={barChartClickHandler}
              />
            </Tab.Pane>
            <Menu
              size="tiny"
              widths={inputPaneItems().length}
              attached="bottom"
              items={inputPaneItems(ecalDateSelectHandler)
                .map(x => ({ ...x, active: (x.time === endDate) }))}
            />
          </React.Fragment>),
      },
      {
        menuItem: { content: 'Stock Search', as: (Link), to: '/earnings-explorer/search' },
        tabName: 'search',
        render: () => (
          <Tab.Pane attached="bottom">
            <Grid stackable className="search-second-step">
              <Grid.Row style={{ padding: '10px 0px' }} columns={1} >
                <Grid.Column>
                  <StockSearch onResultSelect={handleResultSelect} />
                </Grid.Column>
              </Grid.Row>
              <GaugeRows />
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { content: 'Stock Screen', as: (Link), to: '/earnings-explorer/screen' },
        tabName: 'screen',
        render: () => (
          <Tab.Pane attached="bottom">
            <Screener />
          </Tab.Pane>),
      },
    ];
    return (
      <Dimmer.Dimmable dimmed={ecal.isLoading}>
        <Dimmer inverted style={{ borderRadius: '2px' }} active={ecal.isLoading} >
          <Loader />
        </Dimmer>
        {membership && membership.mem_level !== 4 && /screen|search/.test(location.pathname) ? <Upgrade /> : ''}
        <Tab
          menu={{
            attached: 'top',
            widths: 3,
          }}
          panes={membership ? panes.map(x => ({
            ...x,
            menuItem: {
              ...x.menuItem,
              content: membership.mem_level !== 4 ?
                x.menuItem.content : x.menuItem.content,
              // className: `${x.tabName} first-step`,
            },
          })) :
          ''}
          activeIndex={activeIndex}
        />
      </Dimmer.Dimmable>);
  }
}

EarningsExplorerInput.propTypes = {
  ecal: PropTypes.object.isRequired,
  handleResultSelect: PropTypes.func.isRequired,
  handleEESearch: PropTypes.func.isRequired,
  ecalDateSelectHandler: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  barChartClickHandler: PropTypes.func.isRequired,
  endDate: PropTypes.number.isRequired,
  getEcalData: PropTypes.func.isRequired,
  tickerList: PropTypes.object.isRequired,
  membership: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  resetEETickerData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ecal: state.data.ecal,
  endDate: state.view.earningsExplorer.endDate,
  tickerList: state.data.tickerList,
  membership: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  getEcalData: () => dispatch(getEcal()),
  ecalDateSelectHandler: (e, d) => {
    dispatch(setEETablePage(1));
    dispatch(setEndDate(d.time));
    dispatch(setFilterDate(undefined));
  },
  handleEESearch: ({ ticker, company }) => {
    dispatch(getChartData(ticker));
    dispatch(getQuarterlyData(ticker));
    dispatch(setEETicker({ title: ticker, description: company }));
  },
  handleResultSelect: (e, d) => {
    dispatch(navigateToPage(`/earnings-explorer/search/${d.result.title}`));
  },
  setMode: (mode) => {
    dispatch(setEEMode(mode));
    dispatch(setEETablePage(1));
  },
  barChartClickHandler: (bar) => {
    dispatch(setFilterDate(bar.time));
    dispatch(setEETablePage(1));
  },
  resetEETickerData: () => {
    dispatch(getChartData(null));
    dispatch(setEETicker(null));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EarningsExplorerInput));
