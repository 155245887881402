import React from 'react';
import * as data from '../data';
import * as researchActions from '../content/research';
import * as userActions from '../user';
import * as categories from '../../api/category';
import Summary from '../../components/presentational/Summary';
import CatName from '../../components/presentational/SearchCategoryName';
import { formatGICS } from '../../components/presentational/TextFormats';

// const tickerRE = /[$]([a-zA-Z]+)\b/;

const searchStringMatches = (query, search) => {
  const index = query.toUpperCase().indexOf(search.toUpperCase());
  return index === 0 || (index > 0 && query[index - 1] === ' ');
};


const types = {
  TICKER: 'TICKER',
  BLOG_POST: 'BLOG_POST',
  POST: 'POST',
  HOME: 'HOME',
  PORTAL: 'PORTAL',
  HELP: 'HELP',
  PAGE: 'PAGE',
};

const viewTypes = {
  CARDS: 'CARDS',
};

// // matches- case insensitive
// const portals = {
//   home: {
//    title: '🏠 Home', type: types.HOME,
//    description: 'Our most recent research and must reads'
//   },
//   research: {
//    title: '📚 Research Portal',
//    description: 'View our most recent research and archives'
//   },
//   tools: {
//    title: '🔧 Interactive Tools',
//    description: 'Get access to all of our tools in one spot.'
//   },
//   portfolios: {
//    title: '🚀 Custom Portfolios',
//    descriptions: 'Easily track stocks & ETFs with our custom portfolios feature.'
//   },
// };
//

const showHelp = () => ({
  // put the commands here.
  name: 'Commands',
  results: [],
});

const searchTickers = onlyTickers => async (dispatch, string) => {
  const category = {
    name: <CatName text="Stocks" />,
    key: 'stocks',
    results: [],
  };
  const partialMatches = [];
  const exactMatches = [];
  const tickerCheck = string.toUpperCase();
  const companyCheck = string.toLowerCase();
  const list = await dispatch(data.tickers.getList(onlyTickers));
  // Compare args against companies / tickers in the list
  for (let i = 0; i < list.length; i += 1) {
    const ticker = (onlyTickers ? list[i] : list[i].ticker).toUpperCase();
    // Check ticker partial + exact matching
    if (ticker === tickerCheck) {
      exactMatches.push(ticker);
    } else if (!onlyTickers && list[i].company.toLowerCase() === companyCheck) {
      exactMatches.push(ticker);
    } else if (ticker.startsWith(tickerCheck)) {
      partialMatches.push(ticker);
    } else if (!onlyTickers && list[i].company.toLowerCase().startsWith(companyCheck)) {
      partialMatches.push(ticker);
    }
  }
  // Get 10 tickers in the list, and see if their reference data is in the cache
  const matches = [...exactMatches, ...partialMatches].slice(0, 4);

  // Updating uncached ticker data
  if (matches.length > 0) {
    const resp = await dispatch(data.tickers.getSummaryData(matches));
    resp.tickers.forEach((x) => {
      const summary = <Summary key="summary" ticker={x} timing trend />;
      const tickerItem = {
        title: `$${x.ticker} - ${x.company}`,
        description: [formatGICS(x.gics_sector, x.gics_group), <br key="lb" />, summary],
        view: viewTypes.CARDS,
        type: types.TICKER,
        ticker: x,
        tabIndex: 0,
      };
      if (x.ticker === tickerCheck) {
        category.results.unshift(tickerItem);
      } else {
        category.results.push(tickerItem);
      }
    });
  }
  // Format reference data into items for the category.
  if (onlyTickers) {
    return {
      tickers: category,
    };
  }
  return category;
};

const searchResearch = onlyPosts => async (dispatch, string) => {
  if (string === '') {
    return ({
      error: {
        name: <CatName text="Help" />,
        key: 'search-help',
        results: [
          {
            title: 'R: <query>',
            description: 'Search all Bespoke research for <query>',
            type: types.HELP,
            tabIndex: 0,
          },
        ],
      },
    });
  }
  const category = {
    name: <CatName text="Research" />,
    key: 'research',
    results: [],
  };
  const dateOpts = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  let result;
  try {
    result = await dispatch(researchActions.getSearchData(string, 1, 10, false));
  } catch (err) {
    return ({
      error: {
        name: <CatName text="Error" />,
        key: 'search-error',
        results: [
          {
            title: 'Error!',
            description: err.message,
            type: 'ERROR',
          },
        ],
      },
    });
  }
  category.results = result.slice(0, 5).map(x => ({
    title: x.title,
    description: `${x.category_name.join(', ')} - ${new Date(x.date_modified).toLocaleString([], dateOpts)}`,
    post: x.id,
    view: viewTypes.CARDS,
    type: types.POST,
    url: `/posts/${x.category_slug[0]}/${x.post_slug}`,
    tabIndex: 0,
  }));
  category.results.unshift({
    title: `Show all research results for "${string}"`,
    type: types.PAGE,
    url: `/research/search/${string}/1`,
    tabIndex: 0,
  });
  if (onlyPosts) {
    return ({
      research: category,
    });
  }
  return category;
};

const searchTools = onlyTools => async (dispatch, string) => {
  const category = {
    name: <CatName text="Tools" />,
    key: 'tools',
    results: [],
  };
  const tools = [
    {
      title: 'Trend Analyzer',
      description: 'View stock & ETF trends at a glance.',
      type: types.PAGE,
      url: '/trend-analyzer',
    },
    {
      title: 'Chart Scanner',
      description: 'Easily browse through a large number of charts to find bullish or bearish patterns.',
      type: types.PAGE,
      url: '/chart-scanner',
    },
    {
      title: 'Seasonality Database',
      description: 'See historic stock seasonality data.',
      type: types.PAGE,
      url: '/seasonality-tool',
    },
    {
      title: 'Earnings Explorer',
      description: 'See upcoming and historical earnings insights for over 3000 equities.',
      type: types.PAGE,
      url: '/earnings-explorer',
    },
    {
      title: 'Economic Monitors',
      description: 'Economic data releases, central bank meetings, and fed sentiment.',
      type: types.PAGE,
      url: '/economic-monitors',
    },
    {
      title: 'International Revenues Database',
      description: 'Search domestic and international revenues.',
      type: types.PAGE,
      url: '/international-revenues',
    },
    {
      title: 'Triple Plays',
      description: 'View recent earnings triple plays.',
      type: types.PAGE,
      url: '/triple-plays',
    },
    {
      title: 'Economic Indicators Database',
      description: 'Search economic indicators with historic readings, estimates, and reactions.',
      type: types.PAGE,
      url: '/economic-database',
    },
    {
      title: 'Security Analysis',
      description: 'Detailed insights on any stock or ETF we track.',
      type: types.PAGE,
      url: '/ticker/SPY',
    },
    // {
    //   title: 'Earnings Screener / Database',
    //   description: 'Earnings Screener / Database copy here.',
    //   type: types.PAGE,
    //   url: '/earnings-screener',
    // },
    // {
    //   title: 'Earnings Calendar',
    //   description: 'Earnings Calendar copy here.',
    //   type: types.PAGE,
    //   url: '/earnings-calendar',
    // },
  ];
  for (let i = 0; i < tools.length; i += 1) {
    if (searchStringMatches(tools[i].title, string)) {
      category.results.push(tools[i]);
    }
  }
  try {
    const results = await categories.getAll();
    for (let i = 0; i < results.length; i += 1) {
      const cat = results[i];
      if (cat.name.toUpperCase().includes(string.toUpperCase()) && string !== '') {
        if (cat.in_chartbook === 1) {
          category.results.push({
            title: `Chart Scanner -- ${cat.name}`,
            type: types.PAGE,
            url: `/chart-scanner/${cat.id}`,
          });
        }
        if (cat.in_trend_analyzer === 1) {
          category.results.push({
            title: `Trend Analyzer -- ${cat.name}`,
            type: types.PAGE,
            url: `/trend-analyzer/${cat.id}`,
          });
        }
      }
    }
  } catch (err) {
    console.log(err);
  }
  if (string === '') {
    category.results.unshift({
      title: 'IT: <category|name>',
      description: 'Search for interactive tools by subcategory (eg. \'Health Care\') or tool name.',
      type: types.HELP,
    });
  }

  if (onlyTools) {
    return ({
      tools: category,
    });
  }
  category.results = category.results.slice(0, 4); // limit amount of tools returned
  return category;
};

const searchPortfolios = onlyPortfolios => async (dispatch, string) => {
  const category = {
    name: <CatName text="Portfolios" />,
    key: 'portfolios',
    results: [],
  };
  const { byId } = await dispatch(userActions.getPortfolios());
  const keys = Object.keys(byId);
  for (let i = 0; i < keys.length; i += 1) {
    const pf = byId[keys[i]];
    if (pf.name.toUpperCase().includes(string.toUpperCase())) {
      category.results.push({
        title: pf.name,
        description: `This portfolio contains: ${pf.members}`,
        type: types.PAGE,
        tabIndex: 0,
        url: `/portfolio/${pf.id}`,
      });
    }
  }
  if (onlyPortfolios) {
    return ({
      portfolios: category,
    });
  }
  return category;
};

// @todo total result round robin picking from each category
export const searchGeneral = (dispatch, args) => {
  const promises = [];
  promises.push(searchTickers(false)(dispatch, args));
  promises.push(searchResearch(false)(dispatch, args));
  promises.push(searchTools(false)(dispatch, args));
  promises.push(searchPortfolios(false)(dispatch, args));
  return Promise.all(promises)
    .then(([tickers, research, tools, portfolios]) => {
      const result = {};
      if (tools.results.length > 0) {
        result.tools = tools;
      }
      if (research.results.length > 0) {
        result.research = research;
      }
      if (portfolios.results.length > 0) {
        result.portfolios = portfolios;
      }
      if (tickers.results.length > 0) {
        result.tickers = tickers;
      }
      return result;
    });
};

export const commands = {
  $: {
    args: [{ name: 'TICKER', optional: false }],
    format: '$',
    description: 'Search for stocks and ETFs by ticker.',
    suggestionFunc: searchTickers(true),
  },
  R: {
    args: [{ name: 'query', optional: true }],
    format: 'R: ',
    description: 'Search for research. Displays most recent research if no argument provided.',
    suggestionFunc: searchResearch(true),
  },
  // CS: {args: [], description: '' },
  IT: {
    args: [],
    description: 'Search for interactive tools.',
    suggestionFunc: searchTools(true),
  },
  P: {
    args: [{ name: 'portfolio', optional: true }],
    description: 'Search for your custom portfolios',
    suggestionFunc: searchPortfolios(true),
  },
  // TA: { args: [], description: '' },
  // MON: { args: [], description: '' },
  // ES: { args: [], description: '' },
  // SZN: { args: [], description: '' },
  // EC: { args: [], description: '' },
  // EI: { args: [], description: '' },
  // IR: { args: [], description: '' },
  HELP: {
    args: [],
    description: 'List all available commands.',
    suggestionFunc: showHelp,
  },
};
