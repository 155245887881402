import React from 'react';
import { Search } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class InternationalRevenuesSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.TOTAL_MATCH_LIMIT = 5;

    this.resultSelect = this.resultSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = {
      results: [],
      value: '',
    };
  }

  resultSelect(parentFunction) {
    return (event, data) => {
      this.setState({
        value: data.result.title,
      });
      return parentFunction(event, data);
    };
  }

  handleSearchChange(e, data) {
    const { tickers } = this.props;
    const exactMatches = [];
    const partialMatches = [];
    for (let i = 0, len = tickers.length; i < len; i += 1) {
      // Assign vars
      const { company, ticker, sector } = tickers[i];
      // check exact matches first
      if (company.toLowerCase() === data.value.toLowerCase()
        || ticker.toUpperCase() === data.value.toUpperCase()) {
        exactMatches.push({ title: ticker, description: company, sector });
      } else if (company.toLowerCase().startsWith(data.value.toLowerCase()) ||
          ticker.toUpperCase().startsWith(data.value.toUpperCase())) {
        partialMatches.push({ title: ticker, description: company, sector });
      }
    }
    const newResults = [...exactMatches, ...partialMatches]
      .slice(0, this.TOTAL_MATCH_LIMIT);
    // generate the updated list
    this.setState({
      value: data.value,
      results: newResults,
    });
  }

  render() {
    const { onSelect } = this.props;
    return (
      <Search
        style={{ maxWidth: '300px' }}
        placeholder="Filter by ticker or company..."
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.resultSelect(onSelect)}
        results={this.state.results}
        value={this.state.value}
        className="fifth-step"
      />);
  }
}

InternationalRevenuesSearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  tickers: PropTypes.array.isRequired,
};

export default InternationalRevenuesSearch;
