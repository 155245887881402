import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AddPortfolio from './addPortfolio';

class AddPortfolioModal extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;

    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click() })}
        <Modal basic onClose={this.close} open={open} {...this.props} closeIcon>
          <center>
            <h1 style={{ color: 'white' }}>Create a New Custom Portfolio</h1>
            <Modal.Content>
              <p>
              Type in a name to create a new portfolio.
              </p>
              <br />
            </Modal.Content>
            <Modal.Actions>
              {/* <Button basic color="white" inverted onClick={this.close}>
                Nevermind
              </Button>
              <Button color="red" inverted onClick={() => { window.location = 'https://www.bespokepremium.com/upgrade-landing-page/'; }}>
                <Icon name="remove" /> Delete
              </Button> */}
              <AddPortfolio submitAction={(result) => { this.props.submitAction(result); this.close(); }} style={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Modal.Actions>
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

AddPortfolioModal.defaultProps = {
  submitAction: () => null,
  content: (<Icon link name="plus" color="green" />),
};

AddPortfolioModal.propTypes = {
  submitAction: PropTypes.func,
  content: PropTypes.element,

};

export default AddPortfolioModal;
