import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Header } from 'semantic-ui-react';
import SignUp from '../presentational/signup';
import BackButton from '../presentational/interactive/backButton';


class Account extends React.PureComponent {
  resizeIframe = (event) => {
    const ifr = event.target;
    ifr.style.height = `${ifr.contentWindow.document.body.scrollHeight}px`;
    ifr.contentDocument.getElementById('main-header').outerHTML = '';
    ifr.contentDocument.getElementById('footer-bottom').outerHTML = '';
    setTimeout(() => { ifr.contentDocument.getElementById('page-container').style = 'padding-top: 0px'; }, 500);
    ifr.contentDocument.getElementsByClassName('main_title')[0].outerHTML = '';
    ifr.contentDocument.getElementsByClassName('container')[0].style = 'padding-top: 0px !important; width: 100% !important';
  }

  render() {
    const { access } = this.props;
    if (access === null) {
      return (<SignUp
        message={['You don\'t have access to this post. If you would like to see this post, please log-in or become a member.']}
      />);
    }

    return (
      <Container className="iframe">
        <style type="text/css">
          {'body{ background-color: white; }'}
        </style>
        <div className="account content">
          <BackButton />
          <Header as="h1">My Account</Header>
          {/* <Tab panes={panes} /> */}
          <iframe
            title="Account"
            src="https://www.bespokepremium.com/my-account/"
            frameBorder={0}
            scrolling="no"
            onLoad={this.resizeIframe}
            style={{
              width: '100%',
              minHeight: '20px',
            }}
          />
        </div>
      </Container>);
  }
}

Account.defaultProps = {
  // post: undefined,
};

Account.propTypes = {
  access: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  access: state.user.access,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
