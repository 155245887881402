import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Message } from 'semantic-ui-react';
import { createPortfolio } from '../../../../actions/user';

class addPortfolio extends React.Component {
  state = { name: '' };

  handleChange = (e, { name, value }) => this.setState({ [name]: value, error: false, errorMessage: '' })

  handleSubmit = () => this.props.createPortfolioAction(this.state.name)
    .then(result => this.props.submitAction(result))
    .catch(e => this.setState({ error: true, errorMessage: e.message }))

  render() {
    const { name, error, errorMessage } = this.state;
    return (
      <Form
        error={error}
        onSubmit={this.handleSubmit}
      >
        <Form.Group style={{ width: '320px', textAlign: 'center' }}>
          <Form.Input error={error} placeholder="Create a new portfolio..." name="name" value={name} onChange={this.handleChange} />
          <Form.Button content="Create" />
        </Form.Group>
        <Message
          error
          // header="Error"
          content={errorMessage}
        />
      </Form>);
  }
}

addPortfolio.defaultProps = {
  submitAction: () => null, // to pass in modal close from addPortfolioModal
};

addPortfolio.propTypes = {
  createPortfolioAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func,
};

const mapStateToProps = state => ({
  state,
});

const mapDispatchToProps = dispatch => ({
  createPortfolioAction: name => dispatch(createPortfolio(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(addPortfolio);
