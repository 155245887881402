import { executeQuery, mainPath, encodeArgs } from './query';

const userPath = `${mainPath}/api/users`;
// GET
export const getUser = () => executeQuery(`${userPath}`);

// GET List all of a user's portfolios
export const getPortfolios = () => executeQuery(`${userPath}/portfolios`);

// POST Create a new custom portfolio
export const createPortfolio = name => executeQuery(`${userPath}/portfolios`, 'POST', { name });

// PUT Update (rename) a custom portfolio
export const updatePortfolio = (name, id) => executeQuery(`${userPath}/portfolios/${id}`, 'PUT', { name });

// DELETE Delete a custom portfolio
export const deletePortfolio = id => executeQuery(`${userPath}/portfolios/${id}`, 'DELETE');

// GET List the tickers in a custom portfolio
export const getPortfolioMembers = id => executeQuery(`${userPath}/portfolios/${id}/members`);

// POST Add one or more tickers to a custom portfolio
export const addPortfolioMembers = (id, tickers) => executeQuery(`${userPath}/portfolios/${id}/members`, 'POST', { tickers });

// DELETE Delete members of a custom portfolio. user query params for id's.
export const deletePortfolioMembers = (id, tickers) => executeQuery(`${userPath}/portfolios/${id}/members/?${encodeArgs(tickers, 'tickers')}`, 'DELETE');

export const togglePortfolioCustomReport = (id, include) => executeQuery(`${userPath}/portfolios/${id}/custom-report`, 'POST', { include });

// GET List a user's bullish and bearish calls.
export const getBullishBearish = () => executeQuery(`${userPath}/chartbook`);

// POST Update a user's bullish and bearish calls
export const updateBullishBearish = data => executeQuery(`${userPath}/chartbook`, 'POST', data);

// GET List a user's starred posts.
export const getStarredPosts = () => executeQuery(`${userPath}/starred`);

// POST Add a new starred post.
export const addStarredPost = data => executeQuery(`${userPath}/starred`, 'POST', data);

// DELETE Delete a starred post
export const deleteStarredPost = postId => executeQuery(`${userPath}/starred/${postId}`, 'DELETE');

// GET user settings
export const getSettings = () => executeQuery(`${userPath}/settings`);

// Update user settings
export const saveSettings = settings => executeQuery(`${userPath}/settings`, 'POST', settings);

// Copy Portfolio
export const copyPortfolio = id => executeQuery(`${userPath}/copy-portfolio/${id}`);
