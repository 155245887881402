import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Icon } from 'semantic-ui-react';

class Touring extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      target: null,
    };

    this.openTour = this.openTour.bind(this);
    this.closeTour = this.closeTour.bind(this);
    this.disableBody = this.disableBody.bind(this);
    this.checkCookie = this.checkCookie.bind(this);
  }

  componentDidMount() {
    this.checkCookie();
  }

  componentDidUpdate() {
    this.checkCookie();
  }

  checkCookie() {
    if (!cookie.load(this.props.cookieName)) {
      this.openTour();
    }
  }

  openTour() {
    this.setState({ open: true });
  }

  closeTour() {
    const { target } = this.state;
    this.saveCookie();
    this.setState({ open: false });
    enableBodyScroll(target);
  }

  disableBody(target) {
    disableBodyScroll(target);
    this.setState({ open: true, target });
  }

  saveCookie = (days) => {
    let d = 3650;
    if (days !== undefined) {
      d = days;
    }

    const expDays = new Date();
    expDays.setDate(expDays.getDate() + d);

    cookie.save(this.props.cookieName, true, { expires: expDays });
  };

  render() {
    const { open } = this.state;
    const {
      steps,
      cookieName,
      update,
      updateDelay,
      hideIcon,
    } = this.props;
    const accentColor = '#003051';

    if (cookie.load(cookieName) && !open) {
      if (hideIcon) {
        return '';
      }

      return (
        <Icon link name="info circle" onClick={this.openTour} size="small" style={{ marginLeft: '5px' }} />
      );
    }

    return (
      <Tour
        steps={steps}
        isOpen={open}
        onRequestClose={this.closeTour}
        maskClassName="mask"
        className="helper"
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={this.disableBody}
        update={update}
        updateDelay={updateDelay}
      />
    );
  }
}

Touring.propTypes = {
  cookieName: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  update: PropTypes.string,
  updateDelay: PropTypes.number,
  hideIcon: PropTypes.bool,
};

Touring.defaultProps = {
  update: null,
  updateDelay: 1,
  hideIcon: false,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});


export default connect(mapDispatchToProps)(Touring);
