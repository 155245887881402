import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Dimmer,
  Dropdown,
  Form, Grid,
  Icon,
  Loader,
  Menu,
  Modal,
  Radio,
  // Input,
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import { isMobile } from 'react-device-detect';
import { getCategories, getScreens, getCSData } from '../../actions/data/tools';
import { updateCSSettings, loadSettings, saveSettings } from '../../actions/user';
import ChartGrid from '../presentational/interactive/chartScanner/ChartGrid';
import ColorPicker from '../presentational/ColorPicker';
import FloatingScrollTop from '../presentational/FloatingScrollTop';
import UpgradeConnected from '../presentational/upgradeConnected';
import { navigateToPage, toggleSearch, updateSearch } from '../../actions/search';
import SharePortfolioModal from '../presentational/interactive/customPortfolios/sharePortfolioModal';
import Touring from '../presentational/interactive/tour';

// ////////////////////////////////////////////////////////// //
// █▀▀ █░░█ █▀▀█ █▀▀█ ▀▀█▀▀   █▀▀ █▀▀ █▀▀█ █▀▀▄ █▀▀▄ █▀▀ █▀▀█ //
// █░░ █▀▀█ █▄▄█ █▄▄▀ ░░█░░   ▀▀█ █░░ █▄▄█ █░░█ █░░█ █▀▀ █▄▄▀ //
// ▀▀▀ ▀░░▀ ▀░░▀ ▀░▀▀ ░░▀░░   ▀▀▀ ▀▀▀ ▀░░▀ ▀░░▀ ▀░░▀ ▀▀▀ ▀░▀▀ //
// ////////////////////////////////////////////////////////// //

class ChartScanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
    this.CpComp = ColorPicker(SketchPicker);

    // function binding
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.handleColorChange = debounce(this.handleColorChange, 100).bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.modalClose = this.modalClose.bind(this);

    // set custom categories here
    this.customScreens = [];
  }

  componentDidMount() {
    // Check path and take action
    const { match } = this.props;
    const cat = match && match.params && match.params.category;
    const id = match && match.params && match.params.id;
    if (cat === undefined && id === undefined) {
      this.props.navigateToPage('/chart-scanner/etfs/21');
    }

    if (cat && id) {
      this.props.getCSData(cat, id);
    }
    // Load all categories
    this.props.getCategories();
    // Load all screens
    this.props.getScreens();
    //  Load user settings
    this.props.loadSettings();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (match !== prevProps.match || this.props.portfolios !== prevProps.portfolios) {
      // Url has changed
      let cat = match && match.params && match.params.category;
      let id = match && match.params && match.params.id;
      if (cat === undefined && id === undefined) {
        cat = 'etfs';
        id = '21';
      }

      if (cat && id) {
        this.props.getCSData(cat, id);
      }
    }

    if (
      prevProps &&
      this.props.searchActive !== prevProps.searchActive &&
      this.props.searchActive
    ) {
      this.props.onSearchChangeHandler();
    }
  }

  handleSettingsChange(e, d) {
    // handle changed settings
    const { updateSettings } = this.props;
    updateSettings(d.name, d.value);
  }

  handleColorChange(val, name) {
    // handle change in color
    const { updateSettings } = this.props;
    updateSettings(name, val);
  }

  handleClose() {
    this.props.saveSettings();
    this.props.loadSettings();
    this.modalClose();
  }

  handleOpen() {
    this.setState({ modalOpen: true });
  }

  modalClose() {
    this.setState({ modalOpen: false });
  }

  render() {
    const {
      categories,
      chartScannerData,
      portfolios,
      screens,
      tickerCalls,
      csSettings,
      handleToggleSearch,
      match,
    } = this.props;

    const { CpComp } = this;
    const cat = match && match.params && match.params.category;
    const catId = match && match.params && match.params.id;
    const id = cat === 'custom-portfolios' && catId ? catId : '';
    const title = id === '' ? 'Share This Page' : '';
    const text = id === '' ? 'Copy link to share this page with other Bespoke Premium and Institutional members.' : '';
    const disabled = cat === 'my-charts';

    const steps = [
      {
        selector: '.first-step',
        content: 'Our Chart Scanner tool is the best way for chart readers to quickly browse through a large number of stocks or ETFs.',
      },
      {
        selector: '.second-step',
        content: 'Start by clicking one of the drop-down categories and select an index, sector, or asset class.  You can view charts of securities in your Custom Portfolios and pre-made Screens as well.',
      },
      {
        selector: '.third-step',
        content: 'Click the "Settings" button to choose your preferred chart type and save it as your default chart setting.',
      },
      {
        selector: '.forth-step',
        content: 'Each chart is interactive.  Drag the price (Y-axis) up or down or the date (X-axis) right or left to zoom in or out.  Hover over the middle of the chart for a price and date tracker.',
      },
      {
        selector: '.fifth-step',
        content: 'If you think a chart looks attractive or bullish, click the "Bull" icon.  If you think a chart looks unattractive or bearish, click the "Bear" icon.',
      },
      {
        selector: '.sixth-step',
        content: 'Once you\'re done browsing, all of the charts you\'ve marked as Bullish or Bearish will populate in the "Bullish" and "Bearish" pages of your "My Charts" section.  Any time you come back to the Chart Scanner tool, your Bullish and Bearish charts will be saved in your "My Charts" section.',
      },
      {
        selector: '.seventh-step',
        content: 'You can easily clear your Bullish and Bearish charts by simply clicking the "Bull" or "Bear" icons on each chart.',
      },
      {
        selector: '.eighth-step',
        content: 'By consistently marking charts as "Bullish" or "Bearish," you\'ll test and hopefully improve your chart reading skills over time.  The number of charts you mark as "Bullish" or "Bearish" is also a good way to gauge your overall view of the market.',
      },
      {
        selector: '.ninth-step',
        content: 'Our Chart Scanner tool is available to Bespoke Premium and Bespoke Institutional members.  This tool is meant to be a starting point for further research and is NOT a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="chartScannerTouring"
        steps={steps}
      />);

    // Generate the Chart Scanner menu
    // @todo: Loading indicator?
    const menu = (
      <Menu className="second-step" style={{ display: isMobile ? 'none' : '' }} stackable>
        <Dropdown closeOnBlur key="indices" item text="Indices">
          <Dropdown.Menu>
            {categories.data.filter(category => category.is_index === 1)
              .map(item => (
                <Dropdown.Item key={item.id}>
                  <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/indices/${item.id}`}>{item.name}</Link>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown closeOnBlur key="sectors" item text="Sectors">
          <Dropdown.Menu>
            <Dropdown.Header>
              S&amp;P 500 by Sector&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Dropdown.Header>
            {categories.data.filter(category => category.in_chartbook === 1)
              .map(item => (
                <Dropdown.Item key={item.id}>
                  <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/sectors/${item.id}`}>{item.name}</Link>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown closeOnBlur key="etfs" item text="ETFs">
          <Dropdown.Menu>
            {categories.data.filter(category => category.is_etf_cat === 1)
              .map(item => (
                <Dropdown.Item key={item.id}>
                  <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/etfs/${item.id}`}>{item.name}</Link>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown closeOnBlur key="custom-pf" item text="Custom Portfolios">
          <Dropdown.Menu>
            {
              Object.entries(portfolios.byId).length > 0 ?
                Object.entries(portfolios.byId)
                  .map(([key, value]) => (
                    <Dropdown.Item key={key}>
                      <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/custom-portfolios/${key}`}>{`${value.name} (${value.members.length})`}</Link>
                    </Dropdown.Item>
                  ))
                :
                <Dropdown.Item key="AddPortfolio">
                  <Link style={{ display: 'block', width: '100%' }} to="/portfolio?action=add" >Add Portfolio </Link>
                </Dropdown.Item>
            }
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="sixth-step" closeOnBlur key="my-charts" item text="My Charts">
          <Dropdown.Menu>
            {Object.entries(tickerCalls.byId)
              .map(([key, value]) => (
                <Dropdown.Item key={key}>
                  <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/my-charts/${key}`}>{`${key} (${value.length})`}</Link>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown closeOnBlur key="screens" item text="Screens">
          <Dropdown.Menu>
            {screens.data
              .map(item => (
                <Dropdown.Item key={item.id}>
                  <Link style={{ display: 'block', width: '100%' }} to={`/chart-scanner/screens/${item.id}`}>{`${item.name} (${item.results})`}</Link>
                </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );

    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />

        <style>
          {`.chart-series { display: none; } .chart-series.${csSettings.type}{ display: block; }`}
          {`.chart-series.line { stroke: ${csSettings.colors.line}; } .chart-series.candle .down { stroke: ${csSettings.colors.down}; fill: ${csSettings.colors.down};}`}
          {`.chart-series.candle .up { stroke: ${csSettings.colors.up}; fill: ${csSettings.colors.up};}`}
          {`.chart-series.ohlc .up { stroke: ${csSettings.colors.up}; } .chart-series.ohlc .down { stroke: ${csSettings.colors.down}; }`}
          {`.sma20 { stroke: ${csSettings.colors.dma20}; display: ${csSettings.indicators.dma20 ? 'inline' : 'none'}; }`}
          {`.sma50 { stroke: ${csSettings.colors.dma50}; display: ${csSettings.indicators.dma50 ? 'inline' : 'none'}; }`}
          {`.sma100 { stroke: ${csSettings.colors.dma100}; display: ${csSettings.indicators.dma100 ? 'inline' : 'none'}; }`}
          {`.sma200 { stroke: ${csSettings.colors.dma200}; display: ${csSettings.indicators.dma200 ? 'inline' : 'none'}; }`}
        </style>
        <Dimmer inverted active={chartScannerData.loading || portfolios.lastFetched === null}>
          <Loader />
        </Dimmer>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <h1 className="first-step">Chart Scanner {chartScannerData.title} {tour}</h1>
            </Grid.Column>
            <Grid.Column className="right aligned content" verticalAlign="middle">
              {/* Settings modal (can be extracted into a component) */}
              <Modal
                style={{ marginTop: '75px' }}
                trigger={
                  <Button
                    color="blue"
                    size="mini"
                    onClick={this.handleOpen /* open settings menu */}
                    className="third-step"
                  >
                    <Icon name="cogs" />
                    SETTINGS
                  </Button>
                }
                open={this.state.modalOpen}
                closeIcon
                onClose={this.modalClose}
              >
                <Modal.Header>Chart Scanner Settings</Modal.Header>
                <Modal.Content image>
                  <Modal.Description>
                    <h3>Chart Type</h3>
                    <Form>
                      <Form.Field>
                        <Radio
                          label="Candlestick"
                          name="type"
                          value="candle"
                          checked={csSettings.type === 'candle'}
                          onChange={this.handleSettingsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Line"
                          name="type"
                          value="line"
                          checked={csSettings.type === 'line'}
                          onChange={this.handleSettingsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="OHLC"
                          name="type"
                          value="ohlc"
                          checked={csSettings.type === 'ohlc'}
                          onChange={this.handleSettingsChange}
                        />
                      </Form.Field>
                    </Form>
                    <h3>Chart Colors</h3>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <CpComp
                        text="Line"
                        color={csSettings.colors.line}
                        handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.line')}
                      />
                      <CpComp
                        text="Candle Up"
                        color={csSettings.colors.up}
                        handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.up')}
                      />
                      <CpComp
                        text="Candle Down"
                        color={csSettings.colors.down}
                        handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.down')}
                      />
                    </div>
                    <h3>Indicators</h3>
                    <Form>
                      <Form.Field>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            label="20-DMA"
                            name="indicators.dma20"
                            value={!csSettings.indicators.dma20}
                            checked={csSettings.indicators.dma20}
                            onChange={this.handleSettingsChange}
                          />
                          <CpComp
                            text="Color"
                            buttonStyle={{ fontSize: '14px', padding: '5px 10px' }}
                            color={csSettings.colors.dma20}
                            handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.dma20')}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            label="50-DMA"
                            name="indicators.dma50"
                            value={!csSettings.indicators.dma50}
                            checked={csSettings.indicators.dma50}
                            onChange={this.handleSettingsChange}
                          />
                          <CpComp
                            text="Color"
                            buttonStyle={{ fontSize: '14px', padding: '5px 10px' }}
                            color={csSettings.colors.dma50}
                            handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.dma50')}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            label="100-DMA"
                            name="indicators.dma100"
                            value={!csSettings.indicators.dma100}
                            checked={csSettings.indicators.dma100}
                            onChange={this.handleSettingsChange}
                          />
                          <CpComp
                            text="Color"
                            buttonStyle={{ fontSize: '14px', padding: '5px 10px' }}
                            color={csSettings.colors.dma100}
                            handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.dma100')}
                          />
                        </div>
                      </Form.Field>
                      <Form.Field>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox
                            label="200-DMA"
                            name="indicators.dma200"
                            value={!csSettings.indicators.dma200}
                            checked={csSettings.indicators.dma200}
                            onChange={this.handleSettingsChange}
                          />
                          <CpComp
                            text="Color"
                            buttonStyle={{ fontSize: '14px', padding: '5px 10px' }}
                            color={csSettings.colors.dma200}
                            handleChangeComplete={val => this.handleColorChange(val.hex, 'colors.dma200')}
                          />
                        </div>
                      </Form.Field>
                    </Form>
                    <h3>Chart Range</h3>
                    <Form>
                      <Form.Field>
                        <Radio
                          label="6 Months"
                          name="range"
                          value={0}
                          checked={csSettings.range === 0}
                          onChange={this.handleSettingsChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="1 Year"
                          name="range"
                          value={1}
                          checked={csSettings.range === 1}
                          onChange={this.handleSettingsChange}
                        />
                      </Form.Field>
                    </Form>
                    <Button
                      style={{ marginTop: '10px' }}
                      onClick={this.handleClose}
                    >
                      Save As Default
                    </Button>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              <SharePortfolioModal id={id} title={title} text={text} disabled={disabled} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {menu}
        <ChartGrid
          // can be object or array, set up for polymorphic data
          data={Object.values(chartScannerData.byId)}
          range={csSettings.range}
        />
        <FloatingScrollTop />

        <center style={{ display: isMobile ? 'block' : 'none' }}>
          <p style={{ textAlign: 'center', display: isMobile ? 'block' : 'none', color: '#888' }}>
            *Bespoke&apos;s Chart Scanner tool is unavailable on mobile devices at this time
            due to memory constraints.  Please use our search tool below to find a specific ticker.
          </p>
          <br />
          <Button onClick={() => handleToggleSearch(true)}>
            FIND A TICKER
          </Button>
        </center>
      </React.Fragment>
    );
  }
}

ChartScanner.propTypes = {
  categories: PropTypes.object.isRequired,
  csSettings: PropTypes.object.isRequired,
  chartScannerData: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCSData: PropTypes.func.isRequired,
  getScreens: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  saveSettings: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  portfolios: PropTypes.object.isRequired,
  screens: PropTypes.object.isRequired,
  tickerCalls: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  handleToggleSearch: PropTypes.func.isRequired,
  onSearchChangeHandler: PropTypes.func.isRequired,
  searchActive: PropTypes.bool.isRequired,
  navigateToPage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  categories: state.data.categories,
  chartScannerData: state.view.chartScanner,
  csSettings: state.user.settings.chartScanner,
  portfolios: state.user.portfolios,
  screens: state.data.screens,
  tickerCalls: state.user.tickerCalls,
  searchActive: state.search.toggleSearch,
});

const mapDispatchToProps = dispatch => ({
  getCategories: cacheExp => dispatch(getCategories(cacheExp)),
  getCSData: (cat, id) => dispatch(getCSData(cat, id)),
  getScreens: cacheExp => dispatch(getScreens(cacheExp)),
  loadSettings: () => dispatch(loadSettings()),
  saveSettings: () => dispatch(saveSettings()),
  updateSettings: (name, val) => dispatch(updateCSSettings(name, val)),
  handleToggleSearch: status => dispatch(toggleSearch(status)),
  onSearchChangeHandler: () => (dispatch(updateSearch('$'))),
  navigateToPage: url => dispatch(navigateToPage(url, true)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChartScanner));
