import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { zScoreToPercentile } from '../../../../../server/helpers/statistics';
import SvgGauge from '../../../presentational/SvgGauge';
import * as api from '../../../../api';

class SeasonalityGauges extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gaugesLoaded: false,
      gauges: {
        week: {
          med: 0.2789,
          avg: 0.2789,
          std: 0.6964,
        },
        month: {
          med: 1.1144,
          avg: 1.1144,
          std: 1.3153,
        },
        three_month: {
          med: 2.5417,
          avg: 2.5417,
          std: 1.8708,
        },
      },
    };
  }

  componentDidMount() {
    api.tools.getSeasonalityGauges()
      .then((results) => {
        this.setState({ gauges: results });
        this.setState({ gaugesLoaded: true });
      });
  }

  render() {
    const { gauges, gaugesLoaded } = this.state;
    const values = {
      week: Math.round(100 * zScoreToPercentile((
        gauges.week.med - gauges.week.avg) /
        gauges.week.std)),
      month: Math.round(100 * zScoreToPercentile((
        gauges.month.med - gauges.month.avg) /
        gauges.month.std)),
      three_month: Math.round(100 * zScoreToPercentile((
        gauges.three_month.med - gauges.three_month.avg) /
        gauges.three_month.std)),
    };

    return (
      <Segment className="second-step">
        <div
          className="titleBar"
          style={{
            marginBottom: '15px',
            fontSize: '16px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
        >
          S&P 500 Seasonality: Median Historical Performance Over the Next:
        </div>
        <Grid stackable >
          <Grid.Row columns="3">
            <Grid.Column>
              <SvgGauge
                title={`1 Week: ${gaugesLoaded ? `${gauges.week.med.toFixed(2)}%` : 'Loading...'}`}
                value={gaugesLoaded ? values.week : 0}
              />
            </Grid.Column>
            <Grid.Column>
              <SvgGauge
                title={`1 Month: ${gaugesLoaded ? `${gauges.month.med.toFixed(2)}%` : 'Loading...'}`}
                value={gaugesLoaded ? values.month : 0}
              />
            </Grid.Column>
            <Grid.Column>
              <SvgGauge
                title={`3 Months  ${gaugesLoaded ? `${gauges.three_month.med.toFixed(2)}%` : 'Loading...'}`}
                value={gaugesLoaded ? values.three_month : 0}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <p style={{ marginTop: '10px' }}>* 0 = Worst Period of the Year, 100 = Best Period of the Year</p>
      </Segment>);
  }
}

export default SeasonalityGauges;
