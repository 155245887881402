import React from 'react';
import { Modal, Icon, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddReport from './add';
import DeleteReport from './delete';
import { togglePortfolioCustomReport } from '../../../../../actions/user';
// import ToggleReport from './toggleReport';

class ToggleReportModal extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;
    const {
      name,
      includeReport,
      setIncludeReport,
    } = this.props;
    const triggerElement = (
      <Popup
        content={includeReport ?
          (<span>Remove this portfolio from your <b>Daily Portfolio Highlights</b></span>)
        : (<span>Add this portfolio to your <b>Daily Portfolio Highlights</b></span>)}
        position="bottom left"
        trigger={
          <Icon
            link
            className={`custom-report-button ${includeReport ? 'active' : ''}`}
            name={`eye ${includeReport ? '' : 'slash'}`}
            onClick={this.click}
          />
        }
      />
    );

    return (
      <React.Fragment>
        {React.cloneElement(triggerElement, { onClick: () => this.click() })}
        <Modal basic onClose={this.close} open={open} {...this.props} closeIcon>
          <center>
            {
            includeReport ?
              <DeleteReport
                onClick={() => { setIncludeReport(false); this.close(); }}
                name={name}
              />
            : <AddReport onClick={() => { setIncludeReport(true); this.close(); }} name={name} />
            }
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

ToggleReportModal.defaultProps = {
  submitAction: () => null,
  content: (<Icon link name="chart bar" color="orange" />),
};

ToggleReportModal.propTypes = {
  name: PropTypes.string.isRequired,
  submitAction: PropTypes.func,
  content: PropTypes.element,
  includeReport: PropTypes.bool.isRequired,
  setIncludeReport: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  toggleCustomReport: (id, included) => dispatch(togglePortfolioCustomReport(id, included)),
});

export default connect(null, mapDispatchToProps)(ToggleReportModal);
