import React from 'react';
import PropTypes from 'prop-types';
import TopMenu from '../containers/TopMenu';
import SideMenu from '../containers/SideMenu';
import AccountWarning from '../presentational/AccountWarning';
import Footer from '../presentational/Footer';

// import Ghost from '../containers/Ghost';

export default function Container({ children }) {
  return (
    <div
      className="container"
      style={{
        padding: '0.1px',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minHeight: '100vh',
      }}
    >
      <SideMenu className="hide-on-print" visibility />
      <TopMenu />
      <div style={{ flex: '1 0 auto' }} className="ui full-height container top bottom margin lg">
        <AccountWarning />
        { children }
      </div>
      <Footer style={{ flexShrink: 0 }} className="hide-on-print" />
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.any.isRequired,
};
