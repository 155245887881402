import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';
import { getList } from '../../actions/data/tickers';

class StockSearch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleResultSelect = this.handleResultSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchFocus = this.handleSearchFocus.bind(this);

    this.state = {
      searchLoading: false,
      searchResults: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    const { getTickerList } = this.props;
    getTickerList();
    this.searchInput.focus();
  }

  handleResultSelect(e, data) {
    const { onResultSelect } = this.props;
    onResultSelect(e, data);
    this.setState({
      searchValue: data.result.title,
    });
  }

  handleSearchChange(e, data) {
    if (data.value === '') {
      this.setState({
        searchValue: '',
        searchResults: [],
      });
      return;
    }
    const { tickerList } = this.props;
    const exactMatches = [];
    const partialMatches = [];
    for (let i = 0, len = tickerList.length; i < len; i += 1) {
      // Assign vars
      const { company, ticker } = tickerList[i];
      // check exact matches first
      if (company.toLowerCase() === data.value.toLowerCase()
        || ticker.toUpperCase() === data.value.toUpperCase()) {
        exactMatches.push({ title: ticker, description: company });
      } else if (company.toLowerCase().startsWith(data.value.toLowerCase()) ||
          ticker.toUpperCase().startsWith(data.value.toUpperCase())) {
        partialMatches.push({ title: ticker, description: company });
      }
    }
    const newResults = [...exactMatches, ...partialMatches]
      .slice(0, this.TOTAL_MATCH_LIMIT);
    // generate the updated list
    this.setState({
      searchValue: data.value,
      searchResults: newResults,
    });
  }

  handleSearchFocus() {
    return this;
  }

  render() {
    const { placeholder } = this.props;
    return (
      <Search
        input={{ ref: (ref) => { (this.searchInput = ref); } }}
        placeholder={placeholder}
        value={this.state.searchValue}
        loading={this.state.searchLoading}
        onSearchChange={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        results={this.state.searchResults}
      />
    );
  }
}

StockSearch.defaultProps = {
  placeholder: 'Search for tickers or companies...',
};

StockSearch.propTypes = {
  getTickerList: PropTypes.func.isRequired,
  onResultSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  tickerList: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  tickerList: state.data.tickerList.list,
});

const mapDispatchToProps = dispatch => ({
  getTickerList: () => dispatch(getList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockSearch);
