import React from 'react';
import PropTypes from 'prop-types';
import { GreenText, YellowText, RedText } from './ColoredText';
import { formatDate, formatBigNumber } from './TextFormats';

function ifGoodNeutralBad(value, good, neutral, bad) {
  if (value > 0) {
    return good;
  } else if (value < 0) {
    return bad;
  }
  return neutral;
}
/**
  * Returns a formatted string based on the trend
  * (-1:down, 0:neutral, 1:up, otherwise Not Enough Data)
  * @param {number} trend - number indicating current trend rating
  * @return {object} output - output object
  * @property {object[]} string - an array representing a JSX string
  * @property {number} sentiment - -1, 0, or 1 representing +, -, or neutral
  * @property {number} importance - score for calculating importance (for trimming)
  */
function getTrend(ticker) {
  const output = {
    string: [], sentiment: 0, importance: 1, isSimple: true,
  };
  const { trend } = ticker.current_trend;
  if (trend === 1) {
    output.string = [`${ticker.ticker} is currently in an`, <GreenText>uptrend</GreenText>];
    output.sentiment = 1;
  } else if (trend === -1) {
    output.string = [`${ticker.ticker} is currently in a `, <RedText>downtrend</RedText>];
    output.sentiment = -1;
  } else if (trend === 0) {
    output.string = [`${ticker.ticker} is currently in a`, <YellowText>sideways trend</YellowText>];
  } else if (!trend) {
    output.importance = 0;
    output.isSimple = false;
  }
  return output;
}

function getTradingRange(ticker) {
  const output = {
    string: [], sentiment: 0, importance: 1, isSimple: true,
  };
  output.string.push(`${ticker.ticker} is currently trading in `);
  const curRange = ticker.current_trend.cur_range;
  if (curRange > 2) {
    output.string.push(<RedText>extremely overbought</RedText>);
    output.sentiment = -1;
  } else if (curRange > 1) {
    output.string.push(<RedText>overbought</RedText>);
    output.sentiment = -1;
  } else if (curRange < 1) {
    output.string.push(<GreenText>oversold</GreenText>);
    output.sentiment = 1;
  } else if (curRange < 2) {
    output.string.push(<GreenText>extremely oversold</GreenText>);
    output.sentiment = 1;
  } else {
    output.string.push(<YellowText>neutral</YellowText>);
  }
  output.string.push(' territory');
  return output;
}

function getScreens(screens) {
  const output = { string: [], sentiment: 0, importance: 100 };
  const screenNames = [];
  if (screens.length === 0) {
    return null;
  }
  output.string.push(formatDate(screens[0].date));
  output.string.push(' was ');
  screens.forEach((screen) => {
    switch (screen.id) {
      case 1:
        screenNames.push([' a ', <GreenText>52 week high</GreenText>, ' 😁']);
        output.sentiment += 1;
        break;
      case 2:
        screenNames.push([' a ', <RedText>52 week low</RedText>, ' 😬']);
        output.sentiment += 1;
        break;
      case 10:
        screenNames.push([' a ', <GreenText>golden cross</GreenText>, ' 😮']);
        output.sentiment += 1;
        break;
      case 11:
        screenNames.push([' a ', <RedText>death cross</RedText>, ' ☠️']);
        output.sentiment += 1;
        break;
      default:
    }
  });
  switch (screenNames.length) {
    case 1:
      screenNames[0].forEach(s => output.string.push(s));
      return output;
    case 2:
      return {
        string: [...output.string, ...screenNames[0], ' and ', ...screenNames[1]],
        sentiment: output.sentiment,
        importance: output.importance,
      };
    default:
      for (let n = 0; n < screenNames.length; n += 1) {
        if (n !== screenNames.length - 1) {
          screenNames[n].forEach(s => output.string.push(s));
          output.string.push(', ');
        } else {
          output.string.push(' and ');
          screenNames[n].forEach(s => output.string.push(s));
        }
      }
      return null;
  }
}

function getEarningsCalendar(ticker, append = false) {
  const output = { string: [], sentiment: 0, importance: 1 };
  const ecal = ticker.earnings_calendar;
  const earnings = ticker.current_earnings;
  if (ecal === null ||
    (new Date(ecal.reportdate)).getTime() - (new Date()).getTime() > 30 * 24 * 3600 * 1000) {
    return null;
  }
  if ((new Date(ecal.reportdate)).getTime() - (new Date()).getTime() < 24 * 3600 * 1000 * 5) {
    output.importance = 100;
  }
  const now = new Date();
  now.setUTCHours(0, 0, 0, 0);
  const reportDate = new Date(ecal.reportdate);

  if (reportDate.getTime() > now.getTime()) {
    output.string.push(`${ticker.ticker} is reporting earnings `);
  } else {
    output.string.push(`${ticker.ticker} reported earnings `);
  }
  const reportTimeMap = {
    AM: 'before the open',
    D: 'intraday',
    PM: 'after the close',
  };
  output.string.push(<b>{formatDate(ecal.reportdate, { general: x => `on ${x}` })}</b>);
  if (append) {
    output.string.push(` ${reportTimeMap[ecal.reporttime]}`);
    return output;
  }
  output.string.push(` ${reportTimeMap[ecal.reporttime]}. `);
  output.string.push(' They historically beat EPS ');
  if (ticker.epsbeats >= 80) {
    output.string.push(<GreenText>{ticker.epsbeats}%</GreenText>);
  } else if (ticker.epsbeats > 50) {
    output.string.push(<YellowText>{ticker.epsbeats}%</YellowText>);
  } else {
    output.string.push(<RedText>{ticker.epsbeats}%</RedText>);
  }
  output.string.push(' of the time, and typically average a move of ');
  output.string.push(ifGoodNeutralBad(
    ecal.avg_1day, <GreenText>{ecal.avg_1day}%</GreenText>,
    <YellowText>0%</YellowText>,
    <RedText>{ecal.avg_1day * -1}%</RedText>,
  ));
  output.string.push(' on their earnings reaction day');
  if (earnings.eps_avse > 0 && earnings.rev_avse > 0 && earnings.guidance === 'Raised') {
    output.string.push(`. On their last report (${formatDate(earnings.date)}), ${ticker.ticker} posted a Triple Play ⚾`);
  }
  return output;
}

function getEarnings(earnings) {
  const output = { string: ['earnings were reported '], sentiment: 0, importance: 1 };
  if (earnings === undefined ||
    (new Date()).getTime() - (new Date(earnings.date)).getTime() > 30 * 24 * 3600 * 1000) {
    return null;
  }
  switch (earnings.time_of_day) {
    case 'AM':
      output.string.push(' before the open ');
      break;
    case 'PM':
      output.string.push(' after the close ');
      break;
    case 'D':
      output.string.push(' during the day ');
      break;
    default:
  }
  // output.string.push(' on ');
  output.string.push(formatDate(earnings.date, { general: x => `on ${x}` }));
  output.string.push('. ');

  if (earnings.guidance !== 'None') {
    output.string.push(' Guidance was ');
    if (earnings.guidance === 'Inline') {
      output.string.push(<YellowText>inline</YellowText>);
    }
    if (earnings.guidance === 'Raised') {
      output.string.push(<GreenText>raised</GreenText>);
      output.sentiment = 1;
    }
    if (earnings.guidance === 'Lowered') {
      output.string.push(<RedText>lowered</RedText>);
      output.sentiment = -1;
    }
    if (earnings.eps_avse * output.sentiment >= 0) {
      output.string.push(' and they');
    }
    if (earnings.eps_avse * output.sentiment < 0) {
      output.string.push(' but they');
    }
  } else {
    output.string.push(' They ');
  }

  ifGoodNeutralBad(
    earnings.eps_avse,
    () => { output.string.push(<GreenText>beat</GreenText>); },
    (() => {
      output.string.push(' were ');
      output.string.push(<YellowText>inline</YellowText>);
      output.string.push(' with ');
    }),
    (() => output.string.push(<RedText>missed</RedText>)),
  )();

  output.string.push(` EPS ${earnings.primary_key % 2 === 0 ? 'forecasts' : 'estimates'}`);
  // output.string.push(' (');
  //
  // if (earnings.eps_actual && earnings.eps_estimate) {
  //   output.string.push(ifGoodNeutralBad(
  //     earnings.eps_actual,
  //     <GreenText>{formatMoney(earnings.eps_actual)}</GreenText>,
  //     <b>{formatMoney(earnings.eps_actual * -1)}</b>,
  //     <RedText>{formatMoney(earnings.eps_actual * -1)}</RedText>,
  //   ));
  //
  //   output.string.push(' reported / ');
  //   output.string.push(earnings.eps_estimate >= 0 ?
  //     <b>{formatMoney(earnings.eps_estimate)}</b> :
  //     <RedText>{formatMoney(earnings.eps_estimate * -1)}</RedText>);
  //   output.string.push(' estimated), ');
  // }
  output.string.push(' with the stock ');
  if (earnings.time_of_day === 'PM') {
    ifGoodNeutralBad(
      earnings.gap_pct,
      (() => {
        output.string.push(' gapping ');
        output.string.push(<GreenText>up {earnings.gap_pct.toFixed(2)}%</GreenText>);
      }),
      (() => {
        output.string.push(' opening ');
        output.string.push(<YellowText>flat</YellowText>);
      }),
      (() => {
        output.string.push(' gapping ');
        output.string.push(<RedText>down {(earnings.gap_pct * -1).toFixed(2)}%</RedText>);
      }),
    )();
  } else {
    ifGoodNeutralBad(
      earnings.open_to_close_pct,
      (() => {
        output.string.push(' moving ');
        output.string.push(<GreenText>up {earnings.open_to_close_pct.toFixed(2)}%</GreenText>);
      }),
      (() => {
        output.string.push(' keeping ');
        output.string.push(<YellowText>flat</YellowText>);
      }),
      (() => {
        output.string.push(' moving ');
        output.string.push(<RedText>down {(earnings.open_to_close_pct * -1).toFixed(2)}%</RedText>);
      }),
    )();
  }
  if (earnings.time_of_day === 'PM') {
    output.string.push(' at the open the following day');
  } else {
    output.string.push(' by the close that day');
  }
  if (earnings.eps_avse > 0 && earnings.rev_avse > 0 && earnings.guidance === 'Raised') {
    output.string.push('. They also ');
    output.string.push(<GreenText>beat</GreenText>);
    output.string.push(' revenue estimates (');
    if (earnings.eps_actual && earnings.eps_estimate) {
      output.string.push(earnings.rev_actual >= 0 ?
        <GreenText>${formatBigNumber(earnings.rev_actual)}MM</GreenText> :
        <RedText>${(formatBigNumber(earnings.rev_actual * -1))}MM</RedText>);
      output.string.push(' reported / ');
      output.string.push(earnings.rev_estimate >= 0 ?
        <b>${formatBigNumber(earnings.rev_estimate)}MM</b> :
        <RedText>${formatBigNumber(earnings.rev_estimate * -1)}MM</RedText>);
      output.string.push(' estimated) - Triple Play ⚾');
    }
  }
  return output;
}

function getEarningsWrapper(ticker) {
  if (ticker.earnings_calendar === null) {
    return getEarnings(ticker.current_earnings);
  }
  const ecalDate = new Date(ticker.earnings_calendar.reportdate);
  const lastEarningsDate = new Date(ticker.current_earnings && ticker.current_earnings.date);
  const now = new Date();
  now.setUTCHours(0, 0, 0, 0);
  if (ecalDate.getTime() > lastEarningsDate.getTime()) {
    if (ecalDate.getTime() < now.getTime()) {
      return getEarningsCalendar(ticker, true);
    }
    return getEarningsCalendar(ticker);
  }
  return getEarnings(ticker.current_earnings);
}

/**
  * Returns a formatted string based on the timing
  * @param {number} timing - number indicating current short-term timing score
  * (number between 0 and 4. 0 is worst, 2 neutral, 4 best.)
  * @return {object} output - output object
  * @property {object[]} string - an array representing a JSX string
  * @property {number} sentiment - -1, 0, or 1 representing +, -, or neutral
  * @property {number} importance - score for calculating importance (for trimming)
  */
function getTiming(timing) {
  const output = {
    string: [], sentiment: 0, importance: 0, isSimple: true,
  };
  switch (timing) {
    case 0:
      output.string = ['the short-term timing is ', <RedText>BAD</RedText>];
      output.sentiment = -1;
      output.importance = 10;
      break;
    case 1:
      output.string = ['the short-term timing is ', <RedText>poor</RedText>];
      output.sentiment = -1;
      output.importance = 1;
      break;
    case 2:
      output.string = ['the short-term timing is ', <YellowText>neutral</YellowText>];
      output.sentiment = 0;
      output.importance = 1;
      break;
    case 3:
      output.string = ['the short-term timing is ', <GreenText>good</GreenText>];
      output.sentiment = 1;
      output.importance = 1;
      break;
    case 4:
      output.string = ['the short-term timing is ', <GreenText>PERFECT</GreenText>];
      output.sentiment = 1;
      output.importance = 10;
      break;
    default:
      output.isSimple = false;
      break;
  }
  return output;
}


function get50DMAChange(fiftyDayPctMva) {
  const output = {
    string: [], sentiment: 0, importance: 1, isSimple: true,
  };
  if (fiftyDayPctMva > 0) {
    output.string.push(...["it's ", <GreenText>{fiftyDayPctMva.toFixed(2)}% above</GreenText>]);
    // output.sentiment = 1;
  } else if (fiftyDayPctMva < 0) {
    output.string.push(...["it's ", <RedText>{(fiftyDayPctMva * -1).toFixed(2)}% below</RedText>]);
    // output.sentiment = -1;
  } else {
    return ['it ', <YellowText>has not changed</YellowText>, "from it's 50-day moving average"];
  }
  output.string.push(" it's 50-day moving average");
  return output;
}


/**
 * @ignore
 */
function capitalize(jsxStringArray) {
  const leadingString = jsxStringArray[0];
  const newJsxStringArray = jsxStringArray;
  newJsxStringArray[0] = leadingString.charAt(0).toUpperCase() + leadingString.slice(1);
  return newJsxStringArray;
}

/**
 * @ignore
 */
function sentence(jsxStringArray) {
  if (jsxStringArray.length === 0) {
    return [];
  }
  // console.log(jsxStringArray);
  const newJsxStringArray = capitalize(jsxStringArray);
  newJsxStringArray.push('. ');
  return newJsxStringArray;
}


function conjoin(arg1, arg2) {
  if (arg1.string.length + arg2.string.length === 0) {
    return [];
  }
  if (arg1.string.length === 0) {
    return sentence(arg2.string);
  }
  if (arg2.string.length === 0) {
    return sentence(arg1.string);
  }
  if (arg1.sentiment * arg2.sentiment < 0 || (arg1.sentiment === 0 && arg2.sentiment !== 0)) {
    return sentence([...arg1.string, ', but ', ...arg2.string]);
  }
  return sentence([...arg1.string, ', and ', ...arg2.string]);
}

/*
function bold(child) {
  return <b>{ child }</b>;
}
*/

/**
  * React Component.
  * Generates natural language summaries with specified props for a ticker.
  * If nothing is flagged, attempt to add everything.
  * @todo Talk to Matt about possibly just passing in elements as a prop instead of
  * miscellaneous things like ticker, trend, timing, to make the card more general
  * @param {Object} props - Props to pass
  * @param {Ticker} props.ticker - ticker object.
  * @param {number} props.max - max amount of sentences.
  * Will try to remove least important sentences if necessary.
  * If absent, no max.
  * @param {object} [props.trend] - adds trend to summary if it exists.
  * Should have string "important" to ensure a property is included.
  * @param {object} [props.timing] - adds timing to summary if it exists.
  * Should have string "important" to ensure a property is included.
  */
const Summary = ({
  ticker,
  max,
  trend,
  timing,
  earnings,
  screens,
  fiftydma,
  tradingrange,
  tableData,
}) => {
  const summaryText = [];
  const queue = [];
  const mandatoryQueue = [];
  let additionalSlots = max;
  const currTrend = ticker.current_trend;
  const t = ticker;
  // Calculate the eps beats
  if (typeof tableData !== 'undefined' && tableData.length > 0) {
    const len = tableData.length;
    const result = tableData.reduce((acc, curr) => {
      if (curr.eps_avse > 0) {
        acc.epsbeats += 1;
      }
      return acc;
    }, {
      epsbeats: 0,
    });
    result.epsbeats /= len;
    t.epsbeats = Math.round(result.epsbeats * 100);
  }

  const elements = [
    { flag: tradingrange, property: ticker, func: getTradingRange },
    { flag: fiftydma, property: currTrend.fifty_day_pct_mva, func: get50DMAChange },
    { flag: trend, property: ticker, func: getTrend },
    { flag: timing, property: currTrend.timing, func: getTiming },
    { flag: earnings, property: t, func: getEarningsWrapper },
    { flag: screens, property: ticker.current_screens, func: getScreens },

  ];

  // Unlimited if props.max is undefined
  if (additionalSlots === undefined) {
    additionalSlots = Infinity;
  }

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i].func(elements[i].property);
    if (element != null) { // if a string is returned
      if (elements[i].flag === 'important') {
        mandatoryQueue.push();
        additionalSlots -= 1;
      } else if (elements[i].flag) {
        queue.push(element);
      }
    }
  }

  // If nothing is flagged, push all to regular queue with a max of 5
  if (mandatoryQueue.length === 0 && queue.length === 0) {
    additionalSlots = 5;
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i].func(elements[i].property);
      if (element != null) {
        queue.push(element);
      }
    }
  }

  // Order the queue
  queue.sort((a, b) => b.importance - a.importance);
  // Generate summaries
  for (let i = 0; i < mandatoryQueue.length; i += 1) {
    if (i < mandatoryQueue.length - 1 &&
      mandatoryQueue[i].isSimple && mandatoryQueue[i + 1].isSimple) {
      summaryText.push(...conjoin(mandatoryQueue[i], mandatoryQueue[i + 1]));
      i += 1;
    } else {
      summaryText.push(...sentence(mandatoryQueue[i].string));
    }
  }

  for (let i = 0; i < queue.length; i += 1) {
    if (additionalSlots > 0) {
      if (i < queue.length - 1 &&
        queue[i].isSimple && queue[i + 1].isSimple) {
        summaryText.push(...conjoin(queue[i], queue[i + 1]));
        i += 1;
      } else {
        summaryText.push(...sentence(queue[i].string));
      }
      additionalSlots -= 1;
    } else {
      break;
    }
  }

  return <span>{summaryText}</span>;
};

Summary.defaultProps = {
  trend: undefined,
  timing: undefined,
  earnings: undefined,
  screens: undefined,
  fiftydma: undefined,
  tradingrange: undefined,
  max: undefined,
};

Summary.propTypes = {
  ticker: PropTypes.object.isRequired,
  trend: PropTypes.any,
  timing: PropTypes.any,
  earnings: PropTypes.any,
  screens: PropTypes.any,
  max: PropTypes.number,
  tradingrange: PropTypes.any,
  fiftydma: PropTypes.any,
  tableData: PropTypes.array.isRequired,
};

export default Summary;
