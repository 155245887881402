import React from 'react';
import PropTypes from 'prop-types';
import {
  line,
  // curveNatural,
} from 'd3-shape';

class Line extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateD3(props);
  }
  componentWillUpdate(nextProps) {
    this.updateD3(nextProps);
  }

  updateD3(props) {
    const { zoomTransform } = this.props;
    const { xScale, yScale } = this.props.scales;
    this.xScale = xScale;
    this.yScale = yScale;

    if (props.zoomTransform || zoomTransform) {
      this.xScale.domain(props.zoomTransform.rescaleX(xScale).domain());
      // this.yScale.domain(props.zoomTransform.rescaleY(yScale).domain());
    }
  }

  render() {
    const {
      data,
      handleClick,
      colors,
      multi,
      strokeWidth,
      waterLine,
    } = this.props;
    let waterLinePath;
    if (waterLine) {
      waterLinePath = (
        <path
          key="waterline"
          fill="none"
          stroke="red"
          strokeWidth={1}
          d={`M${this.xScale.range()[0] - 0},${this.yScale(waterLine)}H${this.xScale.range()[1]}`}
        />
      );
    }
    const path = multi ? (
      <React.Fragment>
        {colors.map((color, idx) => {
          const pLine = line()
            .x(d => this.xScale(new Date(d.label)))
            .y(d => this.yScale(d.value[idx]))(data);
          return (
            <path
              key={color}
              fill="none"
              stroke={color}
              strokeWidth={strokeWidth}
              d={pLine}
              onClick={handleClick}
              cursor={handleClick ? 'pointer' : undefined}
            />);
        })}
      </React.Fragment>
    ) : (
      <path
        fill="none"
        stroke={colors[0]}
        strokeWidth={strokeWidth}
        d={
          line()
            .x(d => this.xScale(new Date(d.date)))
            .y(d => this.yScale(d.value))(data)
        }
        onClick={handleClick}
        cursor={handleClick ? 'pointer' : undefined}
      />);
    return (
      <g clipPath="url(#clip)">
        {waterLinePath}
        {path}
      </g>
    );
  }
}

Line.defaultProps = {
  handleClick: undefined,
  strokeWidth: 1.5,
  waterLine: undefined,
  zoomTransform: undefined,
};

Line.propTypes = {
  multi: PropTypes.bool.isRequired,
  colors: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  scales: PropTypes.shape({
    xScale: PropTypes.func,
    yScale: PropTypes.func,
  }).isRequired,
  data: PropTypes.array.isRequired,
  strokeWidth: PropTypes.number,
  waterLine: PropTypes.number,
  zoomTransform: PropTypes.object,
};

export default Line;
