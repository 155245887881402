import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Table, Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { navigateToPage } from '../../../actions/search';
// import InlineLoader from '../InlineLoader';
// import * as api from '../../../api';
import { interactiveContent } from '../../../types';
import { BlueLink } from '../ColoredText';
// import { updateCardData } from '../../../actions';

// import { Link } from 'react-router-dom';

function Screens({ cardState, dispatch }) {
  if (cardState && cardState.screens) {
    const makeTable = (items) => {
      const rows = [];
      items.forEach((item) => {
        rows.push((
          <Table.Row onClick={() => dispatch(navigateToPage(`/ticker/${item.ticker}`))}>
            <Table.Cell>
              {item.ticker}
            </Table.Cell>
            <Table.Cell>
              <BlueLink onClick={() => dispatch(navigateToPage(`/ticker/${item.ticker}`))} >{item.company}</BlueLink> {/* screens */}
            </Table.Cell>
            <Table.Cell>
              {item.current_candle.close}
            </Table.Cell>
          </Table.Row>));
      });
      return (
        <Table style={{ fontSize: '1.3em' }} celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell> Ticker </Table.HeaderCell>
              <Table.HeaderCell> Name </Table.HeaderCell>
              <Table.HeaderCell> Last Price </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { rows }
          </Table.Body>
        </Table>
      );
    };

    const panes = cardState.screens.map(screen => ({
      menuItem: `${screen.name} (${screen.tickers.length})`,
      render: () => <Tab.Pane style={{ maxHeight: '350px', overflow: 'scroll', overflowX: 'hidden' }} >{makeTable(screen.tickers)}</Tab.Pane>,
    }));
    return (
      <Tab panes={panes} />
    );
  }

  return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
}

Screens.propTypes = {
  // ticker: PropTypes.object.isRequired,
  cardState: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapState = state => ({
  ticker: state.content.ticker.ticker,
  cardState: state.content.ticker.cards[interactiveContent.SCREENS],
});

const mapDispatch = dispatch => ({
  dispatch,
});

export default connect(mapState, mapDispatch)(Screens);
