import { actions, staticContent, interactiveContent } from '../types';
import * as api from '../api';
// import * as User from './user';
// Action types

// Global id counter
let nextId = 0;

/**
 *add interactive content to the store
 * @param {string} contentType
 * @param {object[]} content
 */
export function addInteractive(contentType, content) {
  nextId += 1;
  return {
    type: actions.ADD_INTERACTIVE,
    id: nextId,
    contentType,
    content, // property value shorthand
  };
}

/**
 *add static content to the store
 * @param {string} contentType
 * @param {object[]} content
 */
export function addStatic(contentType, content) {
  nextId += 1;
  return {
    type: actions.ADD_STATIC,
    id: nextId,
    contentType,
    content,
  };
}

/**
 *
 * @param {*} target
 */
export function setView(target) {
  return {
    type: actions.SET_VIEW,
    target,
  };
}

export function addTickerCards(ticker, cards) {
  return {
    type: actions.ADD_TICKER_CARDS,
    ticker,
    cards,
  };
}

export function updateCardData(card, data) {
  return {
    type: actions.UPDATE_CARD_DATA,
    card,
    data,
  };
}

export function cardView() {
  return {
    type: actions.CARD_VIEW,
  };
}

export function cardsWithHeaderView(header) {
  return {
    type: actions.CARDS_WITH_HEADER_VIEW,
    header,
  };
}

export function setPageTitle(title) {
  return ({
    type: actions.SET_PAGE_TITLE,
    title,
  });
}

export function setViewMeta(data) {
  return {
    type: actions.SET_VIEW_META,
    data,
  };
}

export function toggleMenu() {
  return {
    type: actions.TOGGLE_MENU,
  };
}

export function setPage(page) {
  return {
    type: actions.SET_PAGE,
    page,
  };
}


export function setTicker(ticker) {
  return {
    type: actions.SET_TICKER,
    ticker,
  };
}

export function renderPost(categorySlug, postSlug) {
  return (dispatch, getState) => {
    dispatch(setViewMeta({ category: categorySlug, post: postSlug }));
    return api.research.getPostBySlug(categorySlug, postSlug)
      // Adds the content then needs to go back in just to get the dynamically generated id...
      // Seems kind of silly...
      .then((post) => { dispatch(addStatic(staticContent.POST, { post: post[0] })); })
      .then(() => getState().content.static[getState().content.static.length - 1].id)
      .then((id) => { dispatch(setView(id)); });
  };
}

export function clearStaticCards() {
  return {
    type: actions.CLEAR_STATIC_CARDS,
  };
}

export function render404() {
  return (dispatch) => {
    dispatch(setViewMeta({ 404: true }));
    dispatch(setView(null));
  };
}

/**
 * Takes an array of posts
 * @param {} posts
 */
export function setPosts(posts) {
  return { type: actions.SET_POSTS, posts };
}

export function setLoadingStates(cards, isLoading) {
  return {
    type: actions.SET_LOADING_STATES,
    cards,
    isLoading,
  };
}

export function renderChart(ticker) {
  return (dispatch, getState) => {
    dispatch(setViewMeta({ ticker: ticker.ticker, chart: true }));
    return api.ticker.getHistorical([ticker.ticker], '1998-05-18', (new Date()).toISOString().split('T')[0])
      .then(x => x[ticker.ticker])
      .then(response => response.map(d => ({ ...d, date: new Date(d.date) })).reverse())
      .then((history) => {
        dispatch(addInteractive(interactiveContent.CHART, { ticker, history }));
      })
      .then(() => getState().content.interactive[getState().content.interactive.length - 1].id)
      .then(id => dispatch(setView(id)))
      .then(() => dispatch(setLoadingStates([interactiveContent.CHART], false)));
  };
}
export function setChartData(ticker) {
  return dispatch => api.ticker.getHistorical(
    [ticker.ticker],
    '1998-05-18', (new Date()).toISOString().split('T')[0],
  )
    .then(x => x[ticker.ticker])
    .then(response => response.map(d => ({ ...d, date: new Date(d.date) })).reverse())
    .then((history) => {
      dispatch(updateCardData(interactiveContent.CHART, {
        history,
        tickerName: ticker.ticker,
      }));
    })
    .then(() => dispatch(setLoadingStates([interactiveContent.CHART], false)));
}

export function renderScreens(screenIDs = [1, 2, 10, 11]) {
  return dispatch => api.screen.getScreens(screenIDs)
    // .then((screens) => { globalScreens = screens; })
    .then(async (screens) => {
      const newScreens = screens;
      const promises = [];
      // for (let i = 0; i < screens.length; i += 1) {
      //   promises.push(api.ticker.getSummary(screens[i].tickers));
      // }
      screens.forEach((screen) => { promises.push(api.ticker.getSummary(screen.tickers)); });
      const tickers = await Promise.all(promises);
      for (let i = 0; i < screens.length; i += 1) {
        newScreens[i].tickers = tickers[i];
      }
      return newScreens;
    })
    .then((screens) => {
      dispatch(updateCardData(interactiveContent.SCREENS, { screens }));
    })
    .then(() => dispatch(setLoadingStates([interactiveContent.SCREENS], false)));
}


export function setPortfolio(portfolioID) {
  let portfolioName = null;

  return dispatch => api.user.getPortfolios()
    .then((p) => {
      dispatch(setLoadingStates([interactiveContent.TREND], true));
      return p;
    })
    .then(p => p.reduce((map, curr) => { const m = map; m[curr.id] = curr; return m; }, {}))
    .then((portfolios) => {
      if (portfolioID in portfolios) {
        dispatch(updateCardData('PORTFOLIO', portfolios[portfolioID]));
      }
      portfolioName = `${portfolios[portfolioID].name}`;
      return portfolios[portfolioID].members;
    })
    .then(members => api.ticker.getReference(members))
    .then(tickers => dispatch(updateCardData(interactiveContent.TREND, {
      name: portfolioName,
      tickers,
    })))
    .then(() => dispatch(setLoadingStates([interactiveContent.TREND], false)));
}

// Following aux methods are to remove need to call the api for data at each update.
// intended as aux methods
// local and master records may go out of sync if used incorrectly

/* action to immediately add a portfolio to store reference */
export const addToPortfolioAux = (portfolioID, tickers) => ({
  type: actions.ADD_TO_PORTFOLIO,
  tickers,
  id: portfolioID,
});

/* action to immediately remove a portfolio from store reference */
export const removeFromPortfolioAux = (portfolioID, tickers) => ({
  type: actions.REMOVE_FROM_PORTFOLIO,
  tickers,
  id: portfolioID,
});


export const addToPortfolio = (portfolioID, tickers) => dispatch =>
  api.user.addPortfolioMembers(portfolioID, tickers)
    // .then(() => dispatch(setPortfolio(portfolioID)))
    // .then(() => dispatch(User.getPortfolios(0)));
    .then(() => dispatch(addToPortfolioAux(portfolioID, tickers)));

export const removeFromPortfolio = (portfolioID, tickers) => dispatch =>
  api.user.deletePortfolioMembers(portfolioID, tickers)
    // .then(() => dispatch(setPortfolio(portfolioID)))
    // .then(() => dispatch(User.getPortfolios(0)));
    .then(() => dispatch(removeFromPortfolioAux(portfolioID, tickers)));
