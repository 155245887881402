import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Table,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  Icon, Checkbox, Card, Select,
} from 'semantic-ui-react';
import 'react-virtualized/styles.css';
import {
  disabledTickerActive,
  selectTickerCategory,
  editTickerManager,
} from '../../../actions/data/tickers';


export class StateTicketsTable {
  searchTickers = [];
  sortCol = 'ticker';
  sortDir = 'ASC';
  displayData = [];
  searchTickerInput: '';
  page = 1;
  openModal = false;
  listEditDataCategory = [];
  listSelectTickerId = [];
  lockingTickersData = {
    id: '',
    ticker: '',
    is_active: '',
  };
  messageAlertDuplicate = '';
  modifyTickers = {
    id: '',
    ticker: '',
    company: '',
    gics_sector: '',
    gics_group: '',
    shares_outstanding: '',
  };
}

// Todo: Needs header styles + extra header rows
class TableTickersManagerShow extends React.PureComponent<StateTicketsTable, any> {
  state = new StateTicketsTable();

  handlePageChange = (e, d) => {
    this.setState({
      page: d.activePage,
    });
  }

  handleModifyTickerUpdate = (e, d) => {
    const { name, value } = d;
    this.setState({
      modifyTickers: {
        ...this.state.modifyTickers,
        [name]: value,
      },
    });
  }

  close = () => {
    this.setState({
      openModal: false,
      openModalLocking: false,
      modifyTickers: {
        id: '',
        ticker: '',
        company: '',
        gics_sector: '',
        gics_group: '',
        shares_outstanding: '',
      },
      messageAlertDuplicate: '',
      lockingTickersData: {
        id: '',
        ticker: '',
        is_active: '',
      },
      listEditDataCategory: [],
    });
  };

  // eslint-disable-next-line consistent-return
  DisabledTickersManager = (tickersData) => {
    if (tickersData) {
      this.setState({
        openModalLocking: true,
        lockingTickersData: {
          id: tickersData.id,
          ticker: tickersData.ticker,
          is_active: tickersData.is_active,
        },
      });
    }
  }
  handleTicketActive = (idTicker, valueTicket) => {
    // eslint-disable-next-line no-undef
    this.props.disabledTickerAct({
      idTicker,
      valueChange: !valueTicket,
    }).then(() => {
      this.close();
      window.location.reload(true);
    });
  }

  removeEditCategoryTickers = (tickers) => {
    const dataRemove = this.state.listEditDataCategory
      .filter(item => item.category !== tickers.category);
    this.setState({
      ...this.state,
      listEditDataCategory: dataRemove,
    });
  }

  filterDataTickers = (event, data) => {
    const search = data.value;
    if (search !== '') {
      this.setState({
        // eslint-disable-next-line max-len
        searchTickers: this.props.rows.filter(filterData => filterData.company.toLowerCase().includes(search.toLowerCase()) || filterData.ticker.toLowerCase().includes(search.toLowerCase())).slice(0, 250),
      });
    } else {
      this.setState({
        ...this.state,
        searchTickers: [],
      });
    }
  }

  // eslint-disable-next-line consistent-return
  ModifyTickersManager = async (tickers) => {
    await this.props.selectTickerCat(tickers.id)
      .then((result) => {
        // eslint-disable-next-line array-callback-return
        result.map((item) => {
          this.setState({
            ...this.state,
            // eslint-disable-next-line max-len
            listEditDataCategory: [...this.state.listEditDataCategory, { category: item.category_id }],
          });
        });
      });

    if (tickers) {
      this.setState({
        openModal: true,
        modifyTickers: {
          id: tickers.id,
          ticker: tickers.ticker,
          company: tickers.company,
          gics_sector: tickers.gics_sector,
          gics_group: tickers.gics_group,
          shares_outstanding: tickers.shares_outstanding,
        },
      });
    }
  };

  dataForSelectEdit = (dataSelect) => {
    const dataReturned = [];
    // eslint-disable-next-line array-callback-return
    dataSelect.map((data) => {
      dataReturned.push({
        key: data.id,
        value: data.id,
        text: data.name,
      });
    });
    return dataReturned;
  }

  handleSelectChangeEdit = (e, d) => {
    const { name, value } = d;
    const data = this.state.listEditDataCategory
      .filter(x => Number(x.category) === Number(value));
    if (data.length === 0) {
      this.setState({
        ...this.state,
        listEditDataCategory: [...this.state.listEditDataCategory, { [name]: value }],
        messageAlertDuplicate: '',
      });
    } else {
      this.setState({
        ...this.state,
        messageAlertDuplicate: 'It is already added to the list.',
      });
    }
  }

  sortFunc = (col, dir) => (a, b) => {
    let varA;
    let varB;
    switch (col) {
      case 'ticker':
      case 'company':
      case 'sector':
        varA = (typeof a[col] === 'string') ?
          a[col].toUpperCase() : a[col];
        varB = (typeof b[col] === 'string') ?
          b[col].toUpperCase() : b[col];
        break;
      default:
        return 0;
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (dir === 'DESC' ? (comparison * -1) : comparison);
  };

  createTableBody = (rows, cols, paginated) => {
    if (paginated) { // BEING DEPRECATED
      return (
        <React.Fragment>
          { this.state.searchTickers.length !== 0 ?
            this.state.searchTickers.map(x => (
              <Table.Row key={x.primary_key} className="third-step search-sixth-step">
                {cols.map(col =>
                  (
                    // eslint-disable-next-line no-nested-ternary
                    col.text === 'Actions' || col.text === 'Active' ?
                      col.text === 'Active' ?
                        <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                          <Checkbox
                            toggle
                            checked={+x.is_active}
                            name="actionActive"
                            onClick={() => this.handleTicketActive(x.id, x.is_active === 1)}
                            style={{ float: 'left', marginBottom: '10px' }}
                          />
                        </Table.Cell> :
                        <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                          <Button color="yellow" size="mini" icon="edit" onClick={() => this.ModifyTickersManager(x)} />
                        </Table.Cell>
                      :
                        <Table.Cell
                          key={col.key}
                          style={col.styleFunc && col.styleFunc(x[col.key])}
                        >
                          {col.formatFunc && !(x[col.key] == null) ? col.formatFunc(x[col.key]) : (!(x[col.key] == null) && x[col.key]) || ''}
                        </Table.Cell>
                  ))
                }
              </Table.Row>
            ))
             :
            rows.map(x => (
              <Table.Row key={x.primary_key} className="third-step search-sixth-step">
                {cols.map(col =>
            (
              // eslint-disable-next-line no-nested-ternary
            col.text === 'Actions' || col.text === 'Active' ?
              col.text === 'Active' ?
                <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                  <Checkbox
                    toggle
                    checked={+x.is_active}
                    name="actionActive"
                    onClick={() => this.handleTicketActive(x.id, x.is_active === 1)}
                    style={{ float: 'left', marginBottom: '10px' }}
                  />
                </Table.Cell> :
                <Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>
                  <Button color="yellow" size="mini" icon="edit" onClick={() => this.ModifyTickersManager(x)} />
                </Table.Cell>
            :
                <Table.Cell
                  key={col.key}
                  style={col.styleFunc && col.styleFunc(x[col.key])}
                >
                  {col.formatFunc && !(x[col.key] == null) ? col.formatFunc(x[col.key]) : (!(x[col.key] == null) && x[col.key]) || ''}
                </Table.Cell>
            ))
          }
              </Table.Row>
            ))
          }
        </React.Fragment>
      );
    }
    let htmlStr = '';
    rows.forEach((row) => {
      htmlStr += '<tr>';
      cols.forEach((col) => {
        htmlStr += '<td';
        htmlStr += `${col.styleFunc ? ` style="${col.styleFunc(row[col.key])}"` : ''}>`;
        htmlStr += col.formatFunc ? col.formatFunc(row[col.key]) : row[col.key];
        htmlStr += '</td>';
      });
      htmlStr += '</tr>';
    });
    return { __html: htmlStr };
  };

  handleSort = (column) => {
    let newDir = 'ASC';
    const { sortCol, sortDir, displayData } = this.state;
    if (sortCol === column) {
      newDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    const sortedData = displayData.sort(this.sortFunc(column, newDir));
    this.setState({
      sortCol: column,
      sortDir: newDir,
      displayData: sortedData,
    });
  }

  modifyTickerForButton = () => {
    const sendData = {
      modifyData: this.state.modifyTickers,
      categoryTicker: this.state.listEditDataCategory,
    };
    this.props.editTickerManager({
      sendData,
    }).then(() => {
      this.close();
      window.location.reload(true);
    });
  }

  render() {
    const {
      rows,
      sortCol,
      sortDir,
      style,
      sortColor,
      striped,
      celled,
      rowsPerPage,
      responsive,
      editCategoriesList,
    } = this.props;

    const columns = [
      { text: 'Ticker', key: 'ticker' },
      { text: 'Company', key: 'company' },
      { text: 'Sector', key: 'gics_sector' },
      { text: 'Active', key: 'is_active' },
      { text: 'Actions' },
    ];

    const paginated = true;

    const arr = sortDir === 'DESC' ? `${String.fromCharCode(160)}↑` : `${String.fromCharCode(160)}↓`;

    // If it's paginated process the rows
    let processedRows = rows;
    let totalPages = 1;
    if (paginated) {
      // eslint-disable-next-line max-len
      processedRows = rows.slice((this.state.page - 1) * rowsPerPage, this.state.page * rowsPerPage);
      totalPages = Math.ceil(rows.length / rowsPerPage);
    }

    // Generate responsive style
    let responsiveStyle = null;
    if (responsive === 'split') {
      // dynamically generate CSS based on columns!
      responsiveStyle = '@media screen and (max-width: 768px) {';
      responsiveStyle += 'table.split, .split thead, .split tbody, .split th, .split td, .split tr { display: block !important; }';
      responsiveStyle += '.split thead tr { position: absolute; top: -9999px; left: -9999px; }';
      responsiveStyle += '.split tr { border: 1px solid #ccc !important; }';
      responsiveStyle += '.split td { border: none !important; border-bottom: 1px solid #eee !important; position: relative !important; padding-left: 50% !important; text-align: left !important; }';
      responsiveStyle += '.split td:before { font-weight: bold; text-align: left; position: absolute !important; top: 6px !important; left: 6px !important; width: 45% !important; padding-right: 10px !important; white-space: nowrap; }';
      // Loop through columns
      columns.forEach((x, idx) => {
        responsiveStyle += `.split td:nth-of-type(${idx + 1}):before { content: "${x.text}"; cursor: pointer; }`;
      });
      responsiveStyle += '}';
    } else if (responsive === 'left-fixed') {
      responsiveStyle = '.table-scroll { margin:1em 0; }';
      responsiveStyle += '@media screen and (max-width: 768px) {';
      responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow: scroll; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
      responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
      responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
      // @todo - needs to be autocomputed based on height of first row
      responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
      responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
      responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
      responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
      // Remove left border from second col
      responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
      // Row hover z-index;
      responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
      responsiveStyle += '}';
    }

    let table = (
      <Table
        style={style}
        striped={striped}
        celled={celled}
        className={`${responsive} eighth-step`}
        unstackable
      >
        <Table.Header>
          <Table.Row className="upcoming-sixth-step upcoming-third-step upcoming-forth-step">
            {columns.map(col => (
              <Table.HeaderCell
                key={col.key}
                onClick={this.handleSort && (() => this.handleSort(col.key))}
                style={{
                  cursor: this.handleSort && 'pointer',
                  color: (sortCol === col.key ? sortColor : undefined),
                  ...col.style,
                }}
              >
                {col.key === sortCol ? `${col.text}${arr}` : col.text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
              this.createTableBody(processedRows, columns, true)
          }
        </Table.Body>
      </Table>
    );

    if (responsive === 'left-fixed') {
      table = (<div className="table-scroll">{table}</div>);
    }

    return (
      <React.Fragment>
        <Modal
          dimmer="blurring"
          onClose={this.close}
          open={this.state.openModal}
          closeOnEscape
          closeOnDimmerClick
          size="tiny"
          closeIcon
        >
          <center>
            <br />
            <h1>{ `Modify Ticker ${this.state.modifyTickers.ticker}` }</h1>
            <Modal.Content>
              <br />
              <br />
              <Form>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="YYYY"
                    label="Ticker"
                    name="ticker"
                    onChange={this.handleModifyTickerUpdate}
                    defaultValue={this.state.modifyTickers.ticker}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Name"
                    label="Company"
                    name="company"
                    onChange={this.handleModifyTickerUpdate}
                    defaultValue={this.state.modifyTickers.company}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Sector"
                    label="Sector"
                    name="gics_sector"
                    onChange={this.handleModifyTickerUpdate}
                    defaultValue={this.state.modifyTickers.gics_sector}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="text"
                    placeholder="Group Name ..."
                    label="Group Name"
                    name="gics_group"
                    onChange={this.handleModifyTickerUpdate}
                    defaultValue={this.state.modifyTickers.gics_group}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    style={{ width: '80%' }}
                    type="number"
                    placeholder="Shares Outstanding"
                    name="shares_outstanding"
                    label="Shares Outstanding"
                    onChange={this.handleModifyTickerUpdate}
                    defaultValue={this.state.modifyTickers.shares_outstanding}
                  />
                </Form.Field>
                <Form.Field>
                  <Select
                    style={{ width: '80%' }}
                    options={this.dataForSelectEdit(editCategoriesList.data)}
                    placeholder="Select your category"
                    name="category"
                    onChange={this.handleSelectChangeEdit}
                  />
                </Form.Field>
                <Form.Field>
                  <Card.Group
                    style={{ width: '80%' }}
                  >
                    {
                      this.state.listEditDataCategory.length === 0 ? '' :
                        this.state.listEditDataCategory.map(item => (
                          <Button
                            icon
                            labelPosition="left"
                            onClick={() => this.removeEditCategoryTickers(item)}
                            style={{ margin: '3px' }}
                          >
                            <Icon name="trash alternate" />
                            { editCategoriesList.data.find(data => data.id === item.category).name }
                          </Button>
                        ))
                    }
                  </Card.Group>
                </Form.Field>
                {
                    this.state.messageAlertDuplicate !== '' ?
                      <Form.Field>
                        <Card.Group style={{
                          width: '80%',
                          textAlign: 'center',
                          alignContent: 'center',
                          display: 'block',
                          color: 'red',
                          marginTop: '20px',
                          marginBottom: '5px',
                          }}
                        >
                          { this.state.messageAlertDuplicate }
                        </Card.Group>
                      </Form.Field> : ''
                  }
              </Form>
              <br />
              <Button color="blue" onClick={this.modifyTickerForButton}>
                <Icon name="save" /> Edit Ticker
              </Button>
              <br />
              <br />
            </Modal.Content>
          </center>
        </Modal>
        {responsive ? <style>{responsiveStyle}</style> : null}
        <div className="row">
          {paginated && totalPages > 1 ?
            (<Pagination
              activePage={this.state.page}
              totalPages={Math.ceil(rows.length / rowsPerPage)}
              size="tiny"
              boundaryRange={0}
              onPageChange={this.handlePageChange}
            />) :
            null}
          <Input
            style={{ width: '40%', float: 'right' }}
            type="text"
            placeholder="Search Tickers"
            label="Search"
            name="searchTickers"
            onChange={this.filterDataTickers}
          />
        </div>
        {table}
        {paginated && totalPages > 1 ?
          (<Pagination
            activePage={this.state.page}
            totalPages={Math.ceil(rows.length / rowsPerPage)}
            size="tiny"
            boundaryRange={0}
            onPageChange={this.handlePageChange}
          />) :
          null}
      </React.Fragment>
    );
  }
}

TableTickersManagerShow.defaultProps = {
  style: undefined,
  sortColor: 'darkblue',
  striped: undefined,
  celled: undefined,
  sortCol: '',
  sortDir: '',
  rowsPerPage: 100,
  responsive: '',
};

TableTickersManagerShow.propTypes = {
  rows: PropTypes.array.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  sortColor: PropTypes.string,
  style: PropTypes.object,
  striped: PropTypes.bool,
  celled: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  editCategoriesList: PropTypes.array.isRequired,
  responsive: PropTypes.string,
  disabledTickerAct: PropTypes.func.isRequired,
  selectTickerCat: PropTypes.func.isRequired,
  editTickerManager: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  disabledTickerAct: dataList => dispatch(disabledTickerActive(dataList)),
  selectTickerCat: dataList => dispatch(selectTickerCategory(dataList)),
  editTickerManager: dataList => dispatch(editTickerManager(dataList)),
});

export default connect(null, mapDispatchToProps)(TableTickersManagerShow);
