import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { scaleLinear, scaleTime } from 'd3-scale';
import { extent } from 'd3-array';
import moment from 'moment';
import Axes from './Axes';
import Line from './Line';
import Points from './Points';
import Legend from './Legend';
import ResponsiveWrapper from './ResponsiveWrapper';
import CrossHairCursor from './CrossHairCursor';
import CurrentCoordinate from './CurrentCoordinate';
import MovingAverageTooltip from './MovingAverageTooltip';
import MouseCoordinateX from './MouseCoordinateX';

class LineChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zoomTransform: null,
    };
    this.xScale = scaleTime();
    this.yScale = scaleLinear();
    this.Chart = React.createRef();

    this.zoomed = this.zoomed.bind(this);
    this.mousemove = this.mousemove.bind(this);
    this.mouseout = this.mouseout.bind(this);

    this.zoom = d3.zoom()
      .scaleExtent([1, 100])
      .on('zoom', this.zoomed);
  }

  componentDidMount() {
    const {
      zoomEnabled,
      colors,
      movingAverageTooltip,
      multi,
      data,
    } = this.props;

    if (zoomEnabled) {
      d3.select(this.Chart.current)
        .call(this.zoom)
        .on('touchmove mousemove', this.mousemove)
        .on('touchend mouseout', this.mouseout);
    }

    if (movingAverageTooltip) {
      if (multi) {
        colors.map((color, idx) => {
          const c_name = color.replace('#', '');
          return d3.select(this.Chart.current).select(`g.movingAverageTooltip_${c_name} g text tspan.text`)
            .html(data[data.length - 1].value[idx]);
        });
      } else {
        const c_name = colors[0].replace('#', '');
        d3.select(this.Chart.current).select(`g.movingAverageTooltip_${c_name} g text tspan.text`)
          .html(data[data.length - 1].value);
      }
    }
  }
  componentDidUpdate() {
    if (this.props.zoomEnabled) {
      d3.select(this.Chart.current)
        .call(this.zoom)
        .on('touchmove mousemove', this.mousemove)
        .on('touchend mouseout', this.mouseout);
    }
  }

  mousemove() {
    const {
      crossHairCursor,
      data,
      parentWidth,
      size,
      multi,
      waterLine,
      yAxisPadding,
      margins,
      currentCoordinate,
      colors,
      movingAverageTooltip,
      mouseCoordinateX,
    } = this.props;

    const { zoomTransform } = this.state;

    const bisectDate = d3.bisector(d => (multi ? d.label : d.date)).left;
    let calcSize = size;
    if (parentWidth) {
      calcSize = {
        width: parentWidth,
        height: size.height,
      };
    }

    const xScale = this.xScale
      .domain(extent(data, d => new Date(multi ? d.label : d.date)))
      .range([margins.left, calcSize.width - margins.right]);

    // scaleLinear type
    const yScale = this.yScale
      .domain(extent((waterLine ? [waterLine] : [])
        .concat(...data.map(d => d.value)), d => parseFloat(d))
      // Add the padding
        .map((x, idx) => x + yAxisPadding[idx]))
      .range([calcSize.height - margins.bottom, margins.top]);

    if (zoomTransform) {
      xScale.domain(zoomTransform.rescaleX(xScale).domain());
      yScale.range(zoomTransform.rescaleY(yScale).range());
    }

    const mouse = d3.mouse(d3.event.currentTarget);
    const mousex = mouse[0];
    const mousey = mouse[1];
    const x0 = xScale.invert(mousex);

    const i = bisectDate(data, x0, 1);
    const d0 = data[i - 1];
    const d1 = data[i];
    let d;
    if (multi) {
      d = x0 - d0.label > d1.label - x0 ? d1 : d0;
    } else {
      d = x0 - d0.date > d1.date - x0 ? d1 : d0;
    }

    if (crossHairCursor) {
      d3.select(this.Chart.current).select('.CrossHairCursor')
        .attr('display', '');
      d3.select(this.Chart.current).select('line.verticalLine')
        .attr('x1', xScale(multi ? d.label : d.date))
        .attr('x2', xScale(multi ? d.label : d.date));
      d3.select(this.Chart.current).select('line.horizontalLine')
        .attr('y1', mousey)
        .attr('y2', mousey);
    }
    if (currentCoordinate) {
      const cc = d3.select(this.Chart.current).select('.currentCoordinate');
      cc.attr('display', '');
      if (multi) {
        colors.map((color, idx) => {
          const c_name = color.replace('#', '');
          if (d3.select(this.Chart.current).select(`circle.circle_${c_name}`).empty()) {
            cc.append('circle')
              .attr('class', `circle_${c_name}`)
              .attr('r', 3.5)
              .attr('fill', color);
          }

          return d3.select(this.Chart.current).select(`circle.circle_${c_name}`)
            .attr('cx', xScale(d.label))
            .attr('cy', yScale(d.value[idx]));
        });
      } else {
        const c_name = colors[0].replace('#', '');
        if (d3.select(this.Chart.current).select(`circle.circle_${c_name}`).empty()) {
          cc.append('circle')
            .attr('class', `circle_${c_name}`)
            .attr('r', 3.5)
            .attr('fill', colors[0]);
        }

        d3.select(this.Chart.current).select(`circle.circle_${c_name}`)
          .attr('cx', xScale(d.date))
          .attr('cy', yScale(d.value));
      }
    }
    if (movingAverageTooltip) {
      colors.map((color, idx) => {
        const c_name = color.replace('#', '');
        return d3.select(this.Chart.current).select(`g.movingAverageTooltip_${c_name} g text tspan.text`)
          .html(multi ? d.value[idx] : d.value);
      });
    }
    if (mouseCoordinateX) {
      const formatDate = multi ?
        moment(d.label).format('Y-MM-DD') :
        moment(d.date).format('Y-MM-DD');
      d3.select(this.Chart.current).select('.mouseCoordinateX')
        .style('left', `${mousex - 25}px`)
        .style('opacity', 1)
        .html(formatDate);
    }
  }

  mouseout() {
    const { crossHairCursor, currentCoordinate, mouseCoordinateX } = this.props;
    if (crossHairCursor) {
      d3.select(this.Chart.current).select('.CrossHairCursor')
        .attr('display', 'none');
    }
    if (currentCoordinate) {
      d3.select(this.Chart.current).select('.currentCoordinate')
        .attr('display', 'none');
    }
    if (mouseCoordinateX) {
      d3.select(this.Chart.current).select('.mouseCoordinateX')
        .style('opacity', 0);
    }
  }

  zoomed() {
    this.setState({
      zoomTransform: d3.event.transform,
    });
  }

  render() {
    const {
      data,
      margins,
      size,
      className,
      parentWidth,
      handleClick,
      showPoints,
      multi,
      colors,
      timeFormatter,
      timeTicks,
      waterLine,
      legend,
      labels,
      yAxisPadding,
      crossHairCursor,
      currentCoordinate,
      movingAverageTooltip,
      movingTooltipOrigins,
      movingToolTipTexts,
      timeFormatterAuto,
      mouseCoordinateX,
    } = this.props;
    const { zoomTransform } = this.state;
    let calcSize = size;
    // let tooltipHeight = 0;
    // if (movingAverageTooltip) {
    //   tooltipHeight = 28;
    // }

    if (parentWidth) {
      calcSize = {
        width: parentWidth,
        height: size.height,
      };
    }

    let position = 'left';
    if (data && data[0] && parseFloat(data[0].value) > parseFloat(data[data.length - 1].value)) {
      position = 'right';
    }

    const maxValue = Math.max(...data.map(d => d.value));
    // scaleTime type
    const xScale = this.xScale
      .domain(extent(data, d => new Date(multi ? d.label : d.date)))
      .range([margins.left, calcSize.width - margins.right]);

    // scaleLinear type
    const yScale = this.yScale
      .domain(extent((waterLine ? [waterLine] : [])
        .concat(...data.map(d => d.value)), d => parseFloat(d))
        // Add the padding
        .map((x, idx) => x + yAxisPadding[idx]))
      .range([calcSize.height - margins.bottom, margins.top]);

    return (
      <div ref={this.Chart}>
        {mouseCoordinateX && (
          <MouseCoordinateX
            top={calcSize.height + 40}
          />
        )}
        <svg
          width={calcSize.width}
          height={calcSize.height}
          className={className}
        >
          <clipPath id="clip">
            <rect
              width={calcSize.width - margins.right - margins.left}
              height={calcSize.height - margins.bottom}
              x={margins.left}
              y={0}
            />
          </clipPath>
          {/* Legend */}
          {legend ? (
            <Legend
              position={position}
              calcSize={calcSize}
              colors={colors}
              margins={margins}
              labels={labels}
              padding={5}
              waterLine={waterLine}
            />
          ) : null }
          {/* Lines and Axes */}
          <Axes
            scales={{ xScale, yScale }}
            margins={margins}
            svgDimensions={calcSize}
            xIsTime
            timeFormatter={timeFormatter}
            timeTicks={timeTicks}
            ref={this.Axes}
            zoomTransform={zoomTransform}
            timeFormatterAuto={timeFormatterAuto}
          />
          <Line
            colors={colors}
            data={data}
            multi={multi}
            handleClick={handleClick}
            scales={{ xScale, yScale }}
            strokeWidth={1.5}
            waterLine={waterLine}
            ref={this.Line}
            zoomTransform={zoomTransform}
          />
          {showPoints && (
            <Points
              colorStart="#FF0C23"
              colorEnd="#FF0C23"
              scales={{ xScale, yScale }}
              data={data.filter((x, i) => (i % 261 === 0))}
              maxValue={maxValue}
              handleClick={handleClick}
              svgDimensions={calcSize}
            />)}
          {currentCoordinate && (
            <CurrentCoordinate
              xPadding={margins.left}
            />)}
          {movingAverageTooltip && (
            colors.map((color, idx) => (
              <MovingAverageTooltip
                color={color}
                origin={movingTooltipOrigins[idx]}
                text={movingToolTipTexts[idx]}
              />))
          )}
          {crossHairCursor && (
          <CrossHairCursor
            height={calcSize.height - margins.bottom}
            width={calcSize.width - margins.right - margins.left}
            xPadding={margins.left}
          />)}
        </svg>
      </div>
    );
  }
}

LineChart.defaultProps = {
  className: '',
  colors: ['#497CB7'],
  parentWidth: undefined,
  handleClick: undefined,
  showPoints: false,
  multi: false,
  timeFormatter: undefined,
  timeTicks: undefined,
  waterLine: undefined,
  legend: false,
  labels: undefined,
  yAxisPadding: [0, 0],
  zoomEnabled: false,
  crossHairCursor: false,
  currentCoordinate: false,
  movingAverageTooltip: false,
  movingTooltipOrigins: [],
  movingToolTipTexts: [],
  timeFormatterAuto: false,
  mouseCoordinateX: false,
};

LineChart.propTypes = {
  multi: PropTypes.bool,
  showPoints: PropTypes.bool,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  data: PropTypes.array.isRequired,
  colors: PropTypes.array,
  margins: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  parentWidth: PropTypes.number,
  timeFormatter: PropTypes.string,
  timeTicks: PropTypes.array,
  waterLine: PropTypes.number,
  legend: PropTypes.bool,
  labels: PropTypes.array,
  yAxisPadding: PropTypes.array,
  zoomEnabled: PropTypes.bool,
  crossHairCursor: PropTypes.bool,
  currentCoordinate: PropTypes.bool,
  movingAverageTooltip: PropTypes.bool,
  movingTooltipOrigins: PropTypes.array,
  movingToolTipTexts: PropTypes.array,
  timeFormatterAuto: PropTypes.bool,
  mouseCoordinateX: PropTypes.bool,
};

export default ResponsiveWrapper(LineChart);
