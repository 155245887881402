import React from 'react';
// import { Link, Redirect } from 'react-router-dom';
import { Table, Dropdown } from 'semantic-ui-react';
// import PropTypes from 'prop-types';
// import { BlueText, RedText, GreenText } from '../presentational/ColoredText';
import * as api from '../../api';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';

const formatDate = (date) => {
  const temp = new Date(date);
  return `${temp.getMonth() + 1}/${temp.getDate()}/${`${temp.getFullYear()}`.slice(2)}`;
};

let responsiveStyle = '.table-scroll { margin:1em 0; }';
responsiveStyle += '@media screen and (max-width: 768px) {';
responsiveStyle += '.table-scroll { position:relative; width:100%; z-index:1; overflow-x:scroll; overflow-y: visible; height:calc(100vh - 135px); border: 1px solid rgba(34,36,38,.1);-webkit-overflow-scrolling: touch;}';
responsiveStyle += '.table-scroll table.left-fixed { width: 100%; border: none !important; }';
responsiveStyle += 'table.left-fixed thead tr:first-child th { position: -webkit-sticky; position: sticky; top: 0; white-space:nowrap;}';
// @todo - needs to be autocomputed based on height of first row
responsiveStyle += 'table.left-fixed thead tr:nth-child(2) th { position: -webkit-sticky; position: sticky; top: 30px; }';
responsiveStyle += 'table.left-fixed th:first-child, table.left-fixed td:first-child { background-color:#f9fafb; position: -webkit-sticky; position:sticky; left:0; z-index: 2; border-right: 1px solid rgba(34,36,38,.1);}';
responsiveStyle += 'table.left-fixed tr:nth-child(odd) td:first-child { background-color:#ffffff; }';
responsiveStyle += 'table.left-fixed thead th:first-child { z-index: 5; }';
// Remove left border from second col
responsiveStyle += 'table.left-fixed th:nth-child(2), table.left-fixed td:nth-child(2) {border-left:none !important;}';
// Row hover z-index;
responsiveStyle += 'table.left-fixed tr:hover { opacity: 1; }';
responsiveStyle += '}';

const getNumStyle = (num) => {
  const parsedNum = parseFloat(num);
  const style = {};
  if (parsedNum > 0) {
    style.color = 'green';
  } else if (parsedNum < 0) {
    style.color = 'red';
  } else {
    style.color = 'black';
  }
  return style;
};

const getWordStyle = (word) => {
  const style = {};
  if (['Stronger', 'Overbought', 'Falling'].indexOf(word) !== -1) {
    style.color = 'green';
  } else if (['Weaker', 'Oversold', 'Rising'].indexOf(word) !== -1) {
    style.color = 'red';
  } else {
    style.color = 'black';
  }
  return style;
};

const sortFunc = (col, dir) => (a, b) => {
  const varA = (typeof a[col] === 'string') ?
    a[col].toUpperCase() : a[col];
  const varB = (typeof b[col] === 'string') ?
    b[col].toUpperCase() : b[col];
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (dir === 'DESC' ? (comparison * -1) : comparison);
};

class EconomicDatabase extends React.Component {
  constructor(props) {
    super(props);

    this.cacheExpTime = 900000;
    this.loader = (<p>Loading...</p>);
    this.columns = [
      {
        name: 'date',
        formatFunc: x => formatDate(x),
        styleFunc: () => undefined,
        text: 'Date',
      },
      {
        name: 'timeofday',
        formatFunc: x => x,
        styleFunc: () => undefined,
        text: 'Time',
      },
      {
        name: 'actual',
        formatFunc: x => x,
        styleFunc: () => undefined,
        text: 'Actual',
      },
      {
        name: 'estimate',
        formatFunc: x => x,
        styleFunc: () => undefined,
        text: 'Est.',
      },
      {
        name: 'difference',
        formatFunc: x => parseFloat(x).toFixed(1),
        styleFunc: () => undefined,
        text: 'Diff.',
      },
      {
        name: 'strongweak',
        formatFunc: x => x,
        styleFunc: x => getWordStyle(x),
        text: 'Stronger / Weaker',
      },
      {
        name: 'interest1mo',
        formatFunc: x => x,
        styleFunc: x => getWordStyle(x),
        text: 'Interest 1Mo. Trend',
      },
      {
        name: 'sp500bos',
        formatFunc: x => x,
        styleFunc: x => getWordStyle(x),
        text: 'S&P 500 OB/OS',
      },
      {
        name: 'sp500',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'S&P 500',
        calcAgg: true,
      },
      {
        name: 'consdescret',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Cons. Discret.',
        calcAgg: true,
      },
      {
        name: 'consstaples',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Cons. Staples',
        calcAgg: true,
      },
      {
        name: 'energy',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Energy',
        calcAgg: true,
      },
      {
        name: 'financials',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Fincls',
        calcAgg: true,
      },
      {
        name: 'healthcare',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Health Care',
        calcAgg: true,
      },
      {
        name: 'industrials',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Industr.',
        calcAgg: true,
      },
      {
        name: 'materials',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Materials',
        calcAgg: true,
      },
      {
        name: 'technology',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Tech',
        calcAgg: true,
      },
      {
        name: 'telcomsvcs',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Telecom',
        calcAgg: true,
      },
      {
        name: 'utilities',
        formatFunc: x => parseFloat(x).toFixed(2),
        styleFunc: x => getNumStyle(x),
        text: 'Util.',
        calcAgg: true,
      },
    ];

    this.handleIndicatorChange = this.handleIndicatorChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);

    this.state = {
      sortDir: 'DESC',
      sortCol: 'date',
      isLoading: false,
      displayData: {
        data: [],
        lastFetched: null,
      },
      lastUpdate: {
        data: [],
        lastFetched: null,
      },
    };
  }

  componentDidMount() {
    this.getLastUpdate();
    this.getData('Non Farm Payroll');
  }

  // Get data for indicator if in url
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.state.lastUpdate.lastFetched < Date.now() - this.cacheExpTime) {
        this.getLastUpdate();
      }
    }
  }

  getLastUpdate() {
    this.setState({ isLoading: true });
    api.tools.getEILastUpdate()
      .then(result => this.setState({
        lastUpdate: {
          data: result,
          lastFetched: Date.now(),
        },
      }))
      .finally(() => this.setState({
        isLoading: false,
      }));
  }

  getData(indicator) {
    this.setState({ isLoading: true });
    api.tools.getEIData(indicator)
      .then(result => this.setState({
        displayData: {
          data: result,
          lastFetched: Date.now(),
        },
      }))
      .finally(() => this.setState({
        isLoading: false,
      }));
  }

  handleIndicatorChange(event, data) {
    this.getData(data.value);
  }

  handleSortChange(column, direction) {
    if (column && direction) {
      this.setState({
        sortCol: column,
        sortDir: direction,
      });
    }
  }

  render() {
    const {
      lastUpdate,
      displayData,
      sortCol,
      sortDir,
    } = this.state;
    const parsedOptions = [];

    const steps = [
      {
        selector: '.first-step',
        content: 'Our Economic Indicator Database contains historical data for every major US economic indicator since 1998.  The database contains the indicator\'s actual number and the consensus economist estimate at the time of the release (not revised).',
      },
      {
        selector: '.second-step',
        content: 'Click the drop-down tab to select one of 40+ US economic indicators in the database.  The date shown in parenthesis represents the indicator\'s most recent reading.',
      },
      {
        selector: '.third-step',
        content: 'Summary data for each indicator includes the frequency with which it has historically beaten or missed consensus economist estimates.  Also included is the average one-day percentage change of the S&P 500 and major sectors on the day of the economic release. ',
      },
      {
        selector: '.forth-step',
        content: 'The database\'s main table shows the date and time of each indicator\'s release since 1998.',
      },
      {
        selector: '.fifth-step',
        content: 'Included is the "Actual" reported number at the time of the release, the consensus economist estimate (Est.), the difference between the two, and whether the number was stronger or weaker than expected.',
      },
      {
        selector: '.sixth-step',
        content: 'Also included is the direction that the 10-Year Treasury yield traded in the month leading up to the indicator\'s release and whether the S&P 500 was overbought, oversold, or neutral at the time of the release.',
      },
      {
        selector: '.seventh-step',
        content: 'The final data included is the one-day percentage change experienced by the S&P 500 and its major sectors on the date shown.',
      },
      {
        selector: '.eighth-step',
        content: 'You can sort the main table by clicking any of the column headers.',
      },
      {
        selector: '.ninth-step',
        content: 'Our Economic Indicator Database is available to Bespoke Institutional members only.  This tool is meant to be a starting point for further research and is NOT a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="economicIndicatorTouring"
        steps={steps}
      />);

    if (lastUpdate && lastUpdate.data) {
      for (let i = 0; i < lastUpdate.data.length; i += 1) {
        const item = lastUpdate.data[i];
        const date = new Date(item.date);
        parsedOptions.push({
          text: `${item.report} (${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()})`,
          value: item.report,
          key: item.report,
        });
      }
    }
    const selector = (
      <Dropdown
        style={{ maxWidth: '300px' }}
        closeOnChange
        onChange={this.handleIndicatorChange}
        selection
        search
        loading={this.state.isLoading}
        // placeholder="Select an indicator"
        defaultValue="Non Farm Payroll"
        options={parsedOptions}
        className="second-step"
      />);
    const aggCols = this.columns.filter(x => x.calcAgg);
    const aggData = {
      stronger: { num: 0 },
      weaker: { num: 0 },
      inline: { num: 0 },
      vol: {},
      total: 0,
    };
    aggCols.forEach((x) => {
      aggData.stronger[x.name] = [];
      aggData.weaker[x.name] = [];
      aggData.inline[x.name] = [];
      aggData.vol[x.name] = [];
    });
    // Calc agg data while mapping over
    const rows = displayData.data.sort(sortFunc(sortCol, sortDir)).map((x) => {
      const strwk = x.strongweak.toLowerCase();
      aggData.total += 1;
      aggData[strwk].num += 1;
      aggCols.forEach((col) => {
        aggData[strwk][col.name].push(x[col.name]);
        aggData.vol[col.name].push(x[col.name]);
      });
      return (
        <Table.Row key={x.primary_key}>
          {this.columns.map(col =>
            (
              <Table.Cell
                style={col.styleFunc(x[col.name])}
              >
                {col.formatFunc(x[col.name])}
              </Table.Cell>))
          }
        </Table.Row>
      );
    });
    // calculate aggregate data and display it.
    const aggregate = displayData.data.length > 0 ? (
      <Table
        key="agg-table"
        celled
        className="third-step economic-indicator-table aggregate"
        unstackable
        style={{ fontSize: '20px', scrollX: 'true' }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2" />
            <Table.HeaderCell style={{ textAlign: 'center' }} colSpan="12">
              Market Performance (One Day Percent Change)
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              Average Percent Change
            </Table.HeaderCell>
            <Table.HeaderCell>
              Frequency (%)
            </Table.HeaderCell>
            { aggCols.map(x => (
              <Table.HeaderCell>{x.text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
             When Stronger Than Forecast
            </Table.Cell>
            <Table.Cell>
              {(100 * (aggData.stronger.num / aggData.total)).toFixed(1) }
            </Table.Cell>
            {aggCols.map((x) => {
              const num = aggData.stronger[x.name].reduce((acc, curr) => acc + curr, 0) /
                aggData.stronger[x.name].length;
              return (
                <Table.Cell style={getNumStyle(num)}>
                  {Number.isNaN(num) ? 'N/A' : num.toFixed(2)}
                </Table.Cell>);
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              When Weaker Than Forecast
            </Table.Cell>
            <Table.Cell>
              {(100 * (aggData.weaker.num / aggData.total)).toFixed(1) }
            </Table.Cell>
            {aggCols.map((x) => {
              const num = aggData.weaker[x.name].reduce((acc, curr) => acc + curr, 0) /
                aggData.weaker[x.name].length;
              return (
                <Table.Cell style={getNumStyle(num)}>
                  {Number.isNaN(num) ? 'N/A' : num.toFixed(2)}
                </Table.Cell>);
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              When Inline With Forecast
            </Table.Cell>
            <Table.Cell>
              {(100 * (aggData.inline.num / aggData.total)).toFixed(1) }
            </Table.Cell>
            {aggCols.map((x) => {
              const num = aggData.inline[x.name].reduce((acc, curr) => acc + curr, 0) /
                aggData.inline[x.name].length;
              return (
                <Table.Cell style={getNumStyle(num)}>
                  {Number.isNaN(num) ? 'N/A' : num.toFixed(2)}
                </Table.Cell>);
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Absolute Move (Volatility)
            </Table.Cell>
            <Table.Cell />
            {aggCols.map((x) => {
              const num = aggData.vol[x.name].reduce((acc, curr) => acc + Math.abs(curr), 0) /
                aggData.vol[x.name].length;
              return (
                <Table.Cell>
                  {Number.isNaN(num) ? 'N/A' : num.toFixed(2)}
                </Table.Cell>);
            })}
          </Table.Row>
        </Table.Body>
      </Table>) : null;
    const table = (
      <Table key="data-table" striped celled unstackable className="forth-step economic-indicator-table" style={{ fontSize: '20px' }} >
        <Table.Header>
          <Table.Row className="fifth-step  eighth-step">
            {this.columns.map((x) => {
              let sortArr = '';
              let newDir = 'ASC';
              if (sortCol === x.name) {
                if (sortDir === 'ASC') {
                  newDir = 'DESC';
                  sortArr = ' ↓';
                } else {
                  sortArr = ' ↑';
                }
              }

              let className = '';
              if (x.name === 'sp500bos') {
                className = 'sixth-step';
              }

              if (x.name === 'sp500') {
                className = 'seventh-step';
              }

              return (
                <Table.HeaderCell
                  key={x.name}
                  style={{
                    cursor: 'pointer',
                    color: (sortCol === x.name ? 'darkblue' : undefined),
                    // whiteSpace: 'nowrap',
                  }}
                  onClick={() => this.handleSortChange(x.name, newDir)}
                  className={className}
                >
                  {`${x.text}${sortArr}`}
                </Table.HeaderCell>);
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    );
    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[4]} />
        <style>{responsiveStyle}</style>
        <h1 className="first-step">Economic Indicator Database {tour}</h1>
        <div>{selector}</div>
        <br />
        <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }}>{aggregate}</div>
        <br />
        <div style={{ width: '100%', overflowX: 'scroll', overflowY: 'visible' }}>{table}</div>
      </React.Fragment>
    );
  }
}

export default EconomicDatabase;
