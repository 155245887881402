import { executeQuery, mainPath } from './query';

const toolsPath = `${mainPath}/api/tools`;

// screen to query variable helper function
const screenToQuery = (screen) => {
  let qString = '?';
  // fromDate
  if (screen.fromDate) { qString += `fromDate=${screen.fromDate}&`; }
  // toDate
  if (screen.toDate) { qString += `toDate=${screen.toDate}&`; }
  // eps
  if (screen.eps) {
    Object.entries(screen.eps).forEach((x) => {
      const [direction, value] = x;
      qString += `eps_${direction}=${value}&`;
    });
  }
  // revenue
  if (screen.rev) {
    Object.entries(screen.rev).forEach((x) => {
      const [direction, value] = x;
      qString += `rev_${direction}=${value}&`;
    });
  }
  // guidance
  if (screen.guidance) {
    Object.entries(screen.guidance).forEach((x) => {
      const [direction, value] = x;
      qString += `guidance_${direction}=${value}&`;
    });
  }
  // quarters
  if (screen.quarters) {
    Object.entries(screen.quarters).forEach((x) => {
      const [quarter, value] = x;
      qString += `quarters_${quarter}=${value}&`;
    });
  }
  // gap
  if (screen.gap) {
    qString += `gap_value=${screen.gap.value}&gap_comparator=${encodeURIComponent(screen.gap.comparator)}&`;
  }
  // o2c
  if (screen.o2c) {
    qString += `o2c_value=${screen.o2c.value}&o2c_comparator=${encodeURIComponent(screen.o2c.comparator)}&`;
  }
  // oneDay
  if (screen.oneDay) {
    qString += `oneDay_value=${screen.oneDay.value}&oneDay_comparator=${encodeURIComponent(screen.oneDay.comparator)}&`;
  }
  return qString;
};

export const getMonitorData = (category = 'all') => executeQuery(`${toolsPath}/monitors/${category}`);
export const updateMonitorData = 'updateMonitorData not yet implemented';
export const getEILastUpdate = () => executeQuery(`${toolsPath}/economic-indicators`);
export const getEIData = (indicator = 'all') => executeQuery(`${toolsPath}/economic-indicators/${indicator}`);
export const getIRData = (sector = 'all', column = 'ticker', direction = 'ASC') =>
  executeQuery(`${toolsPath}/international-revenues/${sector}?column=${column}&direction=${direction}`);
export const getECData = () => executeQuery(`${toolsPath}/earnings-calendar`);
export const getECTicker = ticker => executeQuery(`${toolsPath}/earnings-calendar/${ticker}`);
// refactor for raw and rolling window
export const getEEAggregateData = () => executeQuery(`${toolsPath}/earnings-explorer/`);
export const getEEQuarterlyAggregateData = ticker => executeQuery(`${toolsPath}/earnings-explorer/quarterly/${ticker}`);
export const getEEHistoricalData = ticker => executeQuery(`${toolsPath}/earnings-explorer/${ticker}`);
export const getEEScreenResults = screen => executeQuery(`${toolsPath}/earnings-explorer/screen/${screenToQuery(screen)}`);
export const getSeasonalityGauges = () => executeQuery(`${toolsPath}/seasonality/speedo`);
export const getSeasonalityEquities = data => executeQuery(`${toolsPath}/seasonality/us-equities`, 'POST', data);
export const getSeasonalityIndices = data => executeQuery(`${toolsPath}/seasonality/indices`, 'POST', data);
export const getSeasonalityETFS = data => executeQuery(`${toolsPath}/seasonality/etfs`, 'POST', data);
export const getSeasonalityCustomPortfolios = data => executeQuery(`${toolsPath}/seasonality/custom-portfolios`, 'POST', data);
export const getSeasonalityComposite = (ticker, year) => executeQuery(`${toolsPath}/seasonality/composite?ticker=${ticker}&year=${year}`);
export const getRecentTriplePlays = () => executeQuery(`${toolsPath}/triple-plays`);
export const getBiggestMovers = date => executeQuery(`${toolsPath}/biggest-movers?date=${date}`);
export const getOBOSPercentage = (category, timeframe) => executeQuery(`${toolsPath}/bespoke-charts/obos?category=${category}&q=${timeframe}`);
export const getFiftyDmaPercentage = (category, timeframe) => executeQuery(`${toolsPath}/bespoke-charts/50dmapct?category=${category}&q=${timeframe}`);
export const getTwohundredDmaPercentage = (category, timeframe) => executeQuery(`${toolsPath}/bespoke-charts/200dmapct?category=${category}&q=${timeframe}`);
export const getModelPortfolios = () => executeQuery(`${toolsPath}/admin/bespoke-baskets`);
export const getMPDetails = mpId => executeQuery(`${toolsPath}/admin/bespoke-baskets/detail/${mpId}`);
export const postMPDetails = (mpId, body) => executeQuery(`${toolsPath}/admin/bespoke-baskets/detail/${mpId}`, 'POST', body);
export const deleteMPDetails = id => executeQuery(`${toolsPath}/admin/bespoke-baskets/detail/${id}`, 'DELETE');
export const deleteMPortfolio = id => executeQuery(`${toolsPath}/admin/bespoke-baskets/${id}`, 'DELETE');
export const postModelPortfolio = body => executeQuery(`${toolsPath}/admin/bespoke-baskets`, 'POST', body);
export const putModelPortfolio = body => executeQuery(`${toolsPath}/admin/bespoke-baskets`, 'PUT', body);
export const sellMPortfolioDetail = (id, body) => executeQuery(`${toolsPath}/admin/bespoke-baskets/detail/sell/${id}`, 'POST', body);
export const getMPData = (mpId, date) => executeQuery(`${toolsPath}/admin/bespoke-baskets/detail/data/${mpId}/${date}`);
export const getHistoricalChange = () => executeQuery(`${toolsPath}/admin/bespoke-baskets/historical-changes`);
