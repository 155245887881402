import { combineReducers } from 'redux';
import researchReducer from './research';
import staticReducer from './static';
import tickerReducer from './ticker';

/**
 * Full content reducer
 */
export default combineReducers({
  research: researchReducer,
  static: staticReducer,
  ticker: tickerReducer,
});
