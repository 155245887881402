import {
  CONTENT_SET_TICKER,
  CONTENT_SET_TICKER_LOADING,
  CONTENT_SET_TICKER_ERROR,
  CONTENT_SET_HISTORY,
  CONTENT_SET_HISTORY_LOADING,
  CONTENT_SET_HISTORY_ERROR,
} from '../../types/actions';

export default (state = {
  ticker: null,
  isLoading: false,
  error: null,
  history: [],
  historyLoading: false,
  historyError: null,
  cards: {},
}, action) => {
  switch (action.type) {
    case CONTENT_SET_TICKER:
      return ({
        ...state,
        ticker: action.ticker,
        error: null,
      });
    case CONTENT_SET_TICKER_LOADING:
      return ({
        ...state,
        isLoading: action.isLoading,
      });
    case CONTENT_SET_TICKER_ERROR:
      return ({
        ...state,
        error: action.error,
      });
    case CONTENT_SET_HISTORY:
      return ({
        ...state,
        history: action.history,
      });
    case CONTENT_SET_HISTORY_LOADING:
      return ({
        ...state,
        historyLoading: action.isLoading,
      });
    case CONTENT_SET_HISTORY_ERROR:
      return ({
        ...state,
        historyError: action.error,
      });
    default:
      return state;
  }
};
