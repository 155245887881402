import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Sidebar, Menu, Image } from 'semantic-ui-react';
import { toggleMenu, updateCardData } from '../../actions';
import { navigateToPage } from '../../actions/search';
// import * as logo from '../../img/logo_white.png';
import backArrow from '../../img/arrow-left.png';
import { pages } from '../../types';
import * as api from '../../api';

class SideMenu extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(thunkDispatch => api.user.getPortfolios()
      .then(portfolios => thunkDispatch(updateCardData(pages.HOME, { portfolios }))));
  }
  render() {
    const { visibility, dispatch } = this.props;
    return (
      <Sidebar
        style={{ boxShadow: '6px 0px 20px rgba(0, 0, 0, 0.5)' }}
        as={Menu}
        vertical
        animation="overlay"
        visible={visibility}
        inverted
        className="overlay-2"
      >
        <Menu.Item key="close">
          <Image src={backArrow} style={{ maxHeight: '45px' }} onClick={() => dispatch(toggleMenu())} />
        </Menu.Item>
        <Menu.Item key="home" onClick={() => dispatch(navigateToPage('/'))}>
          <span><Icon name="home" />Home</span>
        </Menu.Item>
        <Menu.Item key="research">
          Research
          <Menu.Menu>
            <Menu.Item key="starred-reports" onClick={() => dispatch(navigateToPage('/research/starred-reports'))}>
              Starred
            </Menu.Item>
            <Menu.Item key="key-reports" onClick={() => dispatch(navigateToPage('/research/key-reports'))}>
              Bespoke Report
            </Menu.Item>
            <Menu.Item key="morning-lineup" onClick={() => dispatch(navigateToPage('/research/morning-lineup'))}>
              Morning Lineup
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item key="tools">
          Tools
          <Menu.Menu>
            <Menu.Item key="charts" onClick={() => dispatch(navigateToPage('/charts/'))}>
              Bespoke Charts
            </Menu.Item>
            <Menu.Item key="chart-scanner" onClick={() => dispatch(navigateToPage('/chart-scanner/'))}>
              Bespoke Charts Scanner
            </Menu.Item>
            <Menu.Item key="earnings" onClick={() => dispatch(navigateToPage('/earnings/'))}>
              Bespoke Earnings Tool
            </Menu.Item>
            <Menu.Item key="seasonality" onClick={() => dispatch(navigateToPage('/seasonality/'))}>
              Bespoke Seasonality Tool
            </Menu.Item>
            <Menu.Item key="trend-analyzer" onClick={() => dispatch(navigateToPage('/trend-analyzer/'))}>
              Bespoke Trend Analyzer
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item key="portfolios">
          My Portfolios
          <Menu.Menu>
            {(this.props.portfolios && this.props.portfolios.byId) ?
              Object.entries(this.props.portfolios.byId).map(x => <Menu.Item key={x[1].id} onClick={() => this.props.dispatch(navigateToPage(`/portfolio/${x[1].id}`))}>{x[1].name}</Menu.Item>) :
              <a href="page"><li>No Portfolios</li></a>
              }
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    );
  }
}

SideMenu.defaultProps = {
  visibility: false,
};

SideMenu.propTypes = {
  visibility: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  portfolios: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  state,
  visibility: state.view.menuVisible,
  portfolios: state.user.portfolios,
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
