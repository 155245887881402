import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dimmer, Loader, Pagination, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { GreenText, YellowText, RedText } from '../ColoredText';
import Colors from './colors';

function getColor(positive, neutral, negative, value, trend = 1, change = false) {
  const hexParse = (hexCode) => {
    const v = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/.exec(hexCode)[1];
    let rgb = null;
    if (v.length === 3) {
      rgb = /([A-Fa-f0-9]{1})([A-Fa-f0-9]{1})([A-Fa-f0-9]{1})/.exec(v);
      return { r: rgb[1], g: rgb[2], b: rgb[3] };
    } else if (v.length === 6) {
      rgb = /([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})([A-Fa-f0-9]{2})/.exec(v);
    }
    if (rgb) {
      return [rgb[1], rgb[2], rgb[3]].map(x => parseInt(x, 16));
    }
    return null;
  };
  const interpolate = (a, b, val) => Math.round(((a - b) * val) + b);
  let output = '';
  if (value > 0) {
    for (let i = 0; i < 3; i += 1) {
      output += interpolate(hexParse(positive)[i], hexParse(neutral)[i], value).toString(16);
    }
  } else if (value < 0) {
    for (let i = 0; i < 3; i += 1) {
      if (change) {
        output += interpolate(hexParse(negative)[i], hexParse(neutral)[i], -1 * value).toString(16);
      } else {
        output += interpolate(hexParse(negative)[i], hexParse(neutral)[i], 1 + value).toString(16);
      }
    }
  } else if (trend < 0) {
    return negative;
  } else {
    return neutral;
  }
  return `#${output}`;
}

// normalize between -1 and 1, with 0 being avg.
// const normalize1to1 = (max, min, value) => 2 * ((value - ((max + min) / 2)) / (max - min));
const normalize = (max, min, value) => (value - min) / (max - min);

// sort comparison function
const compFunc = (sortCol, sortDir) => (a, b) => {
  let varA;
  let varB;
  switch (sortCol) {
    case 'volume':
    case 'price':
    case 'cur_price':
    case 'cur_range':
      varA = a[sortCol];
      varB = b[sortCol];
      break;
    case 'tckr':
    case 'company':
    case 'gics_sector':
      varA = (typeof a[sortCol] === 'string') ?
        a[sortCol].toUpperCase() : a[sortCol];
      varB = (typeof b[sortCol] === 'string') ?
        b[sortCol].toUpperCase() : b[sortCol];
      break;
    default:
      return 0;
  }
  let comparison = 0;
  if (varA > varB) {
    comparison = 1;
  } else if (varA < varB) {
    comparison = -1;
  }
  return (sortDir === 'DESC' ? (comparison * -1) : comparison);
};

const TRADING_RANGE_WIDTH_PCT = 20;
const TRADING_RANGE_MIN_WIDTH_PX = 120;

/**
 * Pass in a category object with property name (a string) and tickers (an array of ticker object)
 * @param {*} param0
 */
function BiggestMovers({
  tickers,
  sortCol,
  sortDir,
  handleSortChange,
  page,
  rowsPerPage,
  handlePageChange,
  paginated,
  date,
  handleCalendarChange,
}) {
  if (tickers) {
    const max = { price: null, volume: null };
    const min = { price: null, volume: null };
    // sort tickers
    tickers.sort(compFunc(sortCol, sortDir));
    tickers.forEach((ticker) => {
      Object.keys(max).forEach((key) => {
        if (max[key] === null || ticker[key] > max[key]) {
          max[key] = ticker[key];
        }
        if (min[key] === null || ticker[key] < min[key]) {
          min[key] = ticker[key];
        }
      });
    });

    // If it's paginated process the tickers
    let processedRows = tickers;
    let totalPages = 1;
    if (paginated) {
      processedRows = tickers.slice((page - 1) * rowsPerPage, page * rowsPerPage);
      totalPages = Math.ceil(tickers.length / rowsPerPage);
    }

    const headers = {
      TICKERS: {
        name: 'Tickers',
        priority: 1,
        colname: 'tckr',
        style: { width: '1%', maxWidth: '20px !important' },
      },
      NAME: {
        name: 'Name',
        priority: 2,
        colname: 'company',
      },
      SECTOR: {
        name: 'Sector',
        priority: 2,
        colname: 'gics_sector',
      },
      CUR_PRICE: {
        name: 'Price',
        priority: 2,
        colname: 'cur_price',
        style: { textAlign: 'center' },
      },
      PRICE: {
        name: 'Price',
        subname: 'Change (%)',
        priority: 1,
        colname: 'price',
        style: { 'border-bottom': 'none', 'text-align': 'center', 'vertical-align': 'middle' },
      },
      VOLUME: {
        name: 'Volume vs',
        subname: '50-DMA (%)',
        priority: 1,
        colname: 'volume',
        style: { 'border-bottom': 'none', 'text-align': 'center', 'vertical-align': 'middle' },
      },
      CUR_TRADING_RANGE: {
        name: 'Current',
        priority: 1,
        colname: 'cur_range',
        style: { textAlign: 'center' },
      },
    };
    const header = [
      <Table.Header key="pre-header" className="second-step">
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell style={{ 'border-left': 'none' }} colSpan="3" />
          {Object.values(headers).map((h) => {
            if (h.colname !== 'price' && h.colname !== 'volume') {
              return '';
            }
            let newDir = 'DESC';
            let sortArr = '';
            if (h.colname === sortCol && sortDir === 'DESC') {
              newDir = 'ASC';
            }
            if (h.colname === sortCol) {
              if (newDir === 'DESC') {
                sortArr = ' ↑';
              } else {
                sortArr = ' ↓';
              }
            }
            return (
              <Table.HeaderCell
                key={h.colname}
                style={{
                  width: h.width,
                  ...h.style,
                  cursor: 'pointer',
                  color: (sortCol === h.colname ? 'darkblue' : undefined),
                  whiteSpace: 'nowrap',
                }}
                onClick={(handleSortChange ? () => handleSortChange(h.colname, newDir) : undefined)}
                className={h.colname === 'price' ? 'third-step' : ''}
                rowSpan={2}
              >
                {h.name} <br /> {h.subname}{handleSortChange ? `${sortArr}` : ''}
              </Table.HeaderCell>);
          })}
          <Table.HeaderCell style={{ textAlign: 'center' }} colSpan="6">
            Trading Range
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row key="main-header">
          {Object.values(headers).map((h) => {
            if (h.colname === 'price' || h.colname === 'volume') {
              return '';
            }
            let newDir = 'DESC';
            let sortArr = '';
            if (h.colname === sortCol && sortDir === 'DESC') {
              newDir = 'ASC';
            }
            if (h.colname === sortCol) {
              if (newDir === 'DESC') {
                sortArr = ' ↑';
              } else {
                sortArr = ' ↓';
              }
            }
            return (
              <Table.HeaderCell
                key={h.colname}
                style={{
                  width: h.width,
                  ...h.style,
                  cursor: 'pointer',
                  color: (sortCol === h.colname ? 'darkblue' : undefined),
                  whiteSpace: 'nowrap',
                }}
                onClick={(handleSortChange ? () => handleSortChange(h.colname, newDir) : undefined)}
                className={h.name === 'Current' ? 'forth-step' : ''}
              >
                {handleSortChange ? `${h.name}${sortArr}` : h.name}
              </Table.HeaderCell>);
          })}
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="OS"
            colSpan="2"
          >
            OS
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="50"
            colSpan="2"
          >
            50-DMA
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: `${TRADING_RANGE_WIDTH_PCT / 3}%`,
              minWidth: `${TRADING_RANGE_MIN_WIDTH_PX / 3}px`,
            }}
            key="OB"
            colSpan="1"
          >
            OB
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>];
    const body = [];

    processedRows.forEach((ticker) => {
      const cells = [];
      if ('TICKERS' in headers) {
        cells.push((
          <Table.Cell>
            <Link to={`/ticker/${ticker.tckr}`}>{ticker.tckr}</Link>
          </Table.Cell>));
      }
      if ('NAME' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.company}</Table.Cell>);
      }
      if ('SECTOR' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%' }}>{ticker.gics_sector}</Table.Cell>);
      }
      if ('CUR_PRICE' in headers) {
        cells.push(<Table.Cell style={{ 'white-space': 'nowrap', 'max-width': '100%', textAlign: 'center' }}>{ticker.cur_price.toFixed(2)}</Table.Cell>);
      }
      const getStyle = (value, trend = 1, change = false) => ({
        backgroundColor: getColor(Colors.green, Colors.yellow, Colors.red, value, trend, change),
        color: 'white',
        fontWeight: 'normal',
        textAlign: 'center',
      });
      if ('PRICE' in headers) {
        let priceColorTrend = 1;
        if (ticker.price < 0) {
          priceColorTrend = -1;
        }

        cells.push((
          <Table.Cell
            style={
              getStyle(
                priceColorTrend * Math.abs(normalize(
                priceColorTrend < 0 ? 0 : max.price,
                priceColorTrend > 0 ? 0 : min.price,
                ticker.price,
                )),
                priceColorTrend,
              )
            }
          >
            {ticker.price.toFixed(2)}
          </Table.Cell>));
      }
      if ('VOLUME' in headers) {
        let volumeColorTrend = 1;
        if (ticker.volume < 0) {
          volumeColorTrend = -1;
        }

        cells.push((
          <Table.Cell
            style={
              getStyle(
                volumeColorTrend * Math.abs(normalize(
                volumeColorTrend < 0 ? 0 : max.volume,
                volumeColorTrend > 0 ? 0 : min.volume,
                ticker.volume,
                )),
                volumeColorTrend,
              )
            }
          >
            {ticker.volume.toFixed(2)}
          </Table.Cell>));
      }
      const sdToPercent = (sd) => {
        if (sd < -3) {
          return 0;
        } else if (sd > 3) {
          return 100;
        }
        return ((sd + 3) / 6) * 100;
      };
      const curRange = ticker.cur_range;
      const lastRange = ticker.last_range;
      if ('CUR_TRADING_RANGE' in headers) {
        if (curRange > 2) {
          cells.push((
            <Table.Cell style={{
              ...getStyle(-1, 1, true),
              textAlign: 'center',
              'white-space': 'nowrap',
              'max-width': '100%',
            }}
            >
              <b>Extreme OB</b>
            </Table.Cell>));
        } else if (curRange > 1) {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><RedText>Overbought</RedText></Table.Cell>));
        } else if (curRange < -2) {
          cells.push((<Table.Cell style={{ ...getStyle(1, 1, true), textAlign: 'center' }}><b>Extreme OS</b></Table.Cell>));
        } else if (curRange < -1) {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><GreenText>Oversold</GreenText></Table.Cell>));
        } else {
          cells.push((<Table.Cell style={{ textAlign: 'center' }}><YellowText>Neutral</YellowText></Table.Cell>));
        }
      }

      let style = {};
      let myClass = '';
      if (curRange > lastRange) {
        myClass = 'higher';
        style = {
          left: `${sdToPercent(lastRange).toFixed(2)}%`,
          width: `${(sdToPercent(curRange) - sdToPercent(lastRange)).toFixed(2)}%`,
          zIndex: 1,
        };
      }
      if (curRange < lastRange) {
        myClass = 'lower';
        style = {
          left: `${sdToPercent(curRange).toFixed(2)}%`,
          width: `${(sdToPercent(lastRange) - sdToPercent(curRange)).toFixed(2)}%`,
          zIndex: 1,
        };
      }
      cells.push((
        <Table.Cell
          colSpan="5"
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
            width: `${TRADING_RANGE_WIDTH_PCT}%`,
            minWidth: `${TRADING_RANGE_MIN_WIDTH_PX}px`,
            borderLeftColor: 'rgb(34,168,108)',
          }}
          className="traderange"
        >
          <div style={style} className={`line ${myClass}`} />
        </Table.Cell>));
      body.push((
        <Table.Row>
          {cells}
        </Table.Row>));
    });

    return (
      <div className="table-scroll" style={{ margin: '0px' }}>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column>
              {paginated && totalPages > 1 ?
                (<Pagination
                  activePage={page}
                  totalPages={totalPages}
                  size="tiny"
                  boundaryRange={0}
                  onPageChange={handlePageChange}
                />) :
                null}
            </Grid.Column>
            <Grid.Column className="right aligned column">
              <DateInput
                name="date"
                value={date}
                onChange={handleCalendarChange}
                dateFormat="YYYY-MM-DD"
                iconPosition="left"
                initialDate={date}
                size="mini"
                style={{ width: '150px' }}
                readOnly
                className="fifth-step"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table
          celled
          unstackable
          style={{ fontSize: '20px' }}
          className="left-fixed"
        >
          {header}
          <Table.Body>
            {body}
          </Table.Body>
        </Table>
        {paginated && totalPages > 1 ?
          (<Pagination
            activePage={page}
            totalPages={totalPages}
            size="tiny"
            boundaryRange={0}
            onPageChange={handlePageChange}
          />) :
          null}
      </div>
    );
  }
  return (
    <Dimmer active inverted>
      <Loader inverted>Loading</Loader>
    </Dimmer>
  );
}

BiggestMovers.defaultProps = {
  sortCol: 'volume',
  sortDir: 'DESC',
  handleSortChange: undefined,
  page: 1,
  rowsPerPage: 50,
  handlePageChange: undefined,
  paginated: false,
  date: moment().format('YYYY-MM-DD'),
};

BiggestMovers.propTypes = {
  tickers: PropTypes.array.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  handleSortChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleCalendarChange: PropTypes.func.isRequired,
  paginated: PropTypes.bool,
  date: PropTypes.string,
};

export default BiggestMovers;
