import React from 'react';
import PropTypes from 'prop-types';
import { scaleBand, scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import Axes from './Axes';
import Bars from './Bars';
import ResponsiveWrapper from './ResponsiveWrapper';

class BarChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.xScale = scaleBand();
    this.yScale = scaleLinear();
  }

  render() {
    const {
      data,
      margins,
      size,
      className,
      parentWidth,
      handleClick,
      colorMap,
      showValue,
      rotateX,
    } = this.props;
    let calcSize = size;

    if (parentWidth) {
      calcSize = {
        width: parentWidth,
        height: size.height,
      };
    }

    // scaleBand type
    const yExtent = extent(data, d => parseFloat(d.value));
    if (yExtent[0] > 0) {
      yExtent[0] = 0;
    }
    const xScale = this.xScale
      .padding(0.1)
      // scaleBand domain should be an array of specific values
      // in our case, we want to use movie titles
      .domain(data.map(d => d.label))
      .range([margins.left, calcSize.width - margins.right]);

    // scaleLinear type
    const yScale = this.yScale
      // scaleLinear domain required at least two values, min and max
      .domain(yExtent)
      .range([calcSize.height - margins.bottom, margins.top]);

    let svgHeight = calcSize.height;
    if (rotateX > 0) {
      svgHeight += 15;
    }

    return (
      <svg width={calcSize.width} height={svgHeight} className={className}>
        {/* Bars and Axis comes here */}
        <Axes
          scales={{ xScale, yScale }}
          margins={margins}
          svgDimensions={calcSize}
          rotateX={rotateX}
        />
        <Bars
          showValue={showValue}
          scales={{ xScale, yScale }}
          margins={margins}
          data={data}
          svgDimensions={calcSize}
          handleClick={handleClick}
          colorMap={colorMap}
        />
        <g className="Axis"><path d={`M${margins.left + 0.5},${yScale(Math.max(0, yExtent[0]))}H${calcSize.width - margins.right}`} /></g>
      </svg>
    );
  }
}

BarChart.defaultProps = {
  className: '',
  parentWidth: undefined,
  handleClick: undefined,
  colorMap: undefined,
  showValue: false,
  rotateX: 0,
};

BarChart.propTypes = {
  showValue: PropTypes.bool,
  colorMap: PropTypes.func,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  data: PropTypes.array.isRequired,
  margins: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    bottom: PropTypes.number,
  }).isRequired,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  parentWidth: PropTypes.number,
  rotateX: PropTypes.number,
};

export default ResponsiveWrapper(BarChart);
