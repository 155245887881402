import {
  SEARCH_START,
  SEARCH_RENDER_SUGGESTIONS,
  SEARCH_FOCUS_ON,
  SEARCH_FOCUS_OFF,
  SET_PAGE_TITLE,
  TOGGLE_SEARCH,
} from '../types/actions';
/**
 * Reducer for searchText property of Redux store
 * @param {object} state
 * @param {object} action
 */
export default (state = {
  text: '',
  suggestions: [],
  isLoading: false,
  inFocus: false,
  persistQuery: true, // if the result should be persisted when bar is selected.
}, action) => {
  switch (action.type) {
    case SEARCH_START:
      return { ...state, text: action.text, isLoading: true };
    case SEARCH_RENDER_SUGGESTIONS:
      return { ...state, suggestions: action.results, isLoading: false };
    case SEARCH_FOCUS_ON:// Persists search result in bar, but clears on select.
      if (!state.persistQuery) {
        return {
          ...state, text: '', persistQuery: true, inFocus: true,
        };
      }
      return { ...state, inFocus: true };
    case SET_PAGE_TITLE:
      return { ...state, text: action.title, persistQuery: false };
    case SEARCH_FOCUS_OFF:
      return { ...state, inFocus: false };
    case TOGGLE_SEARCH:
      return { ...state, toggleSearch: action.status };
    default:
      return state;
  }
};
