import React from 'react';
import PropTypes from 'prop-types';

class CurrentCoordinate extends React.PureComponent {
  render() {
    const {
      xPadding,
    } = this.props;
    return (
      <g
        className="currentCoordinate"
        x={xPadding}
        y={0}
        display="none"
        clipPath="url(#clip)"
      />
    );
  }
}

CurrentCoordinate.defaultProps = {
  xPadding: 0,
};

CurrentCoordinate.propTypes = {
  xPadding: PropTypes.number,
};

export default CurrentCoordinate;
