import React from 'react';

/**
 * Generates the static footer for the page.
 */
const Footer = () => (
  <footer id="footer" style={{ backgroundColor: '#c5d1d3', padding: '15px 0px' }}>
    <div className="ui container">
      <div id="footer-content" style={{ textAlign: 'center' }} className="ui stackable reversible grid">
        <div className="four wide column">©
          <span id="year">{new Date().getFullYear()}</span>
          {' Bespoke Investment Group'}
        </div>
        <div id="right-column" className="twelve wide column">
          <a href="https://www.bespokepremium.com/about/legal-policy/">Terms &amp; Privacy</a> <span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://www.bespokepremium.com/wealthmanagement/">Wealth Management</a> <span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://twitter.com/bespokeinvest">Follow us on Twitter</a><span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://www.bespokepremium.com/think-big-blog/">Read our Blog</a> <span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://www.bespokepremium.com/about-us#contactForm">Contact Us</a> <span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://media.bespokepremium.com/uploads/2025/03/Bespoke-Form-ADV-Part-2A-031025.pdf">Form ADV Part 2</a> <span className="middot">&nbsp; · &nbsp;</span>
          <a href="https://media.bespokepremium.com/uploads/2023/03/Bespoke-Form-CRS-03072023.pdf">Form CRS</a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
