import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import EditPortfolio from './editPortfolio';

class EditPortfolioModal extends React.PureComponent {
  state = { open: false };
  close = () => this.setState({ open: false });
  click = () => this.setState({ open: true });
  render() {
    const { open } = this.state;
    const { name, id } = this.props;

    return (
      <React.Fragment>
        {React.cloneElement(this.props.content, { onClick: () => this.click() })}
        <Modal basic onClose={this.close} open={open} {...this.props} closeIcon>
          <center>
            <h1 style={{ color: 'white' }}>Edit Portfolio Name</h1>
            <Modal.Actions>
              <EditPortfolio
                submitAction={() => { this.props.submitAction(this.props.id); this.close(); }}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                portfolioName={name}
                portfolioId={id}
              />
            </Modal.Actions>
          </center>
        </Modal>
      </React.Fragment>
    );
  }
}

EditPortfolioModal.defaultProps = {
  submitAction: () => null,
  content: (<Icon link name="edit" color="blue" />),
};

EditPortfolioModal.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  submitAction: PropTypes.func,
  content: PropTypes.element,
};

export default EditPortfolioModal;
