import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { navigateToPage } from '../../actions/search';
import { getSeasonality } from '../../actions/data/tools';
import SeasonalityGauges from '../presentational/interactive/seasonality/SeasonalityGauges';
import SeasonalityInput from '../presentational/interactive/seasonality/SeasonalityInput';
import SeasonalityChart from '../presentational/interactive/seasonality/SeasonalityChart';
import SeasonalityCompositeChart from '../presentational/interactive/seasonality/SeasonalityCompositeChartRedux';
import SeasonalityTable from '../presentational/interactive/seasonality/SeasonalityTable';
import UpgradeConnected from '../presentational/upgradeConnected';
import Touring from '../presentational/interactive/tour';

// @todo: Make menus, submenus, charts, and tables.
class SeasonalityDatabase extends React.PureComponent {
  componentDidMount() {
    // set URL if none given
    const { match } = this.props;
    if (!(match && match.params && match.params.category)) {
      this.props.navigateToPage('/seasonality-tool/equities');
    }
    // else {
    //   this.props.getSeasonality(match.params.category, {
    //     numYears: 10,
    //     dates: {
    //       smonth: 11,
    //       sday: 2,
    //       emonth: 11,
    //       eday: 9,
    //     },
    //   });
    // }
  }

  // componentDidUpdate(prevProps) {
  //   const { match } = this.props;
  //   if (prevProps.match !== match) {
  //     // Get the new data
  //     // @todo make the numYears + dates part of the state

  //     this.props.getSeasonality(match.params.category, {
  //       numYears: 10,
  //       dates: {
  //         smonth: 11,
  //         sday: 6,
  //         emonth: 11,
  //         eday: 13,
  //       },
  //     });
  //   }
  // }

  render() {
    const { match, dates } = this.props;

    const steps = [
      {
        selector: '.first-step',
        content: 'Our Stock Seasonality Tool lets users monitor seasonal trends for US equities and other asset classes throughout the calendar year.',
      },
      {
        selector: '.second-step',
        content: 'Our S&P 500 Seasonality gauges show the index\'s median one-week, one-month, and three-month return over the last ten years from the current date.',
      },
      {
        selector: '.third-step',
        content: 'The percentile numbers beneath the gauges show how bullish or bearish upcoming seasonals are for the S&P 500 relative to all other periods of the year.',
      },
      {
        selector: '.forth-step',
        content: 'For example, a reading of "100" for the "1 Week" period means the upcoming week of trading has been the absolute best one-week period of the calendar year over the last ten years.  The greener the gauge, the more bullish market seasonals are for the upcoming time frame.',
      },
      {
        selector: '.fifth-step',
        content: 'Change the "Start" and "End" dates to any time frame during the calendar year to find the best and worst performing securities during that period over the last ten years.',
      },
      {
        selector: '.sixth-step',
        content: 'The Seasonality chart shows the five best and worst performing securities over the last ten years during your selected time frame.',
      },
      {
        selector: '.seventh-step',
        content: 'The "SPY Annual Composite Chart" shows the average path that the S&P 500 has taken during the calendar year based on the last ten years of trading.',
      },
      {
        selector: '.eighth-step',
        content: 'The table at the bottom of the page shows the performance of all securities in our database during your selected time frame in each of the last ten years.  You can sort the list by clicking any column, and you can download the table to a CSV or Excel file by clicking the "Download Table" button.',
      },
      {
        selector: '.ninth-step',
        content: 'Our Seasonality tool is available to Bespoke Premium and Bespoke Institutional members. This tool is based on historical price moves and is NOT a recommendation to buy, sell, or hold a specific security.',
      },
    ];
    const tour = (
      <Touring
        cookieName="seasonalityDatabaseTouring"
        steps={steps}
      />);

    return (
      <React.Fragment>
        <UpgradeConnected tool whitelist={[3, 4]} />
        <h1 className="first-step">Seasonality Tool {tour}</h1>
        <SeasonalityGauges />
        {(match && match.params && match.params.category && dates) ? <SeasonalityInput category={match.params.category} /> : ''}
        <Grid
          stackable
          style={{ marginBottom: '0.5px' }}
        >
          <Grid.Row columns="2">
            <Grid.Column>
              <SeasonalityChart />
            </Grid.Column>
            <Grid.Column>
              <SeasonalityCompositeChart
                ticker="SPY"
                numYears={this.props.dates.numYears}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SeasonalityTable />
      </React.Fragment>
    );
  }
}

SeasonalityDatabase.propTypes = {
  // getSeasonality: PropTypes.func.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  dates: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  dates: state.data.seasonality.dates,
});

const mapDispatchToProps = dispatch => ({
  navigateToPage: url => dispatch(navigateToPage(url)),
  getSeasonality: (category, data) => dispatch(getSeasonality(category, data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeasonalityDatabase));
