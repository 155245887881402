import {
  RESEARCH_SET_POSTS,
  RESEARCH_SET_LOADING,
  RESEARCH_HAS_ERRORED,
  RESEARCH_INSERT_CACHE,
  RESEARCH_REMOVE_CACHE,
  RESEARCH_SET_ACCESSIBLE_CATEGORIES,
} from '../../types/actions';
import * as api from '../../api';

export const setPosts = (posts, totalPages) => ({
  type: RESEARCH_SET_POSTS,
  posts,
  totalPages,
});

export const setLoading = loading => ({
  type: RESEARCH_SET_LOADING,
  loading,
});

export const hasErrored = error => ({
  type: RESEARCH_HAS_ERRORED,
  error: {
    ...error,
    message: error.message,
    code: error.code,
  },
});

export const insertCache = ({ byId, allIds }) => ({
  type: RESEARCH_INSERT_CACHE,
  byId,
  allIds,
});

export const removeCache = ids => ({
  type: RESEARCH_REMOVE_CACHE,
  ids,
});

const checkCache = ({ results, totalPages }, getState, cacheExpTime) => {
  const partialResult = [];
  const needToFetch = [];
  const { byId, allIds } = getState().content.research.cache;
  for (let i = 0; i < results.length; i += 1) {
    if (allIds.indexOf(results[i]) !== -1) {
      // Is the cached result still valid?
      if ((Date.now() - cacheExpTime) > byId[results[i]].lastFetched) {
        // It's invalid! we gotta fetch it.
        needToFetch.push(results[i]);
      } else {
        // sparse array to keep ordering
        partialResult[i] = byId[results[i]];
      }
    } else {
      needToFetch.push(results[i]);
    }
  }
  // We've got things to fetch!
  if (needToFetch.length > 0) {
    return Promise.all([partialResult, api.research.getPostsByIds(needToFetch), totalPages]);
  }
  return [partialResult, null, totalPages];
};

const getRemainingPostsAndSet = (values, dispatch, setData) => {
  const [partialResult, fetchedVals, totalPages] = values;
  let fullResult = partialResult;
  if (fetchedVals) {
    // Format the values
    const dateFetched = Date.now();
    const formattedFetchedVals = {
      byId: {},
      allIds: [],
    };
    for (let i = 0; i < fetchedVals.length; i += 1) {
      formattedFetchedVals.allIds.push(fetchedVals[i].id);
      formattedFetchedVals.byId[fetchedVals[i].id] = {
        ...fetchedVals[i],
        lastFetched: dateFetched,
      };
    }
    // Add them to the cache
    dispatch(insertCache(formattedFetchedVals));
    // Add them to the results
    for (let i = 0; i < fullResult.length; i += 1) {
      if (fullResult[i] === undefined) {
        fullResult[i] = fetchedVals.shift();
      }
    }
    // If there are leftovers, concat them to the array
    if (fetchedVals.length > 0) {
      fullResult = [...fullResult, ...fetchedVals];
    }
  }
  // Set the state of the posts
  if (setData) {
    dispatch(setPosts(fullResult, totalPages));
  }
  return fullResult;
};

/**
 * getData action: gets post data but first checks cache.
 * @todo  starred date ordering.
 * @param  {[type]}   category     - category of post to fetch.
 * @param  {Number}   page         - page number to get.
 * @param  {Function} rpp          - results per page.
 * @param  {Number}   cacheExpTime - Time to keep the item cache good for in milliseconds.
 */
export const getData = (
  category,
  page = 1,
  rpp = 20,
  setData = true,
  cacheExpTime = 900000,
) => (dispatch, getState) => {
  if (setData) {
    dispatch(setLoading(true));
  }
  let promise;
  if (category === 'starred-reports') {
    const starred = getState().user.starred.byId;
    const starArr = Object.keys(starred).filter(x => starred[x]);
    promise = Promise.resolve({
      results: starArr,
      totalPages: 1,
    });
  } else {
    promise = api.research.getPosts(category, page, rpp, true);
  }
  return promise
    .then(results => checkCache(results, getState, cacheExpTime))
    .then(values => getRemainingPostsAndSet(values, dispatch, setData))
    .catch((err) => { dispatch(hasErrored(err)); throw err; })
    .finally(() => dispatch(setLoading(false)));
};

export const getSearchData = (
  query,
  page = 1,
  rpp = 20,
  setData = true,
  cacheExpTime = 900000,
) => (dispatch, getState) => {
  if (setData) {
    dispatch(setLoading(true));
  }
  // Special custom categories (Economics / Macro + Stock Ideas)
  return api.research.search(query, page, rpp, true)
    .then(results => checkCache(results, getState, cacheExpTime))
    .then(values => getRemainingPostsAndSet(values, dispatch, setData))
    .catch((err) => { dispatch(hasErrored(err)); throw err; })
    .finally(() => dispatch(setLoading(false)));
};

export const getSinglePost = (category, slug, cacheExpTime = 900000) => (dispatch, getState) => {
  // check if the post exists in the cache
  dispatch(setLoading(true));
  const posts = getState().content.research.cache.byId;
  const keys = Object.keys(posts);
  for (let i = 0; i < keys.length; i += 1) {
    const post = posts[keys[i]];
    if (post.post_slug === 'slug' && post.category_slug.includes(category) && (Date.now() - post.lastFetched) > cacheExpTime) {
      // We found it!
      dispatch(setLoading(false));
      return dispatch(setPosts([post], 1));
    }
  }
  // If it doesn't, fetch it + add to cache
  return api.research.getPostBySlug(category, slug)
    .then(([post]) => {
      const formattedPost = {
        byId: {
          [post.id]: {
            ...post,
            lastFetched: Date.now(),
          },
        },
        allIds: [post.id],
      };
      dispatch(setPosts([post], 1));
      dispatch(insertCache(formattedPost));
    })
    .catch((err) => { dispatch(hasErrored(err)); throw err; })
    .finally(() => dispatch(setLoading(false)));
};

export const setAccessibleCategories = () => dispatch => api.research.getAccessibleCategories()
  .then(categories => dispatch({
    type: RESEARCH_SET_ACCESSIBLE_CATEGORIES,
    categories,
  }));
