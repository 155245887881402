import React from 'react';
import PropTypes from 'prop-types';
import Colors from '../interactive/colors';

class MovingAverageTooltip extends React.PureComponent {
  render() {
    const {
      origin,
      color,
      text,
    } = this.props;
    return (
      <g
        transform={`translate(${origin[0]}, ${origin[1]})`}
        className={`movingAverageTooltip_${color.replace('#', '')}`}
      >
        <g transform="translate(0, 0)">
          <line
            x1={0}
            y1={1}
            x2={0}
            y2={14}
            stroke={color}
            strokeWidth={4}
          />
          <text
            fontSize={11}
            x={5}
            y={11}
            className="tooltip"
          >
            <tspan className="tooltip-label" fill={Colors.blue}>{text} <tspan className="text" fill="black" /></tspan>
          </text>
          <rect x="0" y="0" width="75" height="16" fill="none" stroke="none" />
        </g>
      </g>
    );
  }
}

MovingAverageTooltip.defaultProps = {
  origin: [0, 0],
};

MovingAverageTooltip.propTypes = {
  origin: PropTypes.array,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default MovingAverageTooltip;
