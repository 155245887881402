import { executeQuery, mainPath } from './query';

const categoryPath = `${mainPath}/api/categories`;

export const getAll = () => executeQuery(`${categoryPath}/`);

export const getMembers = categoryId => executeQuery(`${categoryPath}/${categoryId}`);

export function saveNewCategory(body) {
  return executeQuery(`${categoryPath}/new-category`, 'POST', { body });
}

export function editCategoryUpdate(idCategory, body) {
  return executeQuery(`${categoryPath}/edit-category/${idCategory}`, 'PUT', { body });
}

/* Types below */
/**
 * @typedef {Object} Category
 * @property {number} id - The unique category id.
 * @property {string} name - The category name.
 * @property {boolean} in_chartbook - Whether or not the category should
 *   appear in the Chart Scanner.
 *   - 1 - Shows up in Chart Scanner
 *   - 0 - Doesn't show up in Chart Scanner.
 * @property {boolean} in_trend_analyzer - Whether or not the category should
 *   appear in the Trend Analyzer.
 *   - 1 - Shows up in Trend Analyzer
 *   - 0 - Doesn't show up in Trend Analyzer
 * @property {boolean} is_etf_category - Whether or not the category is
 *   comprosed of ETFs.
 *   - 1 - Tickers in this category are all ETFs.
 *   - 0 - Tickers are not all ETFs.
 * @property {boolean} is_index - Whether or not the category is
 *   an index.
 *   - 1 - This category represents an index.
 *   - 0 - This category does not represent an index.
 */
