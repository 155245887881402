import {
  CARD_VIEW,
  SET_VIEW,
  SET_PAGE,
  CARDS_WITH_HEADER_VIEW,
  SET_VIEW_META,
  TOGGLE_MENU,
  CS_SET_DATA,
  CS_SET_LOADING,
  CS_HAS_ERRORED,
  EE_SET_ERROR,
  EE_SET_END_DATE,
  EE_SET_FILTER_DATE,
  EE_SET_ECAL_AGG_FILTER_DATE,
  EE_SET_CHART_DATA,
  EE_SET_CHART_DATA_LOADING,
  EE_SET_TABLE_DATA,
  EE_SET_TABLE_DATA_LOADING,
  EE_SET_SCREEN_DATA,
  EE_SET_SCREEN_DATA_LOADING,
  EE_SET_SORT,
  EE_SET_MODE,
  EE_SET_TICKER,
  EE_SET_TABLE_PAGE, MP_SET_ERROR, EE_SET_QUARTERLY_DATA, EE_SET_QUARTERLY_DATA_LOADING,
} from '../types/actions';
/**
 * Reducer for view property of Redux store
 * @param {object} state - takes a state slice
 * @param {object} action
 * @return {object} state - returns the new state
 */
export default (state = {
  type: 'cards',
  page: null,
  id: null,
  menuVisibile: false,
  meta: {},
  earningsExplorer: {
    mode: 'ecal',
    page: 1,
    rowsPerPage: 100,
    ticker: null,
    sortCol: 'reportdate',
    sortDir: 'ASC',
    chartData: [],
    chartDataLoading: false,
    tableData: [],
    tableDataLoading: false,
    screenData: [],
    screenDataLoading: false,
    quarterlyData: [],
    quarterlyDataLoading: false,
    endDate: 2592000000,
    filterDate: null,
    ecalAggFilterDate: (1000 * 60 * 60 * 24 * 365 * 5), // Initialize to 5 years
    error: null,
    benchmark: {
      eps_beat_avg: 0.63,
      eps_beat_std: 0.18375006,
      rev_beat_avg: 0.59,
      rev_beat_std: 0.19161126,
      reaction_avg: 0.06,
      reaction_std: 1.94837278,
      react_beat_avg: 0.50,
      guidance_avg: -0.0297,
    },
    // 8 Quarters
    // bespokeEarnings: {
    //   eps_beat: [0.620, 0.239],
    //   guidance: [-0.034, 0.412],
    //   pct_pos: [0.504, 0.181],
    //   beat_pct_chg: [1.991, 4.497],
    //   miss_pct_chg: [-2.887, 5.522],
    //   rev_beat: [0.565, 0.254],
    // },
    // 12 Quarters
    bespokeEarnings: {
      pct_pos: [0.505, 0.150],
      eps_beat: [0.625, 0.211],
      guidance: [-0.033, 0.391],
      rev_beat: [0.565, 0.226],
      miss_pct_chg: [-2.833, 5.052],
      beat_pct_chg: [1.975, 4.048],
    },
  },
  // Store historical data for chart scanner charts here.
  chartScanner: {
    title: '',
    sortCol: '',
    sortDir: '',
    isLoading: false,
    error: null,
    byId: {},
    allIds: [],
  },
}, action) => {
  switch (action.type) {
    case CARD_VIEW: // sets the view type to 'cards'
      return { ...state, type: 'cards', id: null };
    case SET_VIEW:
      return { ...state, type: 'full', id: action.target };
    case SET_PAGE:
      return { ...state, page: action.page };
    case CARDS_WITH_HEADER_VIEW:
      return {
        ...state, type: 'cardsWithHeader', id: null, meta: { ...state.meta, header: action.header },
      };
    case SET_VIEW_META:
      return { ...state, meta: action.data };
    case TOGGLE_MENU:
      return { ...state, menuVisible: !state.menuVisible };
    case CS_SET_DATA:
      return {
        ...state,
        chartScanner: {
          ...state.chartScanner,
          ...action.data,
        },
      };
    case CS_SET_LOADING:
      return {
        ...state,
        chartScanner: {
          ...state.chartScanner,
          loading: action.loading,
        },
      };
    case CS_HAS_ERRORED:
      return {
        ...state,
        chartScanner: {
          ...state.chartScanner,
          error: action.loading,
        },
      };
    case EE_SET_END_DATE:
      return { ...state, earningsExplorer: { ...state.earningsExplorer, endDate: action.date } };
    case EE_SET_FILTER_DATE:
      return { ...state, earningsExplorer: { ...state.earningsExplorer, filterDate: action.date } };
    case EE_SET_ECAL_AGG_FILTER_DATE:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          ecalAggFilterDate: action.date,
        },
      };
    case EE_SET_CHART_DATA:
      return { ...state, earningsExplorer: { ...state.earningsExplorer, chartData: action.data } };
    case EE_SET_CHART_DATA_LOADING:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          chartDataLoading: action.loading,
        },
      };
    case EE_SET_QUARTERLY_DATA:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          quarterlyData: action.data,
        },
      };
    case EE_SET_QUARTERLY_DATA_LOADING:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          quarterlyDataLoading: action.loading,
        },
      };
    case EE_SET_TABLE_DATA:
      return { ...state, earningsExplorer: { ...state.earningsExplorer, tableData: action.data } };
    case EE_SET_TABLE_DATA_LOADING:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          tableDataLoading: action.loading,
        },
      };
    case EE_SET_SCREEN_DATA:
      return { ...state, earningsExplorer: { ...state.earningsExplorer, screenData: action.data } };
    case EE_SET_SCREEN_DATA_LOADING:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          screenDataLoading: action.loading,
        },
      };
    case EE_SET_SORT:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          sortCol: action.sortCol,
          sortDir: action.sortDir,
        },
      };
    case EE_SET_MODE:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          mode: action.mode,
          sortCol: action.sortCol,
          sortDir: action.sortDir,
        },
      };
    case EE_SET_TICKER:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          ticker: action.ticker,
        },
      };
    case EE_SET_TABLE_PAGE:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          page: action.page,
        },
      };
    case EE_SET_ERROR:
      return {
        ...state,
        earningsExplorer: {
          ...state.earningsExplorer,
          error: action.error,
        },
      };
    case MP_SET_ERROR:
      return {
        ...state,
        admin: {
          ...state.admin,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
