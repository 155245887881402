import home from './home';
import ticker from './ticker';
import posts from './posts';
import portfolio from './portfolio';
import chart from './chart';
import chartScanner from './chartScanner';
import trendAnalyzer from './trendAnalyzer';
import morningLineup from './morningLineup';
import bespokeReport from './bespokeReport';
import economicMonitor from './economicMonitor';
import economicDatabase from './economicDatabase';
import internationalRevenues from './internationalRevenues';
import earningsCalendar from './earningsCalendar';
import earningsExplorer from './earningsExplorer';
import seasonality from './seasonalityDatabase';
import research from './research';
import tools from './tools';
import triplePlays from './tripleplays';
import error404 from './404';
import error from './Error';
import legal from './legal';
import account from './account';
import upgrade from './upgrade';
import consumerPulseInfo from './consumerPulseInfo';
import biggestMovers from './biggestMovers';
import bespokeCharts from './bespokeCharts';
import modelPortfolios from './admin/model_portfolios';
import admin from './admin/index';
import tickersManager from './admin/tickers_manager';
import newsletter from './newsletter';
import statoftheweek from './statoftheweek';
import dividendnewsletter from './dividendnewsletter';
import markets101newsletter from './markets101newsletter';

export const Home = home;
export const Tools = tools;
export const Ticker = ticker;
export const Posts = posts;
export const Portfolio = portfolio;
export const Chart = chart;
export const ChartScanner = chartScanner;
export const TrendAnalyzer = trendAnalyzer;
export const MorningLineup = morningLineup;
export const BespokeReport = bespokeReport;
export const EconomicMonitor = economicMonitor;
export const EconomicDatabase = economicDatabase;
export const InternationalRevenues = internationalRevenues;
export const EarningsCalendar = earningsCalendar;
export const EarningsExplorer = earningsExplorer;
export const Research = research;
export const Seasonality = seasonality;
export const TriplePlays = triplePlays;
export const Error404 = error404;
export const Error = error;
export const Legal = legal;
export const Account = account;
export const Upgrade = upgrade;
export const ConsumerPulseInfo = consumerPulseInfo;
export const BiggestMovers = biggestMovers;
export const BespokerCharts = bespokeCharts;
export const Admin = admin;
export const ModelPortfolios = modelPortfolios;
export const TickersManager = tickersManager;
export const Newsletter = newsletter;
export const DividendNewsletter = dividendnewsletter;
export const Markets101Newsletter = markets101newsletter;
export const StatOfTheWeek = statoftheweek;
