import { executeQuery, encodeArgs, mainPath } from './query';

const researchPath = `${mainPath}/api/research`;
/**
 * Search all posts and return a list of post objects.
 * @param  {string} query - The search query.
 * @param {number} [resultsPerPage=10] - The number of results to show per page. Defaults to 10.
 * @param {number} [page=1] - The result page to select.
 * @param  {boolean} [onlyIds]     - If true, the method will only return an array of post ID's.
 * @return {Post[]} - An array of Post objects.
 */
export const search = (query, page = 1, resultsPerPage = 10, onlyIds = false) => executeQuery(`${researchPath}/search/${encodeURIComponent(query)}/${page}?rpp=${resultsPerPage}&onlyids=${onlyIds}`);

/**
 * Gets an array of all available categories.
 * @return {Category[]}
 */
export const getCategories = () => executeQuery(`${researchPath}/categories`);

/**
 * Get an array of posts based on category and page number.
 * If both a start and end date are provided the getPosts function will only look
 * fo
 * @param  {String} category       - The category (can be any valid category slug
 *                                   or 'all' for all categories.
 * @param  {Number} page           - Page number to get.
 * @param  {Number} resultsPerPage - Results per page.
 * @param  {boolean} [onlyIds]     - If true, the method will only return an array of post ID's.
 * @return {Post[]}                - An array of Post objects.
 */
export const getPosts = (category = 'all', page = 1, resultsPerPage = 10, onlyIds = false) => executeQuery(`${researchPath}/posts/${category}/page/${page}?rpp=${resultsPerPage}&onlyids=${onlyIds}`);

export const getAccessibleCategories = () => executeQuery(`${researchPath}/categories/access`);

/**
 * Get a multiple Posts by their Id's.
 * @param  {number[]} postId - Array of post Id's
 * @return {Post[]} - The posts.
 */
export const getPostsByIds = (postIds) => {
  const queryStr = encodeArgs(postIds, 'ids');
  return executeQuery(`${researchPath}/posts?${queryStr}`);
};
/**
 * Get a single Post by it's category and slug.
 * @param {string} category - The post category.
 * @param {string} slug - The post slug.
 * @return {Post} - The post.
 */
export const getPostBySlug = (category, slug) => executeQuery(`${researchPath}/posts/${category}/${slug}`);

/**
 * Get the research components of the home page dashboard
 * @return {Post[]} - The most recent post in each research category.
 */
export const getDashboard = () => executeQuery(`${researchPath}/dashboard`);

/* Types Below */

/**
 * An object representation of a post.
 * @typedef {Object} Post
 * @property {number} id - The post ID.
 * @property {string} author - The post author.
 * @property {string} date_created - The date of post creation in YYYY-MM-DD format.
 * @property {string} date_modified - The date of last modification in YYYY-MM-DD format.
 * @property {string} title - The post title.
 * @property {string} content - The html post content.
 * @property {string} category_name - The post category name.
 * @property {string} category_slug - The post category slug.
 * @property {string} post_slug - The URL friendly post slug.
 */

/**
 * An object representation of a category.
 * @typedef {Object} Category
 * @property {number} id - The category id.
 * @property {string} name - The category name.
 * @property {string} slug - The category URL slug used in the API.
 */
