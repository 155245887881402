import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Message, Icon } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';

function AccountWarning({ access }) {
  if (access == null) {
    return null;
  }
  switch (access.status) {
    case 5:
      return (
        <center>
          <Message
            negative
            style={{
              marginBottom: 5,
              fontSize: '1.3em',
            }}
          >
            <Icon name="warning sign" />
            There is an issue with your Subscription and some features
            may be temporarily unavailable. Please
            update your billing details <a href="https://www.bespokepremium.com/my-account/?">here</a> to restore access.
          </Message>
        </center>);
    default: return null;
  }
}

AccountWarning.propTypes = {
  access: PropTypes.any.isRequired,
};

const mapStateToProps = state => ({ access: state.user.access });
const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(AccountWarning);
