/**
 * index.js
 */
/*
  For general React best practices, please read: https://medium.com/@TeeFouad/create-a-react-application-from-scratch-part-7-setting-up-react-and-best-practices-129b6d589056
*/
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import ReactGA from 'react-ga';

import reducer from './reducers';
import './css/semantic.css';
import * as Pages from './components/pages';
import MenuContainer from './components/pages/menuContainer';
import Initialize from './components/containers/Initialize';

// Preventing the interactive site to be iframed
if (window.self !== window.top) {
  window.top.location.href = window.location.href;
}

// console.log('My Portfolios: ');
// api.user.getPortfolios().then(x => console.log(x));
ReactGA.initialize('UA-132800247-1');
const history = createBrowserHistory({ basename: process.env.URL_PREFIX });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  connectRouter(history)(reducer),
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
);

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

if (module.hot) {
  module.hot.accept();
}

/**
 * Renders the application, also provides routing.
 * Initialize component initializes the store.
 */
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.Fragment>
        <Initialize />
        <Switch>
          {/* Iframeable Chart */}
          <Route path="/iframe/:ticker" render={props => <Pages.Chart iframe {...props} />} />
          <MenuContainer>
            <Pages.Error>
              <Switch>
                <Route exact path="/" render={() => <Pages.Home />} />
                {/* Interactive Tools */}
                <Route path="/tools" render={props => <Pages.Tools {...props} />} />
                {/* My Research */}
                <Route path="/research/:category/:query/:page" render={props => <Pages.Research {...props} />} />
                <Route path="/research/:category/:page" render={props => <Pages.Research {...props} />} />
                <Route path="/research/:category" render={props => <Pages.Research {...props} />} />
                <Route path="/research" render={props => <Pages.Research {...props} />} />
                {/* Ticker Page */}
                <Route path="/ticker/:ticker/:extra" render={props => <Pages.Ticker {...props} />} />
                <Route path="/ticker/:ticker" render={props => <Pages.Ticker {...props} />} />
                {/* Posts Page */}
                <Route path="/posts" render={() => <Pages.Posts />} />
                {/* Custom Portfolios */}
                <Route path="/portfolio/copy/:id" render={() => <Pages.Portfolio />} />
                <Route path="/portfolio/:id" render={() => <Pages.Portfolio />} />
                <Route path="/portfolio" render={() => <Pages.Portfolio />} />
                {/* Fullscreen Chart */}
                <Route path="/chart/:ticker" render={props => <Pages.Chart {...props} />} />
                {/* Chart Scanner */}
                <Route path="/chart-scanner/:category/:id" render={() => <Pages.ChartScanner />} />
                <Route path="/chart-scanner/:category" render={() => <Pages.ChartScanner />} />
                <Route path="/chart-scanner" render={() => <Pages.ChartScanner />} />
                {/* Trend Analyzer */}
                <Route path="/trend-analyzer/:category/:id" render={props => <Pages.TrendAnalyzer {...props} />} />
                <Route path="/trend-analyzer/:category" render={props => <Pages.TrendAnalyzer {...props} />} />
                <Route path="/trend-analyzer" render={props => <Pages.TrendAnalyzer {...props} />} />
                {/* Economic Monitor */}
                <Route path="/economic-monitors/:category" render={props => <Pages.EconomicMonitor {...props} />} />
                <Route path="/economic-monitors" render={props => <Pages.EconomicMonitor {...props} />} />
                {/* Economic Indicator Database */}
                <Route path="/economic-database/:indicator" render={props => <Pages.EconomicDatabase {...props} />} />
                <Route path="/economic-database" render={props => <Pages.EconomicDatabase {...props} />} />
                {/* Interational Revenues */}
                <Route path="/international-revenues/ticker/:ticker" render={props => <Pages.InternationalRevenues {...props} />} />
                <Route path="/international-revenues/:sector" render={props => <Pages.InternationalRevenues {...props} />} />
                <Route path="/international-revenues" render={props => <Pages.InternationalRevenues {...props} />} />
                {/* Earnings Calendar */}
                <Route path="/earnings-calendar/:ticker" render={props => <Pages.EarningsCalendar {...props} />} />
                <Route path="/earnings-calendar" render={props => <Pages.EarningsCalendar {...props} />} />
                {/* Earnings Explorer */}
                <Route path="/earnings-explorer/:tab/:ticker" render={props => <Pages.EarningsExplorer {...props} />} />
                <Route path="/earnings-explorer/:tab" render={props => <Pages.EarningsExplorer {...props} />} />
                <Route path="/earnings-explorer" render={props => <Pages.EarningsExplorer {...props} />} />
                {/* Seasonlity Tool */}
                <Route path="/seasonality-tool/:category" render={props => <Pages.Seasonality {...props} />} />
                <Route path="/seasonality-tool/" render={props => <Pages.Seasonality {...props} />} />
                {/* Triple Plays */}
                <Route path="/triple-plays" render={props => <Pages.TriplePlays {...props} />} />
                {/* Legal Policy */}
                <Route path="/legal" render={() => <Pages.Legal />} />
                {/* Misc / Not yet Implemented */}
                <Route path="/morning-lineup" render={() => <Pages.MorningLineup />} />
                <Route path="/bespoke-report" render={() => <Pages.BespokeReport />} />
                {/* My Account */}
                <Route path="/account" render={props => <Pages.Account {...props} />} />
                <Route path="/upgrade" render={props => <Pages.Upgrade {...props} />} />
                <Route path="/consumer-pulse-info" render={props => <Pages.ConsumerPulseInfo {...props} />} />
                <Route path="/biggest-movers" render={props => <Pages.BiggestMovers {...props} />} />
                <Route path="/bespoke-charts" render={props => <Pages.BespokerCharts {...props} />} />
                {/* Admin */}
                <Route path="/admin/bespoke-baskets" render={props => <Pages.ModelPortfolios {...props} />} />
                <Route path="/admin/tickers-manager" render={props => <Pages.TickersManager {...props} />} />
                <Route path="/admin" render={props => <Pages.Admin {...props} />} />
                <Route path="/newsletter" render={props => <Pages.Newsletter {...props} />} />
                <Route path="/dividendnewsletter" render={props => <Pages.DividendNewsletter {...props} />} />
                <Route path="/markets101newsletter" render={props => <Pages.Markets101Newsletter {...props} />} />
                <Route path="/statoftheweek" render={props => <Pages.StatOfTheWeek {...props} />} />
                <Route render={props => <Pages.Error404 {...props} />} />
              </Switch>
            </Pages.Error>
          </MenuContainer>
        </Switch>
      </React.Fragment>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app'),
);

// Log the initial state
// console.log(store.getState());
// Every time the state changes, log it
// Note that subscribe() returns a function for unregistering the listener
// store.subscribe(() =>
//  console.log(store.getState()));

